import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Input,
	FormGroup,
	Table,
	CardFooter,
	Button,
} from 'reactstrap';

import {
	Tabs,
	TabList,
	Tab,
	TabPanel,
} from 'react-tabs';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'moment/locale/pt-br';

import Utils from 'utils/Utils';

import Loader from 'react-loader-spinner';

import EventoModal from 'views/components/EventoModal';
import DiarioAlunoModal from 'views/components/DiarioAlunoModal';

import Alert from 'views/components/Alert';
import HorariosAulasFixo from 'views/components/HorariosAulasFixo';
import EscolaCardapio from 'views/components/EscolaCardapio';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import series from 'assets/csv/series.json';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import EntesService from 'services/EntesService';
import EventosService from 'services/EventosService';
import RegistroDiarioAlunoService from 'services/RegistroDiarioAlunoService';

import TipoEvento from 'assets/csv/tipos-evento.json';

class TurmaAluno extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.horariosAulas = [
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
			[null, null, null, null, null, null],
		];

		this.messages = {
			allDay: 'Dia Inteiro',
			previous: '<',
			next: '>',
			today: 'Hoje',
			month: 'Mês',
			week: 'Semana',
			day: 'Dia',
			agenda: 'Agenda',
			date: 'Data',
			time: 'Hora',
			event: 'Evento',
			showMore: (total) => `+ (${total}) Eventos`,
		}

		this.localizer = momentLocalizer(moment)

		this.loginService = new LoginService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.frequenciaService = new FrequenciaService();
		this.entesService = new EntesService();
		this.eventosService = new EventosService();
		this.registroDiarioAlunoService = new RegistroDiarioAlunoService();

		this.state = {
			showAlert: false,
			eventoSelecionado: {
				titulo: '',
			}
		};


	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {
			const matricula = await this.turmasService
				.carregarMatriculaPorId(this.props.match.params.idMatricula);

			const turma = await this.turmasService
				.carregarTurma(matricula.idTurma);

			const materias = await this.turmasService
				.carregarMateriasDaMatricula(this.props.match.params.idMatricula);

			const professores = await this.turmasService
				.carregarProfessores(turma.id);

			materias.forEach((materia) => {
				Object.assign(materia, {
					professores: professores.filter((professor) => professor.materia.id === materia.id)
						.map((professor) => professor.usuario.nome),
				});
			});

			const horarios = await this.turmasService
				.carregarHorariosAulas(turma.id);

			const boletim = await this.turmasService
				.carregarBoletim(this.props.match.params.idMatricula);


			horarios.forEach((hr) => {
				this.horariosAulas[hr.horario][hr.dia] = materias
					.some((m) => m.nome === hr.materia.nome) ? { materia: hr.materia } : null;
			});

			const faltas = await this.frequenciaService
				.carregarDiasFaltas(this.props.match.params.idMatricula);

			const dadosEnte = await this.entesService
				.carregarDadosEnte();

			const eventosDiario = await this.eventosService
				.carregarEventosDaTurma(turma.id);

			const eventosEscola = await this.eventosService
				.carregarEventosDaEscola(turma.anoLetivo.id, turma.escola.id);

			const eventosAnoLetivo = await this.eventosService
				.carregarEventosDoAnoLetivo(turma.anoLetivo.id);

			const eventos = [...eventosDiario, ...eventosEscola, ...eventosAnoLetivo];
			
			const data = await this.registroDiarioAlunoService
				.carregarTodosOsRegistrosDiarioAluno(this.props.match.params.idMatricula);

			const fotosDiariosPromises = data.registrosDiarioAluno.map((registroDiarioAluno) => new Promise(async (resolve) => {
				const fotos = await this.registroDiarioAlunoService
					.carregarRegistroDiarioAlunoFotos(registroDiarioAluno.id);
				resolve(fotos);
			}));

			const fotosDiarios = await Promise.all(fotosDiariosPromises);

			const imagens = fotosDiarios.map((fotosDiario) => fotosDiario.map((foto) => ({
				...foto,
				src: `${foto.url}?token=${sessionStorage.getItem('auth-token')}`
			})));

			data.registrosDiarioAluno.forEach((registroDiarioAluno, idx) => {
				Object.assign(registroDiarioAluno, { imagens: imagens[idx] });
			});

			const calendar = [];

			eventos.forEach((evento) => calendar.push({
				title: evento.titulo,
				allDay: evento.diaInteiro,
				start: moment(evento.inicio, 'DD/MM/YYYY HH:mm:ss').toDate(),
				end: moment(evento.fim, 'DD/MM/YYYY HH:mm:ss').toDate(),
				hexColor: Utils.getCorEvento(evento.tipo),

				id: evento.id,
				titulo: evento.titulo || '',
				tipo: evento.tipo || evento.tipo === 0 ? evento.tipo : '',
				descricao: evento.descricao || '',

			}));

			data.registrosDiarioAluno.forEach((registroDiarioAluno) => calendar.push({
				title: 'Diário do aluno',
				allDay: true,
				start: moment(registroDiarioAluno.data, 'DD/MM/YYYY HH:mm:ss').toDate(),
				end: moment(registroDiarioAluno.data, 'DD/MM/YYYY HH:mm:ss').toDate(),
				hexColor: Utils.getCorEvento(TipoEvento.DIARIO_ALUNO.value),

				id: registroDiarioAluno.id,
				titulo: 'Diário do aluno',
				tipo: TipoEvento.DIARIO_ALUNO.value,
				descricao: registroDiarioAluno.informacoes || '',
				imagens: registroDiarioAluno.imagens,
			}));

			this.setState({
				turma,
				materias,
				boletim,
				faltas,
				dadosEnte,
				eventos: calendar,
			});
		} catch (e) {
			this.setState({
				erro: true,
			});
		}
	}

	abrirTurmaVirtual(materia) {
		this.props.history.push(`${this.props.layout}`
			+ `/turmas/${this.props.match.params.idMatricula}`
			+ `/turma/${this.state.turma.id}`
			+ `/materia/${materia.id}`
			+ '/turmavirtual');
	}

	abrirModalEvento(evento) {
		if (evento.tipo !== TipoEvento.DIARIO_ALUNO.value) {
			const eventoSelecionado = {
				id: evento.id,
				titulo: evento.titulo || '',
				tipo: evento.tipo || evento.tipo === 0 ? evento.tipo : '',
				descricao: evento.descricao || '',
				diaInteiro: evento.diaInteiro || true,
				inicio: evento.start || new Date(),
				fim: evento.end || new Date(),
				materia: evento.materia,
			};

			this.setState({
				showEventoModal: true,
				eventoSelecionado,
			});
		} else {
			const eventoSelecionado = {
				id: evento.id,
				titulo: evento.titulo || '',
				tipo: evento.tipo || evento.tipo === 0 ? evento.tipo : '',
				descricao: evento.descricao || '',
				diaInteiro: evento.diaInteiro || true,
				inicio: evento.start || new Date(),
				fim: evento.end || new Date(),
				materia: evento.materia,
				imagens: evento.imagens,
			};

			this.setState({
				showDiarioAlunoModal: true,
				eventoSelecionado,
			});
		}

	}

	eventStyleGetter(event, start, end, isSelected) {
		var backgroundColor = event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			color: 'black',
		};
		return {
			style: style
		};
	}

	conteudoPagina() {
		return this.state.turma ? <Row>
			<Col md="12">
				<Alert
					color={this.state.alertColor}
					isOpen={this.state.showAlert}
					toggle={() => { this.setState({ showAlert: false }); }}>
					{this.state.alertMsg}
				</Alert>
				<Breadcrumb>
					<BreadcrumbItem><Link to={`${this.props.layout}/turmas`}>Turmas</Link></BreadcrumbItem>
					<BreadcrumbItem active>{series[this.state.turma.curso.serie].label}</BreadcrumbItem>
				</Breadcrumb>
				<Card>
					<CardHeader>
						<CardTitle tag="h4">Turma</CardTitle>
					</CardHeader>
					<CardBody>
						<Row>
							<Col md="2">
								<FormGroup className="has-label">
									<label>Ano</label>
									<Input
										disabled
										type="text"
										value={this.state.turma.anoLetivo.ano} />
								</FormGroup>
							</Col>
							<Col md="4">
								<FormGroup className="has-label">
									<label>Escola</label>
									<Input
										disabled
										type="text"
										value={this.state.turma.escola.nome} />
								</FormGroup>
							</Col>
							<Col md="4">
								<FormGroup className="has-label">
									<label>Série</label>
									<Input
										disabled
										type="text"
										value={series[this.state.turma.curso.serie].label} />
								</FormGroup>
							</Col>
							<Col md="2">
								<FormGroup className="has-label">
									<label>Turno</label>
									<Input
										disabled
										type="text"
										value={this.turnos[this.state.turma.turno].label} />
								</FormGroup>
							</Col>
						</Row>
						<br />
						<Row>
							<Col md="12">
								<Tabs>
									<TabList>
										<Tab>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
											? 'Campos de experiências' : 'Componentes curriculares'}</Tab>
										{
											this.state.dadosEnte.turmaVirtual
											&& this.props.role === Papel.ALUNO.value
											&& <Tab>Turmas virtuais</Tab>
										}
										<Tab>Horários</Tab>
										<Tab>Boletim</Tab>
										<Tab>Ausências</Tab>
										<Tab>Cardápio</Tab>
										<Tab>Agenda</Tab>
									</TabList>
									<TabPanel>
										<Row>
											<Col md="12">
												<Table hover>
													<thead className="text-primary">
														<tr>
															<th style={{ width: '30%' }}>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
																? 'Campos de experiências' : 'Componentes curriculares'}</th>
															<th style={{ width: '70%' }}>Professores</th>
														</tr>
													</thead>
													<tbody>
														{
															this.state.materias.map((materia, index) => (
																<tr key={index}>
																	<td>{materia.nome}</td>
																	<td>{materia.professores.toString().replace(',', ', ')}</td>
																</tr>
															))
														}
													</tbody>
												</Table>
											</Col>
										</Row>
									</TabPanel>
									{
										this.state.dadosEnte.turmaVirtual
										&& this.props.role === Papel.ALUNO.value
										&& <TabPanel>
											<Row>
												<Col md="12">
													<Table hover>
														<thead className="text-primary">
															<tr>
																<th style={{ width: '80%' }}>Diário</th>
																<th style={{ width: '20%' }}></th>
															</tr>
														</thead>
														<tbody>

															{
																this.state.materias.map((materia, index) => <tr key={index}>
																	<td>{materia.nome}</td>
																	<td className="text-right">
																		<Button color="default" onClick={() => this.abrirTurmaVirtual(materia)}>
																			Turma virtual
																		</Button>
																	</td>
																</tr>)
															}
														</tbody>
													</Table>
												</Col>
											</Row>
										</TabPanel>
									}
									<TabPanel>
										<HorariosAulasFixo
											turno={this.state.turma.turno}
											materias={this.state.turma.estruturaCurricular.materias}
											horariosAulas={this.horariosAulas}
										/>
									</TabPanel>
									<TabPanel>
										<Row>
											<Col md="12">
												{
													this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value
														? <Table className="tboletim">
															<thead>
																<tr>
																	{
																		this.state.boletim[0].map((cell, index) => (
																			<th style={{ width: `${100 / this.state.boletim[0].length}%` }} key={index}>{cell}</th>
																		))
																	}
																</tr>
															</thead>
															<tbody>
																{
																	this.state.boletim.slice(1).map((linha, index) => (
																		<tr key={index}>
																			{
																				linha.map((cell, index2) => (
																					<td key={index2}>{cell}</td>
																				))
																			}
																		</tr>
																	))
																}
															</tbody>
														</Table>
														: <>
															{
																this.state.boletim.map((relatorio, idx) => (
																	<Table key={idx} className="tboletim">
																		<thead>
																			<tr><th>{relatorio[0]}</th></tr>
																		</thead>
																		<tbody>
																			<tr><td style={{ textAlign: 'justify' }}><pre>{relatorio[1]}</pre></td></tr>
																		</tbody>
																	</Table>
																))
															}
														</>
												}
											</Col>
										</Row>
									</TabPanel>
									<TabPanel>
										{
											Object.keys(this.state.faltas).length ?
												<Table className="tboletim">
													<thead>
														<tr>
															<th style={{ width: '20%' }}>Diário</th>
															<th style={{ width: '70%' }}>Dias ausentes</th>
															<th style={{ width: '10%' }}>Total faltas</th>
														</tr>
													</thead>
													<tbody>
														{
															Object.keys(this.state.faltas).map((diario, idx) => <tr key={idx}>
																<td>{diario}</td>
																<td>{this.state.faltas[diario].toString()}</td>
																<td>{this.state.faltas[diario].length}</td>
															</tr>)
														}
													</tbody>
												</Table> : <div style={{ padding: '40px', textAlign: 'center' }}>Nenhuma falta registrada</div>
										}
									</TabPanel>
									<TabPanel>
										<br />
										<EscolaCardapio
											{...this.props}
											idEscola={this.state.turma.escola.id}
											callbackMessage={(msg) => this.setState(msg)}
										/>
									</TabPanel>
									<TabPanel>
										<br />
										<Calendar
											selectable
											localizer={this.localizer}
											events={this.state.eventos}
											startAccessor="start"
											endAccessor="end"
											style={{ height: 800 }}
											onSelectEvent={(event) => this.abrirModalEvento(event)}
											eventPropGetter={(this.eventStyleGetter)}
											messages={this.messages}
										/>
									</TabPanel>
								</Tabs>
							</Col>
						</Row>
					</CardBody>
					<CardFooter>
						<Row>
							<Col>
								<Button color="primary" onClick={() => this.props.history.push(`${this.props.layout}/turmas`)} >
									Voltar
								</Button>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Row> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#053d7c"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<EventoModal
					onlyRead
					isOpen={this.state.showEventoModal}
					evento={this.state.eventoSelecionado} />
				<DiarioAlunoModal
					onlyRead
					isOpen={this.state.showDiarioAlunoModal}
					evento={this.state.eventoSelecionado}
				/>
			</div>
		);
	}
}

export default withRouter(TurmaAluno);
