import React from 'react';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';

import TipoFuncionario from 'assets/csv/tipos-funcionario.json';

class EscolaFuncionarios extends React.Component {
  constructor(props) {
    super(props);

    this.tiposFuncionario = [
      TipoFuncionario.AUXILIAR_ADMINISTRATIVO,
      TipoFuncionario.SECRETARIO_ESCOLAR,
      TipoFuncionario.AUXILIAR_SERVICOS_GERAIS,
      TipoFuncionario.INSPETOR_ESCOLAR,
      TipoFuncionario.PORTEIRO,
      TipoFuncionario.MERENDEIRO,
      TipoFuncionario.SUPORTE_PEDAGOGICO,
      TipoFuncionario.AUXILIAR_MERENDEIRA,
      TipoFuncionario.CUIDADORA,
      TipoFuncionario.VICE_DIRETOR,
      TipoFuncionario.PROFESSOR_SALA_DE_LEITURA,
      TipoFuncionario.DIRETOR_ADMINISTRATIVO_FINANCEIRO,
      TipoFuncionario.DIRETOR_PEDAGOGICO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-escola-funcionarios-${this.props.match.params.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.escolasService = new EscolasService();

    this.state = {
      dadosTabela: {
        header: [['Nome', '60%'], ['Função', '20%']],
        columnAlign: ['text-left', 'text-center'],
        keys: ['nome', 'funcao'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.carregarFuncionarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarFuncionarios(pagina, linhasPorPagina, like) {
    try {
      const data = await this.escolasService
        .carregarFuncionarios(this.props.match.params.idEscola,
          pagina, linhasPorPagina, like);

      data.funcionarios.forEach((funcionario) => Object.assign(
        funcionario, { funcao: this.tiposFuncionario[funcionario.tipo].label },
      ));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.funcionarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar funcionários da escola',
      });
    }
  }

  editarFuncionario(funcionario) {
    this.props.history.push({
      pathname: `${this.props.layout}/escolas/${this.props.match.params.idEscola}/funcionario/edicao/${funcionario.id}`,
    });
  }

  confirmarRemocaoFuncionario(funcionario) {
    this.setState({ funcionarioSelecionado: funcionario, showConfirmModal: true });
  }

  async removerFuncionario() {
    try {
      this.setState({ showAlert: false, showLoaderModal: true });

      await this.escolasService
        .removerFuncionario(this.state.funcionarioSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Funcionário removido com sucesso',
      });

      this.carregarFuncionarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao remover item',
      });
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          clickRows
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disablePrintAction
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarFuncionarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarFuncionarios(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          editButtonCallback={(funcionario) => this.editarFuncionario(funcionario)}
          removeButtonCallback={(funcionario) => this.confirmarRemocaoFuncionario(funcionario)}
          rowCallback={(funcionario) => this.props.history.push({ pathname: `${this.props.layout}/escolas/${this.props.match.params.idEscola}/funcionario/${funcionario.id}` })}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarFuncionarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          data={this.state.dadosTabela} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerFuncionario();
            } else {
              this.setState({ funcionarioSelecionado: null });
            }
          }}
          text='Confirme a exclusão do funcionário' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

export default EscolaFuncionarios;
