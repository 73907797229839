import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Route, Routes, Navigate } from 'react-router-dom';

class RootLayout extends React.Component {
	getRoutes = () => {
		return this.props.routes.map((route, key) => {
			return (
				<Route exact path={route.path} element={<route.component layout={route.layout} />} key={key} />
			);
		});
	};

  render() {
    return (
      <Routes>
        {this.getRoutes()}
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Routes>
    );
  }
}

export default withRouter(RootLayout);
