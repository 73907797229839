import React from 'react';

import AdaptiveTable from 'views/components/AdaptiveTable';

import DiariosService from 'services/DiariosService';

import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class Diarios extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-diarios-professor`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarDiariosDoProfessor = this.carregarDiariosDoProfessor.bind(this);

    this.diariosService = new DiariosService();

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      showAlert: false,

      showLoaderModal: false,

      anoLetivoSelecionado: props.anoLetivoSelecionado,

      dadosTabela: {
        header: [['Nome', '15%'], ['Turma', '15%'], ['Escola', '20%'], [curso, '20%'], ['Situação', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['nome', 'nomeTurma', 'escola', 'curso', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarDiariosDoProfessor = this.carregarDiariosDoProfessor.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.anoLetivoSelecionado !== nextProps.anoLetivoSelecionado) {
      this.setState({
        anoLetivoSelecionado: nextProps.anoLetivoSelecionado,
      });
      this.carregarDiariosDoProfessor(nextProps.anoLetivoSelecionado,
        this.paginaSelecionada, this.linhasPorPagina, this.like);
    }
  }

  async componentDidMount() {
    await this.carregarDiariosDoProfessor(this.state.anoLetivoSelecionado,
      this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarDiariosDoProfessor(anoLetivo, pagina, linhasPorPagina, like) {
    try {
      const data = await this.diariosService
        .carregarDiariosDoProfessorPorAnoLetivo(anoLetivo.value, pagina, linhasPorPagina, like);

      data.diarios.forEach((diario) => {
        Object.assign(diario, { situacao_str: this.situacoes[diario.situacao].label });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.diarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar diários do professor',
      });
    }
  }

  diarioSelecionado(diario) {
    if (diario.tipoAvaliacao === TipoAvaliacao.RELATORIO.value) {
      const address = `/professor/anosletivos/${this.state.anoLetivoSelecionado.value}`
        + `/escola/${diario.idEscola}/turma/${diario.idTurma}/diario/${diario.id}/alfabetizacao`;
      this.props.history.push(address);
    } else {
      const address = `/professor/anosletivos/${this.state.anoLetivoSelecionado.value}`
        + `/escola/${diario.idEscola}/turma/${diario.idTurma}/diario/${diario.id}`;
      this.props.history.push(address);
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          clickRows
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarDiariosDoProfessor(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarDiariosDoProfessor(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarDiariosDoProfessor(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(diario) => this.diarioSelecionado(diario)}
          data={this.state.dadosTabela} />
      </>
    );
  }
}

export default Diarios;
