import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';


class CadastroEstoque extends React.Component {
  constructor(props) {
    super(props);

    this.estoquesService = new EstoquesService();

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      nome: '',
      nomeState: '',
      loaderModalText: '',
      
      estoqueSelecionado: null,
      erro: null,
    };

    this.cadastrarEstoque = this.cadastrarEstoque.bind(this);
    this.atualizarEstoque = this.atualizarEstoque.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.match.params.idEstoque !== undefined) {
        const estoque = await this.estoquesService
          .carregarEstoque(this.props.match.params.idEstoque);

        this.setState({ estoqueSelecionado: estoque }, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.estoqueSelecionado.nome,
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarEstoque() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando estoque...',
    });
    
    const estoque = {
      nome: this.state.nome,
    };

    try {
      await this.estoquesService.cadastrarEstoque(estoque);
      
      this.limparFormulario();
      
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estoque cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar estoque',
      });
    }
  }

  async atualizarEstoque() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando estoque...',
    });

    const estoque = {
      id: this.state.estoqueSelecionado.id,
      nome: this.state.nome,
    };

    try {
      await this.estoquesService.atualizarEstoque(estoque);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estoque atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar estoque',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      nomeState: '',
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.estoqueSelecionado) {
      this.cadastrarEstoque();
    } else {
      this.atualizarEstoque();
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idEstoque !== undefined
      && this.state.estoqueSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/estoques">Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueSelecionado ? this.state.estoqueSelecionado.nome : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do estoque</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        value={this.state.nome}
                        name="nomeEstoque"
                        type="text"
                        maxLength="100"
                        onChange={(e) => this.setState({ nome: e.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do estoque.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" onClick={() => this.props.history.push('/gestor/estoques')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.estoqueSelecionado
                        ? <Button type="submit" color="primary" >
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary">
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroEstoque);
