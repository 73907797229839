import React from 'react';

import {
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
  Button,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import moment from 'moment';

import PhotoGallery from 'views/components/PhotoGallery';

import TipoEvento from 'assets/csv/tipos-evento.json';

class DiarioAlunoModal extends React.Component {
  constructor(props) {
    super(props);

    this.tiposEvento = [
      TipoEvento.AVISO,
      TipoEvento.ATIVIDADE,
      TipoEvento.PROVA,
      TipoEvento.CONTEUDO,
      TipoEvento.DIARIO_ALUNO,
    ]

    this.state = {
      isOpen: props.isOpen,
      id: props.evento.id,
      titulo: props.evento.titulo || '',
      tipoEventoSelecionado: this.props.evento.tipo ? this.tiposEvento[this.props.evento.tipo] : '',
      descricao: props.evento.descricao || '',
      diaInteiro: this.props.evento.diaInteiro || true,
      inicio: props.evento.inicio,
      fim: props.evento.fim,
      data: moment(props.evento.inicio).format('DD/MM/YYYY'),
      imagens: props.evento.imagens,
    };
  }

  componentWillReceiveProps(nextProps) {
    const data = moment(nextProps.evento.inicio).format('DD/MM/YYYY');

    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.id !== nextProps.evento.id) {
      this.setState({ id: nextProps.evento.id });
    }
    if (this.state.titulo !== nextProps.evento.titulo) {
      this.setState({ titulo: nextProps.evento.titulo });
    }
    if (this.state.titulo !== nextProps.evento.tipo) {
      this.setState({ tipoEventoSelecionado: this.tiposEvento[nextProps.evento.tipo] });
    }
    if (this.state.descricao !== nextProps.evento.descricao) {
      this.setState({ descricao: nextProps.evento.descricao });
    }
    if (this.state.diaInteiro !== nextProps.evento.diaInteiro) {
      this.setState({ diaInteiro: nextProps.evento.diaInteiro });
    }
    if (this.state.inicio !== nextProps.evento.inicio) {
      this.setState({ inicio: nextProps.evento.inicio });
    }
    if (this.state.fim !== nextProps.evento.fim) {
      this.setState({ fim: nextProps.evento.fim });
    }
    if (this.state.data !== data) {
      this.setState({ data });
    }
    if (this.state.imagens !== nextProps.evento.imagens) {
      this.setState({ imagens: nextProps.evento.imagens });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.titulo) {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }

    if (!this.state.tipoEventoSelecionado) {
      this.setState({ tipoEventoState: 'danger' });
      ret = false;
    }

    if (!this.state.descricao) {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  cadastrar() {
    if (!this.validarCampos()) return;

    this.limparCampos();

    this.props.callbackCadastrar({
      id: this.state.id,
      titulo: this.state.titulo,
      tipo: this.state.tipoEventoSelecionado.value,
      diaInteiro: this.state.diaInteiro,
      inicio: moment(this.state.inicio).format('DD/MM/YYYY HH:mm:ss'),
      fim: moment(this.state.fim).format('DD/MM/YYYY HH:mm:ss'),
      descricao: this.state.descricao,
    });
  }

  excluir() {
    this.props.callbackExcluir(this.state.id);
  }

  limparCampos() {
    this.setState({
      titulo: '',
      tipoEventoSelecionado: '',
      descricao: '',

      tituloState: '',
      tipoEventoState: '',
      descricaoState: '',
    })
  }

  render() {
    return (
      <Modal size="xl"
        isOpen={this.state.isOpen}>
        <ModalBody>
          <h4>Diário do aluno - {this.state.data}</h4>
          <Row>
            <Col md="12">
              <FormGroup className={`has-label`}>
                <label>Título *</label>
                <Input
                  disabled
                  value={this.state.titulo}
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Tabs>
            <TabList>
              <Tab>Infomações</Tab>
              <Tab>Imagens</Tab>
            </TabList>
            <TabPanel>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.state.descricaoState}`}>
                    <Input
                      disabled={this.props.onlyRead}
                      value={this.state.descricao}
                      onChange={(evt) => this.setState({
                        descricao: evt.target.value,
                        descricaoState: 'has-success',
                      })}
                      type="textarea"
                      style={{ height: '200px' }} />
                  </FormGroup>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row>
                <Col>
                  <PhotoGallery
                    images={this.state.imagens} />
                </Col>
              </Row>
            </TabPanel>
          </Tabs>
          <Row>
            <Col className="text-right">
              <Button
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.limparCampos();
                  this.setState({ isOpen: false })
                }}>
                {this.props.onlyRead ? 'Fechar' : 'Cancelar'}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal >
    );
  }
}

export default DiarioAlunoModal;
