import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	Form,
	FormGroup,
	Input,
	Row,
	Table,
	UncontrolledTooltip
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Select from 'react-select';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import CursosService from 'services/CursosService';
import EntesService from 'services/EntesService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';
import LoginService from 'services/LoginService';
import MateriasService from 'services/MateriasService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Serie from 'assets/csv/series2.json';
import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';

import getCompetenciasEspecificasHabiliadesCreche1 from 'utils/getCompetenciasEspecificasHabiliadesCreche1';
import getCompetenciasEspecificasHabiliadesCreche2 from 'utils/getCompetenciasEspecificasHabiliadesCreche2';
import getCompetenciasEspecificasHabiliadesCreche3 from 'utils/getCompetenciasEspecificasHabiliadesCreche3';
import getCompetenciasEspecificasHabiliadesPre1 from 'utils/getCompetenciasEspecificasHabiliadesPre1';
import getCompetenciasEspecificasHabiliadesPre2 from 'utils/getCompetenciasEspecificasHabiliadesPre2';

import getCompetenciasEspecificasHabiliadesAno1 from 'utils/getCompetenciasEspecificasHabiliadesAno1';
import getCompetenciasEspecificasHabiliadesAno2 from 'utils/getCompetenciasEspecificasHabiliadesAno2';
import getCompetenciasEspecificasHabiliadesAno3 from 'utils/getCompetenciasEspecificasHabiliadesAno3';
import getCompetenciasEspecificasHabiliadesAno4 from 'utils/getCompetenciasEspecificasHabiliadesAno4';
import getCompetenciasEspecificasHabiliadesAno5 from 'utils/getCompetenciasEspecificasHabiliadesAno5';
import getCompetenciasEspecificasHabiliadesAno6 from 'utils/getCompetenciasEspecificasHabiliadesAno6';
import getCompetenciasEspecificasHabiliadesAno7 from 'utils/getCompetenciasEspecificasHabiliadesAno7';
import getCompetenciasEspecificasHabiliadesAno8 from 'utils/getCompetenciasEspecificasHabiliadesAno8';
import getCompetenciasEspecificasHabiliadesAno9 from 'utils/getCompetenciasEspecificasHabiliadesAno9';

class Competencias extends React.Component {
	constructor(props) {
		super(props);

		this.ciclosEnsino = [
			CicloEnsino.ENSINO_INFANTIL,
			CicloEnsino.ENSINO_FUNDAMENTAL_1,
			CicloEnsino.ENSINO_FUNDAMENTAL_2,
			CicloEnsino.ENSINO_MEDIO,
			CicloEnsino.ENSINO_TECNICO,
			CicloEnsino.EJA,
			CicloEnsino.OUTRO,
			CicloEnsino.CORRECAO_FLUXO,
		];

		this.situacoes = [
			SituacaoEstruturaCurricular.ATIVA,
			SituacaoEstruturaCurricular.INATIVA,
		];

		this.getDadosPadraoInfantil = [
			undefined,
			getCompetenciasEspecificasHabiliadesCreche1,
			getCompetenciasEspecificasHabiliadesCreche2,
			getCompetenciasEspecificasHabiliadesCreche3,
			getCompetenciasEspecificasHabiliadesPre1,
			getCompetenciasEspecificasHabiliadesPre2,
		]

		this.getDadosPadrao = [
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			getCompetenciasEspecificasHabiliadesAno1,
			getCompetenciasEspecificasHabiliadesAno2,
			getCompetenciasEspecificasHabiliadesAno3,
			getCompetenciasEspecificasHabiliadesAno4,
			getCompetenciasEspecificasHabiliadesAno5,
			getCompetenciasEspecificasHabiliadesAno6,
			getCompetenciasEspecificasHabiliadesAno7,
			getCompetenciasEspecificasHabiliadesAno8,
			getCompetenciasEspecificasHabiliadesAno9,
		];

		this.loginService = new LoginService();
		this.cursosService = new CursosService();
		this.estruturasCurricularesService = new EstruturasCurricularesService();
		this.materiasService = new MateriasService();
		this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();
		this.entesService = new EntesService();

		this.state = {
			showAlert: false,

			buttonDisabled: false,
		};
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {
			const materiasBase = [
				'Corpo, gestos e movimentos',
				'Escuta, fala, pensamento e imaginação',
				'Espaços, tempos, quantidades, relações e transformações',
				'O eu, o outro e o nós',
				'Traços, sons, cores e formas',
				'Arte',
				'Ciências',
				'Educação Física',
				'Ensino Religioso',
				'Geografia',
				'História',
				'Língua Inglesa',
				'Língua Portuguesa',
				'Matemática',
			];

			function ordenacaoMaterias(m1, m2) {
				if (materiasBase.includes(m1.nome) && !materiasBase.includes(m2.nome)) {
					return -1;
				} else if (!materiasBase.includes(m1.nome) && materiasBase.includes(m2.nome)) {
					return 1;
				} else {
					return m1.nome.localeCompare(m2.nome);
				}
			}

			const dadosEnte = await this.entesService.carregarDadosEnte();

			const curso = await this.cursosService
				.carregarCurso(this.props.match.params.idCurso);

			const estruturaCurricular = await this.estruturasCurricularesService
				.carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

			const materias = await this.materiasService
				.carregarMateriasPorEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

			materias.sort(ordenacaoMaterias);

			const materia = await this.materiasService
				.carregarMateria(this.props.match.params.idMateria);

			await this.carregarHabilidadesCompetenciasEspecificas();

			let titulo1 = `Competências específicas e habilidades para ${materia.nome}`;
			let titulo2 = `Competências específicas e habilidades propostas para os estudantes de ${materia.nome} no ${curso.nome}`;
			let botaoPreencher = 'Preencher com competências específicas e habilidades padrão';
			let aba1 = 'Competências específicas';
			let aba2 = 'Habilidades';
			let botaoAdicionar = 'Adicionar habilidade';
			let botaoSalvar = 'Salvar competências';
			if (curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
				titulo1 = `Direitos de aprendizagem e objetivos de aprendizagem para ${materia.nome}`;
				titulo2 = `Direitos de aprendizagem e objetivos de aprendizagem para ${materia.nome}`;
				botaoPreencher = 'Preencher com direitos e objetivos de aprendizagem padrão';
				aba1 = 'Direitos de aprendizagem';
				aba2 = 'Objetivos de aprendizagem';
				botaoAdicionar = 'Adicionar objetivo de aprendizagem';
				botaoSalvar = 'Salvar direitos e objetivos de aprendizagem';
			}

			materias.forEach((materia, idx) => Object.assign(materia, {
				value: materia.id,
				label: materia.nome,
				index: idx,
			}));

			this.setState({
				curso,
				dadosEnte,
				estruturaCurricular,
				materia,
				titulo1,
				titulo2,
				botaoPreencher,
				aba1,
				aba2,
				botaoAdicionar,
				botaoSalvar,
				materias,
				materiaSelecionada: materias.find((m) => m.id === this.props.match.params.idMateria),
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarHabilidadesCompetenciasEspecificas(idMateria) {
		this.setState({ loadingCompetencias: true });

		if (!idMateria) {
			idMateria = this.props.match.params.idMateria
		}

		const competenciasEspecificas = await this.habilidadesCompetenciasEspecificasService
			.carregarCompetenciasEspecificasPorMateria(idMateria);

		const habilidades = await this.habilidadesCompetenciasEspecificasService
			.carregarHabilidadesPorMateria(idMateria);

		this.setState({
			loadingCompetencias: false,
			competenciasEspecificas,
			habilidades,
		});
	}

	removerHabilidade(habilidade) {
		const habilidades = this.state.habilidades.filter(
			(habilidade2) => habilidade !== habilidade2,
		);

		this.setState({ habilidades });
	}

	validarCampos() {
		let ret = true;

		this.state.habilidades.forEach((habilidade) => {
			if (!habilidade.descricao) {
				Object.assign(habilidade, { descricaoState: 'has-danger' });
				ret = false;
			}
		});

		if (!ret) this.forceUpdate();

		return ret;
	}

	async salvarCompetencias() {
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Salvando competências e habilidades...',
		});

		const habilidadesCompetenciasEspecificas = {
			habilidades: this.state.habilidades,
			competenciasEspecificas: this.state.competenciasEspecificas,
		};

		try {
			await this.habilidadesCompetenciasEspecificasService
				.salvarHabilidadesCompetenciasEspecificas(habilidadesCompetenciasEspecificas);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Habilidades e competências específicas salvas com sucesso',
			});

			await this.carregarHabilidadesCompetenciasEspecificas();
		} catch (e) {

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao salvar habilidades e competências específicas',
			});
		}
	}

	preencherCompetenciasEspecificasHabilidadesInfantil(materia) {
		try {
			const res = this.getDadosPadraoInfantil[this.state.curso.serie](materia, this.props.match.params.idMateria);
			this.setState({
				habilidades: res.habilidades,
				competenciasEspecificas: res.competenciasEspecificas,
			});
		} catch (e) { }
	}

	preencherCompetenciasEspecificasHabilidades(materia) {
		try {
			const res = this.getDadosPadrao[this.state.curso.serie](materia, this.props.match.params.idMateria);
			this.setState({
				habilidades: res.habilidades,
				competenciasEspecificas: res.competenciasEspecificas,
			});
		} catch (e) { }
	}

	conteudoPagina() {
		return this.state.curso
			? <Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/${this.props.match.params.idEstruturaCurricular}`}>Estrutura curricular</Link></BreadcrumbItem>
						<BreadcrumbItem active>{this.state.titulo1}</BreadcrumbItem>
					</Breadcrumb>
					<Form onSubmit={this.onSubmit}>
						<Card>
							<CardHeader>
								<CardTitle tag="h4">{this.state.titulo2}</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="4">
										<FormGroup className={`has-label ${this.state.nomeState}`}>
											<label>Estrucura curricular</label>
											<Input
												disabled
												value={this.state.estruturaCurricular.nome}
												type="text"
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Curso</label>
											<Input
												value={this.state.curso.nome}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Ciclo</label>
											<Input
												value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="2">
										<FormGroup className={'has-label'}>
											<label>Situação</label>
											<Input
												value={this.situacoes[this.state.estruturaCurricular.situacao].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<FormGroup className={'has-label has-danger'}>
											<label>Matérias</label>
											<Select
												noOptionsMessage={() => 'Nenhuma entrada'}
												className={`react-select primary`}
												classNamePrefix="react-select"
												value={this.state.materiaSelecionada}
												onChange={async (event) => {
													this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
														+ `/estruturascurriculares/${this.state.estruturaCurricular.id}`
														+ ` /competencias/materia/${event.value}`);

													let titulo1 = `Competências específicas e habilidades para ${event.nome}`;
													let titulo2 = `Competências específicas e habilidades propostas para os estudantes de ${event.nome} no ${this.state.curso.nome}`;
													if (this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
														titulo1 = `Direitos de aprendizagem e objetivos de aprendizagem para ${event.nome}`;
														titulo2 = `Direitos de aprendizagem e objetivos de aprendizagem para ${event.nome}`;
													}

													this.setState({
														materia: event,
														materiaSelecionada: event,
														titulo1,
														titulo2,
														showAlert: false,
													});

													await this.carregarHabilidadesCompetenciasEspecificas(event.value);
												}}
												options={this.state.materias}
												placeholder="Matérias..."
											/>
										</FormGroup>
									</Col>
									{
										(this.props.dadosUsuario.login === 'ojuara')
										&& this.state.curso.serie < Serie.ENSINO_FUNDAMENTAL_1.value &&
										(this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) && <Col md="6" className="text-right align-bottom">
											<Button
												onClick={() => this.preencherCompetenciasEspecificasHabilidadesInfantil(this.state.materiaSelecionada.index)}>
												{this.state.botaoPreencher}
											</Button>
										</Col>
									}
									{
										(this.props.dadosUsuario.login === 'ojuara')
										&& this.state.curso.serie <= Serie.ENSINO_FUNDAMENTAL_9.value &&
										(this.state.curso.cicloEnsino !== CicloEnsino.ENSINO_INFANTIL.value) && <Col md="6" className="text-right align-bottom">
											<Button
												onClick={() => this.preencherCompetenciasEspecificasHabilidades(this.state.materiaSelecionada.index)}>
												{this.state.botaoPreencher}
											</Button>
										</Col>
									}
								</Row>
								<br />
								{
									!this.state.loadingCompetencias ? <Tabs>
										<TabList>
											<Tab>{this.state.aba1}</Tab>
											<Tab>{this.state.aba2}</Tab>
										</TabList>
										<TabPanel>
											<Row>
												<Col md="12">
													<FormGroup className={`has-label ${this.state.competenciasEspecificasState}`}>
														<TextareaAutosize
															className={`autoheight ${this.state.competenciasEspecificasState}`}
															value={this.state.competenciasEspecificas.descricao}
															onChange={(evt) => {
																Object.assign(this.state.competenciasEspecificas,
																	{
																		descricao: evt.target.value,
																	});
																this.setState({ competenciasEspecificasState: 'primary' });
															}} />
														{this.state.competenciasEspecificasState === 'has-danger' ? (
															<label className="error">
																Informe as competências específicas do componente curricular
															</label>
														) : null}
													</FormGroup>
												</Col>
											</Row>
										</TabPanel>
										<TabPanel>
											<Row>
												<Col>
													<Button
														color="primary"
														onClick={() => {
															this.state.habilidades.push({
																descricao: '',
																materia: { id: this.props.match.params.idMateria },
																ordem: this.state.habilidades.length,
															});
															this.forceUpdate();
														}}>
														{this.state.botaoAdicionar}
													</Button>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Table>
														<thead className="text-primary">
															<tr>
																<th style={{ width: '80%' }}>Descrição</th>
																<th className="text-left" style={{ paddingLeft: 20, width: '15%' }}>Ação</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.habilidades.map((habilidade, index) => (
																	<tr key={index}>
																		<td>
																			<FormGroup className={`has-label ${habilidade.descricaoState}`}>
																				<TextareaAutosize
																					className={`autoheight-curriculo ${habilidade.descricaoState}`}
																					value={habilidade.descricao}
																					onChange={(event) => {
																						Object.assign(habilidade,
																							{
																								descricao: event.target.value,
																								descricaoState: 'primary',
																							});
																						this.forceUpdate();
																					}} />
																				{habilidade.descricaoState === 'has-danger' ? (
																					<label className="error">
																						Informe a descrição da habilidade.
																					</label>
																				) : null}
																			</FormGroup>
																		</td>
																		<td className="text-left" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
																			<Button
																				id={`btnRemoverHabilidade${index}`}
																				className="btn-icon"
																				color="danger"
																				size="sm"
																				onClick={() => this.removerHabilidade(habilidade)}
																				type="button">
																				<i className="fa fa-times" />
																			</Button>
																			<UncontrolledTooltip placement="bottom" target={`btnRemoverHabilidade${index}`} delay={0}>
																				Remover habilidade
																			</UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																		</td>
																	</tr>
																))
															}
														</tbody>
													</Table>
												</Col>
											</Row>
										</TabPanel>
									</Tabs> : <div align="center" style={{ margin: 50 }}>
										<Loader
											type="Oval"
											color="#053d7c"
											height="50"
											width="50" />
									</div>
								}
							</CardBody>
							<CardFooter>
								<Row>
									<Col md="6" className="text-left">
										<Button
											color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.props.history.goBack()} >
											Voltar
										</Button>
									</Col>
									<Col md="6" className="text-right">
										<Button color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.salvarCompetencias()} >
											{this.state.botaoSalvar}
										</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</Form>
				</Col>
			</Row> : <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#053d7c"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className="content">
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text={this.state.loaderModalText} />
			</div>
		);
	}
}

export default withRouter(Competencias);