import React from 'react';
import { withRouter } from 'hoc/withRouter';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import PlanosService from 'services/PlanosService';

import TipoPlano from 'assets/csv/tipos-plano.json';

class PlanosAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.tiposPlano = [
      TipoPlano.K10,
      TipoPlano.K20,
      TipoPlano.K50,
      TipoPlano.CUSTOM,
    ];

    this.state = {
      erro: null,
      planos: null,

      showAlert: false,
    };

    this.loginService = new LoginService();
    this.tiposPlanoService = new PlanosService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const planos = await this.tiposPlanoService.carregarPlanos();
      this.setState({
        planos,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  planoSelecionado(plano) {
    this.props.history.push(`/admin/planos/${plano.id}`);
  }

  conteudoPagina() {
    return this.state.planos === null
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Planos</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Planos</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Table hover style={{ tableLayout: 'fixed' }}>
                    <thead className="text-primary text-center">
                      <tr>
                        <th>
                          Plano
                        </th>
                        <th>
                          Preco
                        </th>
                        <th>
                          Escolas
                        </th>
                        <th>
                          Cursos
                        </th>
                        <th>
                          Alunos
                        </th>
                        <th>
                          E. Currric
                        </th>
                        <th>
                          A. Letivos
                        </th>
                        <th>
                          Turmas
                        </th>
                        <th>
                          Diretores
                        </th>
                        <th>
                          Professores
                        </th>
                        <th>
                          Históricos
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.planos.map((plano, idx) => <tr
                          className="text-center tr-clickable"
                          key={idx}
                          onClick={() => this.planoSelecionado(plano)}>
                          <td>
                            {this.tiposPlano[plano.tipo].label}
                          </td>
                          <td>
                            {plano.preco}
                          </td>
                          <td>
                            {plano.escolas}
                          </td>
                          <td>
                            {plano.cursos}
                          </td>
                          <td>
                            {plano.alunos}
                          </td>
                          <td>
                            {plano.estruturasCurriculares}
                          </td>
                          <td>
                            {plano.anosLetivos}
                          </td>
                          <td>
                            {plano.turmas}
                          </td>
                          <td>
                            {plano.diretores}
                          </td>
                          <td>
                            {plano.professores}
                          </td>
                          <td>
                            {plano.historicos}
                          </td>
                        </tr>)
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(PlanosAdmin);
