import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';

import ReCAPTCHA from 'react-google-recaptcha';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SolicitacaoRecuperacaoSenhaService from 'services/SolicitacaoRecuperacaoSenhaService';

import RootFooter from './Footer';

class RedefinirSenha extends React.Component {
  constructor(props) {
    super(props);

    this.chave = this.props.match.params.chave;

    this.senhaRef = React.createRef();
    this.confirmacaoSenhaRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.state = {
      showAlert: false,

      senhaState: '',
      confirmacaoSenha: '',

      senhaErro: '',
      confirmacaoSenhaErro: '',

      buttonDisabled: false,
      ...props.location.state,
    };

    this.redefinirSenha = this.redefinirSenha.bind(this);
    this.redoCaptcha = this.redoCaptcha.bind(this);
    this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    this.solicitacaoRecuperacaoSenhaService = new SolicitacaoRecuperacaoSenhaService();
  }

  componentWillUnmount() {
    document.body.classList.toggle('register-page');
    this.solicitacaoRecuperacaoSenhaService.abortPedingRequests();
  }

  componentDidMount = () => {
    document.body.classList.toggle('register-page');
  }

  validarCampos() {
    let ret = true;
    if (this.senhaRef.current.value === '' || this.confirmacaoSenhaRef.current.value === '') {
      if (this.senhaRef.current.value === '') {
        this.setState({
          senhaState: 'has-danger',
          senhaErro: 'Informe uma senha.',
        });
        ret = false;
      }
      if (this.confirmacaoSenhaRef.current.value === '') {
        this.setState({
          confirmacaoSenhaState: 'has-danger',
          confirmacaoSenhaErro: 'Informe a confirmação da senha.',
        });
        ret = false;
      }
    } else if (this.senhaRef.current.value !== this.confirmacaoSenhaRef.current.value) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha difere da cofirmação de senha',
        confirmacaoSenhaErro: 'A senha difere da cofirmação de senha',
      });
      ret = false;
    } else if (this.senhaRef.current.value.length < 6) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha deve conter no mínimo seis caracteres',
        confirmacaoSenhaErro: 'A senha deve conter no mínimo seis caracteres',
      });
      ret = false;
    } else if (this.senhaRef.current.value.indexOf(' ') !== -1) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha não pode conter espaços em branco',
        confirmacaoSenhaErro: 'A senha não pode conter espaços em branco',
      });
      ret = false;
    } else {
      this.setState({
        senhaState: 'has-success',
        confirmacaoSenhaState: 'has-success',
      });
    }

    if (!ret) {
      this.recaptchaRef.current.reset();
    }

    return ret;
  }

  async redefinirSenha() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.recaptchaRef.current.execute();
  }

  async enviarDados() {
    this.setState({ buttonDisabled: true, showAlert: false });

    const cadastro = {
      senha: this.senhaRef.current.value,
      chave: this.props.match.params.chave,
    };

    try {
      await this.solicitacaoRecuperacaoSenhaService.redefinirSenha(cadastro);

      this.limparDadosFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Senha redefinida com sucesso',
      });
    } catch (msg) {
      this.recaptchaRef.current.reset();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao redefinir senha',
      });
    }
  }

  limparDadosFormulario() {
    this.senhaRef.current.value = '';
    this.confirmacaoSenhaRef.current.value = '';
  }

  redoCaptcha() {
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  }

  onVerifyRecaptcha() {
    this.enviarDados();
  }

  onErrorRecaptcha() {
    this.setState({
      buttonDisabled: false,
      showAlert: true,
      alertColor: 'danger',
      alertMsg: 'Erro: sua interação foi identificada como um robô. Por favor, recarregue a página e tente novamente.',
    });
  }

  render() {
    return <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li><NavLink to="/">&nbsp;</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Redefinir senha</h2>
              <ol>
                <li><NavLink to="/">Início</NavLink></li>
                <li><NavLink to="/login">Login</NavLink></li>
                <li>Redefinir senha</li>
              </ol>
            </div>
          </div>
        </section>
        <div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
        <section className="inner-page">
          <div className="container">
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  this.redefinirSenha();
                }}>
                  <Card className="card-signup text-left" style={{ padding: '20px' }}>
                    <CardHeader>
                      <CardTitle tag="h4">Redefinir senha</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label ${this.state.senhaState}`}>
                        <label>Senha *</label>
                        <Input
                          innerRef={this.senhaRef}
                          type="password"
                          maxLength="200"
                          onChange={() => this.setState({ senhaState: '' })}
                        />
                        {this.state.senhaState === 'has-danger' ? (
                          <label className="error">
                            {this.state.senhaErro}
                          </label>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${this.state.confirmacaoSenhaState}`}>
                        <label>Confirmação de senha *</label>
                        <Input
                          innerRef={this.confirmacaoSenhaRef}
                          type="password"
                          maxLength="200"
                          onChange={() => this.setState({ confirmacaoSenhaState: '' })}
                        />
                        {this.state.confirmacaoSenhaState === 'has-danger' ? (
                          <label className="error">
                            {this.state.confirmacaoSenhaErro}
                          </label>
                        ) : null}
                      </FormGroup>
                      <Row>
                        <Col>
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            size="invisible"
                            onExpired={this.redoCaptcha}
                            onChange={this.onVerifyRecaptcha}
                            onError={this.onErrorRecaptcha}
                            sitekey={env.SITEKEY}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="text-left">
                          <Link to="/login/recuperacaosenha">
                            Reenviar email de recuperação
                      </Link>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                        Redefinir
                    </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Realizando login...' />
    </>;
  }
}

export default withRouter(RedefinirSenha);
