import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { withRouter } from 'hoc/withRouter';
import { Route, Routes } from 'react-router-dom';

import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import LoggedNavbar from 'components/Navbars/LoggedNavbar';

let ps;

class SideBarLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'black',
      activeColor: 'info',
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      if (this.refs.mainPanel) { ps = new PerfectScrollbar(this.refs.mainPanel); }
    }
  }

  componentWillUnmount = () => {
    if (navigator.platform.indexOf('Win') > -1) {
      if (ps) ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => routes.map((route, key) => {
    if (route.collapse) {
      return this.getRoutes(route.views);
    }
    return <Route exact path={route.path} element={<route.component layout={route.layout} role={route.role}/>} key={key} />
  });

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <LoggedNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Routes>
            {this.getRoutes(this.props.routes.concat(this.props.routesProfile))}
          </Routes>
          {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf('full-screen-map')
              !== -1 ? null : (
                <Footer fluid />
              )}
        </div>
      </div>
    );
  }
}

export default withRouter(SideBarLayout);
