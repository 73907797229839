import BaseService from './BaseService';

class EventosService extends BaseService {

  carregarEventosDoDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/evento/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEventosDaTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/evento/turma/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEvento(idDiario, evento) {
    let url = `/evento/diario/${idDiario}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(evento),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEventoMultiseriado(idTurma, evento) {
    let url = `/evento/turma/multiseriado/${idTurma}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(evento),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEvento(idEvento) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/${idEvento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEventoMultiseriado(idEvento) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/turma/multiseriado/${idEvento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	carregarEventosDaEscola(idAnoLetivo, idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/evento/anoletivo/${idAnoLetivo}/escola/${idEscola}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	cadastrarEventoEscola(idAnoLetivo, idEscola, eventoEscola) {
    let url = `/evento/anoletivo/${idAnoLetivo}/escola/${idEscola}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(eventoEscola),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	removerEventoEscola(idEventoEscola) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/escola/${idEventoEscola}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	carregarEventosDoAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/evento/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	cadastrarEventoAnoLetivo(idAnoLetivo, eventoEscola) {
    let url = `/evento/anoletivo/${idAnoLetivo}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(eventoEscola),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	removerEventoAnoLetivo(idEventoAnoLetivo) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/anoletivo/${idEventoAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default EventosService;
