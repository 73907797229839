import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input,
  Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import AwsUtil from 'utils/AwsUtil';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import UsuarioService from 'services/UsuarioService';
import AnosLetivosService from 'services/AnosLetivosService';
import SolicitacaoMatriculaService from 'services/SolicitacaoMatriculaService';

import Papel from 'assets/csv/papeis.json';
import Turno from 'assets/csv/turnos.json';
import SituacaoSolicitacaoMatricula from 'assets/csv/situacoes-solicitacao-matricula.json';

class SolicitacaoMatricula extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();
    this.anosLetivosService = new AnosLetivosService();
    this.solicitacaoMatriculaService = new SolicitacaoMatriculaService();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoSolicitacaoMatricula.AGUARDANDO,
      SituacaoSolicitacaoMatricula.ACEITA,
      SituacaoSolicitacaoMatricula.RECUSADA,
      SituacaoSolicitacaoMatricula.CANCELADA,
    ]

    this.state = {
      showAlert: false,
      error: null,
    };

    this.recusarSolicitacaoMatricula = this.recusarSolicitacaoMatricula.bind(this);
    this.confirmarRecusaSolicitacaoMatricula = this.confirmarRecusaSolicitacaoMatricula.bind(this);
    this.aceitarSolicitacaoMatricula = this.aceitarSolicitacaoMatricula.bind(this);
    this.confirmarAceiteSolicitacaoMatricula = this.confirmarAceiteSolicitacaoMatricula.bind(this);
  }

  async componentDidMount() {
    try {
      const solicitacaoMatricula = await this.solicitacaoMatriculaService
        .carregarSolicitacaoMatricula(this.props.match.params.idSolicitacaoMatricula);

      const anexos = await this.solicitacaoMatriculaService
        .carregarAnexosSolicitacaoMatricula(this.props.match.params.idSolicitacaoMatricula);

      this.setState({ solicitacaoMatricula, anexos });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  confirmarAceiteSolicitacaoMatricula() {
    this.setState({
      acaoSolicitacaoMatricula: this.aceitarSolicitacaoMatricula,
      showConfirmModal: true,
      confirmModalText: 'Confirme o aceite da solicitação de matrícula.',
      confirmText: 'Aceitar',
      confirmColor: 'success',
    });
  }

  async aceitarSolicitacaoMatricula() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Aceitando solicitação de matrícula...',
    });

    try {
      await this.solicitacaoMatriculaService
        .aceitarSolicitacaoMatricula(this.state.solicitacaoMatricula.id);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula aceita com sucesso. Matrícula realizada.',
        solicitacaoMatricula: {
          ...this.state.solicitacaoMatricula,
          situacao: SituacaoSolicitacaoMatricula.ACEITA.value,
        }
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao aceitar solicitação de matrícula',
      });
    }
  }

  confirmarRecusaSolicitacaoMatricula() {
    this.setState({
      acaoSolicitacaoMatricula: this.recusarSolicitacaoMatricula,
      showConfirmModal: true,
      confirmModalText: 'Confirme a recusa da solicitação de matrícula.',
      confirmText: 'Recusar',
      confirmColor: 'danger',
    });
  }

  async recusarSolicitacaoMatricula() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Recusando solicitação de matrícula...',
    });

    try {
      await this.solicitacaoMatriculaService
        .recusarSolicitacaoMatricula(this.state.solicitacaoMatricula.id);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula recusada com sucesso.',
        solicitacaoMatricula: {
          ...this.state.solicitacaoMatricula,
          situacao: SituacaoSolicitacaoMatricula.RECUSADA.value,
        }
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao recusar solicitação de matrícula',
      });
    }
  }

  confirmarCancelamentoSolicitacaoMatricula() {
    this.setState({
      acaoSolicitacaoMatricula: this.cancelarSolicitacaoMatricula,
      showConfirmModal: true,
      confirmModalText: 'Confirme o cancelamento da solicitação de matrícula.',
      confirmText: 'Cancelar',
      confirmColor: 'danger',
    });
  }

  async cancelarSolicitacaoMatricula() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Cancelando solicitação de matrícula...',
    });

    try {
      await this.solicitacaoMatriculaService
        .cancelarSolicitacaoMatricula(this.state.solicitacaoMatricula.id);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula cancelada com sucesso.',
        solicitacaoMatricula: {
          ...this.state.solicitacaoMatricula,
          situacao: SituacaoSolicitacaoMatricula.CANCELADA.value,
        }
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cancelar solicitação de matrícula',
      });
    }
  }

  conteudoPagina() {
    return this.state.solicitacaoMatricula ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/matriculasonline`}>Matrículas Online</Link></BreadcrumbItem>
            <BreadcrumbItem active>Solicitação de matrícula</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Solicitação de matrícula</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label'}>
                    <label>Aluno</label>
                    <Input
                      disabled
                      value={this.state.solicitacaoMatricula.aluno.nome}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className={'has-label'}>
                    <label>Escola</label>
                    <Input
                      disabled
                      value={this.state.solicitacaoMatricula.turma.escola.nome}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className={'has-label'}>
                    <label>Série</label>
                    <Input
                      disabled
                      value={this.state.solicitacaoMatricula.turma.curso.nome}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label'}>
                    <label>Turma</label>
                    <Input
                      disabled
                      value={this.state.solicitacaoMatricula.turma.nome}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label'}>
                    <label>Turno</label>
                    <Input
                      disabled
                      value={this.turnos[this.state.solicitacaoMatricula.turma.turno].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label'}>
                    <label>Data da solicitação</label>
                    <Input
                      disabled
                      value={this.state.solicitacaoMatricula.data}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Situação</label>
                    <Input
                      disabled
                      value={this.situacoes[this.state.solicitacaoMatricula.situacao].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.anexos.length > 0 && <Table>
                  <thead className="text-primary">
                    <tr>
                      <th>Anexo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.anexos.map((anexo, idx) => <tr key={idx}
                        className='tr-clickable'
                        onClick={() => AwsUtil.open(anexo.url)}>
                        <td>{anexo.nome}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.goBack()} >
                    Voltar
                  </Button>
                </Col>
                {
                  this.state.solicitacaoMatricula.situacao === SituacaoSolicitacaoMatricula.AGUARDANDO.value && <Col md="6" className="text-right">
                    {
                      (this.props.role === Papel.PAIS.value || this.props.role === Papel.ALUNO.value) ?
                        <>
                          <Button
                            color="danger"
                            onClick={() => this.confirmarCancelamentoSolicitacaoMatricula()}>
                            Cancelar
                          </Button>
                        </> :
                        <>
                          <Button
                            color="danger"
                            onClick={() => this.confirmarRecusaSolicitacaoMatricula()}>
                            Recusar
                          </Button>
                          {' '}
                          <Button
                            color="success"
                            onClick={() => this.confirmarAceiteSolicitacaoMatricula()}>
                            Aceitar
                          </Button>
                        </>
                    }
                  </Col>
                }
              </Row>
            </CardFooter>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.state.acaoSolicitacaoMatricula();
            } 
          }}
          confirmText={this.state.confirmText}
          cancelText="Fechar"
          confirmColor={this.state.confirmColor}
          text={this.state.confirmModalText} />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando solicitações de matrícula..." />
      </div>
    );
  }
}

export default withRouter(SolicitacaoMatricula);