import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
	Row,
	Col,
	Button,
	Form,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	FormGroup,
	CardFooter,
	Breadcrumb,
	BreadcrumbItem,
	Label,
	Input,
} from 'reactstrap';

import moment from 'moment';
import isDateFormat from 'is-date-format';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import DatePicker from 'views/components/DatePicker';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import UsuarioService from 'services/UsuarioService';
import MateriasService from 'services/MateriasService';
import TransportesService from 'services/TransportesService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import TipoMatricula from 'assets/csv/tipos-matricula.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class MatriculaAluno extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.tiposMatriculas = [
			TipoMatricula.REGULAR,
			TipoMatricula.DEPENDENCIA,
		];

		this.loginService = new LoginService();
		this.entesService = new EntesService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.usuarioService = new UsuarioService();
		this.materiasService = new MateriasService();
		this.transportesService = new TransportesService();
		this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();

		this.state = {
			showAlert: false,
			error: null,

			carregandoAlunos: false,
			alunoSelecionado: null,

			materiasSelecionadas: [],
			materias: [],

			tipoMatriculaSelecionado: TipoMatricula.REGULAR,
			NA: false,
			PE: false,
			AP: false,
			RA: false,
			TR: false,
			NFE: false,
			EJA: false,
			promocao: false,
			usoImagem: false,
			matricularEmTodasAsMaterias: true,
			usaTransporte: false,
			atualizarFardamento: false,
			atualizarEndereco: false,
			tamanhoCalca: '',
			tamanhoCamisa: '',
			tamanhoCalcado: '',
			endereco: '',

			alunos: [],

			dataMatricula: '',
			viewDate: new Date(new Date().getFullYear(), 0, 1),
		};

		this.matricularAluno = this.matricularAluno.bind(this);
		this.atualizarMatricularAluno = this.atualizarMatricularAluno.bind(this);
	}

	async componentDidMount() {
		this.debouncedFunction = _.debounce((text) => {
			if (text === '') {
				this.setState({ carregandoAlunos: false, alunos: [] });
			} else {
				this.carregarAlunos(text);
			}
		}, 1500);

		try {
			const dadosEnte = await this.entesService.carregarDadosEnte();

			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const turma = await this.turmasService
				.carregarTurma(this.props.match.params.idTurma);

			const materias = await this.materiasService
				.carregarMateriasPorEstruturaCurricular(turma.estruturaCurricular.id);

			const selectMaterias = [];

			materias.forEach((materia) => {
				selectMaterias.push({ label: materia.nome, value: materia.id });
			});

			const data = await this.transportesService
				.carregarTransportes(1, 99999, '');
			data.transportes.forEach((transporte) => {
				Object.assign(transporte, {
					value: transporte.id,
					label: `${transporte.modelo} - ${transporte.itinerario}`,
				});
			});

			this.setState({
				dadosEnte,
				anoLetivo,
				escola,
				turma,
				materias: selectMaterias,
				materiasSelecionadas: selectMaterias,
				transportes: data.transportes,
			});

			if (this.props.match.params.idMatricula !== undefined) {
				const matricula = await this.turmasService
					.carregarMatriculaPorId(this.props.match.params.idMatricula);

				const registro = await this.registroSituacaoMatriculaService
					.carregarPrimeiroRegistroSituacaoMatricula(matricula.id);

				const materias = await this.turmasService
					.carregarMateriasDaMatricula(matricula.id);

				const materiasSelecionadas = [];
				materias.forEach((materia) => {
					materiasSelecionadas.push({ label: materia.nome, value: materia.id });
				});

				this.setState({
					registro,
					matriculaSelecionada: matricula,
					materiasSelecionadas,
				}, () => this.contentLoad());

			}
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	contentLoad() {
		this.setState({
			dataMatricula: this.state.registro.data,
			alunoSelecionado: {
				value: this.state.matriculaSelecionada.usuario.id,
				label: `${this.state.matriculaSelecionada.usuario.nome} (Mãe: ${this.state.matriculaSelecionada.usuario.nomeMae})`,
			},
			tipoMatriculaSelecionado: this.tiposMatriculas[this.state.matriculaSelecionada.tipo],
			NA: this.state.matriculaSelecionada.novatoNoAno,
			PE: this.state.matriculaSelecionada.propriaEscola,
			AP: this.state.matriculaSelecionada.aprovado,
			RA: this.state.matriculaSelecionada.retidoCicloAdultos,
			TR: this.state.matriculaSelecionada.transferido,
			NFE: this.state.matriculaSelecionada.naoFrequentouEscolaAnoAnterior,
			EJA: this.state.matriculaSelecionada.educacaoJovensAdultos,
			promocao: this.state.matriculaSelecionada.promocao,
			observacoes: this.state.matriculaSelecionada.observacoes,
			usoImagem: this.state.matriculaSelecionada.usoImagem,
		});
	}

	validarCampos() {
		let ret = true;

		if (this.state.dataMatricula === '') {
			this.setState({
				dataMatriculaState: 'has-danger',
				dataMatriculaErro: 'Informe a data da matrícula',
			});
			ret = false;
		} else if (!isDateFormat(this.state.dataMatricula, 'dd/mm/yyyy')) {
			this.setState({
				dataMatriculaState: 'has-danger',
				dataMatriculaErro: 'Data inválida',
			});
			ret = false;
		}

		if (!this.state.alunoSelecionado) {
			this.setState({ alunoState: 'danger' });
			ret = false;
		}
		if (!this.state.tipoMatriculaSelecionado) {
			this.setState({ tipoMatriculaState: 'danger' });
			ret = false;
		}
		if (this.state.usaTransporte && !this.state.transporteSelecionado) {
			this.setState({ transporteState: 'danger' });
			ret = false;
		}
		if (this.state.atualizarFardamento) {
			if (!this.state.tamanhoCalca) {
				this.setState({ tamanhoCalcaState: 'has-danger' });
				ret = false;
			}
			if (!this.state.tamanhoCamisa) {
				this.setState({ tamanhoCamisaState: 'has-danger' });
				ret = false;
			}
			if (!this.state.tamanhoCalcado) {
				this.setState({ tamanhoCalcadoState: 'has-danger' });
				ret = false;
			}
		}
		if (this.state.atualizarEndereco) {
			if (!this.state.endereco) {
				this.setState({ enderecoState: 'has-danger' });
				ret = false;
			}
		}
		if (this.state.tipoMatriculaSelecionado.value === TipoMatricula.DEPENDENCIA.value || !this.state.matricularEmTodasAsMaterias) {
			if (!this.state.materiasSelecionadas || this.state.materiasSelecionadas.length === 0) {
				this.setState({ materiasState: 'danger' });
				ret = false;
			}
		}
		return ret;
	}

	async matricularAluno() {
		if (this.state.buttonDisabled) return;
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Cadastrando matrícula...',
		});

		try {
			const matricula = {
				turma: { id: this.props.match.params.idTurma },
				usuario: { id: this.state.alunoSelecionado.value },
				novatoNoAno: this.state.NA,
				propriaEscola: this.state.PE,
				aprovado: this.state.AP,
				retidoCicloAdultos: this.state.RA,
				transferido: this.state.TR,
				naoFrequentouEscolaAnoAnterior: this.state.NFE,
				educacaoJovensAdultos: this.state.EJA,
				promocao: this.state.promocao,
				tipo: this.state.tipoMatriculaSelecionado.value,
				observacoes: this.state.observacoes,
				usoImagem: this.state.usoImagem,
				materias: this.state.materiasSelecionadas.map((materia) => ({ id: materia.value })),
				transporte: this.state.transporteSelecionado ? { id: this.state.transporteSelecionado.value } : null,
				tamanhoCalca: this.state.atualizarFardamento ? this.state.tamanhoCalca : undefined,
				tamanhoCamisa: this.state.atualizarFardamento ? this.state.tamanhoCamisa : undefined,
				tamanhoCalcado: this.state.atualizarFardamento ? this.state.tamanhoCalcado : undefined,
				endereco: this.state.atualizarEndereco ? this.state.endereco : undefined,
			};

			const pdf = await this.turmasService
				.adicionarMatricula(matricula, this.state.dataMatricula);

			const file = Utils.base64ToBlob(pdf);
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.limparFormulario();

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Aluno matriculado com sucesso',
			});
		} catch (msg) {
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao matricular aluno',
			});
		}
	}

	async atualizarMatricularAluno() {
		if (this.state.buttonDisabled) return;
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Atualizando matrícula...',
		});

		try {
			const matricula = {
				id: this.state.matriculaSelecionada.id,
				novatoNoAno: this.state.NA,
				propriaEscola: this.state.PE,
				aprovado: this.state.AP,
				retidoCicloAdultos: this.state.RA,
				transferido: this.state.TR,
				naoFrequentouEscolaAnoAnterior: this.state.NFE,
				educacaoJovensAdultos: this.state.EJA,
				observacoes: this.state.observacoes,
				usoImagem: this.state.usoImagem,
			};

			await this.turmasService.atualizarMatricula(matricula, this.state.dataMatricula);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Matrícula atualizada com sucesso.',
			});
		} catch (msg) {
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao atualizar matrícula.',
			});
		}
	}

	limparFormulario() {
		this.setState({
			alunoSelecionado: null,
			tipoMatriculaSelecionado: TipoMatricula.REGULAR,
			matricularEmTodasAsMaterias: true,
			materiasSelecionadas: this.state.materias,
			NA: false,
			PE: false,
			AP: false,
			RA: false,
			TR: false,
			NFE: false,
			EJA: false,
			promocao: false,
			alunos: [],
			dataMatricula: '',
			usaTransporte: false,
			atualizarFardamento: false,
			atualizarEndereco: false,
			usoImagem: false,
			tamanhoCalca: '',
			tamanhoCamisa: '',
			tamanhoCalcado: '',
			endereco: '',
			transporteSelecionado: undefined,
			observacoes: '',
		});
	}

	async carregarAlunos(like) {
		try {
			let data;
			if (this.state.dadosEnte.alunosPorEscola) {
				data = await this.usuarioService
					.carregarUsuariosVinculadosEscola(this.props.match.params.idEscola, Papel.ALUNO.value, 1, 99999, like);
			} else {
				data = await this.usuarioService
					.carregarUsuarios(Papel.ALUNO.value, 1, 99999, like);
			}

			const selectAlunos = [];
			data.usuarios.forEach((aluno) => {
				selectAlunos.push({
					value: aluno.id,
					label: `${aluno.nome} (Mãe: ${aluno.nomeMae} - CPF: ${aluno.cpf || 'Não informado'})`,
					endereco: aluno.endereco,
				});
			});

			this.setState({
				carregandoAlunos: false,
				alunos: selectAlunos,
			});
		} catch (e) {
			this.setState({
				carregandoAlunos: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Erro ao buscar alunos cadastrados',
			});
		}
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem>
							{!this.state.turma.multiSeriado ? <Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} -&nbsp;
								{this.turnos[this.state.turma.turno].label}</Link> : <Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} -&nbsp;
								{this.turnos[this.state.turma.turno].label}</Link>}
						</BreadcrumbItem>
						<BreadcrumbItem active>Matrícula</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Form>
						<Card>
							<CardHeader>
								<CardTitle tag="h4">Matrícula</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="2">
										<FormGroup className={`has-label ${this.state.dataMatriculaState}`}>
											<label>Data da matrícula *</label>
											<DatePicker
												viewDate={this.state.viewDate}
												value={this.state.dataMatricula}
												onChange={(data) => {
													const dataStr = moment(data).format('DD/MM/YYYY');
													this.setState({
														viewDate: data.toDate ? data.toDate() : this.state.viewDate,
														dataMatricula: dataStr,
														dataMatriculaState: '',
													});
												}}
											/>
											{this.state.dataMatriculaState === 'has-danger' ? (
												<label className="error">
													{this.state.dataMatriculaErro}
												</label>
											) : null}
										</FormGroup>
									</Col>
								</Row>
								<Row className="">
									<Col md="6">
										<FormGroup className={'has-label has-danger'}>
											<label>Aluno *</label>
											<Select
												isDisabled={this.props.match.params.idMatricula}
												isLoading={this.state.carregandoAlunos}
												loadingMessage={() => 'Carregando...'}
												noOptionsMessage={() => 'Nenhuma entrada'}
												className={`react-select primary ${this.state.alunoState}`}
												classNamePrefix="react-select"
												value={this.state.alunoSelecionado}
												onInputChange={(text) => {
													this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
													this.debouncedFunction(text);
												}}
												onChange={(event) => {
													this.setState({ alunoState: 'primary', alunoSelecionado: event, endereco: event.endereco });
												}}
												options={this.state.alunos}
												placeholder="Digite o nome ou CPF de um aluno..."
											/>
											{this.state.alunoState === 'danger' ? (
												<label className="error">
													Informe o nome ou CPF de um aluno.
												</label>
											) : null}
										</FormGroup>
									</Col>
									<Col md="2">
										<FormGroup className={'has-label has-danger'}>
											<label>Tipo da matrícula *</label>
											<Select
												isDisabled={this.props.match.params.idMatricula || this.state.turma.multiSeriado}
												className={`react-select primary ${this.state.tipoMatriculaState}`}
												classNamePrefix="react-select"
												value={this.state.tipoMatriculaSelecionado}
												onChange={(event) => {
													this.setState({
														tipoMatriculaState: 'primary',
														tipoMatriculaSelecionado: event,
														matricularEmTodasAsMaterias: event.value === TipoMatricula.REGULAR.value,
														materiasSelecionadas: event.value === TipoMatricula.DEPENDENCIA.value ? [] : this.state.materias,
													});
												}}
												options={this.tiposMatriculas}
											/>
											{this.state.tipoMatriculaState === 'danger' ? (
												<label className="error">
													Informe o tipo da matrícula.
												</label>
											) : null}
										</FormGroup>
									</Col>
									<Col md="3">
										{
											!this.props.match.params.idMatricula && this.state.tipoMatriculaSelecionado.value === TipoMatricula.REGULAR.value
											&& <FormGroup check style={{ marginTop: '30px' }}>
												<Label className="form-check-label">
													<Input
														disabled={this.state.turma.multiSeriado}
														className="form-check-input"
														type="checkbox"
														checked={this.state.matricularEmTodasAsMaterias}
														onChange={(e) => this.setState({
															matricularEmTodasAsMaterias: e.target.checked,
															materiasSelecionadas: e.target.checked ? this.state.materias : this.state.materiasSelecionadas,
														})} />
													Matricular em todos os {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'campos de experiência' : 'componentes curriculares'}
													<span className="form-check-sign">
														<span className="check"></span>
													</span>
												</Label>
											</FormGroup>
										}
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<FormGroup className={'has-label has-danger'}>
											<label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
												? 'Campos de experiências' : 'Componentes curriculares'} *</label>
											<Select
												isDisabled={this.props.match.params.idMatricula || this.state.matricularEmTodasAsMaterias}
												noOptionsMessage={() => 'Nenhuma entrada'}
												className={`react-select primary ${this.state.materiasState}`}
												classNamePrefix="react-select"
												placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
													? 'Campos de experiências...' : 'Componentes curriculares...'}
												closeMenuOnSelect={false}
												isMulti
												value={this.state.materiasSelecionadas}
												onChange={(value) => this.setState({ materiasState: '', materiasSelecionadas: value })}
												options={this.state.materias}
											/>
											{this.state.materiasState === 'danger' ? (
												<label className="error">
													Informe ao menos um componente curricular.
												</label>
											) : null}
										</FormGroup>
									</Col>
								</Row>
								{
									!this.props.match.params.idMatricula && this.state.dadosEnte.transportes && <Row>
										<Col md="2">
											<FormGroup check style={{ marginTop: '30px' }}>
												<Label className="form-check-label">
													<Input
														className="form-check-input"
														type="checkbox"
														checked={this.state.usaTransporte}
														onChange={(e) => this.setState({ usaTransporte: e.target.checked })} />
													Usa transporte escolar
													<span className="form-check-sign">
														<span className="check"></span>
													</span>
												</Label>
											</FormGroup>
										</Col>
										{
											this.state.usaTransporte && <Col md="6">
												<FormGroup className={'has-label has-danger'}>
													<label>Transporte escolar *</label>
													<Select
														isDisabled={!this.state.usaTransporte}
														className={`react-select primary ${this.state.transporteState}`}
														classNamePrefix="react-select"
														value={this.state.transporteSelecionado}
														onChange={(event) => {
															this.setState({ transporteState: 'primary', transporteSelecionado: event });
														}}
														options={this.state.transportes}
													/>
													{this.state.transporteState === 'danger' ? (
														<label className="error">
															Informe o transporte escolar.
														</label>
													) : null}
												</FormGroup>
											</Col>
										}
									</Row>
								}
								{
									!this.props.match.params.idMatricula && <>
										<Row>
											<Col md="2">
												<FormGroup check style={{ marginTop: '30px' }}>
													<Label className="form-check-label">
														<Input
															className="form-check-input"
															type="checkbox"
															checked={this.state.atualizarFardamento}
															onChange={(e) => this.setState({ atualizarFardamento: e.target.checked })} />
														Atualizar informações de fardamento
														<span className="form-check-sign">
															<span className="check"></span>
														</span>
													</Label>
												</FormGroup>
											</Col>
											{
												this.state.atualizarFardamento && <>
													<Col md="2">
														<FormGroup className={`has-label ${this.state.tamanhoCalcaState}`}>
															<label>Tamanho da calça</label>
															<Input
																value={this.state.tamanhoCalca}
																type="text"
																maxLength="200"
																onChange={(evt) => this.setState({
																	tamanhoCalca: evt.target.value,
																	tamanhoCalcaState: 'has-success',
																})}
															/>
															{this.state.tamanhoCalcaState === 'has-danger' ? (
																<label className="error">
																	Informe o tamanho da calça.
																</label>
															) : null}
														</FormGroup>
													</Col>
													<Col md="2">
														<FormGroup className={`has-label ${this.state.tamanhoCamisaState}`}>
															<label>Tamanho da camisa</label>
															<Input
																value={this.state.tamanhoCamisa}
																type="text"
																maxLength="200"
																onChange={(evt) => this.setState({
																	tamanhoCamisa: evt.target.value,
																	tamanhoCamisaState: 'has-success',
																})}
															/>
															{this.state.tamanhoCamisaState === 'has-danger' ? (
																<label className="error">
																	Informe o tamanho da camisa.
																</label>
															) : null}
														</FormGroup>
													</Col>
													<Col md="2">
														<FormGroup className={`has-label ${this.state.tamanhoCalcadoState}`}>
															<label>Tamanho do calçado</label>
															<Input
																value={this.state.tamanhoCalcado}
																type="text"
																maxLength="200"
																onChange={(evt) => this.setState({
																	tamanhoCalcado: evt.target.value,
																	tamanhoCalcadoState: 'has-success',
																})}
															/>
															{this.state.tamanhoCalcadoState === 'has-danger' ? (
																<label className="error">
																	Informe o tamanho do calçado.
																</label>
															) : null}
														</FormGroup>
													</Col>
												</>
											}
										</Row>
									</>
								}
								{
									!this.props.match.params.idMatricula && <>
										<Row>
											<Col md="2">
												<FormGroup check style={{ marginTop: '30px' }}>
													<Label className="form-check-label">
														<Input
															className="form-check-input"
															type="checkbox"
															checked={this.state.atualizarEndereco}
															onChange={(e) => this.setState({ atualizarEndereco: e.target.checked })} />
														Atualizar endereço
														<span className="form-check-sign">
															<span className="check"></span>
														</span>
													</Label>
												</FormGroup>
											</Col>
											{
												this.state.atualizarEndereco && <>
													<Col md="10">
														<FormGroup className={`has-label ${this.state.enderecoState}`}>
															<label>Endereço</label>
															<Input
																value={this.state.endereco}
																type="text"
																maxLength="200"
																onChange={(evt) => this.setState({
																	endereco: evt.target.value,
																	enderecoState: 'has-success',
																})}
															/>
															{this.state.enderecoState === 'has-danger' ? (
																<label className="error">
																	Informe o endereço.
																</label>
															) : null}
														</FormGroup>
													</Col>
												</>
											}
										</Row>
										<br />
									</>
								}
								<Row>
									<Col>
										<FormGroup className={'has-label'}>
											<label>Observações</label>
											<Input
												value={this.state.observacoes}
												onChange={(evt) => {
													this.setState({ observacoes: evt.target.value });
												}}
												type="textarea"
												style={{ height: '100px' }} />
										</FormGroup>
									</Col>
								</Row>
								{
									!this.props.match.params.idMatricula && <Row>
										<Col>
											<FormGroup check style={{ marginTop: '20px' }}>
												<Label className="form-check-label">
													<Input
														disabled={this.props.match.params.idMatricula}
														className="form-check-input"
														type="checkbox"
														checked={this.state.usoImagem}
														onChange={(e) => this.setState({ usoImagem: e.target.checked })} />
													O responsável autoriza o uso de imagem do(a) aluno(a)
													<span className="form-check-sign">
														<span className="check"></span>
													</span>
												</Label>
											</FormGroup>
											<label></label>
										</Col>
									</Row>
								}
								{
									!this.props.match.params.idMatricula && <Row>
										<Col>
											<FormGroup check style={{ marginTop: '20px' }}>
												<Label className="form-check-label">
													<Input
														disabled={this.props.match.params.idMatricula}
														className="form-check-input"
														type="checkbox"
														checked={this.state.promocao}
														onChange={(e) => this.setState({ promocao: e.target.checked })} />
													Matrícula em turma avançada
													(Ex: aluno concluiu a 1ª série e está sendo matrículado na 3ª) ou dependência
													<span className="form-check-sign">
														<span className="check"></span>
													</span>
												</Label>
											</FormGroup>
											<label></label>
										</Col>
									</Row>
								}
							</CardBody>
							<CardFooter>
								<Row>
									<Col className="text-left">
										<Button
											color="primary"
											onClick={() => this.props.history.goBack()}>
											Voltar
										</Button>
									</Col>
									<Col className="text-right">
										{
											!this.props.match.params.idMatricula
												? <Button
													type="button"
													onClick={() => this.matricularAluno()}
													disabled={this.state.buttonDisabled}
													color="primary">
													Matricular
												</Button>
												: <Button
													type="button"
													onClick={() => this.atualizarMatricularAluno()}
													disabled={this.state.buttonDisabled}
													color="primary">
													Atualizar
												</Button>
										}
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</Form>
				</Col >
			</Row >
		</>
			: <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#053d7c"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text={this.state.loaderModalText} />
			</div>
		);
	}
}

export default withRouter(MatriculaAluno);
