import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  CardFooter,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';

import DiretoriosService from 'services/DiretoriosService';
import AulasService from 'services/AulasService';
import UsuarioService from 'services/UsuarioService';

class ListagemAulasProfessor extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-${props.match.params.idProfessor}-aulas-cadastradas`;

    this.stackDir = JSON.parse(sessionStorage.getItem(`${this.idPagina}-stackDir`)) || { data: [{ id: null, nome: 'Minhas aulas' }] };
    sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));

    this.state = {
      showAlert: false,
    };

    this.usuarioService = new UsuarioService();
    this.diretoriosService = new DiretoriosService();
    this.aulasService = new AulasService();
  }

  async componentDidMount() {
    try {
      const professor = await this.usuarioService
        .carregarUsuario(this.props.match.params.idProfessor);
      await this.carregarDiretorios();
      await this.carregarAulas();
      this.setState({ professor });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarDiretorios() {
    const diretorios = await this.diretoriosService.carregarDiretoriosDoProfessor(
      this.props.match.params.idProfessor,
      this.stackDir.data[this.stackDir.data.length - 1].id,
    );
    this.setState({ diretorios });
  }

  async carregarAulas() {
    const aulas = await this.aulasService.carregarAulasDoDiretorioDoProfessor(
      this.props.match.params.idProfessor,
      this.stackDir.data[this.stackDir.data.length - 1].id,
    );
    this.setState({ aulas });
  }

  async abrirDiretorio(diretorio, voltando) {
    if (!voltando) {
      this.stackDir.data.push(diretorio);
      sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
    }

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Entrando no diretório...',
    });

    try {
      await this.carregarDiretorios();

      await this.carregarAulas();

      this.setState({
        showLoaderModal: false,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao entrar no diretório',
      });
    }
  }

  abrirAula(aula) {
    this.props.history.push(`${this.props.layout}/aulasprofessores/professor/${this.props.match.params.idProfessor}/listagemaulas/aula/${aula.id}`);
  }

  conteudoPagina() {
    return this.state.professor ? <>
      <Alert
        color={this.state.alertColor}
        isOpen={this.state.showAlert}
        toggle={() => { this.setState({ showAlert: false }); }}>
        {this.state.alertMsg}
      </Alert>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`${this.props.layout}/aulasprofessores`}>Aulas Professores</Link></BreadcrumbItem>
        <BreadcrumbItem active>{this.state.professor.nome}</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">{this.state.professor.nome}</CardTitle>
        </CardHeader>
        <CardBody>
          {
            !this.state.showLoaderModal ?

              <Table className="aulas">
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '2%' }}></th>
                    <th style={{ width: '50%' }}>Nome</th>
                    <th style={{ width: '30%' }}>Código</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.stackDir.data[this.stackDir.data.length - 1].id && <tr>
                      <td className="td-dir"><i className="fas fa-folder"></i></td>
                      <td
                        className="td-name"
                        onClick={() => {
                          this.stackDir.data.pop();
                          sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
                          this.abrirDiretorio(this.stackDir.data[this.stackDir.data.length - 1], true);
                        }}>..</td>
                      <td></td>
                    </tr>
                  }
                  {
                    this.state.diretorios.map((diretorio, idx) => <tr key={idx}>
                      <td className="td-dir"><i className="fas fa-folder"></i></td>
                      <td
                        className="td-name"
                        onClick={() => this.abrirDiretorio(diretorio)}>{diretorio.nome}</td>
                      <td></td>
                    </tr>)
                  }
                  {
                    this.state.aulas.map((aula, idx) => <tr key={idx}>
                      <td></td>
                      <td
                        className="td-name"
                        onClick={() => this.abrirAula(aula)}>{aula.nome}</td>
                      <td>{aula.codigo}</td>
                    </tr>)
                  }
                </tbody>
              </Table>
              : <div align="center" style={{ margin: 50 }}>
                <Loader
                  type="Oval"
                  color="#053d7c"
                  height="50"
                  width="50" />
              </div>
          }
        </CardBody>
        <CardFooter>
          <Row>
            <Col>
              <Button
                color="primary"
                onClick={() => this.props.history.goBack()}>
                Voltar
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ListagemAulasProfessor);