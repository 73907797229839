import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import moment from 'moment';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import DatePicker from 'views/components/DatePicker';

import LoginService from 'services/LoginService';
import EscolasService from 'services/EscolasService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';
import EstoquesService from 'services/EstoquesService';

import Papel from 'assets/csv/papeis.json';

class ImprimirRelatorioSaidasEstoquePorEscola extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.escolasService = new EscolasService();
    this.relatoriosService = new RelatoriosService();
    this.estoquesService = new EstoquesService();

    this.imprimirRelatorioSaidasEstoquePorEscola = this.imprimirRelatorioSaidasEstoquePorEscola.bind(this);

    this.state = {
      showAlert: false,
      error: null,

      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };
  }

  async componentDidMount() {
    try {

      const data = await this.escolasService.carregarEscolas(1, 99999, '');
      let { escolas } = data;
      escolas.forEach((escola) => Object.assign(
        escola, { value: escola.id, label: escola.nome },
      ));
      if (this.props.role === Papel.GESTOR.value) {
        escolas = [{ value: null, label: 'Estoque central' }].concat(escolas);
      }

      const data2 = await this.estoquesService.carregarEstoques(1, 99999, "");
      let { estoques } = data2;
      estoques.forEach((estoque) => Object.assign(
        estoque, { value: estoque.id, label: estoque.nome },
      ));

      this.setState({
        escolas,
        estoques,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async imprimirRelatorioSaidasEstoquePorEscola(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo relatório de saídas de estoque por escola para pdf...',
    });

    try {
      const dataIncio = this.state.dataInicio.replaceAll('/', '-');
      const dataFim = this.state.dataFim.replaceAll('/', '-');

      const pdf = await this.relatoriosService
        .imprimirRelatorioSaidasEstoquePorEscola(this.state.escolaSelecionada.id,
          this.state.estoqueSelecionado.id, dataIncio, dataFim);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de saídas de estoque por escola impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de saídas de estoque por escola.',
      });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }
    if (!this.state.estoqueSelecionado) {
      this.setState({ estoqueState: 'danger' });
      ret = false;
    }
    if (!this.state.dataInicio) {
      this.setState({ dataInicioState: 'has-danger' });
      ret = false;
    }
    if (!this.state.dataFim) {
      this.setState({ dataFimState: 'has-danger' });
      ret = false;
    }
    if (this.state.dataInicio && this.state.dataFim) {
      const mDataInicio = moment(this.state.dataInicio, 'DD/MM/YYYY');
      const mDataFim = moment(this.state.dataFim, 'DD/MM/YYYY');

      if(!mDataInicio.isBefore(mDataFim)) {
        this.setState({ dataInicioState: 'has-danger' });
        this.setState({ dataFimState: 'has-danger' });
        ret = false;
      }
    }
    return ret;
  }

  conteudoPagina() {
    return this.state.escolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios/estoques`}>Relatórios de estoques</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de saídas de estoque por escola</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioSaidasEstoquePorEscola}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Relatório de saídas de estoque por escola</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        placeholder="Escola..."
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            escolaSelecionada: event,
                            escolaState: '',
                          });
                        }}
                        options={this.state.escolas}
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Estoque *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.estoqueState}`}
                        classNamePrefix="react-select"
                        placeholder="Estoque..."
                        value={this.state.estoqueSelecionado}
                        onChange={(event) => {
                          this.setState({
                            estoqueSelecionado: event,
                            estoqueState: '',
                          });
                        }}
                        options={this.state.estoques}
                      />
                      {this.state.estoqueState === 'danger' ? (
                        <label className="error">
                          Informe o estoque.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataInicioState}`}>
                      <label>Data de início *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataInicio}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataInicio: dataStr,
                            dataInicioState: '',
                          });
                        }}
                      />
                      {this.state.dataInicioState === 'has-danger' ? (
                        <label className="error">
                          Informe uma data de início válida
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataFimState}`}>
                      <label>Data de fim *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataFim}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataFim: dataStr,
                            dataFimState: '',
                          });
                        }}
                      />
                      {this.state.dataFimState === 'has-danger' ? (
                        <label className="error">
                          Informe uma data de fim válida
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios/estoques`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.showLoaderModal}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirRelatorioSaidasEstoquePorEscola);
