import BaseService from './BaseService';

class HabilidadesCompetenciasEspecificasService extends BaseService {
  salvarHabilidadesCompetenciasEspecificas(habilidadesCompetenciasEspecificas) {
    const requestInfo = {
      method: 'post',
      url: '/habilidades/competencias',
      data: JSON.stringify(habilidadesCompetenciasEspecificas),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCompetenciasEspecificasPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/competencias/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHabilidadesPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoHabilidadeNoDiarioEMateria(idHabilidade, idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/${idHabilidade}/diario/${idDiario}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacoesHabilidadesNoDiarioEMateria(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/diario/${idDiario}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarHabilidadesTrabalhadas(idDiario, idMateria, situacoesHabilidades) {
    const requestInfo = {
      method: 'post',
      url: `/habilidades/diario/${idDiario}/materia/${idMateria}/situacoes`,
      data: JSON.stringify(situacoesHabilidades),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoHabilidadeNaMatriculaEMateria(idHabilidade, idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/${idHabilidade}/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacoesHabilidadesNaMatriculaEMateria(idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoHabilidadeNaMatriculaMateriaEBimestre(idHabilidade, idMatricula, idMateria, bimestre) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/${idHabilidade}/matricula/${idMatricula}/materia/${idMateria}/bimestre/${bimestre}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacoesHabilidadesBimestresNaMatriculaMateria(idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/habilidades/bimestres/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarHabilidadesAdquiridasMatriculaMateria(idMatricula, idMateria, situacoesHabilidades) {
    const requestInfo = {
      method: 'post',
      url: `/habilidades/matricula/${idMatricula}/materia/${idMateria}/situacoes`,
      data: JSON.stringify(situacoesHabilidades),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarHabilidadesAdquiridasMatriculaMateriaBimestres(idMatricula, idMateria, situacoesBimestres) {
    const requestInfo = {
      method: 'post',
      url: `/habilidades/matricula/${idMatricula}/materia/${idMateria}/situacoes/bimestres`,
      data: JSON.stringify(situacoesBimestres),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default HabilidadesCompetenciasEspecificasService;
