import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import CursosService from 'services/CursosService';
import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import series from 'assets/csv/series.json';
import Serie from 'assets/csv/series2.json';

class Cursos extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.slicesCursos = [
      { inicio: 0, fim: 6 },
      { inicio: 6, fim: 11 },
      { inicio: 11, fim: 15 },
      { inicio: 15, fim: 18 },
      undefined,
      { inicio: 18, fim: 20 },
      undefined,
      { inicio: 21, fim: 23 },
    ];
    this.serieNaoDefinida = Serie.NAO_DEFINIDA.value;

    this.cursosService = new CursosService();
    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,

      cicloEnsinoState: '',
      serieState: '',
      nomeCursoState: '',

      nomeCurso: '',

      cicloEnsinoSelecionado: '',
      serieSelecionada: '',
      seriesDoCiclo: undefined,

      seriesSelecionadas: '',
      seriesState: '',

      buttonDisabled: false,

      loaderModalText: '',

      dadosTabela: {
        header: [['Nome', '80%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.cadastrarCurso = this.cadastrarCurso.bind(this);
    this.atualizarCurso = this.atualizarCurso.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    const dadosEnte = await this.entesService.carregarDadosEnte();
    if(dadosEnte.bnccNacional) {
      series[1] = { label : "Creche 1", labelcurto : "Creche 1", value: 1 };
      series[2] = { label : "Creche 2", labelcurto : "Creche 2", value: 2 };
      series[3] = { label : "Creche 3", labelcurto : "Creche 3", value: 3 };

      this.slicesCursos[0] = { inicio: 1, fim: 6 };
    }

    try {
      if (this.props.match.params.idCurso !== undefined) {

        const curso = await this.cursosService.carregarCurso(this.props.match.params.idCurso);

        if (curso.cicloEnsino === CicloEnsino.EJA.value || curso.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value) {
          const seriesCurso = await this.cursosService.carregarSeries(this.props.match.params.idCurso);
          const seriesSelecionadas = seriesCurso.map((s) => ({ label: series[s.serie].label, value: series[s.serie].value }))

          let seriesOpt = [];
          if (curso.serie === Serie.EJA_ENSINO_FUNDAMENTAL.value || curso.serie === Serie.CORRECAO_FLUXO_ENSINO_FUNDAMENTAL.value) {
            seriesOpt = [Serie.ENSINO_FUNDAMENTAL_1, Serie.ENSINO_FUNDAMENTAL_2,
            Serie.ENSINO_FUNDAMENTAL_3, Serie.ENSINO_FUNDAMENTAL_4, Serie.ENSINO_FUNDAMENTAL_5,
            Serie.ENSINO_FUNDAMENTAL_6, Serie.ENSINO_FUNDAMENTAL_7, Serie.ENSINO_FUNDAMENTAL_8, Serie.ENSINO_FUNDAMENTAL_9];
          } else if (curso.serie === Serie.EJA_ENSINO_MEDIO.value || curso.serie === Serie.CORRECAO_FLUXO_ENSINO_MEDIO.value) {
            seriesOpt = [Serie.ENSINO_MEDIO_ANO_1, Serie.ENSINO_MEDIO_ANO_2, Serie.ENSINO_MEDIO_ANO_3];
          }

          this.setState({ series: seriesOpt, seriesSelecionadas });
        }

        this.temTurmas = curso.temTurmas && this.props.dadosUsuario.login !== 'ojuara';

				this.setState({ cursoSelecionado: curso }, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar curso',
      });
    }
  }

  contentLoad() {
    this.setState({
      cicloEnsinoSelecionado: this.ciclosEnsino[this.state.cursoSelecionado.cicloEnsino],
      seriesDoCiclo: (this.state.cursoSelecionado.cicloEnsino < CicloEnsino.ENSINO_TECNICO.value
        || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
        || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value)
        ? series.slice(this.slicesCursos[this.state.cursoSelecionado.cicloEnsino].inicio,
          this.slicesCursos[this.state.cursoSelecionado.cicloEnsino].fim) : undefined,
      serieSelecionada: (this.state.cursoSelecionado.cicloEnsino < CicloEnsino.ENSINO_TECNICO.value
        || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
        || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value)
        ? series[this.state.cursoSelecionado.serie] : '',
      nomeCurso: this.state.cursoSelecionado.nome,
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.cicloEnsinoSelecionado) {
      this.setState({ cicloEnsinoState: 'danger' });
      ret = false;
    }
    if (this.state.cicloEnsinoSelecionado.value < CicloEnsino.ENSINO_TECNICO.value
      || this.state.cicloEnsinoSelecionado.cicloEnsino === CicloEnsino.EJA.value
      || this.state.cicloEnsinoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value) {
      if (!this.state.serieSelecionada) {
        this.setState({ serieState: 'danger' });
        ret = false;
      }
    } else if (this.state.nomeCurso === '') {
      this.setState({ nomeCursoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarCurso() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando curso...',
    });

    const curso = {
      cicloEnsino: this.state.cicloEnsinoSelecionado.value,
      serie: this.state.serieSelecionada ? this.state.serieSelecionada.value : this.serieNaoDefinida,
      nome: this.state.nomeCurso ? this.state.nomeCurso : this.state.serieSelecionada.label,
      series: this.state.seriesSelecionadas ? this.state.seriesSelecionadas.map((serie) => ({ serie: serie.value })) : [],
    };

    try {
      await this.cursosService.cadastrarCurso(curso);
      this.limparFormulario();

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Curso cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar curso',
      });
    }
  }

  async atualizarCurso() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando curso...',
    });

    const curso = {
      id: this.state.cursoSelecionado.id,
      cicloEnsino: this.state.cicloEnsinoSelecionado.value,
      serie: this.state.serieSelecionada ? this.state.serieSelecionada.value : this.serieNaoDefinida,
      nome: this.state.nomeCurso ? this.state.nomeCurso : this.state.serieSelecionada.label,
      series: this.state.seriesSelecionadas ? this.state.seriesSelecionadas.map((serie) => ({ serie: serie.value })) : [],
    };

    try {
      await this.cursosService.atualizarCurso(curso);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Curso atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar curso',
      });
    }
  }

  limparFormulario() {
    this.setState({
      cicloEnsinoState: '',
      serieState: '',
      nomeCursoState: '',

      cicloEnsinoSelecionado: '',
      serieSelecionada: '',
      seriesDoCiclo: undefined,
      seriesSelecionadas: '',

      nomeCurso: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.cursoSelecionado) {
      this.cadastrarCurso();
    } else {
      this.atualizarCurso();
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
              <BreadcrumbItem active>{this.state.cursoSelecionado ? 'Edição curso' : 'Cadastro curso'}</BreadcrumbItem>
            </Breadcrumb>
            <Form onSubmit={this.onSubmit}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Dados do curso</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Etapa de ensino *</label>
                        <Select
                          isDisabled={this.temTurmas}
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.cicloEnsinoState}`}
                          classNamePrefix="react-select"
                          value={this.state.cicloEnsinoSelecionado}
                          onChange={(event) => {
                            this.setState({
                              series: [],
                              cicloEnsinoState: 'primary',
                              cicloEnsinoSelecionado: event,
                              serieSelecionada: '',
                              seriesDoCiclo: (event.value < CicloEnsino.ENSINO_TECNICO.value
                                || event.value === CicloEnsino.EJA.value
                                || event.value === CicloEnsino.CORRECAO_FLUXO.value)
                                ? series.slice(this.slicesCursos[event.value].inicio,
                                  this.slicesCursos[event.value].fim) : undefined,
                            });
                          }}
                          options={this.ciclosEnsino}
                          placeholder="Etapa de ensino..."
                        />
                        {this.state.cicloEnsinoState === 'danger' ? (
                          <label className="error">
                            Informe o ciclo de ensino.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {this.state.cicloEnsinoSelecionado
                      && (this.state.cicloEnsinoSelecionado.value < CicloEnsino.ENSINO_TECNICO.value
                        || this.state.cicloEnsinoSelecionado.value === CicloEnsino.EJA.value
                        || this.state.cicloEnsinoSelecionado.value === CicloEnsino.CORRECAO_FLUXO.value)
                      && <Col md="3">
                        <FormGroup className={'has-label has-danger'}>
                          <label>Série *</label>
                          <Select
                            isDisabled={this.temTurmas}
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={`react-select primary ${this.state.serieState}`}
                            classNamePrefix="react-select"
                            value={this.state.serieSelecionada}
                            onChange={(event) => {
                              let series = [];
                              if (event.value === Serie.EJA_ENSINO_FUNDAMENTAL.value || event.value === Serie.CORRECAO_FLUXO_ENSINO_FUNDAMENTAL.value) {
                                series = [Serie.ENSINO_FUNDAMENTAL_1, Serie.ENSINO_FUNDAMENTAL_2,
                                Serie.ENSINO_FUNDAMENTAL_3, Serie.ENSINO_FUNDAMENTAL_4, Serie.ENSINO_FUNDAMENTAL_5,
                                Serie.ENSINO_FUNDAMENTAL_6, Serie.ENSINO_FUNDAMENTAL_7, Serie.ENSINO_FUNDAMENTAL_8, Serie.ENSINO_FUNDAMENTAL_9];
                              } else if (event.value === Serie.EJA_ENSINO_MEDIO.value || event.value === Serie.CORRECAO_FLUXO_ENSINO_MEDIO.value) {
                                series = [Serie.ENSINO_MEDIO_ANO_1, Serie.ENSINO_MEDIO_ANO_2, Serie.ENSINO_MEDIO_ANO_3];
                              }
                              this.setState({ serieState: '', serieSelecionada: event, series, seriesSelecionadas: '' });
                            }}
                            options={this.state.seriesDoCiclo}
                            placeholder="Série..."
                          />
                          {this.state.serieState === 'danger' ? (
                            <label className="error">
                              Informe a série do curso.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    }
                    {this.state.cicloEnsinoSelecionado
                      && this.state.cicloEnsinoSelecionado.value >= CicloEnsino.ENSINO_TECNICO.value
                      && <Col md="5">
                        <FormGroup className={`has-label ${this.state.nomeCursoState}`}>
                          <label>Nome do curso *</label>
                          <Input
                            disabled={this.temTurmas}
                            value={this.state.nomeCurso}
                            type="text"
                            maxLength="200"
                            onChange={(event) => this.setState({ nomeCurso: event.target.value, nomeCursoState: 'has-success' })}
                          />
                          {this.state.nomeCursoState === 'has-danger' ? (
                            <label className="error">
                              Informe o nome do curso.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    }
                  </Row>
                  {
                    (this.state.cicloEnsinoSelecionado.value === CicloEnsino.EJA.value || 
                      this.state.cicloEnsinoSelecionado.value === CicloEnsino.CORRECAO_FLUXO.value) && <Row>
                      <Col md="12">
                        <FormGroup className={'has-label has-danger'}>
                          <label>Séries englobadas</label>
                          <Select
                            isDisabled={this.temTurmas}
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={`react-select primary ${this.state.seriesState}`}
                            classNamePrefix="react-select"
                            placeholder={'Séries englobadas pelo curso'}
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.seriesSelecionadas}
                            onChange={(value) => {
                              this.setState({
                                seriesSelecionadas: value,
                                seriesState: '',
                              });
                            }}
                            options={this.state.series}
                          />
                          {this.state.seriesState === 'danger' ? (
                            <label className="error">
                              Informe as séries englobadas pelo curso
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  {
                    this.temTurmas && <Row>
                      <Col>
                        <FormGroup className={'has-label'}>
                          <label>Observações</label>
                          <Input
                            value={'Existem turmas que usam este curso, por isso ele não pode ser alterado.'}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  <div className="category form-category">
                    * Campos obrigatórios
                  </div>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col md="6" className="text-left">
                      <Button
                        color="primary" disabled={this.state.buttonDisabled}
                        onClick={() => this.props.history.push('/gestor/cursos')} >
                        Voltar
                      </Button>
                    </Col>
                    <Col md="6" className="text-right">
                      {
                        !this.state.cursoSelecionado
                          ? <Button color="primary" disabled={this.state.buttonDisabled}>
                            Cadastrar
                          </Button>
                          : <Button style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled || this.temTurmas}>
                            Atualizar
                          </Button>
                      }
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text='Atualizando...' />
      </div>
    );
  }
}

export default withRouter(Cursos);

