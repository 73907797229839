import React from 'react';
import { withRouter } from 'hoc/withRouter';

import AdaptiveTable from 'views/components/AdaptiveTable';

import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Papel from 'assets/csv/papeis.json';

class Servidores extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-servidores-${this.props.papel}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarServidores = this.carregarServidores.bind(this);

    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();

    this.state = {
      showAlert: false,
      dadosTabela: {
        header: [['Email', '30%'], ['Nome', '70%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['email_str', 'nome_str'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    this.carregarServidores(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarServidores(pagina, linhasPorPagina, like) {
    this.setState({ showAlert: false });

    try {
      const data = await this.usuarioService
        .carregarUsuarios(this.props.papel, pagina, linhasPorPagina, like);

      const fn = (usuario) => new Promise((resolve) => resolve(
        this.professorAuxiliarService
          .carregarProfessorAuxiliar(usuario.id),
      ));

      const actions = data.usuarios.map(fn);

      const professorAuxiliar = await Promise.all(actions);

      data.usuarios.forEach((servidor, idx) => {
        Object.assign(servidor, {
          email_str: servidor.credenciais.email,
          nome_str: servidor.nome ? servidor.nome : '-',
          checked: professorAuxiliar[idx].auxiliar,
          checked2: professorAuxiliar[idx].contratado,
          professorAuxiliar: professorAuxiliar[idx],
        });
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      if (this.umount) return;
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar servidores cadastrados',
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  async atualizarProfessorAuxiliar(servidor, checked) {
    try {
      Object.assign(servidor, {
        checked,
        professorAuxiliar: {
          ...servidor.professorAuxiliar,
          auxiliar: checked,
        },
      });

      const id = await this.professorAuxiliarService
        .salvarProfessorAuxiliar(servidor.professorAuxiliar);

      Object.assign(servidor, {
        professorAuxiliar: {
          ...servidor.professorAuxiliar,
          auxiliar: checked,
          id,
        },
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Professor auxiliar atualizado com sucesso',
      });
    } catch (e) {
      if (this.umount) return;
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar professor auxiliar',
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  async atualizarProfessorContratado(servidor, checked2) {
    try {
      Object.assign(servidor, {
        checked2,
        professorAuxiliar: {
          ...servidor.professorAuxiliar,
          contratado: checked2,
        },
      });

      const id = await this.professorAuxiliarService
        .salvarProfessorAuxiliar(servidor.professorAuxiliar);

      Object.assign(servidor, {
        professorAuxiliar: {
          ...servidor.professorAuxiliar,
          contratado: checked2,
          id,
        },
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Professor contratado atualizado com sucesso',
      });
    } catch (e) {
      if (this.umount) return;
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar professor contratado',
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  servidorSelecionado(servidor) {
    this.props.history.push(`/gestor/servidores/${this.props.categoria}/${servidor.id}`);
  }

  render() {
    return (
      <AdaptiveTable
        selectedPage={this.paginaSelecionada}
        rowsPerPage={this.linhasPorPagina}
        rowsPerPageCallback={(info) => {
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.linhasPorPagina = info.rowsPerPage;
          sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
          this.carregarServidores(this.paginaSelecionada, info.rowsPerPage, this.like);
        }}
        likeCallback={(text) => {
          this.like = text;
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarServidores(this.paginaSelecionada, this.linhasPorPagina, text);
        }}
        paginatorCallback={(page) => {
          this.paginaSelecionada = page;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarServidores(this.paginaSelecionada, this.linhasPorPagina, this.like);
        }}
        rowCallback={(servidor) => {
          this.servidorSelecionado(servidor);
        }}
        checkBoxCallback={(servidor, checked) => {
          this.atualizarProfessorAuxiliar(servidor, checked);
        }}
        checkBoxCallback2={(servidor, checked) => {
          this.atualizarProfessorContratado(servidor, checked);
        }}
        data={this.state.dadosTabela}
        showCheckBox={this.props.papel === Papel.PROFESSOR.value}
        checkBoxTitle="Auxiliar"
        showCheckBox2={this.props.papel === Papel.PROFESSOR.value}
        checkBoxTitle2="Contratado"
        disableActions
        clickRows />
    );
  }
}

export default withRouter(Servidores);
