import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Table,
	Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import RelatoriosService from 'services/RelatoriosEstatisticosService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class RelatoriosAlunos extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showAlert: false,
		}
		this.entesService = new EntesService();
		this.relatoriosService = new RelatoriosService();
	}

	async componentDidMount() {
		try {
			const dadosEnte = await this.entesService
				.carregarDadosEnte();

			this.setState({
				dadosEnte,
			})
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async imprimirRelatorioAlunosSemMatriculas() {
		this.setState({
			showAlert: false,
			showLoaderModal: true,
			loaderModalText: 'Imprimindo relatório de alunos sem matrículas...',
		});

		try {
			const pdf = await this.relatoriosService
				.imprimirRelatorioAlunosSemMatriculas();

			const file = Utils.base64ToBlob(pdf);
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Relatório de alunos sem matrícula impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao imprimir relatório de alunos sem matrículas',
			});
		}
	}

	render = () => <div className="content">
		<Row>
			<Col md="12">
				<Alert
					color={this.state.alertColor}
					isOpen={this.state.showAlert}
					toggle={() => { this.setState({ showAlert: false }); }}>
					{this.state.alertMsg}
				</Alert>
				<Breadcrumb>
					<BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
					<BreadcrumbItem active>Relatórios de alunos</BreadcrumbItem>
				</Breadcrumb>
			</Col>
		</Row>
		<Row>
			<Col>
				{
					this.state.dadosEnte ? <Card>
						<CardHeader>
							<CardTitle></CardTitle>
						</CardHeader>
						<CardBody>
							<Table hover>
								<thead className="text-primary">
									<tr>
										<th>Relatórios de alunos</th>
									</tr>
								</thead>
								<tbody>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/porescola`)}>
										<td>Relatório de alunos por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/porturma`)}>
										<td>Relatório de alunos por turma</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/pneporescola`)}>
										<td>Relatório de alunos com necessidades educacionais específicas por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/pneporturma`)}>
										<td>Relatório de alunos com necessidades educacionais específicas por turma</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/poridade`)}>
										<td>Relatório de alunos por idade</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/foradefaixaporescola`)}>
										<td>Relatório de alunos fora de faixa por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/abaixodamediaporturma`)}>
										<td>Relatório de alunos abaixo da média por turma</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/habilidadesadquiridasporaluno`)}>
										<td>Relatório de objetivos de aprendizagem/habilidades adquiridas por aluno</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/habilidadesadquiridasporturma`)}>
										<td>Relatório de objetivos de aprendizagem/habilidades adquiridas por turma</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/notasporturma`)}>
										<td>Relatório de notas de alunos por turma</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/mediasporescola`)}>
										<td>Relatório de médias de alunos por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/notaspormateria`)}>
										<td>Relatório de notas de alunos por componente curricular</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/notasporprofessor`)}>
										<td>Relatório de notas de alunos por professor</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/notasporbimestre`)}>
										<td>Relatório de notas de alunos por bimestre</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/frequenciapormes`)}>
										<td>Relatório de frequência de alunos por mês</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/frequenciaporbimestre`)}>
										<td>Relatório de frequência de alunos por bimestre</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/frequenciapormateria`)}>
										<td>Relatório de frequência de alunos por componente curricular/objetivo de aprendizagem</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/contagemporescola`)}>
										<td>Relatório de contagem de alunos por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/contagemporescolaetapa`)}>
										<td>Relatório de contagem de alunos por escola e etapa de ensino</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/percentualaprovadoreprovadoporescola`)}>
										<td>Relatório de contagem de alunos aprovados/reprovados por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/faltas`)}>
										<td>Relatório de faltas do aluno</td>
									</tr>
									{
										this.props.role === Papel.GESTOR.value && <tr
											className='tr-clickable'
											onClick={() => this.imprimirRelatorioAlunosSemMatriculas()}>
											<td>Relatórios de alunos sem matrículas e sem históricos</td>
										</tr>
									}
									{
										this.state.dadosEnte.avaliacaoEspecial && <tr
											className='tr-clickable'
											onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/pei`)}>
											<td>Relatório de plano educacional individualizado</td>
										</tr>
									}
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/turno`)}>
										<td>Relatório de alunos por turno por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/matriculados/dependencia`)}>
										<td>Relatório de alunos matriculados em dependência</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos/aprovados/dependencia`)}>
										<td>Relatório de alunos aprovados em dependência</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunosbolsafamilia/porescola`)}>
										<td>Relatório de alunos do bolsa família por escola</td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/fardamentoalunos/porescola`)}>
										<td>Relatório de fardamento de alunos por escola</td>
									</tr>
								</tbody>
							</Table>
						</CardBody>
						<CardFooter>
							<Row>
								<Col md="6" className="text-left">
									<Button
										color="primary"
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios`)} >
										Voltar
									</Button>
								</Col>
							</Row>
						</CardFooter>
					</Card> : <Card>
						<div align="center" style={{ margin: 50 }}>
							<Loader
								type="Oval"
								color="#053d7c"
								height="50"
								width="50" />
						</div>
					</Card>
				}
			</Col>
		</Row>
		<LoaderModal
			isOpen={this.state.showLoaderModal}
			text={this.state.loaderModalText} />

	</div>

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
			</div>
		);
	}
}

export default withRouter(RelatoriosAlunos);
