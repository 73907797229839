import React from 'react';

import {
	Modal,
	ModalBody,
	Spinner,
} from 'reactstrap';

class LoaderModal extends React.Component {
	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}>
				<ModalBody>
					<div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
						<Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
						<br /><br />
						{this.props.text}
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

export default LoaderModal;
