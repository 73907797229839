import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import TransportesService from 'services/TransportesService';
import UsuarioService from 'services/UsuarioService';

class AdicionarUsuarioTransporte extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.transportesService = new TransportesService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      carregandoPassageiros: false,
      passageiroSelecionado: null,

      passageiros: [],
    };

    this.adicionarPassageiro = this.adicionarPassageiro.bind(this);
  }


  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoPassageiros: false, passageiros: [] });
      } else {
        this.carregarPassageiros(text);
      }
    }, 1500);

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const transporte = await this.transportesService
        .carregarTransporte(this.props.match.params.idTransporte);

      this.setState({
        anoLetivo,
        transporte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.passageiroSelecionado) {
      this.setState({ passageiroState: 'danger' });
      ret = false;
    }

    return ret;
  }

  async adicionarPassageiro(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    try {
      await this.transportesService
        .adicionarPassageiro(this.props.match.params.idTransporte,
          this.state.passageiroSelecionado.value,
          this.props.match.params.idAnoLetivo);

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Passageiro adicionado com sucesso',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao adicionar passageiro',
      });
    }
  }

  limparFormulario() {
    this.setState({
      passageiroState: '',
      passageiroSelecionado: null,
      passageiros: [],
      carregandoPassageiros: false,
    });
  }

  async carregarPassageiros(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuariosDoEnte(1, 99999, like);

      const selectPassageiros = [];
      data.usuarios.forEach((passageiro) => {
        selectPassageiros.push({
          value: passageiro.id,
          label: `${passageiro.nome} (Mãe: ${passageiro.nomeMae
            ? passageiro.nomeMae : 'Não informada'})`,
        });
      });

      this.setState({
        carregandoPassageiros: false,
        passageiros: selectPassageiros,
      });
    } catch (e) {
      this.setState({
        carregandoPassageiros: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar usuários cadastrados',
      });
    }
  }

  conteudoPagina() {
    return this.state.transporte ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/transporte/${this.state.transporte.id}`}>Placa {this.state.transporte.placa}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Adicionar passageiro</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.adicionarPassageiro}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Adicionar passageiro</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Passageiro *</label>
                      <Select
                        isLoading={this.state.carregandoPassageiros}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.passageiroState}`}
                        classNamePrefix="react-select"
                        value={this.state.passageiroSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoPassageiros: text !== '', passageiroState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ passageiroState: 'primary', passageiroSelecionado: event });
                        }}
                        options={this.state.passageiros}
                        placeholder="Digite o nome de um usuário cadastro no sistema..."
                      />
                      {this.state.passageiroState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um usuário cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/transporte/${this.state.transporte.id}`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Adicionando usuário ao transporte..." />
      </div>
    );
  }
}

export default withRouter(AdicionarUsuarioTransporte);
