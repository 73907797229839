import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Row,
	Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import TextareaAutosize from 'react-textarea-autosize';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import AbonosService from 'services/AbonosService';
import AnosLetivosService from 'services/AnosLetivosService';
import DiariosService from 'services/DiariosService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';
import FrequenciaService from 'services/FrequenciaService';
import LoginService from 'services/LoginService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import TurmasService from 'services/TurmasService';

import Papel from 'assets/csv/papeis.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Turno from 'assets/csv/turnos.json';

class Abono extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.validarCampos = this.validarCampos.bind(this);
    this.salvarAbonos = this.salvarAbonos.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.abonosService = new AbonosService();
    this.frequenciaService = new FrequenciaService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();
      
      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const faltas = await this.frequenciaService
        .carregarFaltasDoDiario(this.props.match.params.idDiario);

      const abonos = await this.abonosService
        .carregarAbonos(this.props.match.params.idDiario);

      const fn2 = (abono) => new Promise((resolve) => resolve(
        this.frequenciaService
          .carregarMotivosAusenciaPorMatricula(this.props.match.params.idDiario, abono.matricula.id),
      ));

      const actions2 = abonos.map(fn2);

      const motivosAusencia = await Promise.all(actions2);

      abonos.forEach((abono, idx) => Object.assign(
        abono, { totalFaltasJustificadas: motivosAusencia[idx].length },
      ));

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        abonos,
        faltas,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    this.state.abonos.forEach((abono, idx) => {
      if (!abono.quantidade && abono.quantidade !== 0) {
        ret = false;
        Object.assign(abono, {
          quantidadeState: 'has-danger',
          quantidadeError: 'Informe a quantidade de abonos',
        });
      }
      if (abono.quantidade && ((abono.quantidade + abono.totalFaltasJustificadas) > this.totalFaltas(this.state.faltas[idx]))) {
        ret = false;
        Object.assign(abono, {
          quantidadeState: 'has-danger',
          quantidadeError: 'Quantidade de faltas abonadas e faltas justificadas maior que total de faltas',
        });
      }
    });
    return ret;
  }

  async salvarAbonos() {
    if (!this.validarCampos()) {
      this.forceUpdate();
      return;
    }

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.abonosService.salvarAbonos(
        this.state.diario.id, this.state.abonos,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Abonos de faltas salvos com sucesso',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar abonos de faltas',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  totalFaltas = (faltas) => faltas.faltasBimestre1
    + faltas.faltasBimestre2
    + faltas.faltasBimestre3
    + faltas.faltasBimestre4;

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>
              {this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Abono de faltas</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Abono de faltas
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '30%' }}>Aluno</th>
                    <th style={{ width: '10%' }} className="text-center">Total faltas</th>
                    <th style={{ width: '10%' }} className="text-center">Faltas Justificadas</th>
                    <th style={{ width: '10%' }} className="text-center">Faltas abonadas</th>
                    <th style={{ width: '40%' }} className="text-center">Justificativa do abono de faltas</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.abonos.map((abono, idx) => <tr key={idx}>
                      <td>{abono.matricula.usuario.nome} (Mãe: {abono.matricula.usuario.nomeMae}) {abono.matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[abono.matricula.situacao].label})` : ''}</td>
                      <td className="text-center">{this.totalFaltas(this.state.faltas[idx])}</td>
                      <td className="text-center">{abono.totalFaltasJustificadas}</td>
                      <td>
                        <FormGroup className={`has-label ${abono.quantidadeState}`}>
                          <Input
                            disabled={this.props.role !== Papel.PROFESSOR.value}
                            value={abono.quantidade}
                            decimalSeparator={false}
                            allowNegative={false}
                            style={{ textAlign: 'right' }}
                            onValueChange={(val) => {
                              Object.assign(
                                abono, { quantidade: parseInt(val.formattedValue), quantidadeState: '' },
                              );
                              this.forceUpdate();
                            }}
                            tag={NumberFormat} />
                          {abono.quantidadeState === 'has-danger' ? (
                            <label className="error">
                              {abono.quantidadeError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup className={`has-label ${abono.justificativaState}`}>
                          <TextareaAutosize
                            disabled={this.props.role !== Papel.PROFESSOR.value}
                            className={`autoheight-curriculo ${abono.justificativaState}`}
                            value={abono.justificativa}
                            onChange={(event) => {
                              Object.assign(abono,
                                {
                                  justificativa: event.target.value,
                                  justificativaState: 'primary',
                                });
                              this.forceUpdate();
                            }} />
                          {abono.justificativaState === 'has-danger' ? (
                            <label className="error">
                              Informe a justificativa do abono.
                            </label>
                          ) : null}
                        </FormGroup>
                      </td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      onClick={this.salvarAbonos} >
                      Lançar abonos
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando abonos de faltas..." />
      </div>
    );
  }
}

export default withRouter(Abono);
