import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	Button,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';

import moment from 'moment';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Utils from 'utils/Utils';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import MateriasService from 'services/MateriasService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import SituacaoFrequencia from 'assets/csv/situacoes-frequencia.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Papel from 'assets/csv/papeis.json';

class Frequencia extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.situacoes = [
			SituacaoFrequencia.PRESENTE,
			SituacaoFrequencia.AUSENTE,
			SituacaoFrequencia.TRANSFERIDO,
			SituacaoFrequencia.DESISTENTE,
			SituacaoFrequencia.NAO_MATRICULADO,
			SituacaoFrequencia.FALECIDO,
		];

		this.state = {
			showAlert: false,
			showLoaderModal: false,
			showConfirmModal: false,
			carregandoFrequencia: true,

			error: null,
		};

		this.entesService = new EntesService();
		this.loginService = new LoginService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.frequenciaService = new FrequenciaService();
		this.materiasService = new MateriasService();
		this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
		this.professorAuxiliarService = new ProfessorAuxiliarService();

		this.adicionarAula = this.adicionarAula.bind(this);
		this.removerAula = this.removerAula.bind(this);
		this.cadastrarFrequencia = this.cadastrarFrequencia.bind(this);
		this.confirmarRemocaoFrequencia = this.confirmarRemocaoFrequencia.bind(this);
		this.removerFrequencia = this.removerFrequencia.bind(this);
		this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);
	}

	adicionarAula(disabled) {
		const limite = this.state.anoLetivo.ente.estado.uf === 'PB' ? 16 : 10;
		this.state.matriculas.forEach((matricula) => {
			if (matricula.frequencia.length === limite) return;
			let situacao;
			switch (matricula.situacao) {
				case SituacaoMatricula.TRANSFERIDO.value:
					situacao = SituacaoFrequencia.TRANSFERIDO.value;
					break;
				case SituacaoMatricula.DESISTENTE.value:
					situacao = SituacaoFrequencia.DESISTENTE.value;
					break;
				case SituacaoMatricula.FALECIDO.value:
					situacao = SituacaoFrequencia.FALECIDO.value;
					break;
				case SituacaoMatricula.NAO_MATRICULADO.value:
					situacao = SituacaoFrequencia.NAO_MATRICULADO.value;
					break;
				default:
					situacao = SituacaoFrequencia.PRESENTE.value;
					break;
			}
			matricula.frequencia.push({
				situacao,
				ordem: matricula.frequencia.length + 1,
				matricula: { id: matricula.id },
				diario: { id: this.state.diario.id },
				disabled,
			});
		});
		this.forceUpdate();
	}

	adicionarAulaAluno(matricula, disabled) {
		const limite = this.state.anoLetivo.ente.estado.uf === 'PB' ? 16 : 10;

		if (matricula.frequencia.length === limite) return;
		let situacao;
		switch (matricula.situacao) {
			case SituacaoMatricula.TRANSFERIDO.value:
				situacao = SituacaoFrequencia.TRANSFERIDO.value;
				break;
			case SituacaoMatricula.DESISTENTE.value:
				situacao = SituacaoFrequencia.DESISTENTE.value;
				break;
			case SituacaoMatricula.FALECIDO.value:
				situacao = SituacaoFrequencia.FALECIDO.value;
				break;
			case SituacaoMatricula.NAO_MATRICULADO.value:
				situacao = SituacaoFrequencia.NAO_MATRICULADO.value;
				break;
			default:
				situacao = SituacaoFrequencia.PRESENTE.value;
				break;
		}
		matricula.frequencia.push({
			situacao,
			ordem: matricula.frequencia.length + 1,
			matricula: { id: matricula.id },
			diario: { id: this.state.diario.id },
			disabled,
		});
		this.forceUpdate();
	}

	removerAula() {
		this.state.matriculas.forEach((matricula) => {
			if (matricula.frequencia.length === 0) return;
			matricula.frequencia.pop();
		});
		this.forceUpdate();
	}

	removerAulaAluno(matricula) {
		if (matricula.frequencia.length === 0) return;
		matricula.frequencia.pop();
		this.forceUpdate();
	}

	async cadastrarFrequencia() {
		const frequencia = this.state.matriculas.map((matricula) => matricula.frequencia);

		this.setState({
			showLoaderModal: true,
			showAlert: false,
			loaderModalText: 'Salvando frequência...',
		});

		try {
			await this.frequenciaService.cadastrarFrequencia(
				this.props.match.params.idDiario, this.state.data, frequencia,
			);

			const aulasLancadas = await this.diariosService
				.carregarAulasLancadas(this.props.match.params.idDiario);

			this.setState({
				aulasLancadas,
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Frequência cadastrada com sucesso',
			});
		} catch (e) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao cadastrar frequência',
			});
		}
	}

	confirmarRemocaoFrequencia() {
		this.setState({ showConfirmModal: true });
	}

	async removerFrequencia() {
		this.setState({
			showLoaderModal: true,
			showAlert: false,
			loaderModalText: 'Removendo frequência...'
		});

		try {
			await this.frequenciaService
				.removerFrequencia(this.props.match.params.idDiario, this.state.data);

			this.carregarFrequencia(this.state.diaSelecionado, this.state.fimDeSemana);

			const aulasLancadas = await this.diariosService
				.carregarAulasLancadas(this.props.match.params.idDiario);

			this.setState({
				aulasLancadas,
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Frequência removida com sucesso',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao remover frequência',
			});
		}
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const dadosEnte = await this.entesService.carregarDadosEnte();

			const professoreAuxiliar = await this.professorAuxiliarService
				.carregarMeuProfessorAuxiliar();

			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const turma = await this.turmasService
				.carregarTurma(this.props.match.params.idTurma);

			const diario = await this.diariosService
				.carregarDiario(this.props.match.params.idDiario);

			const aulasLancadas = await this.diariosService
				.carregarAulasLancadas(this.props.match.params.idDiario);

			const matriculas = await this.turmasService
				.carregarListaFrequencia(this.props.match.params.idTurma, this.props.match.params.idDiario);

			const fn = (matricula) => new Promise((resolve) => resolve(this.registroSituacaoMatriculaService
				.carregarRegistrosSituacaoMatricula(matricula.id)));

			const actions = matriculas.map(fn);

			const registros = await Promise.all(actions);

			matriculas.forEach((matricula, idx) => Object.assign(matricula, { registros: registros[idx] }));

			const materiasProfessor = await this.materiasService
				.carregarMateriasDoProfessor(this.props.match.params.idTurma)

			const diasUteis = await this.anosLetivosService
				.carregarDiasUteisAnoLetivoProfessor(this.props.match.params.idAnoLetivo,
					this.props.match.params.idDiario);

			const selectDiasUteis = [];
			diasUteis.forEach((diaUtil) => {
				selectDiasUteis.push({ value: diaUtil, label: diaUtil });
			});

			const diaSelecionado = {
				value: this.props.match.params.data,
				label: this.props.match.params.data,
			};

			const mData = moment(this.props.match.params.data, 'DD-MM-YYYY');
			const fimDeSemana = mData.day() === 6 || mData.day() === 0;

			this.setState({
				dadosEnte,
				aulasLancadas,
				anoLetivo,
				escola,
				turma,
				diario,
				data: this.props.match.params.data,
				matriculas,
				selectDiasUteis,
				diaSelecionado,
				materiasProfessor,
				fimDeSemana,
				auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
			});

			this.carregarFrequencia(diaSelecionado, fimDeSemana);
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarFrequencia(diaSelecionado, fimDeSemana) {
		this.setState({
			carregandoFrequencia: true,
			showAlert: false,
		});

		try {
			const mData = moment(diaSelecionado.value, 'DD-MM-YYYY');
			const dia = mData.weekday() - 1;

			const frequencias = await this.frequenciaService
				.carregarFrequenciaPorData(this.props.match.params.idDiario, diaSelecionado.value);

			const horariosAulasDoDia = await this.diariosService
				.carregarHorariosAulasPorDia(this.props.match.params.idDiario, dia)

			this.state.matriculas.forEach((matricula) => {
				const situacao = Utils.getSituacaoNoDia(matricula, mData);
				Object.assign(matricula, {
					situacao,
					frequencia: frequencias.filter((frequencia) => frequencia.matricula.id === matricula.id),
				});
				matricula.frequencia.forEach((f, idx) => {
					Object.assign(f, {
						disabled: !fimDeSemana && (horariosAulasDoDia[idx] ?
							!this.state.materiasProfessor.some((m) => horariosAulasDoDia[idx].materia.id === m.id) : true),
					});
				});
			});

			if (this.state.dadosEnte.frequenciaFixa && frequencias.length === 0) {
				horariosAulasDoDia.forEach((ha) => {
					const disabled = !this.state.materiasProfessor.some((m) => ha.materia.id === m.id)
					this.adicionarAula(disabled);
				});
			}

			this.setState({
				carregandoFrequencia: false,
				diaSelecionado,
				data: diaSelecionado.value,
			});
		} catch (e) {
			this.setState({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao carregar frequência',
			});
		}
	}

	voltar() {
		if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
			return `${this.props.layout}`
				+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
				+ `/escola/${this.state.escola.id}`
				+ `/turma/${this.state.turma.id}`
				+ `/diario/${this.state.diario.id}`;
		}
		return `${this.props.layout}`
			+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
			+ `/escola/${this.state.escola.id}`
			+ `/turma/${this.state.turma.id}`
			+ `/diario/${this.state.diario.id}/alfabetizacao`;
	}

	getTooltip(situacao, aula) {
		switch (situacao) {
			case SituacaoFrequencia.PRESENTE.value:
				return `Presente na ${aula}ª aula`;
			case SituacaoFrequencia.AUSENTE.value:
				return `Ausente na ${aula}ª aula`;
			default:
				return this.situacoes[situacao].label2;
		}
	}

	getDataLimite(dataStr, lastTime) {
		if (!dataStr) return undefined;

		const dataLimite = moment(dataStr, 'DD/MM/YYYY');
		if (lastTime) {
			dataLimite.set('hours', 23);
			dataLimite.set('minutes', 59);
			dataLimite.set('seconds', 59);
		}

		return dataLimite;
	}

	dataLimiteExcedida() {
		if (!this.state.dadosEnte.limitesBimestrais) return false;

		const today = moment();

		const mData = moment(this.state.data, 'DD/MM/YYYY');

		const dataInicioBimestre1 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre1);
		const dataInicioBimestre2 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre2);
		const dataInicioBimestre3 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre3);
		const dataInicioBimestre4 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre4);

		const dataFimBimestre1 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre1, true);
		const dataFimBimestre2 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre2, true);
		const dataFimBimestre3 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre3, true);
		const dataFimBimestre4 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre4, true);

		const dataLimiteBimestre1 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre1, true);
		const dataLimiteBimestre2 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre2, true);
		const dataLimiteBimestre3 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre3, true);
		const dataLimiteBimestre4 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre4, true);

		if (dataLimiteBimestre1 && today.isAfter(dataLimiteBimestre1) && mData.isBetween(dataInicioBimestre1, dataFimBimestre1, undefined, '[]')) {
			return true;
		} else if (dataLimiteBimestre2 && today.isAfter(dataLimiteBimestre2) && mData.isBetween(dataInicioBimestre2, dataFimBimestre2, undefined, '[]')) {
			return true;
		} else if (dataLimiteBimestre3 && today.isAfter(dataLimiteBimestre3) && mData.isBetween(dataInicioBimestre3, dataFimBimestre3, undefined, '[]')) {
			return true;
		} else if (dataLimiteBimestre4 && today.isAfter(dataLimiteBimestre4) && mData.isBetween(dataInicioBimestre4, dataFimBimestre4, undefined, '[]')) {
			return true;
		}
		return false;
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/frequencia`}>Calendário Frequência</Link></BreadcrumbItem>
						<BreadcrumbItem active>Frequência {this.state.data.replace(/-/gi, '/')}</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">
								<Row>
									<Col>
										Frequência {this.state.data.replace(/-/gi, '/')}
									</Col>
									<Col className="text-right">
										&emsp;Aulas a lançar:&nbsp;
										{
											this.state.diario.aulasALancar || this.state.diario.aulasALancar === 0
												? `${this.state.aulasLancadas} / ${this.state.diario.aulasALancar}`
												: 'Não informado'
										}
									</Col>
								</Row>
							</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="2">
									<Select
										autosize={true}
										noOptionsMessage={() => 'Nenhuma entrada'}
										className={`react-select primary`}
										classNamePrefix="react-select"
										value={this.state.diaSelecionado}
										onChange={(event) => {
											this.carregarFrequencia(event, this.state.fimDeSemana);

											this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
												+ `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
												+ `/diario/${this.state.diario.id}/frequencia/data/${event.value}`);
										}}
										options={this.state.selectDiasUteis}
										placeholder="Dia..."
									/>
								</Col>
							</Row>
							<br />
							{
								this.state.carregandoFrequencia ? <div align="center" style={{ margin: 50 }}>
									<Loader
										type="Oval"
										color="#053d7c"
										height="50"
										width="50" />
								</div> : <>
									<Table>
										<thead className="text-primary">
											<tr>
												<th style={{ width: '20%' }}>Nome</th>
												<th style={{ width: '20%' }}>Mãe</th>
												<th className="text-right" style={{ width: '60%' }}>
													{
														this.props.role === Papel.PROFESSOR.value &&
														this.state.diario.curso !== "Atendimento Educacional Especializado" && <>
															{
																(!this.state.dadosEnte.frequenciaFixa || this.state.fimDeSemana) && <>
																	<Button
																		disabled={this.dataLimiteExcedida()}
																		id="btnMenosAulas"
																		size="sm"
																		className="btn-round btn-link btn-icon"
																		color="primary"
																		onClick={() => this.removerAula()}>
																		<i className="fa fa-minus" />
																	</Button>
																	<UncontrolledTooltip placement="bottom" target="btnMenosAulas" delay={0}>
																		Menos aulas
																	</UncontrolledTooltip>
																</>
															}
															&nbsp;Aulas&nbsp;
															{
																(!this.state.dadosEnte.frequenciaFixa || this.state.fimDeSemana) && <>
																	<Button
																		disabled={this.dataLimiteExcedida()}
																		id="btnMaisAulas"
																		size="sm"
																		className="btn-round btn-link btn-icon"
																		color="primary"
																		onClick={() => this.adicionarAula()}>
																		<i className="fa fa-plus" />
																	</Button>
																	<UncontrolledTooltip placement="bottom" target="btnMaisAulas" delay={0}>
																		Mais aulas
																	</UncontrolledTooltip>
																</>
															}
														</>
													}
												</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.matriculas.map((matricula, idx1) => (
													<tr key={idx1}>
														<td>{matricula.usuario.nome}</td>
														<td>{matricula.usuario.nomeMae}</td>
														<td className="text-right">
															{
																matricula.frequencia.map((frequencia, idx2) => <div style={{ display: 'inline' }} key={idx2}>
																	<Button
																		id={`btnFreq${idx1}${idx2}`}
																		size="sm"
																		// eslint-disable-next-line no-nested-ternary
																		color={frequencia.situacao === SituacaoFrequencia.PRESENTE.value
																			? 'success' : frequencia.situacao === SituacaoFrequencia.AUSENTE.value ? 'danger' : 'default'}
																		disabled={this.props.role !== Papel.PROFESSOR.value || (this.state.dadosEnte.frequenciaFixa && frequencia.disabled)}
																		onClick={() => {
																			if (matricula.situacao === SituacaoMatricula.NAO_MATRICULADO.value) {
																				if (frequencia.situacao === SituacaoFrequencia.NAO_MATRICULADO.value) {
																					Object.assign(frequencia, {
																						situacao: SituacaoFrequencia.AUSENTE.value,
																					});
																				} else {
																					Object.assign(frequencia, {
																						situacao: SituacaoFrequencia.NAO_MATRICULADO.value,
																					});
																				}
																			} else {
																				switch (frequencia.situacao) {
																					case SituacaoFrequencia.TRANSFERIDO.value:
																					case SituacaoFrequencia.DESISTENTE.value:
																					case SituacaoFrequencia.FALECIDO.value:
																					case SituacaoFrequencia.NAO_MATRICULADO.value:
																						return;
																					default:
																				}
																				Object.assign(frequencia, {
																					situacao: (frequencia.situacao + 1) % 2,
																				});
																			}
																			this.forceUpdate();
																		}}>{this.situacoes[frequencia.situacao].label}</Button>
																	<UncontrolledTooltip placement="bottom" target={`btnFreq${idx1}${idx2}`} delay={0}>
																		{matricula.frequencia.length > 1 ? this.getTooltip(frequencia.situacao, idx2 + 1) : this.situacoes[frequencia.situacao].label2}
																	</UncontrolledTooltip>&nbsp;
																</div>)
															}
															{
																this.props.role === Papel.PROFESSOR.value &&
																this.state.diario.curso === "Atendimento Educacional Especializado" && <>
																	<Button
																		disabled={this.dataLimiteExcedida()}
																		id="btnMenosAulas"
																		size="sm"
																		className="btn-round btn-link btn-icon"
																		color="primary"
																		onClick={() => this.removerAulaAluno(matricula)}>
																		<i className="fa fa-minus" />
																	</Button>
																	<UncontrolledTooltip placement="bottom" target="btnMenosAulas" delay={0}>
																		Menos aulas
																	</UncontrolledTooltip>
																	<Button
																		disabled={this.dataLimiteExcedida()}
																		id="btnMaisAulas"
																		size="sm"
																		className="btn-round btn-link btn-icon"
																		color="primary"
																		onClick={() => this.adicionarAulaAluno(matricula)}>
																		<i className="fa fa-plus" />
																	</Button>
																	<UncontrolledTooltip placement="bottom" target="btnMaisAulas" delay={0}>
																		Mais aulas
																	</UncontrolledTooltip>
																</>
															}
														</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								</>
							}
						</CardBody>
						<CardFooter>
							<Row>
								<Col className="text-left">
									<Button
										color="primary"
										onClick={() => this.props.history
											.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/frequencia`)} >
										Voltar
									</Button>
								</Col>
								<Col className="text-right">
									{
										this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <div>
											{
												this.state.dadosEnte.frequenciaFixa && <Button
													color="danger"
													onClick={this.confirmarRemocaoFrequencia} >
													Remover frequência
												</Button>
											}
											{' '}
											<Button
												disabled={this.dataLimiteExcedida()}
												color="primary"
												onClick={this.cadastrarFrequencia} >
												Lançar frequência
											</Button>
										</div>
									}
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#053d7c"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.showLoaderModal}
					text={this.state.loaderModalText} />
				<ConfirmModal
					isOpen={this.state.showConfirmModal}
					callback={(confirm) => {
						this.setState({ showConfirmModal: false });
						if (confirm) {
							this.removerFrequencia();
						}
					}}
					text='Confirme a remoção da frequência' />
			</div>
		);
	}
}

export default withRouter(Frequencia);
