import React from 'react';

import AdaptiveTable from 'views/components/AdaptiveTable';

import TurmasService from 'services/TurmasService';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class RelatorioMatriculasPorSerieGrupo extends React.Component {
	constructor(props) {
		super(props);

		this.situacoes = [
			SituacaoMatricula.MATRICULADO,
			SituacaoMatricula.TRANSFERIDO,
			SituacaoMatricula.DESISTENTE,
			SituacaoMatricula.APROVADO,
			SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
			SituacaoMatricula.REPROVADO_POR_NOTA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
			SituacaoMatricula.NAO_MATRICULADO,
			SituacaoMatricula.FALECIDO,
		];

		this.idPagina = `${this.props.dadosUsuario.idVinculo}-relatorio-matriculas-serie-grupo-${this.props.serie}-${this.props.idAnoLetivo}-${this.props.idEscola}`;
		this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
		this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
		this.like = '';

		const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

		this.state = {
			dadosTabela: {
				header: [['Nome', '20%'], ['Mãe', '20%'], ['Escola', '20%'], ['Turma', '10%'], [curso, '20%'], ['Situação', '10%']],
				columnAlign: ['text-left', 'text-left', 'text-left', 'text-left', 'text-left', 'text-left'],
				keys: ['nome', 'mae', 'escola', 'nomeTurma', 'curso', 'situacao_str'],
				rows: [],
				total: undefined,
			},
		};

		this.turmasService = new TurmasService();
	}

	async componentDidMount() {
		await this.carregarMatriculasPorAnoLetivoEEscola(
			this.paginaSelecionada, this.linhasPorPagina, this.like,
		);
	}

	async carregarMatriculasPorAnoLetivoEEscola(pagina, linhasPorPagina, like) {
		try {
			const data = await this.turmasService
				.carregarMatriculasPorAnoLetivoEEscola(this.props.idAnoLetivo,
					this.props.idEscola, this.props.situacao, this.props.serie,
					pagina, linhasPorPagina, like);

			data.matriculas.forEach((matricula) => {
				Object.assign(matricula, {
					nome: matricula.usuario.nome,
					mae: matricula.usuario.nomeMae,
					situacao_str: this.situacoes[matricula.situacao].label
				});
			});

			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela,
					{ rows: data.matriculas, total: data.total }),
			});
		} catch (e) {
			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
			});
		}
	}

	render() {
		return (
			<div style={{ paddingTop: '20px' }}>
				<AdaptiveTable
					selectedPage={this.paginaSelecionada}
					rowsPerPage={this.linhasPorPagina}
					disableActions
					rowsPerPageCallback={(info) => {
						this.paginaSelecionada = 1;
						sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
						this.linhasPorPagina = info.rowsPerPage;
						sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
						this.carregarMatriculasPorAnoLetivoEEscola(
							this.paginaSelecionada, info.rowsPerPage, this.like,
						);
					}}
					likeCallback={(text) => {
						this.like = text;
						this.paginaSelecionada = 1;
						sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
						this.carregarMatriculasPorAnoLetivoEEscola(
							this.paginaSelecionada, this.linhasPorPagina, text,
						);
					}}
					paginatorCallback={(page) => {
						this.paginaSelecionada = page;
						sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
						this.carregarMatriculasPorAnoLetivoEEscola(
							this.paginaSelecionada, this.linhasPorPagina, this.like,
						);
					}}
					data={this.state.dadosTabela} />
			</div>
		);
	}
}

export default RelatorioMatriculasPorSerieGrupo;
