import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

class ConfirmModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}>
        <ModalBody>
          <div align="center" style={{ paddingTop: '20px' }}>
            <p>
              {this.props.text}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => { this.props.callback(false); }}>
            {this.props.cancelText || 'Cancelar'}
            </Button>
          <Button
            color={this.props.confirmColor || 'danger'}
            type="button"
            onClick={() => { this.props.callback(true); }}>
            {this.props.confirmText || 'Excluir'}
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal >
    );
  }
}

export default ConfirmModal;
