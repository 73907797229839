import React from 'react';
import { withRouter } from 'hoc/withRouter';

import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';

import CursosService from 'services/CursosService';
import AnosLetivosService from 'services/AnosLetivosService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class Cursos extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-cursos`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;

    this.like = '';

    this.anosLetivosService = new AnosLetivosService();
    this.cursosService = new CursosService();

    this.state = {
      showAlert: false,

      cursoSelecionado: null,

      showLoaderModal: false,
      showConfirmModal: false,

      dadosTabela: {
        header: [['Nome', '30%'], ['Etapa de ensino', '50%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'ciclo'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarCursos = this.carregarCursos.bind(this);
    this.removerCurso = this.removerCurso.bind(this);
    this.confirmarRemocaoCurso = this.confirmarRemocaoCurso.bind(this);
    this.editarCurso = this.editarCurso.bind(this);
  }

  async componentDidMount() {
    this.carregarCursos(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarCursos(pagina, linhasPorPagina, like) {
    try {
      const data = await this.cursosService
        .carregarCursos(pagina, linhasPorPagina, like);

      data.cursos.forEach((curso) => {
        Object.assign(curso, { ciclo: this.ciclosEnsino[curso.cicloEnsino].label });
      });

      this.setState({
        dadosTabela: Object.assign(
          this.state.dadosTabela,
          { rows: data.cursos, total: data.total },
        ),
      });
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar cursos cadastrados',
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  editarCurso(curso) {
    this.props.history.push({
      pathname: `/gestor/cursos/edicao/${curso.id}`,
    });
  }

  confirmarRemocaoCurso(curso) {
    this.setState({ cursoSelecionado: curso, showConfirmModal: true });
  }

  async removerCurso() {
    this.setState({ showLoaderModal: true });

    try {
      await this.cursosService.removerCurso(this.state.cursoSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Curso removido com sucesso',
      });

      this.carregarCursos(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover curso',
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Cursos</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Cursos cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disablePrintAction
                  clickRows
                  editButtonCallback={(curso) => this.editarCurso(curso)}
                  removeButtonCallback={(curso) => this.confirmarRemocaoCurso(curso)}
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarCursos(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarCursos(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarCursos(this.paginaSelecionada, this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(curso) => {
                    this.props.history.push({
                      pathname: `/gestor/cursos/${curso.id}`,
                    });
                  }}
                  data={this.state.dadosTabela} />
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary"
                  onClick={() => {
                    this.props.history.push({ pathname: '/gestor/cursos/cadastro' });
                  }}>
                  Cadastrar curso
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerCurso();
            } else {
              this.setState({ cursoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do curso' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </div>
    );
  }
}

export default withRouter(Cursos);
