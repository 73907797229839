import BaseService from './BaseService';

class FrequenciaService extends BaseService {
  carregarFrequencia(idDiario, idMatricula, data) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/diario/${idDiario}/matricula/${idMatricula}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaPorData(idDiario, data) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/diario/${idDiario}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaPeriodo(idDiario, dataInicioStr, dataFimStr) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/diario/${idDiario}/inicio/${dataInicioStr}/fim/${dataFimStr}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarFrequencia(idDiario, data, frequencia) {
    const requestInfo = {
      method: 'post',
      url: `/frequencia/diario/${idDiario}/data/${data}`,
      data: JSON.stringify(frequencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarFrequenciaMes(idDiario, frequencias) {
    const requestInfo = {
      method: 'post',
      url: `/frequencia/mes/diario/${idDiario}`,
      data: JSON.stringify(frequencias),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiasLancados(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/diario/${idDiario}/diaslancados`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	carregarDiasLancadosTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/turma/multiseriado/${idTurma}/diaslancados`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFaltasDoDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/faltas/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFaltasTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/faltas/turma/multiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFaltasDoDiarioPorMes(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/faltas/diario/${idDiario}/mes`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaPorMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiasFaltas(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/dias/faltas/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFaltasMatricula(idMatricula, idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/faltas/matricula/${idMatricula}/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarFrequenciaMultiseriado(idTurma, data, frequencia) {
    const requestInfo = {
      method: 'post',
      url: `/frequencia/turma/multiseriado/${idTurma}/data/${data}`,
      data: JSON.stringify(frequencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarFrequenciaMesMultiseriado(idTurma, frequencias) {
    const requestInfo = {
      method: 'post',
      url: `/frequencia/turma/multiseriado/${idTurma}/mes`,
      data: JSON.stringify(frequencias),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaTurmaMultiseriado(idTurma, idMatricula, data) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/turma/multiseriado/${idTurma}/matricula/${idMatricula}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaTurmaMultiseriadoPorData(idTurma, data) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/turma/multiseriado/${idTurma}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFrequenciaTurmaMultiseriadoPeriodo(idTurma, dataInicioStr, dataFimStr) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/turma/multiseriado/${idTurma}/inicio/${dataInicioStr}/fim/${dataFimStr}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMotivosAusencia(idFrequencia) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/${idFrequencia}/motivos/ausencia`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMotivosAusenciaPorMatricula(idDiario, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/motivos/ausencia/diario/${idDiario}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarMotivosAusencia(idDiario, idMatricula, frequencia) {
    const requestInfo = {
      method: 'post',
      url: `/frequencia/motivos/ausencia/diario/${idDiario}/matricula/${idMatricula}`,
      data: JSON.stringify(frequencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulasLancadasTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/frequencia/aulaslancadas/turmamultiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerFrequencia(idDiario, data) {
    const requestInfo = {
      method: 'delete',
      url: `/frequencia/diario/${idDiario}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerFrequenciaMultiseriado(idTurma, data) {
    const requestInfo = {
      method: 'delete',
      url: `/frequencia/turma/multiseriado/${idTurma}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default FrequenciaService;
