import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Form,
} from 'reactstrap';

class ConfirmTypingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      senhaState: '',
    };
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}>
        <Form onSubmit={(e) => {
          e.preventDefault();
          if (!this.state.typedText) {
            this.setState({ senhaState: 'has-danger' });
          } else {
            this.props.callback(this.state.typedText);
          }
        }}>
          <ModalBody>
            <br />
            <Row>
              <Col md="12" className="text-center">
                <b>{this.props.deletionInstruction}</b>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="12" className="text-center">
                <font color="red">{this.props.deletionWarning}</font>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={{ size: 10, offset: 1 }} className="text-center">
                <FormGroup className={`has-label ${this.state.senhaState}`}>
                  <Input
                    type="password"
                    maxLength="50"
                    placeholder={this.props.deletionText}
                    onChange={
                      (event) => this.setState({
                        typedText: event.target.value,
                        senhaState: '',
                      })
                    }
                  />
                  {this.state.senhaState === 'has-danger' ? (
                    <label className="error">
                      Informe a senha.
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.setState({ typedText: undefined });
                this.props.callback(undefined);
              }}>
              Cancelar
            </Button>
            <Button
              color="danger"
              type="submit">
              {this.props.buttonText || 'Excluir'}
            </Button>&nbsp;&nbsp;&nbsp;
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default ConfirmTypingModal;
