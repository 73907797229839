import React from 'react';

import {
  Button,
	Table,
} from 'reactstrap';

import Horario from 'assets/csv/horarios.json';

import Papel from 'assets/csv/papeis.json';

class HorariosAulas extends React.Component {
  constructor(props) {
    super(props);

    this.tempos = [
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
    ];

    this.horarios = [
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
      Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
      Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
      Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
      Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
      Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
      Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
      Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
      Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5]
    ];

    this.state = {
      horariosAulas: this.props.horariosAulas,
    };
  }

  drop = (ev) => {
    ev.preventDefault();
    const materia = JSON.parse(ev.dataTransfer.getData('materia'));
    const hr = ev.currentTarget.getAttribute('hr');
    const dia = ev.currentTarget.getAttribute('dia');
    if (dia === '5' && !this.props.sabadoLetivo) return;
    const horariosAulas = { ...this.state.horariosAulas };
    horariosAulas[hr][dia] = { materia: { id: materia.id, nome: materia.nome } };
    this.setState({ horariosAulas });
    this.props.dropCallback(hr, dia, { materia: { id: materia.id, nome: materia.nome } });
  }

  drag = (ev, materia) => {
    ev.dataTransfer.setData('materia', JSON.stringify(materia));
  }

  remove = (ev) => {
    ev.preventDefault();
    if (this.props.role === Papel.PROFESSOR.value) return;
    const hr = ev.currentTarget.getAttribute('hr');
    const dia = ev.currentTarget.getAttribute('dia');
    const horariosAulas = { ...this.state.horariosAulas };
    horariosAulas[hr][dia] = null;
    this.setState({ horariosAulas });
    this.props.dropCallback(hr, dia, null);
  }

  render() {
    return (
      <>
        {
          this.props.role !== Papel.PROFESSOR.value && <Table className="materias">
            <tbody>
              <tr>
                {
                  this.props.materias
                    .map((materia, idx) => <td dragable="true" key={idx}>
                      <Button
                        className="cursor-move"
                        size="sm"
                        draggable="true"
                        onDragStart={(ev) => this.drag(ev, materia)}
                        onClick={(ev) => ev.preventDefault()}>
                        {materia.nome}
                      </Button>
                    </td>)
                }
              </tr>
            </tbody>
          </Table>
        }
        <Table className="horarios">
          <thead>
            <tr>
              <th className="td-hr"></th>
              <th>Segunda</th>
              <th>Terça</th>
              <th>Quarta</th>
              <th>Quinta</th>
              <th>Sexta</th>
              <th>Sábado</th>
            </tr>
          </thead>
          <tbody>
            {
              this.horarios[this.props.turno].map((horario, index) => <tr key={index}>
                <td className="td-hr">{horario.label}</td>
                {
                  this.state.horariosAulas[horario.value].map((horarioAula, idx) => <td key={idx} bgcolor={idx === 5 && !this.props.sabadoLetivo ? '#EEEEEE' : null}>
                    <div hr={horario.value} dia={idx} className="drop-div" onDrop={this.drop} onDragOver={(ev) => ev.preventDefault()}>
                      {
                        horarioAula && <div
                          className="cursor-pointer"
                          hr={horario.value}
                          dia={idx}
                          size="sm"
                          onClick={(ev) => this.remove(ev)}>
                          {horarioAula.materia.nome}
                        </div>
                      }
                    </div>
                  </td>)
                }
              </tr>)
            }
          </tbody>
        </Table>
        {
          this.props.role !== Papel.PROFESSOR.value && <Table className="materias">
            <tbody>
              <tr>
                {
                  this.props.materias
                    .map((materia, idx) => <td dragable="true" key={idx}>
                      <Button
                        size="sm"
                        draggable="true"
                        onDragStart={(ev) => this.drag(ev, materia)}
                        onClick={(ev) => ev.preventDefault()}>
                        {materia.nome}
                      </Button>
                    </td>)
                }
              </tr>
            </tbody>
          </Table>
        }
      </>
    );
  }
}

export default HorariosAulas;
