import React from 'react';
import { withRouter } from 'hoc/withRouter';

import env from 'conf/env.json';

class RootFooter extends React.Component {
  render = () => <footer id="footer" className="fixed-bottom">
    <div className="container d-md-flex py-4">
      <div className="col-md-6 footer-left">
        <i className="icofont-envelope"></i>&nbsp;<hidden-info data-user={env.EMAIL.split('@')[0]} data-domain={env.EMAIL.split('@')[1]}>@</hidden-info>
      </div>
      <div className="col-md-6 footer-right">
        <div className="copyright">
          &copy; {1900 + new Date().getYear()}, <span>{env.SYSNAME}</span>
        </div>
      </div>
    </div>
  </footer>
}

export default withRouter(RootFooter);
