import React from 'react';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';

class EscolaSecretarios extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-escola-secretarios-${this.props.match.params.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.escolasService = new EscolasService();

    this.state = {
      dadosTabela: {
        header: [['Email', '30%'], ['Nome', '60%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['email_str', 'nome_str'],
        rows: [],
        total: undefined,
      },
    };
  }


  async componentDidMount() {
    await this.carregarSecretarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarSecretarios(pagina, linhasPorPagina, like) {
    try {
      const data = await this.escolasService
        .carregarSecretarios(this.props.match.params.idEscola,
          pagina, linhasPorPagina, like);

      data.secretarios.forEach((secretario) => {
        Object.assign(secretario, {
          login_str: secretario.usuario.credenciais.login,
          email_str: secretario.usuario.credenciais.email,
          nome_str: secretario.usuario.nome ? secretario.usuario.nome : '-',
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.secretarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar secretários da escola',
      });
    }
  }

  confirmarRemocaoSecretario(secretario) {
    this.setState({ secretarioSelecionado: secretario, showConfirmModal: true });
  }

  async removerSecretario() {
    try {
      this.setState({ showAlert: false, showLoaderModal: true });

      await this.escolasService
        .removerSecretario(this.state.secretarioSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Secretário removido com sucesso',
      });

      this.carregarSecretarios(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao remover secretário',
      });
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions={this.props.disableActions}
          disablePrintAction
          disableEditAction
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarSecretarios(this.paginaSelecionada,
              this.linhasPorPagina, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarSecretarios(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          removeButtonCallback={(secretario) => this.confirmarRemocaoSecretario(secretario)}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarSecretarios(this.paginaSelecionada,
              this.linhasPorPagina, this.like);
          }}
          data={this.state.dadosTabela} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerSecretario();
            } else {
              this.setState({ secretarioSelecionado: null });
            }
          }}
          text='Confirme a exclusão do secretário' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

export default EscolaSecretarios;
