import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';

import Utils from 'utils/Utils';

import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import EventoModal from 'views/components/EventoModal';

import EventosService from 'services/EventosService';

import Papel from 'assets/csv/papeis.json';

class AgendaEscola extends React.Component {
	constructor(props) {
		super(props);

		this.messages = {
			allDay: 'Dia Inteiro',
			previous: '<',
			next: '>',
			today: 'Hoje',
			month: 'Mês',
			week: 'Semana',
			day: 'Dia',
			agenda: 'Agenda',
			date: 'Data',
			time: 'Hora',
			event: 'Evento',
			showMore: (total) => `+ (${total}) Eventos`,
		}

		this.localizer = momentLocalizer(moment);

		this.state = {
			eventoSelecionado: {
				titulo: '',
			}
		};

		this.eventosService = new EventosService();

	}

	async componentDidMount() {
		await this.carregarEventosDaEscola();
	}

	async cadastrarEventoEscola(evento) {
		this.props.callbackMessage({
			showAlert: false,
			loaderModalText: 'Cadastrando evento...',
		})

		this.setState({
			showEventoModal: false,
			showLoaderModal: true,
		});

		try {
			Object.assign(evento, {
				anoLetivo: { id: this.props.match.params.idAnoLetivo },
				escola: { id: this.props.match.params.idEscola },
			});

			await this.eventosService.cadastrarEventoEscola(
				this.props.match.params.idAnoLetivo,
				this.props.match.params.idEscola,
				evento,
			);

			await this.carregarEventosDaEscola();

			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'success',
				alertMsg: `Evento ${!evento.id ? 'cadastrado' : 'atualizado'} com sucesso`,
			});

			this.setState({
				showLoaderModal: false,
			});
		} catch (e) {
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || `Erro ao ${!evento.id ? 'cadastrar' : 'atualizar'} evento`,
			});

			this.setState({
				showLoaderModal: false,
			});
		}
	}

	async carregarEventosDaEscola() {
		const eventosEscola = await this.eventosService
			.carregarEventosDaEscola(this.props.match.params.idAnoLetivo, this.props.match.params.idEscola);

		eventosEscola.forEach((evento) => Object.assign(evento, {
			escola: true,
			autor: 'Escola',
		}));

		const eventosAnoLetivo = await this.eventosService
			.carregarEventosDoAnoLetivo(this.props.match.params.idAnoLetivo);

		eventosAnoLetivo.forEach((evento) => Object.assign(evento, {
			escola: false,
			autor: 'SME',
		}));

		const eventos = [...eventosEscola, ...eventosAnoLetivo];

		eventos.forEach((evento) => Object.assign(evento, {
			title: `${evento.autor}: ${evento.titulo}`,
			allDay: evento.diaInteiro,
			start: moment(evento.inicio, 'DD/MM/YYYY HH:mm:ss').toDate(),
			end: moment(evento.fim, 'DD/MM/YYYY HH:mm:ss').toDate(),
			hexColor: Utils.getCorEvento(evento.tipo),
		}));

		this.setState({ eventos });
	}

	abrirModalEvento(evento) {
		const eventoSelecionado = {
			id: evento.id,
			titulo: evento.titulo || '',
			tipo: evento.tipo || evento.tipo === 0 ? evento.tipo : '',
			descricao: evento.descricao || '',
			diaInteiro: evento.diaInteiro || true,
			inicio: evento.start || new Date(),
			fim: evento.end || new Date(),
			escola: evento.escola !== false
		};

		this.setState({
			showEventoModal: true,
			eventoSelecionado,
		});
	}

	confirmarRemocaoEventoEscola(idEvento) {
		this.setState({
			idEventoSelecionado: idEvento,
			showEventoModal: false,
			showConfirmModal: true,
		});
	}

	async removerEventoEscola() {
		this.props.callbackMessage({
			showAlert: false,
		});

		this.setState({
			showLoaderModal: true,
			loaderModalText: 'Excluindo evento...'
		});

		try {
			await this.eventosService
				.removerEventoEscola(this.state.idEventoSelecionado);

			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Evento removido com sucesso',
			});

			this.setState({
				showLoaderModal: false,
			});

			this.carregarEventosDaEscola();
		} catch (msg) {
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao remover evento',
			});

			this.setState({
				showLoaderModal: false,
			});
		}
	}

	eventStyleGetter(event, start, end, isSelected) {
		var backgroundColor = event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			color: 'black',
		};
		return {
			style: style
		};
	}

	render() {
		return <>
			<Calendar
				selectable
				localizer={this.localizer}
				events={this.state.eventos}
				startAccessor="start"
				endAccessor="end"
				style={{ height: 800 }}
				onSelectEvent={(event) => this.abrirModalEvento(event)}
				onSelectSlot={(event) => (this.props.role === Papel.DIRETOR.value || this.props.role === Papel.SECRETARIO.value) ? this.abrirModalEvento(event) : undefined}
				eventPropGetter={(this.eventStyleGetter)}
				messages={this.messages}
			/>
			<LoaderModal
				isOpen={this.state.showLoaderModal}
				text={this.state.loaderModalText} />
			<ConfirmModal
				isOpen={this.state.showConfirmModal}
				callback={(confirm) => {
					this.setState({
						showConfirmModal: false
					});
					if (confirm) {
						this.removerEventoEscola();
					} else {
						this.setState({ idEventoSelecionado: null });
					}
				}}
				text="Confirme a exclusão do evento." />
			<EventoModal
				onlyRead={(this.props.role !== Papel.DIRETOR.value && this.props.role !== Papel.SECRETARIO.value) || !this.state.eventoSelecionado.escola}
				professor={false}
				isOpen={this.state.showEventoModal}
				evento={this.state.eventoSelecionado}
				callbackCadastrar={(evento) => this.cadastrarEventoEscola(evento)}
				callbackExcluir={(idEvento) => this.confirmarRemocaoEventoEscola(idEvento)} />
		</>;
	}
}

export default AgendaEscola;
