import React from 'react';
import { withRouter } from 'hoc/withRouter';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import UsuarioService from 'services/UsuarioService';

class MatriculasOnlinePais extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      dadosTabela: {
        header: [['Nome', '40%'], ['Turma', '40%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'turma'],
        rows: [],
        total: 0,
      },
    };
  }

  async componentDidMount() {
    try {
      const filhos = await this.usuarioService.carregarFilhos();
      this.setState({ filhos });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.filhos ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Matrículas Online</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Matrículas Online</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Table hover>
                    <thead className="text-primary">
                      <tr>
                        <th>
                          FILHO
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.filhos.map((filho, idx) => <tr 
                          key={idx} 
                          className='tr-clickable'
                          onClick={() => this.props.history.push(`${this.props.layout}/matriculasonline/filho/${filho.id}`)}>
                          <td>{ filho.nome }</td>
                        </tr>)
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(MatriculasOnlinePais);