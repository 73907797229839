import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	Table,
	Button,
	Input,
	UncontrolledTooltip,
	FormGroup,
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import AvaliacoesService from 'services/AvaliacoesService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import Papel from 'assets/csv/papeis.json';

class Notas extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.situacoesMatricula = [
			SituacaoMatricula.MATRICULADO,
			SituacaoMatricula.TRANSFERIDO,
			SituacaoMatricula.DESISTENTE,
			SituacaoMatricula.APROVADO,
			SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
			SituacaoMatricula.REPROVADO_POR_NOTA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
			SituacaoMatricula.NAO_MATRICULADO,
			SituacaoMatricula.FALECIDO,
		];

		this.salvarAvaliacoes = this.salvarAvaliacoes.bind(this);
		this.conteudoRecuperacao = this.conteudoRecuperacao.bind(this);
		this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);

		this.state = {
			showAlert: false,
			showLoaderModal: false,
			buttonDisabled: false,

			error: null,
		};

		this.loginService = new LoginService();
		this.entesService = new EntesService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.materiasService = new MateriasService();
		this.avaliacoesService = new AvaliacoesService();
		this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
		this.professorAuxiliarService = new ProfessorAuxiliarService();
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const dadosEnte = await this.entesService
				.carregarDadosEnte();

			const professoreAuxiliar = await this.professorAuxiliarService
				.carregarMeuProfessorAuxiliar();

			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const turma = await this.turmasService
				.carregarTurma(this.props.match.params.idTurma);

			const diario = await this.diariosService
				.carregarDiario(this.props.match.params.idDiario);

			const materia = await this.materiasService
				.carregarMateria(this.props.match.params.idMateria);

			const matriculas = await this.diariosService
				.carregarMatriculasNaMateria(this.props.match.params.idDiario,
					this.props.match.params.idMateria);

			const avaliacoes = await this.avaliacoesService
				.carregarAvaliacoes(this.props.match.params.idDiario,
					this.props.match.params.idMateria);

			const exercicios = await this.avaliacoesService
				.carregarExerciciosPorDiarioEMateria(this.props.match.params.idDiario, 
					this.props.match.params.idMateria);

			avaliacoes.forEach((avaliacao) => {
				const exerciciosAvaliacao = exercicios.filter((exercicio) => exercicio.avaliacao.id === avaliacao.id);
				Object.assign(avaliacao, {
					exercicios: exerciciosAvaliacao.length > 0 ? exerciciosAvaliacao : undefined,
				})
			});

			matriculas.forEach((matricula) => {
				const avaliacoesMatricula = avaliacoes.filter((avaliacao) => avaliacao.matricula.id === matricula.id);
				Object.assign(matricula, { 
					avaliacoes: avaliacoesMatricula, 
				})
			});

			const materiasProfessor = await this.diariosService
				.carregarMateriasDoProfessor(this.props.match.params.idDiario);

			const avaliacaoAtelie = dadosEnte.avaliacaoAtelie &&
				parseInt(anoLetivo.ano) >= dadosEnte.avaliacaoAtelieAno;

			const selectMaterias = [];
			materiasProfessor.forEach((materia) => {
				if (!avaliacaoAtelie || !materia.atelie) {
					selectMaterias.push({
						label: materia.nome,
						value: materia.id,
					});
				}
			});

			const materiaSelecionada = {
				label: materia.nome,
				value: materia.id,
			};

			this.setState({
				dadosEnte,
				anoLetivo,
				escola,
				turma,
				diario,
				materia,
				matriculas,
				selectMaterias,
				materiaSelecionada,
				auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
			}, () => {
				if (materia.exercicios) {
					avaliacoes.forEach((avaliacao) => {
						if (!avaliacao.exercicios) {
							for (var i = 0; i < materia.exercicios - 1; i++) {
								this.adicionarExercicio(avaliacao.descricaoAvaliacao.id);
							}
						}
					});
				}
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async salvarAvaliacoes() {
		this.setState({
			buttonDisabled: true,
			showAlert: false,
			showLoaderModal: true,
		});

		try {
			const avaliacoes = this.state.matriculas
				.reduce((acc, matricula) => acc.concat(matricula.avaliacoes), []);

			await this.avaliacoesService.salvarAvaliacoes(
				this.state.diario.id, this.state.materia.id, avaliacoes,
			);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Notas salvas com sucesso',
			});
		} catch (e) {
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao salvar notas',
			});
		}
	}

	formatarNumero = (val) => {
		if (val.length === 1) {
			return val;
		}
		if (val.length === 2) {
			return `${val[0]},${val[1]}`;
		}
		if (val.length === 3) {
			if (val === '100') {
				return '10,0';
			}
			return `${val[0]},${val[2]}`;
		}
		return '10,0';
	}

	mediaAnual = (avaliacoes) => {
		let soma = 0;
		let total = 0;

		for (let i = 0; i < avaliacoes.length; i += 1) {
			if (!avaliacoes[i].descricaoAvaliacao.recuperacao) {
				soma += parseFloat(avaliacoes[i].resultado.replace(',', '.'));
				total += 1;
			}
		}
		soma = soma.toFixed(1);
		return parseFloat((soma / total).toFixed(1));
	}

	notaAnual = (avaliacoes) => {
		if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
			return '';
		}

		const mediaAnual = this.mediaAnual(avaliacoes);

		return Number.isInteger(mediaAnual) ? `${mediaAnual},0` : mediaAnual;
	}

	notaFinal = (avaliacoes) => {
		if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
			return '';
		}

		const mediaAnual = this.mediaAnual(avaliacoes);

		if (!this.state.turma.temRecuperacao) {
			return Number.isInteger(mediaAnual) ? `${mediaAnual},0` : mediaAnual;
		}

		if (mediaAnual >= this.state.turma.mediaReprovacaoAntesRecuperacao
			&& mediaAnual < this.state.turma.mediaAprovacao) {
			const avaliacaoRecuperacao = avaliacoes
				.find((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao);

			if (avaliacaoRecuperacao.resultado === '') {
				return '';
			}

			const notaRecuperacao = parseFloat(avaliacaoRecuperacao.resultado.replace(',', '.'));

			let resultado = (this.state.turma.pesoMediaAvaliacoes * mediaAnual
				+ this.state.turma.pesoRecuperacao * notaRecuperacao)
				/ (this.state.turma.pesoMediaAvaliacoes + this.state.turma.pesoRecuperacao);

			resultado = parseFloat(resultado.toFixed(1));

			return Number.isInteger(resultado) ? `${resultado},0` : Math.round(resultado * 10) / 10;
		}

		return Number.isInteger(mediaAnual) ? `${mediaAnual},0` : Math.round(mediaAnual * 10) / 10;
	}

	alunoEmRecuperacao = (avaliacoes) => {
		if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
			return false;
		}

		const mediaAnual = this.mediaAnual(avaliacoes);

		if (mediaAnual >= this.state.turma.mediaReprovacaoAntesRecuperacao
			&& mediaAnual < this.state.turma.mediaAprovacao) {
			return true;
		}

		return false;
	}

	avaliacoesBimestresFeitas = (avaliacoes) => avaliacoes
		.every((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao || avaliacao.resultado !== '')

	conteudoRecuperacao(avaliacoes, recuperacao) {
		return this.alunoEmRecuperacao(avaliacoes) ? recuperacao.resultado : '';
	}

	voltar() {
		if (!this.state.turma.multiSeriado) {
			this.props.history
				.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`);
		} else {
			this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`);
		}
	}

	adicionarExercicio(idDescricaoAvaliacao) {
		this.state.matriculas.forEach((matricula) => {
			matricula.avaliacoes.forEach((avaliacao) => {
				if (avaliacao.descricaoAvaliacao.id === idDescricaoAvaliacao) {
					if (!avaliacao.exercicios) {
						avaliacao.exercicios = [
							{
								resultado: avaliacao.resultado,
								recuperacao: '',
								ordem: 0,
							},
							{
								resultado: '',
								recuperacao: '',
								ordem: 1,
							}
						];
						avaliacao.resultado = '';
					} else {
						const ordem = avaliacao.exercicios.length;
						if (avaliacao.exercicios.length < 3) {
							avaliacao.exercicios.push({
								resultado: '',
								recuperacao: '',
								ordem,
							});
						}
					}
				}
			})
		})

		this.forceUpdate();
	}

	removerExercicio(idDescricaoAvaliacao, i) {
		this.state.matriculas.forEach((matricula) => {
			matricula.avaliacoes.forEach((avaliacao) => {
				if (avaliacao.descricaoAvaliacao.id === idDescricaoAvaliacao) {
					avaliacao.exercicios = avaliacao.exercicios.filter((_, idx) => i !== idx);
					if (avaliacao.exercicios.length === 1) {
						avaliacao.resultado = avaliacao.exercicios[0].resultado
						avaliacao.exercicios = undefined;
					}
				}
			})
		})
		this.forceUpdate();
	}

	calcularNotaAvaliacao(avaliacao) {
		let soma = 0;
		let count = 0;
		avaliacao.exercicios.forEach((exercicio) => {
			if (!exercicio.resultado) return;
			const resultado = parseFloat(exercicio.resultado.replace(',', '.'));
			if (resultado >= this.state.turma.mediaAprovacao || !this.state.dadosEnte.recuperacaoExercicios) {
				exercicio.recuperacao = '';
				soma += resultado;
			} else {
				if (!exercicio.recuperacao) return;
				const recuperacao = parseFloat(exercicio.recuperacao.replace(',', '.'));
				soma += Math.max(resultado, recuperacao);
			}
			count += 1;
		});
		if (count === avaliacao.exercicios.length) {
			let nota = soma / avaliacao.exercicios.length;
			nota = ((Math.round(nota * 10) / 10)).toString();
			avaliacao.resultado = nota === '10' ? '10,0' : this.formatarNumero(nota);
		} else {
			avaliacao.resultado = '';
		}
		this.forceUpdate();
	}

	calcularNotaAvaliacaoSomatorio(avaliacao) {
		let soma = 0;
		let count = 0;
		avaliacao.exercicios.forEach((exercicio, idx) => {
			if (!exercicio.resultado) return;
			const resultado = parseFloat(exercicio.resultado.replace(',', '.'));
			if (resultado >= 3.0 || idx < avaliacao.exercicios.length - 1) {
				exercicio.recuperacao = '';
				soma += resultado;
			} else {
				if (idx === avaliacao.exercicios.length - 1) {
					if (!exercicio.recuperacao) return;
					const recuperacao = parseFloat(exercicio.recuperacao.replace(',', '.'));
					soma += Math.max(resultado, recuperacao);
				};
			}
			count += 1;
		});
		if (count === avaliacao.exercicios.length) {
			let nota = Math.min(soma, 10);
			nota = ((Math.round(nota * 10) / 10)).toString();
			avaliacao.resultado = nota === '10' ? '10,0' : this.formatarNumero(nota);
		} else {
			avaliacao.resultado = '';
		}
		this.forceUpdate();
	}

	num(str) {
		return parseFloat(str.toString().replace(',', '.'))
	}

	dataLimiteExcedida(avaliacao) {
		if (!this.state.dadosEnte.limitesBimestrais) return false;

		let dataLimite = null;
		if (avaliacao.descricaoAvaliacao.nome === '1º bimestre' && this.state.anoLetivo.dataLimiteBimestre1) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre1, 'DD/MM/YYYY');
		} else if (avaliacao.descricaoAvaliacao.nome === '2º bimestre' && this.state.anoLetivo.dataLimiteBimestre2) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre2, 'DD/MM/YYYY');
		} else if (avaliacao.descricaoAvaliacao.nome === '3º bimestre' && this.state.anoLetivo.dataLimiteBimestre3) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre3, 'DD/MM/YYYY');
		} else if (avaliacao.descricaoAvaliacao.nome === '4º bimestre' && this.state.anoLetivo.dataLimiteBimestre4) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre4, 'DD/MM/YYYY');
		}

		if (!dataLimite) return false;

		dataLimite.set('hours', 23);
		dataLimite.set('minutes', 59);
		dataLimite.set('seconds', 59);

		const today = moment();

		return today.isAfter(dataLimite)
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Alert
				color={this.state.alertColor}
				isOpen={this.state.showAlert}
				toggle={() => { this.setState({ showAlert: false }); }}>
				{this.state.alertMsg}
			</Alert>
			<Breadcrumb>
				<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
				<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
				{
					!this.state.turma.multiSeriado
						? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
						: <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
				}
				{
					!this.state.turma.multiSeriado
						? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
						: <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
				}
				<BreadcrumbItem active>Notas {this.state.materia.nome}</BreadcrumbItem>
			</Breadcrumb>
			<Card>
				<CardHeader>
					<CardTitle tag="h4">
						Notas {this.state.materia.nome}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="3">
							<FormGroup className={'has-label has-danger'}>
								<label>Componente curricular</label>
								<Select
									noOptionsMessage={() => 'Nenhuma entrada'}
									className={'react-select primary'}
									classNamePrefix="react-select"
									value={this.state.materiaSelecionada}
									onChange={async (event) => {
										if (!this.state.turma.multiSeriado) {
											this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
												+ `/escola/${this.state.escola.id}`
												+ `/turma/${this.state.turma.id}`
												+ `/diario/${this.state.diario.id}`
												+ `/notas/${event.value}`);
										} else {
											this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
												+ `/escola/${this.props.match.params.idEscola}`
												+ `/turma/multiseriado/${this.props.match.params.idTurma}`
												+ `/diario/${this.props.match.params.idDiario}`
												+ `/notas/${event.value}`);
										}
										this.setState({ loadingNotas: true });
										await this.componentDidMount();
										this.setState({ loadingNotas: false });

									}}
									options={this.state.selectMaterias}
								/>
							</FormGroup>
						</Col>
					</Row>
					{
						this.state.loadingNotas ? <div align="center" style={{ padding: 30 }}>
							<Loader
								type="Oval"
								color="#053d7c"
								height="50"
								width="50" />
						</div> : <Table className="mt-3">
							<thead className="text-primary">
								<tr>
									<th style={{ width: '10%' }}>Aluno</th>
									{
										this.state.matriculas[0] && this.state.matriculas[0].avaliacoes.map((avaliacao, idx) => !avaliacao.descricaoAvaliacao.recuperacao ? <th key={idx} style={{ width: '7.14%' }} className="text-center">
											{avaliacao.descricaoAvaliacao.nome} <i id={`btnDividirNota${idx}`} className="success icone-dividir-nota fas fa-plus-circle"
												onClick={() => {
													if (this.state.dadosEnte.somatorioExercicios) return;
													this.adicionarExercicio(avaliacao.descricaoAvaliacao.id)
												}}></i>
											<UncontrolledTooltip placement="bottom" target={`btnDividirNota${idx}`} delay={0}>
												Dividir nota
											</UncontrolledTooltip>
											<div style={{ display: 'flex', justifyContent: 'space-around' }}>
												{
													avaliacao.exercicios && avaliacao.exercicios.map((_, idx3) => <div key={idx3}>
														EX{idx3 + 1} <br /><i id={`btnRemoverNota${idx}`} className="icone-dividir-nota fas fa-minus-circle"
															onClick={() => {
																if (this.state.dadosEnte.somatorioExercicios) return;
																this.removerExercicio(avaliacao.descricaoAvaliacao.id, idx3)
															}}></i>
													</div>)

												}
												{avaliacao.exercicios && <div>NB</div>}
											</div>
										</th> : null)
									}
									<th style={{ width: '2%' }} className="text-center">Média Anual</th>
									{
										this.state.matriculas[0] && this.state.matriculas[0].avaliacoes.map((avaliacao, idx) => avaliacao.descricaoAvaliacao.recuperacao ? <th key={idx} style={{ width: '2%' }} className="text-center">
											{avaliacao.descricaoAvaliacao.nome !== 'Recuperação' ? avaliacao.descricaoAvaliacao.nome : 'Prova Final'}
										</th> : null)
									}
									<th style={{ width: '2%' }} className="text-center">Média Final</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.matriculas.map((matricula, idx) => <tr key={idx}>
										<td >{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg" />} {matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? <><br />({this.situacoesMatricula[matricula.situacao].label} - {matricula.dataSituacao})</> : ''}</td>
										{
											matricula.avaliacoes.map((avaliacao, idx2) => !avaliacao.descricaoAvaliacao.recuperacao ? <React.Fragment key={idx2}>
												<td key={idx2} className="text-center">
													<div style={{ display: 'flex', alignItems: 'start' }}>
														{
															avaliacao.exercicios && avaliacao.exercicios.map((exercicio, idx3) => <div key={idx3}>
																<div className="input-nota-exercicio" style={{ paddingRight: 3 }}>
																	<Input
																		disabled={this.dataLimiteExcedida(avaliacao)}
																		className={!this.state.dadosEnte.somatorioExercicios && this.num(exercicio.resultado) < this.state.turma.mediaAprovacao ? 'vermelha' : ''}
																		value={exercicio.resultado}
																		style={{ textAlign: 'right', marginBottom: 3 }}
																		decimalSeparator=","
																		decimalScale={1}
																		format={this.formatarNumero}
																		placeholder={`ex${idx3 + 1}`}
																		onValueChange={(val) => {
																			if (this.state.dadosEnte.somatorioExercicios) {
																				const resultado = parseFloat(val.formattedValue.replace(',', '.'));
																				Object.assign(exercicio, { resultado: resultado > 5 ? '5,0' : val.formattedValue });
																				this.calcularNotaAvaliacaoSomatorio(avaliacao);
																			} else {
																				Object.assign(exercicio, { resultado: val.formattedValue });
																				this.calcularNotaAvaliacao(avaliacao);
																			}
																		}}
																		onBlur={() => {
																			if (exercicio.resultado
																				&& exercicio.resultado.length === 1) {
																				Object.assign(
																					exercicio, { resultado: `${exercicio.resultado},0` },
																				);
																				this.forceUpdate();
																			}
																		}}
																		tag={NumberFormat}
																		max={5}
																	/>
																	{
																		(this.state.dadosEnte.recuperacaoExercicios || (this.state.dadosEnte.somatorioExercicios && idx3 === avaliacao.exercicios.length - 1)) && <Input
																			disabled={
																				!exercicio.resultado ||
																				(this.state.dadosEnte.somatorioExercicios && exercicio.resultado.replace(',', '.') >= 3.0) ||
																				(!this.state.dadosEnte.somatorioExercicios && exercicio.resultado.replace(',', '.') >= this.state.turma.mediaAprovacao) ||
																				this.dataLimiteExcedida(avaliacao)
																			}
																			className={!this.state.dadosEnte.somatorioExercicios && this.num(exercicio.resultado) < this.state.turma.mediaAprovacao ? 'vermelha' : ''}
																			value={exercicio.recuperacao}
																			style={{ textAlign: 'right' }}
																			decimalSeparator=","
																			decimalScale={1}
																			format={this.formatarNumero}
																			placeholder={`rec${idx3 + 1}`}
																			onValueChange={(val) => {
																				if (this.state.dadosEnte.somatorioExercicios) {
																					const resultado = parseFloat(val.formattedValue.replace(',', '.'));
																					Object.assign(exercicio, { recuperacao: resultado > 5 ? '5,0' : val.formattedValue });
																					this.calcularNotaAvaliacaoSomatorio(avaliacao);
																				} else {
																					Object.assign(exercicio, { recuperacao: val.formattedValue });
																					this.calcularNotaAvaliacao(avaliacao);
																				}
																			}}
																			onBlur={() => {
																				if (exercicio.recuperacao
																					&& exercicio.recuperacao.length === 1) {
																					Object.assign(
																						exercicio, { recuperacao: `${exercicio.recuperacao},0` },
																					);
																					this.forceUpdate();
																				}
																			}}
																			tag={NumberFormat}
																		/>
																	}
																</div>
															</div>)
														}
														<div className="input-nota-exercicio" style={{ display: 'flex' }}>
															<Input
																className={this.num(avaliacao.resultado) < this.state.turma.mediaAprovacao ? 'vermelha' : ''}
																value={avaliacao.descricaoAvaliacao.recuperacao
																	? this.conteudoRecuperacao(matricula.avaliacoes, avaliacao)
																	: avaliacao.resultado}
																style={{ textAlign: 'right', height: '100%' }}
																decimalSeparator=","
																decimalScale={1}
																format={this.formatarNumero}
																onValueChange={(val) => {
																	let valor = val.formattedValue;
																	if (avaliacao.exercicios && valor.length === 1) {
																		valor = `${valor},0`
																	}
																	Object.assign(
																		avaliacao, { resultado: valor },
																	);
																	this.forceUpdate();
																}}
																onBlur={() => {
																	if (avaliacao.resultado && avaliacao.resultado.length === 1) {
																		Object.assign(
																			avaliacao, { resultado: `${avaliacao.resultado},0` },
																		);
																		this.forceUpdate();
																	}
																}}
																disabled={avaliacao.exercicios || this.props.role !== Papel.PROFESSOR.value
																	|| (avaliacao.descricaoAvaliacao.recuperacao && !this.alunoEmRecuperacao(matricula.avaliacoes))
																	|| this.dataLimiteExcedida(avaliacao)}
																tag={NumberFormat} />
														</div>
													</div>
												</td>
											</React.Fragment> : null)
										}
										<td className="text-center">
											<div className="input-nota-exercicio">
												<Input
													className={this.num(this.notaAnual(matricula.avaliacoes)) < this.state.turma.mediaAprovacao ? 'vermelha' : ''}
													value={this.notaAnual(matricula.avaliacoes)}
													style={{ textAlign: 'right' }}
													decimalSeparator=","
													decimalScale={1}
													tag={NumberFormat}
													disabled={true} />
											</div>
										</td>
										{
											matricula.avaliacoes.map((avaliacao, idx2) => avaliacao.descricaoAvaliacao.recuperacao ? <td key={idx2} className="input-nota-exercicio text-center">
												<div className="input-nota-exercicio">
													<Input
														className={this.num(this.conteudoRecuperacao(matricula.avaliacoes, avaliacao)) < this.state.turma.mediaAprovacao ? 'vermelha' : ''}
														value={avaliacao.descricaoAvaliacao.recuperacao
															? this.conteudoRecuperacao(matricula.avaliacoes, avaliacao)
															: avaliacao.resultado}
														style={{ textAlign: 'right' }}
														decimalSeparator=","
														decimalScale={1}
														format={this.formatarNumero}
														onValueChange={(val) => {
															Object.assign(
																avaliacao, { resultado: val.formattedValue },
															);
															this.forceUpdate();
														}}
														onBlur={() => {
															if (avaliacao.resultado
																&& avaliacao.resultado.length === 1) {
																Object.assign(
																	avaliacao, { resultado: `${avaliacao.resultado},0` },
																);
																this.forceUpdate();
															}
														}}
														disabled={this.props.role !== Papel.PROFESSOR.value
															|| (avaliacao.descricaoAvaliacao.recuperacao
																&& !this.alunoEmRecuperacao(matricula.avaliacoes))}
														tag={NumberFormat} />
												</div>
											</td> : null)
										}
										<td className="input-nota-exercicio text-center">
											<div className="input-nota-exercicio">
												<Input
													className={this.num(this.notaFinal(matricula.avaliacoes)) < this.state.turma.mediaAprovacaoAposRecuperacao ? 'vermelha' : ''}
													value={this.notaFinal(matricula.avaliacoes)}
													style={{ textAlign: 'right' }}
													decimalSeparator=","
													decimalScale={1}
													tag={NumberFormat}
													disabled={true} />
											</div>
										</td>
									</tr>)
								}
							</tbody>
						</Table>
					}
				</CardBody>
				<CardFooter>
					<Row>
						<Col className="text-left">
							<Button
								color="primary"
								onClick={() => this.voltar()} >
								Voltar
							</Button>
						</Col>
						{
							this.state.matriculas[0]
							&& this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Col className="text-right">
								<Button
									color="primary"
									disabled={this.state.buttonDisabled}
									onClick={this.salvarAvaliacoes} >
									Salvar Notas
								</Button>
							</Col>
						}
					</Row>
				</CardFooter>
			</Card>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#053d7c"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text="Salvando notas..." />
			</div>
		);
	}
}

export default withRouter(Notas);
