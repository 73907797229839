import React from 'react';

import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';

import EscolasService from 'services/EscolasService';
import AnosLetivosService from 'services/AnosLetivosService';

import Papel from 'assets/csv/papeis.json';

class EscolasAnoLetivo extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-escolas-ano-letivo-${this.props.match.params.idAnoLetivo}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.escolasService = new EscolasService();
    this.anosLetivosService = new AnosLetivosService();

    this.state = {
      dadosTabela: {
        header: [['Nome', '60%'], ['Endereço', '30%'], ['Alunos ativos', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-center'],
        keys: ['nome', 'endereco', 'total_alunos'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.carregarEscolas(this.paginaSelecionada,
      this.linhasPorPagina, this.like);
  }

  async carregarEscolas(pagina, linhasPorPagina, like) {
    try {
      const data = this.props.role !== Papel.PROFESSOR.value
        ? await this.escolasService
          .carregarEscolas(pagina, linhasPorPagina, like)
        : await this.escolasService
          .carregarEscolasPorProfessorNoAnoLetivo(
            this.props.idAnoLetivo, pagina, linhasPorPagina, like,
          );

      const fn = (escola) => new Promise((resolve) => resolve(
        this.anosLetivosService.carregarTotalAlunosAtivosPorAnoLetivoEEscola(
          this.props.idAnoLetivo, escola.id, ''),
      ));

      const actions = data.escolas.map(fn);

      const totalAlunos = await Promise.all(actions);
      
      data.escolas.forEach((escola, idx) => {
        Object.assign(escola, {
          total_alunos: totalAlunos[idx],
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.escolas, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  render() {
    return <>
      <Row>
        <Col>
          <AdaptiveTable
            selectedPage={this.paginaSelecionada}
            rowsPerPage={this.linhasPorPagina}
            disableActions
            clickRows
            rowsPerPageCallback={(info) => {
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.linhasPorPagina = info.rowsPerPage;
              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
              this.carregarEscolas(this.paginaSelecionada, info.rowsPerPage, this.like);
            }}
            likeCallback={(text) => {
              this.like = text;
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarEscolas(this.paginaSelecionada, this.linhasPorPagina, text);
            }}
            paginatorCallback={(page) => {
              this.paginaSelecionada = page;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarEscolas(this.paginaSelecionada, this.linhasPorPagina, this.like);
            }}
            rowCallback={(escola) => {
              this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${escola.id}` });
            }}
            data={this.state.dadosTabela}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary" disabled={this.state.buttonDisabled}
            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos`)} >
            Voltar
          </Button>
        </Col>
      </Row>
    </>;
  }
}

export default EscolasAnoLetivo;
