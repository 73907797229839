import AwsService from "services/AwsService";

class AwsUtil {

  constructor() {
    this.awsService = new AwsService();
  }

  async open(url) {
    const newWindow = window.open("", "_blank");
    const preSignedUrl = await this.awsService.getPresignedUrl(url);
    newWindow.location.href = preSignedUrl;
  }

	async openValidacao(url) {
    const newWindow = window.open("", "_blank");
    const preSignedUrl = await this.awsService.getPresignedUrlValidacao(url);
    newWindow.location.href = preSignedUrl;
  }

}

export default new AwsUtil();