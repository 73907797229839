import BaseService from './BaseService';

class ObjetivosAprendizagemCampoExperienciaService extends BaseService {
  salvarObjetivosAprendizagemCampoConhecimento(objetivosAprendizagemCampoExperiencia) {
    const requestInfo = {
      method: 'post',
      url: '/objetivos/campoexperiencia',
      data: JSON.stringify(objetivosAprendizagemCampoExperiencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCampoExperienciaPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/campoexperiencia/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarObjetivosAprendizagemPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarObjetivosEspecificosDaMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/especificos/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoObjetivoAprendizagemDiarioMateria(idHabilidade, idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/${idHabilidade}/diario/${idDiario}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarObjetivosAprendizagemTrabalhados(idDiario, idMateria, objetivosAprendizagem) {
    const requestInfo = {
      method: 'post',
      url: `/objetivos/diario/${idDiario}/materia/${idMateria}/situacoes`,
      data: JSON.stringify(objetivosAprendizagem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoObjetivoAprendizagemMatriculaMateria(idObjetivoEspecifico, idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/${idObjetivoEspecifico}/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacoesObjetivosEspecificosMatriculaMateria(idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/especificos/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacoesObjetivosEspecificosDiarioMateria(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/especificos/diario/${idDiario}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarObjetivosAdquiridosMatriculaMateria(idMatricula, idMateria, objetivosAprendizagem) {
    const requestInfo = {
      method: 'post',
      url: `/objetivos/matricula/${idMatricula}/materia/${idMateria}/situacoes`,
      data: JSON.stringify(objetivosAprendizagem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	carregarObjetivosEspecificosDoObjetivoAprendizagem(idObjetivoAprendizagem) {
    const requestInfo = {
      method: 'get',
      url: `/objetivos/${idObjetivoAprendizagem}/objetivosespecificos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ObjetivosAprendizagemCampoExperienciaService;
