import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Table,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import UsuarioService from 'services/UsuarioService';
import AnosLetivosService from 'services/AnosLetivosService';
import SolicitacaoMatriculaService from 'services/SolicitacaoMatriculaService';

import Turno from 'assets/csv/turnos.json';

class SolicitarMatriculaPais extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();
    this.anosLetivosService = new AnosLetivosService();
    this.solicitacaoMatriculaService = new SolicitacaoMatriculaService();

    this.fileInputRef = React.createRef();
    this.fileChange = this.fileChange.bind(this);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      error: null,

      anexos: [],

      dadosTabela: {
        header: [['Nome', '40%'], ['Turma', '40%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'turma'],
        rows: [],
        total: 0,
      },
    };
  }

  async componentDidMount() {
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const filho = await this.usuarioService
        .carregarUsuario(this.props.match.params.idFilho);

      const turmas = await this.solicitacaoMatriculaService
        .carregarTurmasMatriculaAluno(anoLetivo.id, this.props.match.params.idFilho);

      turmas.forEach((turma) => {
        Object.assign(turma, { selecionada: false });
      });

      this.setState({ anoLetivo, filho, turmas });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarTurmasMatriculaAluno(anoLetivo) {

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Carregando turmas...',
    });

    try {
      const turmas = await this.solicitacaoMatriculaService
        .carregarTurmasMatriculaAluno(anoLetivo.id, this.props.match.params.idFilho);

      this.setState({
        showLoaderModal: false,
        turmas,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar turmas do ano letivo',
      });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.turmaSelecionada) {
      this.setState({ turmaSelecionadaState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async solicitarMatricula() {
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Cadastrando solicitação de matrícula...',
    });

    try {
      const solicitacaoMatricula = {
        turma: { id: this.state.turmaSelecionada.id },
        aluno: { id: this.props.match.params.idFilho },
        arquivos: this.state.anexos,
      };

      await this.solicitacaoMatriculaService
        .solicitarMatricula(solicitacaoMatricula);

      this.limparFormulario();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula cadastrada com sucesso...',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao solicitar matrícula na turma selecionada',
      });
    }
  }

  limparFormulario() {
    this.state.turmas.forEach((turma) => {
      Object.assign(turma, { selecionada: false });
    });
    this.setState({
      turmaSelecionada: undefined,
      anexos: [],
    });
  }

  selecionarTurma(turma) {
    this.state.turmas.forEach((turma) => {
      Object.assign(turma, { selecionada: false });
    });
    Object.assign(turma, { selecionada: !turma.selecionada });
    this.setState({ 
      turmaSelecionada: turma,
      turmaSelecionadaState: '',
    });
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 5000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo permitido é 5MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            const anexo = {
              nome: file.name,
              conteudo: matches[4],
              extensao: ext,
              tipo: matches[1],
            };

            this.setState({ anexos: this.state.anexos.concat([anexo]) });
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  conteudoPagina() {
    return this.state.filho ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/matriculasonline`}>Matrículas Online</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/matriculasonline/filho/${this.props.match.params.idFilho}`}>{this.state.filho.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Solicitação de matrícula {this.state.anoLetivo.ano}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Solicitação de matrícula {this.state.anoLetivo.ano}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  {
                    this.state.turmas.length > 0 ?
                      <>
                        <Table hover>
                          <thead className="text-primary">
                            <tr>
                              <th className="text-center" style={{ width: '3%' }}></th>
                              <th style={{ width: '30%' }}>Escola</th>
                              <th style={{ width: '15%' }}>Série</th>
                              <th style={{ width: '30%' }}>Turma</th>
                              <th className="text-center" style={{ width: '15%' }}>Turno</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.turmas.map((turma, idx) => <tr className='tr-clickable' key={idx} onClick={() => this.selecionarTurma(turma)}>
                                <td className="text-center">
                                  <FormGroup check>
                                    <Label className="form-check-label">
                                      <Input
                                        disabled={this.props.match.params.idMatricula}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={turma.selecionada}
                                        onChange={() => this.selecionarTurma(turma)} />
                                      <span className="form-check-sign">
                                        <span className="check"></span>
                                      </span>
                                    </Label>
                                  </FormGroup>
                                </td>
                                <td>{turma.escola.nome}</td>
                                <td>{turma.curso.nome}</td>
                                <td>{turma.nome}</td>
                                <td className="text-center">{this.turnos[turma.turno].label}</td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                        <FormGroup className={`has-label ${this.state.turmaSelecionadaState}`}>
                          {this.state.turmaSelecionadaState === 'has-danger' ? (
                            <label className="error">
                              Selecione uma turma
                            </label>
                          ) : null}
                        </FormGroup>
                        <Row>
                          <Col>
                            <div className="fileinput text-center">
                              <input
                                type="file"
                                onChange={this.fileChange}
                                ref={this.fileInputRef} />
                            </div>
                            <Button
                              style={{ marginTop: 25 }}
                              color="primary"
                              onClick={() => this.fileInputRef.current.click()}>
                              Anexar arquivo
                            </Button>
                          </Col>
                        </Row>
                        {
                          this.state.anexos.length > 0 && <Table>
                            <thead className="text-primary">
                              <tr>
                                <th style={{ width: '90%' }}>Anexo</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.anexos.map((anexo, idx) => <tr key={idx}>
                                  <td>{anexo.nome}</td>
                                  <td className='text-center'>
                                    <Button
                                      id={`btnRemoverAnexo${idx}`}
                                      className="btn-icon"
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        this.setState({
                                          anexos: this.state.anexos.filter((_, idx2) => idx2 !== idx),
                                        })
                                      }}
                                      type="button">
                                      <i className="fa fa-times" />
                                    </Button>
                                  </td>
                                </tr>)
                              }
                            </tbody>
                          </Table>
                        }
                      </> : <div align="center" style={{ margin: 50 }}>
                        Não foi possível encontrar turmas para o aluno. Por favor se dirija até a unidade de ensino municipal onde o aluno esteve matriculado.
                      </div>
                  }
                </Col>
              </Row>
            </CardBody>
            <br /><br />
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/matriculasonline/filho/${this.props.match.params.idFilho}`)} >
                    Voltar
                  </Button>
                </Col>
                {
                  this.state.turmas.length && <Col md="6" className="text-right">
                    <Button
                      color="primary"
                      onClick={() => this.solicitarMatricula()}>
                      Solicitar matrícula
                    </Button>
                  </Col>
                }
              </Row>
            </CardFooter>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando turmas..." />
      </div>
    );
  }
}

export default withRouter(SolicitarMatriculaPais);