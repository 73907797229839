import React from 'react';
import { withRouter } from 'hoc/withRouter';

import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem, Button,
  Card,
  CardBody,
  CardFooter, CardHeader,
  CardTitle,
  Col, Form, FormGroup,
  Input,
  Row,
} from 'reactstrap';

import AwsUtil from 'utils/AwsUtil';

import ChamadosService from 'services/ChamadosService';
import LoginService from 'services/LoginService';
import UsuarioService from 'services/UsuarioService';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import TipoChamado from 'assets/csv/tipos-chamado.json';

class CadastroChamado extends React.Component {
  constructor(props) {
    super(props);

    this.tipos = [
      TipoChamado.DUVIDA,
      TipoChamado.SUGESTAO,
      TipoChamado.ERRO,
    ];

    this.fileInputRef = React.createRef();

    this.chamadosService = new ChamadosService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      tipoSelecionado: '',
      titulo: '',
      mensagem: '',

      anexos: [],

      loaderModalText: '',

      chamadoSelecionado: null,
      erro: null,
    };

    this.cadastrarChamado = this.cadastrarChamado.bind(this);
    this.atualizarChamado = this.atualizarChamado.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileChange = this.fileChange.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      if (this.props.match.params.idChamado !== undefined) {
        const chamado = await this.chamadosService
          .carregarChamado(this.props.match.params.idChamado);

        const mensagens = await this.chamadosService
          .carregarMensagens(this.props.match.params.idChamado);

        const anexos = await this.chamadosService
          .carregarAnexosMensagem(mensagens[0].id);

        Object.assign(mensagens[0], { anexos });

        this.setState({
          mensagens,
          chamadoSelecionado: chamado,
        }, () => this.contentLoad());

      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      titulo: this.state.chamadoSelecionado.titulo,
      tipoSelecionado: this.tipos[this.state.chamadoSelecionado.tipo],
      mensagem: this.state.mensagens[0].texto,
      idMensagem: this.state.mensagens[0].id,
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.titulo) {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }
    if (!this.state.tipoSelecionado) {
      this.setState({ tipoState: 'danger' });
      ret = false;
    }
    if (!this.state.mensagem) {
      this.setState({ mensagemState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarChamado() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando chamado...',
    });

    const chamado = {
      titulo: this.state.titulo,
      tipo: this.state.tipoSelecionado.value,
      mensagens: [{ texto: this.state.mensagem, arquivos: this.state.anexos }],
    };
    try {
      await this.chamadosService.cadastrarChamado(chamado);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Chamado cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar chamado',
      });
    }
  }

  async atualizarChamado() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando chamado...',
    });

    const chamado = {
      id: this.state.chamadoSelecionado.id,
      titulo: this.state.titulo,
      tipo: this.state.tipoSelecionado.value,
      mensagens: [{
        id: this.state.idMensagem,
        texto: this.state.mensagem,
        arquivos: this.state.anexos,
      }],
    };

    try {
      await this.chamadosService.atualizarChamado(chamado);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Chamado atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar chamado',
      });
    }
  }

  limparFormulario() {
    this.setState({
      titulo: '',
      tipoSelecionado: '',
      mensagem: '',
      anexos: [],

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.chamadoSelecionado) {
      this.cadastrarChamado();
    } else {
      this.atualizarChamado();
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 5000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo permitido é 5MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            const anexo = {
              nome: file.name,
              conteudo: matches[4],
              extensao: ext,
              tipo: matches[1],
            };

            this.setState({ anexos: this.state.anexos.concat([anexo]) });
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idChamado !== undefined
      && this.state.chamadoSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/chamados">Chamados</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.chamadoSelecionado ? 'Atualização' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do chamado</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup className={`has-label ${this.state.tituloState}`}>
                      <label>Título *</label>
                      <Input
                        type="text"
                        value={this.state.titulo}
                        maxLength="200"
                        onChange={(evt) => {
                          this.setState({ titulo: evt.target.value, tituloState: '' });
                        }} />
                      {this.state.tituloState === 'has-danger' ? (
                        <label className="error">
                          Informe o título do chamado.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoState: '', tipoSelecionado: event });
                        }}
                        options={this.tipos}
                        placeholder="Tipo..."
                      />
                      {this.state.tipoState === 'danger' ? (
                        <label className="error">
                          Informe o tipo do chamado.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {
                    this.props.match.params.idChamado && <>
                      <Col md="2">
                        <FormGroup className={'has-label'}>
                          <label>Data</label>
                          <Input
                            disabled
                            type="text"
                            value={this.state.chamadoSelecionado.data} />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={'has-label'}>
                          <label>Protocolo</label>
                          <Input
                            disabled
                            type="text"
                            value={this.state.chamadoSelecionado.protocolo} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.mensagemState}`}>
                      <label>Descrição *</label>
                      <Input
                        value={this.state.mensagem}
                        onChange={(evt) => {
                          this.setState({ mensagem: evt.target.value, mensagemState: '' });
                        }}
                        type="textarea"
                        style={{ height: '200px' }} />
                      {this.state.mensagemState === 'has-danger' ? (
                        <label className="error">
                          Informe a descrição do chamado.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="fileinput text-center">
                      <input
                        type="file"
                        onChange={this.fileChange}
                        ref={this.fileInputRef} />
                    </div>
                    <Button color="primary"
                      onClick={() => this.fileInputRef.current.click()}>
                      Anexar arquivo
                    </Button>
                  </Col>
                </Row>
                {
                  this.props.match.params.idChamado
                  && this.state.mensagens[0].anexos.map((anexo, idx) => <Row key={idx}>
                    <Col><Link to="#" onClick={() => AwsUtil.open(anexo.url)}>{anexo.nome}</Link></Col>
                  </Row>)
                }
                {
                  this.state.anexos.map((anexo, idx) => <Row key={idx}>
                    <Col>{anexo.nome}</Col>
                  </Row>)
                }
                <br />
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push('/gestor/chamados')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                      {!this.state.chamadoSelecionado ? 'Cadastrar' : 'Atualizar'}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroChamado);
