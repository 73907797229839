import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import Aulas from 'views/components/Aulas';
import Tarefas from 'views/components/Tarefas';
import Forum from 'views/components/Forum';
import ArquivosTurmaVirtual from 'views/components/ArquivosTurmaVirtual';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class TurmaVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,
      showConfirmModal: false,
      showImportarAulaModal: false,

      erro: false,

      dadosTabela: {
        header: [['Aula', '50%'], ['Código', '15%']],
        columnAlign: ['text-left', 'text-center'],
        keys: ['nome', 'codigo'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService.carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idEscola
        ? await this.escolasService.carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idTurma
        ? await this.turmasService.carregarTurma(this.props.match.params.idTurma) : null;

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  voltar() {
    if (this.state.anoLetivo) {
      if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
        return `${this.props.layout}`
          + `/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.state.escola.id}`
          + `/turma/${this.state.turma.id}`
          + `/diario/${this.state.diario.id}`;
      }
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}/alfabetizacao`;
    }
    return `${this.props.layout}/ava/turmasvirtuais`;
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.state.anoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem active>Turma virtual - {this.state.materia.nome}</BreadcrumbItem>
              </> : <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais`}>Turmas Virtuais</Link></BreadcrumbItem>
                <BreadcrumbItem active>{this.state.diario.ano} - {this.state.diario.nome} - {this.state.diario.nomeTurma} - {this.state.diario.escola} - {this.state.materia.nome}</BreadcrumbItem>
              </>
            }
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Turma virtual - {this.state.materia.nome}</CardTitle>
            </CardHeader>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Aulas</Tab>
                  <Tab>Arquivos</Tab>
                  <Tab>Tarefas</Tab>
                  <Tab>Fórum</Tab>
                </TabList>
                <TabPanel>
                  <br />
                  <Aulas
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <ArquivosTurmaVirtual
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <Tarefas
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <Forum
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(TurmaVirtual);
