import React from 'react';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Table,
  Button,
} from 'reactstrap';

import Paginator from 'views/components/Paginator';

import TipoMatricula from 'assets/csv/tipos-matricula.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoAvaliacaoConceitual from 'assets/csv/situacoes-avaliacao-conceitual';
import Serie from 'assets/csv/series2.json';

class HistoricoMatricula extends React.Component {
  constructor(props) {
    super(props);

    this.tiposMatriculas = [
      TipoMatricula.REGULAR,
      TipoMatricula.DEPENDENCIA,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.situacoesAvaliacaoConceitual = [
      SituacaoAvaliacaoConceitual.DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_INFORMADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-aluno-${this.props.idAluno}`;

    this.state = {
      page: sessionStorage.getItem(`${this.idPagina}-pg`) || 1,
      rowsPerPage: this.props.rowsPerPage,
    };

    this.linkParaTurma = this.linkParaTurma.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.rowsPerPage !== nextProps.rowsPerPage) {
      this.setState({
        page: sessionStorage.getItem(`${this.idPagina}-pg`) || 1,
        rowsPerPage: nextProps.rowsPerPage,
      });
    }
  }

  linkParaTurma(matricula) {
    return `${this.props.layout}/anosletivos/${matricula.turma.anoLetivo.id}`
      + `/escola/${matricula.turma.escola.id}/turma/${matricula.turma.id}`;
  }

  render() {
    const start = Math.min((this.state.page - 1) * this.state.rowsPerPage,
      this.props.matriculas.length - 1);

    const end = start + this.state.rowsPerPage;

    const matriculas = this.props.matriculas.slice(start, end);

    return <>
      {
        matriculas.map((matricula, idx) => <div key={idx}>
          <Row>
            <Col md="12">
              
              <Table
                className="table-head-bordered-bold"
                style={{ marginTop: idx > 0 ? 50 : 0 }}>
                <thead>
                  <tr>
                    <th className="text-center" colSpan="8">{matricula.turma.escola.nome}</th>
                  </tr>
                  <tr>
                    <th className="text-center" style={{ width: '14%' }}>TIPO MATRÍCULA</th>
                    <th className="text-center" style={{ width: '14%' }}>ANO</th>
                    <th className="text-center" style={{ width: '14%' }}>CURSO</th>
                    <th className="text-center" style={{ width: '14%' }}>DIAS LETIVOS</th>
                    <th className="text-center" style={{ width: '14%' }}>CARGA HORÁRIA</th>
                    <th className="text-center" style={{ width: '14%' }}>FREQUÊNCIA MÉDIA</th>
                    <th className="text-center" style={{ width: '16%' }}>SITUAÇÃO FINAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{this.tiposMatriculas[matricula.tipo].label}</td>
                    <td className="text-center">{matricula.turma.anoLetivo.ano}</td>
                    <td className="text-center">
                      {!matricula.arquivo ? <Link to={this.linkParaTurma(matricula)}>
                          {matricula.turma.curso.nome}</Link> : matricula.turma.curso.nome}</td>
                    <td className="text-center">{matricula.diasLetivos}</td>
                    <td className="text-center">{matricula.cargaHoraria}</td>
                    <td className="text-center">{matricula.frequencia}</td>
                    <td className="text-center">{this.situacoesMatricula[matricula.situacao].label}</td>
                  </tr>
                </tbody>
              </Table>
              {
                matricula.dadosAvaliacoes.length > 0 && (!this.props.dadosEnte.avaliacaoConceitual || matricula.turma.curso.serie > Serie.ENSINO_FUNDAMENTAL_3.value) && <Table
                  className="table-head-bordered-bold"
                  style={{ marginTop: -15 }}>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: '33.333%' }}>MATÉRIA</th>
                      <th className="text-center" style={{ width: '33.333%' }}>MÉDIA FINAL</th>
                      <th className="text-center" style={{ width: '33.333%' }}>SITUAÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      matricula.dadosAvaliacoes.map((dados, idx2) => <tr key={idx2}>
                        <td className="text-center">{dados.materia}</td>
                        <td className="text-center">{dados.mediaFinal}</td>
                        <td className="text-center">{this.situacoesMatricula[dados.situacao].label}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              }
              {
                matricula.dadosAvaliacoes.length > 0 && this.props.dadosEnte.avaliacaoConceitual && matricula.turma.curso.serie <= Serie.ENSINO_FUNDAMENTAL_3.value && <Table
                  className="table-head-bordered-bold"
                  style={{ marginTop: -15 }}>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: '33.333%' }}>MATÉRIA</th>
                      <th className="text-center" style={{ width: '66.666%' }}>AVALIAÇÃO CONCEITUAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      matricula.dadosAvaliacoes.map((dados, idx2) => <tr key={idx2}>
                        <td className="text-center">{dados.materia}</td>
                        <td className="text-center">{this.situacoesAvaliacaoConceitual[dados.situacaoAvaliacaoConceitual].label}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              }
            </Col>
          </Row>
          {
            matricula.arquivo && <Row>
              <Col md="12" className="text-center">
                <Button
                  color="primary"
                  disabled={this.state.buttonDisabled}
                  onClick={() => this.props.editarHistorico(matricula.id)} >
                  Editar
                </Button>
                <Button
                  color="danger"
                  disabled={this.state.buttonDisabled}
                  onClick={() => this.props.excluirHistorico(matricula.id)} >
                  Excluir
                </Button>
              </Col>
            </Row>
          }
        </div>)
      }
      <div className="col-md-4 offset-md-4" style={{ marginTop: 20 }}>
        <Paginator
          selectedPage={this.state.page}
          callback={(page) => {
            sessionStorage.setItem(`${this.idPagina}-pg`, page);
            this.setState({ page });
          }}
          rowsPerPage={this.state.rowsPerPage}
          total={this.props.matriculas.length} />
      </div>
    </>;
  }
}

export default HistoricoMatricula;
