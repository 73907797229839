import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Button,
} from 'reactstrap';

import Papel from 'assets/csv/papeis.json';

class RelatoriosDistorcaoIdadeSerie extends React.Component {
  render = () => <div className="content">
    <Row>
      <Col md="12">
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
          <BreadcrumbItem active>Relatórios de distorção idade série</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>
          <CardBody>
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th>Relatórios de distorção idade série</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/distorcaoidadeserie/porescola`)}>
                  <td>Relatório de distorção idade série por escola</td>
                </tr>
                {
                  this.props.role === Papel.GESTOR.value && <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/distorcaoidadeserie/pormunicipio`)}>
                    <td>Relatório de distorção idade série no município</td>
                  </tr>
                }
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6" className="text-left">
                <Button
                  color="primary"
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios`)} >
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
}

export default withRouter(RelatoriosDistorcaoIdadeSerie);
