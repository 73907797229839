import React from 'react';

import {
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
  Button,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import moment from 'moment';

import PhotoGallery from 'views/components/PhotoGallery';

import AwsService from 'services/AwsService';

import TipoEvento from 'assets/csv/tipos-evento.json';

class DiarioAlunoModal extends React.Component {
  constructor(props) {
    super(props);

    this.tiposEvento = [
      TipoEvento.AVISO,
      TipoEvento.ATIVIDADE,
      TipoEvento.PROVA,
      TipoEvento.CONTEUDO,
      TipoEvento.DIARIO_ALUNO,
    ]

    this.state = {
      isOpen: props.isOpen,
      id: props.evento.id,
      titulo: props.evento.titulo || '',
      tipoEventoSelecionado: this.props.evento.tipo ? this.tiposEvento[this.props.evento.tipo] : '',
      descricao: props.evento.descricao || '',
      diaInteiro: this.props.evento.diaInteiro || true,
      inicio: props.evento.inicio,
      fim: props.evento.fim,
      data: moment(props.evento.inicio).format('DD/MM/YYYY'),
      imagens: props.evento.imagens,
    };

		this.awsService = new AwsService();
  }

	async componentDidUpdate(prevProps) {
		if (this.props.evento?.imagens && this.props.evento !== prevProps.evento) {
			
			const data = moment(this.props.evento.inicio).format('DD/MM/YYYY');

			const urlPromises = this.props.evento.imagens.map((foto) => new Promise(async (resolve) => {
				const url = await this.awsService.getPresignedUrl(foto.url);
				resolve(url);
			}));
	
			const urls = await Promise.all(urlPromises);
	
			const imagens = this.props.evento.imagens.map((foto, idx) => ({
				...foto,
				src: urls[idx],
			}));

			this.setState({ data, imagens });
		}
	}

  validarCampos() {
    let ret = true;

    if (!this.props.evento.titulo) {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }

    if (!this.state.tipoEventoSelecionado) {
      this.setState({ tipoEventoState: 'danger' });
      ret = false;
    }

    if (!this.props.evento.descricao) {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  cadastrar() {
    if (!this.validarCampos()) return;

    this.limparCampos();

    this.props.callbackCadastrar({
      id: this.props.evento.id,
      titulo: this.props.evento.titulo,
      tipo: this.state.tipoEventoSelecionado.value,
      diaInteiro: this.props.evento.diaInteiro,
      inicio: moment(this.props.evento.inicio).format('DD/MM/YYYY HH:mm:ss'),
      fim: moment(this.props.evento.fim).format('DD/MM/YYYY HH:mm:ss'),
      descricao: this.props.evento.descricao,
    });
  }

  excluir() {
    this.props.callbackExcluir(this.props.evento.id);
  }

  limparCampos() {
    this.setState({
      titulo: '',
      tipoEventoSelecionado: '',
      descricao: '',

      tituloState: '',
      tipoEventoState: '',
      descricaoState: '',
    })
  }

  render() {
    return (
      <Modal size="xl"
        isOpen={this.props.isOpen}>
        <ModalBody>
          <h4>Diário do aluno - {this.state.data}</h4>
          <Row>
            <Col md="12">
              <FormGroup className={`has-label`}>
                <label>Título *</label>
                <Input
                  disabled
                  value={this.props.evento.titulo}
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Tabs>
            <TabList>
              <Tab>Infomações</Tab>
              <Tab>Imagens</Tab>
            </TabList>
            <TabPanel>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.props.evento.descricaoState}`}>
                    <Input
                      disabled={this.props.onlyRead}
                      value={this.props.evento.descricao}
                      onChange={(evt) => this.setState({
                        descricao: evt.target.value,
                        descricaoState: 'has-success',
                      })}
                      type="textarea"
                      style={{ height: '200px' }} />
                  </FormGroup>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row>
                <Col>
                  <PhotoGallery
                    images={this.state.imagens} />
                </Col>
              </Row>
            </TabPanel>
          </Tabs>
          <Row>
            <Col className="text-right">
              <Button
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.props.callbackFechar();
                }}>
                {this.props.onlyRead ? 'Fechar' : 'Cancelar'}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal >
    );
  }
}

export default DiarioAlunoModal;
