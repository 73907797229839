import React from 'react';

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import ImportarAulaModal from 'views/components/ImportarAulaModal';

import LoginService from 'services/LoginService';
import AulasService from 'services/AulasService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';

class Aulas extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-turmavirtual-${this.props.match.params.idTurma}-${this.props.match.params.idMateria}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.loginService = new LoginService();
    this.aulasService = new AulasService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,
      showConfirmModal: false,
      showImportarAulaModal: false,

      erro: false,

      dadosTabela: {
        header: [['Aula', '50%'], ['Código', '15%']],
        columnAlign: ['text-left', 'text-center'],
        keys: ['nome', 'codigo'],
        rows: [],
        total: undefined,
      },
    };

    this.removerAula = this.removerAula.bind(this);
    this.confirmarRemocaoAula = this.confirmarRemocaoAula.bind(this);
    this.editarAula = this.editarAula.bind(this);
    this.carregarAulas = this.carregarAulas.bind(this);
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      await this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAulas(pagina, linhasPorPagina, like) {
    this.setState({ buttonDisabled: true, showAlert: false });

    try {
      const data = await this.aulasService
        .carregarAulas(this.props.match.params.idTurma,
          this.props.match.params.idMateria,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.aulas, total: data.total }),
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar aulas cadastradas',
      });
    }
  }

  confirmarRemocaoAula(aula) {
    this.setState({ aulaSelecionada: aula, showConfirmModal: true });
  }

  async removerAula() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.aulasService
        .removerAulaDaTurmaVirtual(this.state.aulaSelecionada.id,
          this.props.match.params.idTurma,
          this.props.match.params.idMateria);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aula removida com sucesso',
      });

      this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover aula',
      });
    }
  }

  editarAula(aula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/${this.props.match.params.idTurma}`
      + `/diario/${this.props.match.params.idDiario}`
      + `/materia/${this.props.match.params.idMateria}`
      + `/turmavirtual/edicaoaula/${aula.id}`);
  }

  aulaSelecionada(aula) {
    if (this.props.role !== Papel.ALUNO.value) {
      if (this.props.match.params.idAnoLetivo) {
        this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.props.match.params.idEscola}`
          + `/turma/${this.props.match.params.idTurma}`
          + `/diario/${this.props.match.params.idDiario}`
          + `/materia/${this.props.match.params.idMateria}`
          + `/turmavirtual/aula/${aula.id}`);
      } else {
        this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
          + `/turma/${this.props.match.params.idTurma}`
          + `/diario/${this.props.match.params.idDiario}`
          + `/materia/${this.props.match.params.idMateria}`
          + `/aula/${aula.id}`);
      }
    } else {
      this.props.history.push(`/aluno/turmas/${this.props.match.params.idMatricula}`
        + `/turma/${this.props.match.params.idTurma}`
        + `/materia/${this.props.match.params.idMateria}`
        + `/turmavirtual/aula/${aula.id}`);
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={this.props.role !== Papel.PROFESSOR.value}
              disablePrintAction
              disableEditAction
              clickRows
              editButtonCallback={(aula) => this.editarAula(aula)}
              removeButtonCallback={(aula) => this.confirmarRemocaoAula(aula)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarAulas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(aula) => this.aulaSelecionada(aula)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary"
              onClick={() => this.props.history.goBack()}>
              Voltar
            </Button>
          </Col>
          <Col md="6" className="text-right">
            {
              this.props.role === Papel.PROFESSOR.value && <>
                <Button color="primary"
                  onClick={() => this.setState({ showImportarAulaModal: true })}>
                  Importar aula
                </Button>
                {/*
                &nbsp;&nbsp;
                <Button color="primary"
                  onClick={() => {
                    this.props.history.push(`${this.props.layout}`
                      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
                      + `/escola/${this.props.match.params.idEscola}`
                      + `/turma/${this.props.match.params.idTurma}`
                      + `/diario/${this.props.match.params.idDiario}`
                      + `/materia/${this.props.match.params.idMateria}`
                      + '/turmavirtual/cadastroaula');
                  }}>
                  Cadastrar aula
                </Button>
                 */}
              </>
            }
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerAula();
            } else {
              this.setState({ aulaSelecionada: null });
            }
          }}
          text='Confirme a exclusão da aula' />
        {
          this.props.role === Papel.PROFESSOR.value && <>
            <LoaderModal
              isOpen={this.state.showLoaderModal}
              text='Excluindo...' />
            <ImportarAulaModal
							{...this.props}
              isOpen={this.state.showImportarAulaModal}
              idTurma={this.props.match.params.idTurma}
              idMateria={this.props.match.params.idMateria}
              importCallback={() => {
                this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              closeCallback={() => {
                this.carregarAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
                this.setState({ showImportarAulaModal: false });
              }} />
          </>
        }
      </>
    );
  }
}

export default Aulas;
