import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

import {
	Table,
	Card,
	CardHeader,
	CardBody,
	Col,
	Row,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import Papel from 'assets/csv/papeis.json';

import LoginService from 'services/LoginService';
import UsuarioService from 'services/UsuarioService';

import SituacaoVinculo from 'assets/csv/situacoes-vinculo.json';
import SituacaoEnte from 'assets/csv/situacoes-entes.json';

import RootFooter from './Footer';

class EscolherVinculo extends React.Component {
	constructor(props) {
		super(props);

		this.papeis = [
			Papel.GESTOR,
			Papel.DIRETOR,
			Papel.PROFESSOR,
			Papel.ADMINISTRADOR,
			Papel.ALUNO,
			Papel.PAIS,
			Papel.COORDENADOR_PEDAGOGICO,
			Papel.TREINAMENTO,
			Papel.SECRETARIO,
		];

		this.loginService = new LoginService();
		this.usuarioService = new UsuarioService();

		this.state = {
			showAlert: false,

			login: this.props.location.state ? this.props.location.state.login : null,
			senha: this.props.location.state ? this.props.location.state.senha : null,
			usuario: this.props.location.state ? this.props.location.state.usuario : null,
		};

		this.selecionarVinculo = this.selecionarVinculo.bind(this);
	}

	async selecionarVinculo(vinculo) {
		const credenciais = {
			login: this.state.login,
			senha: this.state.senha,
			vinculoAtivo: vinculo,
		};

		this.setState({ buttonDisabled: true });

		const func = this.state.login && this.state.senha
			? this.loginService.ativarVinculoNaoAutenticado.bind(this.loginService)
			: this.loginService.ativarVinculoAutenticado.bind(this.loginService);

		try {
			const token = await func(credenciais);

			sessionStorage.setItem('auth-token', token);

			const foto = await this.usuarioService.carregarFoto(this.state.usuario.id);

			this.props.editDadosUsuario({
				idUsuario: this.state.usuario.id,
				idVinculo: vinculo.id,
				idEnte: vinculo.ente.id,
				login: this.state.usuario.credenciais.login,
				nome: this.state.usuario.nome ? this.state.usuario.nome : this.state.usuario.credenciais.login,
				ente: `${vinculo.ente.nome}/${vinculo.ente.estado.uf}`,
				uf: vinculo.ente.estado.uf,
				papel: this.papeis[vinculo.papel].label,
				foto,
			});

			this.setState({ buttonDisabled: false });

			switch (credenciais.vinculoAtivo.papel) {
				case Papel.ADMINISTRADOR.value:
					this.props.history.push({ pathname: '/admin' });
					break;
				case Papel.GESTOR.value:
					this.props.history.push({ pathname: '/gestor' });
					break;
				case Papel.DIRETOR.value:
					this.props.history.push({ pathname: '/diretor' });
					break;
				case Papel.SECRETARIO.value:
					this.props.history.push({ pathname: '/secretario' });
					break;
				case Papel.PROFESSOR.value:
					this.props.history.push({ pathname: '/professor' });
					break;
				case Papel.ALUNO.value:
					this.props.history.push({ pathname: '/aluno' });
					break;
				case Papel.PAIS.value:
					this.props.history.push({ pathname: '/pais' });
					break;
				case Papel.COORDENADOR_PEDAGOGICO.value:
					this.props.history.push({ pathname: '/coordenadorpedagogico' });
					break;
				default:
					throw new Error('O papel selecionado é inválido');
			}
		} catch (err) {
			const msg = err instanceof Error ? err.message : err;
			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao definir função',
			});
		}
	}

	render() {
		if (!this.props.location.state) {
			this.props.history.push('/login');
			return <></>;
		}

		return <>
			<header id="header" className="fixed-top">
				<div className="container d-flex align-items-center">
					<h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
					<nav className="nav-menu d-none d-lg-block">
						<ul>
							<li><NavLink to="/">&nbsp;</NavLink></li>
						</ul>
					</nav>
				</div>
			</header>
			<main id="main">
				<section className="breadcrumbs">
					<div className="container">
						<div className="d-flex justify-content-between align-items-center">
							<h2>Escolher vínculo</h2>
							<ol>
								<li><NavLink to="/">Início</NavLink></li>
								<li><NavLink to="/login">Login</NavLink></li>
								<li>Escolher vínculo</li>
							</ol>
						</div>
					</div>
				</section>
				<div className="container">
					<Alert
						style={{ marginTop: '20px' }}
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
				</div>
				<section className="inner-page">
					<div className="container">
						<Row>
							<Col className="ml-auto mr-auto" md="6">
								<Card className="card-login">
									<CardHeader>
										<CardHeader>
											<h3 className="header text-center">Escolher Vínculo</h3>
										</CardHeader>
									</CardHeader>
									<CardBody>
										<Table hover>
											<thead className="text-primary">
												<tr>
													<th>Papel</th>
													<th>Ente</th>
												</tr>
											</thead>
											<tbody>
												{
													this.state.usuario.vinculos.filter((vinculo) => vinculo.situacao
														=== SituacaoVinculo.ATIVO.value
														&& vinculo.ente.situacao === SituacaoEnte.ATIVO.value)
														.map((vinculo, idx) => (
															<tr key={idx} className='tr-clickable' onClick={() => this.selecionarVinculo(vinculo)}>
																<td className="td-actions">{this.papeis[vinculo.papel].label}</td>
																<td className="td-actions">{`${vinculo.ente.nome}/${vinculo.ente.estado.uf}`}</td>
															</tr>
														))
												}
											</tbody>
										</Table>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</section>
			</main>
			<RootFooter />
			<LoaderModal
				isOpen={this.state.buttonDisabled}
				text='Definindo vínculo...' />
		</>;
	}
}

export default withRouter(EscolherVinculo);
