import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';
import RelatorioMatriculasPorSerieSituacaoGrupo from 'views/components/RelatorioMatriculasPorSerieSituacaoGrupo';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EntesService from 'services/EntesService';

import series from 'assets/csv/series.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class RelatorioTaxaEvasao extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.entesService = new EntesService();

    this.state = {
      buttonDisabled: false,
      showAlert: false,

      anoLetivo: null,

      error: null,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    const dadosEnte = await this.entesService
      .carregarDadosEnte();

    if (dadosEnte.bnccNacional) {
      series.shift();
      series[0].labelcurto = 'Creche 1';
      series[1].labelcurto = 'Creche 2';
      series[2].labelcurto = 'Creche 3';
    }

    this.setState({
      showAlert: false,
      buttonDisabled: true,
    });

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      this.setState({
        buttonDisabled: false,
        anoLetivo,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.anoLetivo ? <>
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/dashboard`}>Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>
            Taxa de Evasão {this.state.anoLetivo.ano}
          </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Taxa de Evasão {this.state.anoLetivo.ano}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Tabs>
                      <TabList>
                        {
                          series.map((serie, idx) => <Tab key={idx}>
                            {serie.labelcurto}
                          </Tab>)
                        }
                        <Tab>Total</Tab>
                      </TabList>
                      {
                        series.map((serie, idx) => <TabPanel key={idx}>
                          <RelatorioMatriculasPorSerieSituacaoGrupo
														{...this.props}
                            idAnoLetivo={this.props.match.params.idAnoLetivo}
                            idEscola={this.props.match.params.idEscola}
                            serie={serie.value}
                            situacao={SituacaoMatricula.DESISTENTE.value}
                          />
                        </TabPanel>)
                      }
                      <TabPanel>
                        <RelatorioMatriculasPorSerieSituacaoGrupo
													{...this.props}
                          idAnoLetivo={this.props.match.params.idAnoLetivo}
                          idEscola={this.props.match.params.idEscola}
                          situacao={SituacaoMatricula.DESISTENTE.value}
                        />
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      disabled={this.state.buttonDisabled}
                      color="primary"
                      type="button"
                      onClick={() => this.props.history.goBack()}>
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Carregando..." />
      </div>
    );
  }
}

export default withRouter(RelatorioTaxaEvasao);
