import React from 'react';

import env from 'conf/env.json';

import {
  Modal,
  Button,
} from 'reactstrap';

class TermosDeUsoModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        size="xl">
        <div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }} className="modal-body">
          <h4>Termos de Uso</h4>
          1. Você concorda em não utilizar o sistema de maneira a prejudicar alguém, incluindo o
            {env.SYSNAME} (Sistema de Auxílio À Educação).<br /><br />
          2. O uso do sistema deve ser feito somente através da interface exposta propositadamente
            para você como usuário, que não deve acessá-lo através de outras maneiras.<br /><br />
          3. É reservado o direito ao {env.SYSNAME} de interromper o serviço caso algum termo
            seja violado.<br /><br />
          4. O {env.SYSNAME} não é responsável por suas ações como cliente e usuário do sistema, sejam
            elas feitas dentro ou fora do mesmo. Esperamos respeito mútuo nas mensagens, sem ofensas
            pessoais ou a grupos.<br /><br />
          5. Os serviços prestados aos usuários são para uso do Gestor e de seus convidados
            Professores e Diretores, Secretários, Coordenadores Pedagógicos, Aluno e PAIS, 
            por tempo limitado e intransferíveis. É vetada a cópia, revenda ou a criação de outros produtos em cima dos
            nossos sem prévia autorização. A utilização do sistema com qualquer um desses fins
            permite ao {env.SYSNAME} o cancelamento do uso do sistema, com
            o dever de pagar multas por danos causados.<br /><br />
          6. É responsabilidade do usuário manter seus dados atualizados, assim como pela segurança
            de seus dados pessoais de acesso no sistema, como email, login e senha.<br /><br />
          7. O sistema pode ser atualizado, adicionando, removendo ou alterando funcionalidades
            a qualquer instante.<br /><br />
          8. O {env.SYSNAME} permanecerá no ar o sistema pelo maior tempo possível mas, devido à
            natureza da arquitetura web, isso é algo que pode não acontecer sempre.<br /><br />
          9. Todas as informações postadas pelo usuário no sistema passam a fazer parte do sistema
            de gestão e podem ser mantidas no mesmo para acesso por outros usuários, até mesmo após
            a expiração do acesso ou encerramento da conta.<br /><br />
          10. Todo conteúdo gerado pelo usuário, além de seu perfil, pode ficar exposto para outros
            usuários e visitantes acessarem. Quando o usuário encerrar sua conta, seu perfil ficará
            escondido, mas todo o conteúdo gerado pode permanecer visível.<br /><br />
          11. Todos os planos de acesso do {env.SYSNAME} têm acesso por 1 ano, contados a partir da data da
            do contrato, salvo informado algo diferente.<br /><br />
          12. O acesso ao {env.SYSNAME} é condicionado à assinatura de algum dos planos oferecidos,
            reconhecida após confirmação de pagamento pela instituição financeira
            intermediária, salvo estabelecido em contrário por estes Termos de Uso.<br /><br />
          13. Atualizações aos termos de uso e política de privacidade podem ser feitas quando
            necessário. Os usuários serão notificados de mudanças, e só poderão acessar o sistema
            quando concordarem com os novos termos.<br /><br />
          14. Após o vencimento do plano assinado, o acesso ao sistema será mantido por 1 mês apenas
            para consultas, sendo vetado qualquer tipo de cadastro de novas informações. Após esse
            período, não havendo renovação de assinatura de um dos planos do {env.SYSNAME}, o acesso
            será bloqueado, podendo haver remoção dos dados inseridos pelos usuários relacionados
            à conta.<br /><br />
          15. Esses termos aparecem durante o cadastro e no menu opções.<br /><br />
          <Button type="button" color="primary" onClick={() => this.props.closeCallback()}>
            Fechar
          </Button>
        </div>
      </Modal>
    );
  }
}

export default TermosDeUsoModal;
