
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from 'redux/store/store';

import SideBarLayout from 'layouts/sidebarlayout/SideBarLayout';
import RootLayout from 'layouts/root/RootLayout';

import adminRoutes, { routesProfile as adminRoutesProfile } from 'routes/routes-admin';
import gestorRoutes, { routesProfile as gestorRoutesProfile } from 'routes/routes-gestor';
import diretorRoutes, { routesProfile as diretorRoutesProfile } from 'routes/routes-diretor';
import secretarioRoutes, { routesProfile as secretarioRoutesProfile } from 'routes/routes-secretario';
import professorRoutes, { routesProfile as professorRoutesProfile } from 'routes/routes-professor';
import coordenadorRoutes, { routesProfile as coordenadorRoutesProfile } from 'routes/routes-coordenador-pedagogico';
import alunoRoutes, { routesProfile as alunoRoutesProfile } from 'routes/routes-aluno';
import paisRoutes, { routesProfile as paisRoutesProfile } from 'routes/routes-pais';
import raizRoutes from 'routes/routes-raiz';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
					<Routes>
						<Route path="/gestor" exact element={<Navigate to="/gestor/dashboard" replace={true}/>} />
						<Route path="/diretor" exact element={<Navigate to="/diretor/dashboard" replace={true}/>} />
						<Route path="/secretario" exact element={<Navigate to="/secretario/dashboard" replace={true}/>} />
						<Route path="/professor" exact element={<Navigate to="/professor/dashboard" replace={true}/>} />
						<Route path="/coordenadorpedagogico" exact element={<Navigate to="/coordenadorpedagogico/dashboard" replace={true}/>} />
						<Route path="/aluno" exact element={<Navigate to="/aluno/turmas" replace={true}/>} />
						<Route path="/pais" exact element={<Navigate to="/pais/turmas" replace={true}/>} />
						<Route path="/admin" exact element={<Navigate to="/admin/dashboard" replace={true}/>} />

						<Route path="/gestor/*" element={<SideBarLayout layout="/gestor" routes={gestorRoutes} routesProfile={gestorRoutesProfile}/>} />
						<Route path="/diretor/*" element={<SideBarLayout layout="/diretor" routes={diretorRoutes} routesProfile={diretorRoutesProfile}/>} />
						<Route path="/secretario/*" element={<SideBarLayout layout="/secretario" routes={secretarioRoutes} routesProfile={secretarioRoutesProfile}/>} />
						<Route path="/professor/*" element={<SideBarLayout layout="/professor" routes={professorRoutes} routesProfile={professorRoutesProfile}/>} />
						<Route path="/coordenadorpedagogico/*" element={<SideBarLayout layout="/coordenadorpedagogico" routes={coordenadorRoutes} routesProfile={coordenadorRoutesProfile}/>} />
						<Route path="/aluno/*" element={<SideBarLayout layout="/aluno" routes={alunoRoutes} routesProfile={alunoRoutesProfile}/>} />
						<Route path="/pais/*" element={<SideBarLayout layout="/pais" routes={paisRoutes} routesProfile={paisRoutesProfile}/>} />
						<Route path="/admin/*" element={<SideBarLayout layout="/admin" routes={adminRoutes} routesProfile={adminRoutesProfile}/>} />
						<Route path="/*" element={<RootLayout layout="/" routes={raizRoutes}/>} />
					</Routes>
				</BrowserRouter>
    </PersistGate>
  </Provider>
);
