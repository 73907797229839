import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';

import 'react-tabs/style/react-tabs.scss';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AtaFinalNotaTurmaMultiseriado from 'views/components/AtaFinalNotaTurmaMultiseriado';
import AtaFinalRelatorioTurmaMultiseriado from 'views/components/AtaFinalRelatorioTurmaMultiseriado';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AvaliacoesService from 'services/AvaliacoesService';
import FrequenciaService from 'services/FrequenciaService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import AbonosService from 'services/AbonosService';

import Turno from 'assets/csv/turnos.json';
import CardFooter from 'reactstrap/lib/CardFooter';

import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class AtaFinal extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
    this.frequenciaService = new FrequenciaService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
    this.abonosService = new AbonosService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

      turma.turmas.sort((t1, t2) => t1.curso.serie - t2.curso.serie);

      const fn = (t) => new Promise((resolve) => resolve(
        this.diariosService.carregarDiariosDaTurma(t.id),
      ));

      const actions = turma.turmas.map(fn);

      const diarios = await Promise.all(actions);

      turma.turmas.forEach((t, idx) => Object.assign(
        t, { diario: diarios[idx][0] },
      ));

      this.setState({
        anoLetivo,
        escola,
        turma,
        diarios,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario`}>Diário Único</Link></BreadcrumbItem>
            <BreadcrumbItem active>Ata Final</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Ata Final</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Tabs>
                    <TabList>
                      {
                        this.state.turma.turmas.map((turma, idx) => <Tab key={idx}>{turma.curso.nome}</Tab>)
                      }
                    </TabList>
                    {
                      this.state.turma.turmas.map((turma, idx) => <TabPanel key={idx}>
                        {
                          turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value
                            ? <AtaFinalRelatorioTurmaMultiseriado
                                turma={turma}
                                diario={turma.diario}/>
                            : <AtaFinalNotaTurmaMultiseriado
                                turma={turma}
                                diario={turma.diario}/>
                        }
                      </TabPanel>)
                    }
                  </Tabs>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(AtaFinal);
