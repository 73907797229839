import React from 'react';

import {
	Table,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import AbonosService from 'services/AbonosService';
import AvaliacoesService from 'services/AvaliacoesService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import TurmasService from 'services/TurmasService';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class AtaFinalRelatorioTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      turma: this.props.turma,
      diario: this.props.diario,
      loading: true,
    };

    this.turmasService = new TurmasService();
    this.frequenciaService = new FrequenciaService();
    this.abonosService = new AbonosService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
  }

  async componentDidMount() {
    const materias = await this.diariosService
      .carregarMaterias(this.state.diario.id);

    const matriculas = await this.diariosService
      .carregarMatriculas(this.state.diario.id);

    const faltas = await this.frequenciaService
      .carregarFaltasDoDiario(this.state.diario.id);

    const abonos = await this.abonosService
      .carregarAbonos(this.state.diario.id);

    this.setState({
      faltas,
      abonos,
      matriculas,
      materias,
      loading: false,
    });
  }

  totalFaltas = (faltas) => faltas.faltasBimestre1
    + faltas.faltasBimestre2
    + faltas.faltasBimestre3
    + faltas.faltasBimestre4;

  resultadoFinal(totalDeFaltas, faltasAbonadas, matricula) {
    if (matricula.situacao !== SituacaoMatricula.MATRICULADO.value) {
      switch (matricula.situacao) {
        case SituacaoMatricula.TRANSFERIDO.value:
          return 'Transferido';
        case SituacaoMatricula.DESISTENTE.value:
          return 'Evadido';
        case SituacaoMatricula.FALECIDO.value:
          return 'Falecido';
        default:
          break;
      }
    }

    const reprovadoFalta = (1 - (totalDeFaltas - faltasAbonadas)
      / this.state.diario.aulasALancar) < this.state.turma.frequenciaMinima / 100;

    if (reprovadoFalta) {
      return 'Reprovado por falta';
    }
    return 'Aprovado';
  }

  render() {
    return !this.state.loading
      ? 
        <Table>
        <thead className="text-primary">
          <tr>
            <th style={{ width: '60%' }}>Nome</th>
            <th className="text-center" style={{ width: '10%' }}>% Frequência</th>
            <th className="text-center" style={{ width: '10%' }}>Faltas</th>
            <th className="text-center" style={{ width: '10%' }}>Faltas Abonadas</th>
            <th className="text-center" style={{ width: '20%' }}>Resultado Final</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.matriculas.map((matricula, idx) => <tr key={idx}>
              <td>{matricula.usuario.nome}</td>
              <td className="text-center">
                {
                  this.state.diario.aulasALancar
                    ? ((1 - ((this.totalFaltas(this.state.faltas[idx])
                      - this.state.abonos[idx].quantidade) / this.state.diario.aulasALancar)) * 100).toFixed(1).replace('.', ',')
                    : ''
                }
              </td>
              <td className="text-center">{this.totalFaltas(this.state.faltas[idx])}</td>
              <td className="text-center">{this.state.abonos[idx].quantidade}</td>
              <td className="text-center">{this.resultadoFinal(this.totalFaltas(this.state.faltas[idx]),
                this.state.abonos[idx].quantidade, matricula)}</td>
            </tr>)
          }
        </tbody>
      </Table> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>;
  }
}

export default AtaFinalRelatorioTurmaMultiseriado;
