import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import validarCpf from 'validar-cpf';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

import Utils from 'utils/Utils';

import TipoFuncionario from 'assets/csv/tipos-funcionario.json';
import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';
import Escolaridade from 'assets/csv/escolaridades.json';

class CadastroFuncionario extends React.Component {
  constructor(props) {
    super(props);

    this.tiposFuncionario = [
      TipoFuncionario.AUXILIAR_ADMINISTRATIVO,
      TipoFuncionario.SECRETARIO_ESCOLAR,
      TipoFuncionario.AUXILIAR_SERVICOS_GERAIS,
      TipoFuncionario.INSPETOR_ESCOLAR,
      TipoFuncionario.PORTEIRO,
      TipoFuncionario.MERENDEIRO,
      TipoFuncionario.SUPORTE_PEDAGOGICO,
      TipoFuncionario.AUXILIAR_MERENDEIRA,
      TipoFuncionario.CUIDADORA,
      TipoFuncionario.VICE_DIRETOR,
      TipoFuncionario.PROFESSOR_SALA_DE_LEITURA,
      TipoFuncionario.DIRETOR_ADMINISTRATIVO_FINANCEIRO,
      TipoFuncionario.DIRETOR_PEDAGOGICO,
    ];

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.pnes = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesBolsaFamilia = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.escolaridades = [
      Escolaridade.ENSINO_MEDIO_COMPLETO,
      Escolaridade.ENSINO_SUPERIOR_INCOMPLETO,
      Escolaridade.ENSINO_SUPERIOR_COMPLETO,
    ];

    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      loaderModalText: '',

      nome: '',
      endereco: '',
      naturalidade: '',
      cpf: '',
      rg: '',
      cartaoSUS: '',
      nis: '',
      dataNascimento: '',

      funcionarioSelecionado: null,
      erro: null,
    };

    this.cadastrarFuncionario = this.cadastrarFuncionario.bind(this);
    this.atualizarFuncionario = this.atualizarFuncionario.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({ escola });

      if (this.props.match.params.idFuncionario !== undefined) {
        const funcionario = await this.escolasService
          .carregarFuncionario(this.props.match.params.idFuncionario);

        this.setState({
          funcionarioSelecionado: funcionario,
        }, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.funcionarioSelecionado.nome || '',
      endereco: this.state.funcionarioSelecionado.endereco || '',
      naturalidade: this.state.funcionarioSelecionado.naturalidade || '',
      cpf: this.state.funcionarioSelecionado.cpf || '',
      rg: this.state.funcionarioSelecionado.rg || '',
      cartaoSUS: this.state.funcionarioSelecionado.cartaoSUS || '',
      nis: this.state.funcionarioSelecionado.nis || '',
      dataNascimento: this.state.funcionarioSelecionado.dataNascimento || '',

      tipoFuncionarioSelecionado: this.tiposFuncionario[this.state.funcionarioSelecionado.tipo],
      sexoSelecionado: this.sexos[this.state.funcionarioSelecionado.sexo],
      etniaSelecionada: this.etnias[this.state.funcionarioSelecionado.etnia],
      pneSelecionado: this.pnes[this.state.funcionarioSelecionado.pne ? 1 : 0],
      bolsaFamilia: this.opcoesBolsaFamilia[this.state.funcionarioSelecionado.bolsaFamilia ? 1 : 0],
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.tipoFuncionarioSelecionado) {
      this.setState({ tipoFuncionarioState: 'danger' });
      ret = false;
    }
    if (this.state.cpf && !validarCpf(this.state.cpf)) {
      this.setState({ cpfState: 'has-danger' });
      ret = false;
    }
    if (this.state.cartaoSUS && !Utils.validarCNS(this.state.cartaoSUS.replace(/\s/g, ''))) {
      this.setState({ cartaoSUSState: 'has-danger' });
      ret = false;
    }
    if (this.state.nis && this.state.nis.length < 11) {
      this.setState({ nisState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarFuncionario() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando funcionário...',
    });

    const funcionario = {
      nome: this.state.nome,
      tipo: this.state.tipoFuncionarioSelecionado.value,
      endereco: this.state.endereco,
      naturalidade: this.state.naturalidade,
      cpf: this.state.cpf,
      rg: this.state.rg,
      cartaoSUS: this.state.cartaoSUS.replace(/\s/g, ''),
      nis: this.state.nis,
      dataNascimento: this.state.dataNascimento,
      sexo: this.state.sexoSelecionado ? this.state.sexoSelecionado.value : undefined,
      etnia: this.state.etniaSelecionada ? this.state.etniaSelecionada.value : undefined,
      pne: this.state.pneSelecionado ? this.state.pneSelecionado.value : undefined,
      bolsaFamilia: this.state.bolsaFamilia ? this.state.bolsaFamilia.value : undefined,
      escola: {
        id: this.props.match.params.idEscola,
      },
    };

    try {
      await this.escolasService.cadastrarFuncionario(funcionario);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Funcionário cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar funcionário',
      });
    }
  }

  async atualizarFuncionario() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando funcionário...',
    });

    const funcionario = {
      id: this.state.funcionarioSelecionado.id,
      nome: this.state.nome,
      tipo: this.state.tipoFuncionarioSelecionado.value,
      endereco: this.state.endereco,
      naturalidade: this.state.naturalidade,
      cpf: this.state.cpf,
      rg: this.state.rg,
      cartaoSUS: this.state.cartaoSUS.replace(/\s/g, ''),
      nis: this.state.nis,
      dataNascimento: this.state.dataNascimento,
      sexo: this.state.sexoSelecionado ? this.state.sexoSelecionado.value : undefined,
      etnia: this.state.etniaSelecionada ? this.state.etniaSelecionada.value : undefined,
      pne: this.state.pneSelecionado ? this.state.pneSelecionado.value : undefined,
      bolsaFamilia: this.state.bolsaFamilia ? this.state.bolsaFamilia.value : undefined,
    };

    try {
      await this.escolasService.atualizarFuncionario(funcionario);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Funcionário atualizado com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar funcionário',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      tipoFuncionarioSelecionado: '',
      endereco: '',
      naturalidade: '',
      cpf: '',
      rg: '',
      cartaoSUS: '',
      nis: '',
      dataNascimento: '',
      sexoSelecionado: '',
      etniaSelecionada: '',
      pneSelecionado: '',
      bolsaFamilia: '',

      nomeState: '',
      tipoFuncionarioState: '',
      enderecoState: '',
      naturalidadeState: '',
      cpfState: '',
      rgState: '',
      cartaoSUSState: '',
      nisState: '',
      dataNascimentoState: '',
      sexoState: '',
      etniaState: '',
      pneState: '',
      bolsaFamiliaState: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.funcionarioSelecionado) {
      this.cadastrarFuncionario();
    } else {
      this.atualizarFuncionario();
    }
  }

  conteudoPagina() {
    return (!this.state.escola)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/escolas">Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.funcionarioSelecionado ? 'Edição de funcionário' : 'Cadastro de funcionário'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do funcionário</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        type="text"
                        maxLength="200"
                        value={this.state.nome}
                        onChange={(evt) => this.setState({ nome: evt.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Função *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoFuncionarioState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoFuncionarioSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoFuncionarioState: 'primary', tipoFuncionarioSelecionado: event });
                        }}
                        options={this.tiposFuncionario}
                        placeholder="Função..."
                      />
                      {this.state.tipoFuncionarioState === 'danger' ? (
                        <label className="error">
                          Informe a função.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.enderecoState}`}>
                      <label>Endereço</label>
                      <Input
                        type="text"
                        maxLength="200"
                        value={this.state.endereco}
                        onChange={(evt) => this.setState({ endereco: evt.target.value, enderecoState: 'has-success' })}
                      />
                      {this.state.enderecoState === 'has-danger' ? (
                        <label className="error">
                          Informe o endereço do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.naturalidadeState}`}>
                      <label>Naturalidade</label>
                      <Input
                        value={this.state.naturalidade}
                        type="text"
                        maxLength="100"
                        onChange={(evt) => this.setState({ naturalidade: evt.target.value, naturalidadeState: 'has-success' })}
                      />
                      {this.state.naturalidadeState === 'has-danger' ? (
                        <label className="error">
                          Informe a naturalidade.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cpfState}`}>
                      <label>CPF</label>
                      <Input
                        value={this.state.cpf}
                        type="text"
                        mask="999.999.999-99"
                        maskChar=""
                        onChange={(evt) => this.setState({ cpf: evt.target.value, cpfState: 'has-success' })}
                        tag={InputMask}
                      />
                      {this.state.cpfState === 'has-danger' ? (
                        <label className="error">
                          Informe um CPF válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.rgState}`}>
                      <label>RG</label>
                      <Input
                        value={this.state.rg}
                        type="text"
                        maxLength="100"
                        onChange={(evt) => this.setState({ rg: evt.target.value, rgState: 'has-success' })}
                      />
                      {this.state.rgState === 'has-danger' ? (
                        <label className="error">
                          Informe os dados do RG do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cartaoSUSState}`}>
                      <label>Cartão SUS</label>
                      <Input
                        value={this.state.cartaoSUS}
                        type="text"
                        mask="999 9999 9999 9999"
                        maskChar=""
                        onChange={(evt) => this.setState({ cartaoSUS: evt.target.value, cartaoSUSState: 'has-success' })}
                        tag={InputMask}
                      />
                      {this.state.cartaoSUSState === 'has-danger' ? (
                        <label className="error">
                          Informe um número do SUS válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.nisState}`}>
                      <label>NIS</label>
                      <Input
                        value={this.state.nis}
                        type="text"
                        mask="99999999999"
                        maskChar=""
                        tag={InputMask}
                        onChange={(evt) => this.setState({ nis: evt.target.value, nisState: 'has-success' })}
                      />
                      {this.state.nisState === 'has-danger' ? (
                        <label className="error">
                          NIS inválido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataNascimentoState}`}>
                      <label>Data de nascimento</label>
                      <Input
                        value={this.state.dataNascimento}
                        type="text"
                        mask="99/99/9999"
                        maskChar=""
                        tag={InputMask}
                        onChange={(evt) => this.setState({ dataNascimento: evt.target.value, dataNascimentoState: 'has-success' })}
                      />
                      {this.state.dataNascimentoState === 'has-danger' ? (
                        <label className="error">
                          Informe uma data válida.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Sexo</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.sexoState}`}
                        classNamePrefix="react-select"
                        value={this.state.sexoSelecionado}
                        onChange={(event) => {
                          this.setState({ sexoState: 'primary', sexoSelecionado: event });
                        }}
                        options={this.sexos}
                        placeholder="Sexo..."
                      />
                      {this.state.sexoState === 'danger' ? (
                        <label className="error">
                          Informe o sexo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Etnia</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.etniaState}`}
                        classNamePrefix="react-select"
                        value={this.state.etniaSelecionada}
                        onChange={(event) => {
                          this.setState({ etniaState: 'primary', etniaSelecionada: event });
                        }}
                        options={this.etnias}
                        placeholder="Etnia..."
                      />
                      {this.state.etniaState === 'danger' ? (
                        <label className="error">
                          Informe a etnia.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>PCD</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.pneState}`}
                        classNamePrefix="react-select"
                        value={this.state.pneSelecionado}
                        onChange={(event) => {
                          this.setState({ pneState: 'primary', pneSelecionado: event });
                        }}
                        options={this.pnes}
                        placeholder="Pessoa com deficiência..."
                      />
                      {this.state.pneState === 'danger' ? (
                        <label className="error">
                          Informe se possui deficiência.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Bolsa família</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.bolsaFamiliaState}`}
                        classNamePrefix="react-select"
                        value={this.state.bolsaFamilia}
                        onChange={(event) => {
                          this.setState({ bolsaFamiliaState: 'primary', bolsaFamilia: event });
                        }}
                        options={this.opcoesBolsaFamilia}
                        placeholder="Recebe bolsa família..."
                      />
                      {this.state.bolsaFamiliaState === 'danger' ? (
                        <label className="error">
                          Informe se recebe bolsa família.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                      {!this.state.funcionarioSelecionado ? 'Cadastrar' : 'Atualizar'}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroFuncionario);
