import React from 'react';

import {
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import AnosLetivosService from 'services/AnosLetivosService';

import Papel from 'assets/csv/papeis.json';

class CargaHorariaAnoLetivo extends React.Component {
  constructor(props) {
    super(props);

    this.anosLetivosService = new AnosLetivosService();

    this.state = {
      showLoaderModal: false,

      cargasHoraria: [],
    }


    this.carregarCargasHoraria = this.carregarCargasHoraria.bind(this);
    this.confirmarRemocaoCargaHoraria = this.confirmarRemocaoCargaHoraria.bind(this);
    this.removerCargaHoraria = this.removerCargaHoraria.bind(this);

  }

  async componentDidMount() {
    await this.carregarCargasHoraria();
  }

  async carregarCargasHoraria() {
    try {
      const cargasHoraria = await this.anosLetivosService
        .carregarCargasHoraria(this.props.idAnoLetivo);

      this.setState({
        cargasHoraria,
      })
    } catch (msg) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao carregar carga horária',
      });
    }
  }

  confirmarRemocaoCargaHoraria(cargaHoraria) {
    this.setState({ cargaHorariaSelecionada: cargaHoraria, showConfirmModal: true });
  }

  async removerCargaHoraria() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.anosLetivosService
        .removerCargaHoraria(this.state.cargaHorariaSelecionada.id);

     this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Carga horária removida com sucesso',
      });

      this.carregarCargasHoraria();
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover carga horária',
      });
    }
  }

  render() {
    return <>
      <Row>
        <Col>
          {
            this.state.cargasHoraria.length > 0 ? <Table>
              <thead className="text-primary">
                <tr>
                  <th>Curso</th>
                  <th className='text-center'>Dias letivos</th>
                  <th className='text-center'>Carga horária</th>
                  <th className='text-center'>Opções</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.cargasHoraria.map((cargaHoraria, idx) => <tr key={idx}>
                    <td>{cargaHoraria.curso.nome}</td>
                    <td className='text-center'>{cargaHoraria.diasLetivos}</td>
                    <td className='text-center'>{cargaHoraria.cargaHoraria}</td>
                    <td className='text-center'>
                        <Button
                          id={`btnEditarItem${idx}`}
                          className="btn-icon"
                          color="success"
                          size="sm"
                          onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.idAnoLetivo}/cargahoraria/edicao/${cargaHoraria.id}`)}
                          type="button">
                          <i className="fa fa-edit" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnEditarItem${idx}`} delay={0}>
                          Editar
                        </UncontrolledTooltip>
                        {' '}
                        <Button
                          id={`btnRemoverItem${idx}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={() => this.confirmarRemocaoCargaHoraria(cargaHoraria)}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${idx}`} delay={0}>
                          Excluir
                        </UncontrolledTooltip>
                      </td>
                  </tr>)
                }
              </tbody>
            </Table> : <div className='text-center'>Nenhuma carga horária cadastrada</div>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary" disabled={this.state.showLoaderModal}
            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos`)} >
            Voltar
          </Button>
        </Col>
        <Col className="text-right">
          {
            this.props.role === Papel.GESTOR.value
            && <Button
              color="primary"
              onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.idAnoLetivo}/cargahoraria/cadastro`)} >
              Cadastrar carga horária
            </Button>
          }
        </Col>
      </Row>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={this.state.loaderModalText} />
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerCargaHoraria();
          } else {
            this.setState({ cargaHorariaSelecionada: null });
          }
        }}
        text='Confirme a exclusão da carga horária' />
    </>;
  }
}

export default CargaHorariaAnoLetivo;
