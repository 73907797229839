import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Alert from 'views/components/Alert';

import ListaServidores from 'views/gestor/ListaServidores';

import Papel from 'assets/csv/papeis.json';

class Servidores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
    };
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Servidores</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardBody>
                <Tabs>
                  <TabList>
                    <Tab>Diretores</Tab>
                    <Tab>Secretários</Tab>
                    <Tab>Coordenadores</Tab>
                    <Tab>Professores</Tab>
                  </TabList>
                  <TabPanel>
                    <br />
                    <ListaServidores
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                      papel={Papel.DIRETOR.value}
                      categoria="diretores"/>
                  </TabPanel>
                  <TabPanel>
                    <br />
                    <ListaServidores
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                      papel={Papel.SECRETARIO.value}
                      categoria="secretarios"/>
                  </TabPanel>
                  <TabPanel>
                    <br />
                    <ListaServidores
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                      papel={Papel.COORDENADOR_PEDAGOGICO.value}
                      categoria="coordenadores"/>
                  </TabPanel>
                  <TabPanel>
                    <br />
                    <ListaServidores
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                      papel={Papel.PROFESSOR.value}
                      categoria="professores"/>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Servidores);
