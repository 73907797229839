import React from 'react';

import {
  Modal,
  Button,
} from 'reactstrap';

class TextoModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        size="xl">
        <div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }} className="modal-body">
          <h4>{this.props.titulo}</h4>
          <br /><br />
          <div className="preserve-format">{this.props.conteudo}</div>
          <br /><br />
          <Button type="button" color="primary" onClick={() => this.props.closeCallback()}>
            Fechar
          </Button>
        </div>
      </Modal>
    );
  }
}

export default TextoModal;
