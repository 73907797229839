import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

class ImageSourceModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}>
        <ModalBody>
          <div align="center" style={{ paddingTop: '20px' }}>
            <p>
              De onde deseja carregar a foto?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => { this.props.callback(0); }}>
            <i className="fa fa-file"></i>
              Arquivo
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={() => { this.props.callback(1); }}>
            <i className="fa fa-camera"></i>
              Câmera
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImageSourceModal;
