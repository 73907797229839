import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	Form,
	FormGroup,
	Input,
	Row,
	Table,
	UncontrolledTooltip
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Select from 'react-select';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import LoginService from 'services/LoginService';
import MateriasService from 'services/MateriasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';

import getCamposAprendizagemBercario1Bercario2 from 'utils/objetivosAprendizagemBercario1Bercario2';
import getCamposAprendizagemCreche1Creche2 from 'utils/objetivosAprendizagemCreche1Creche2';
import getCamposAprendizagemPre1Pre2 from 'utils/objetivosAprendizagemPre1Pre2';

class ObjetivosAprendizagem extends React.Component {
	constructor(props) {
		super(props);

		this.ciclosEnsino = [
			CicloEnsino.ENSINO_INFANTIL,
			CicloEnsino.ENSINO_FUNDAMENTAL_1,
			CicloEnsino.ENSINO_FUNDAMENTAL_2,
			CicloEnsino.ENSINO_MEDIO,
			CicloEnsino.ENSINO_TECNICO,
			CicloEnsino.EJA,
			CicloEnsino.OUTRO,
			CicloEnsino.CORRECAO_FLUXO,
		];

		this.situacoes = [
			SituacaoEstruturaCurricular.ATIVA,
			SituacaoEstruturaCurricular.INATIVA,
		];

		this.getDadosPadrao = [
			getCamposAprendizagemBercario1Bercario2,
			getCamposAprendizagemBercario1Bercario2,
			getCamposAprendizagemCreche1Creche2,
			getCamposAprendizagemCreche1Creche2,
			getCamposAprendizagemPre1Pre2,
			getCamposAprendizagemPre1Pre2,
		];

		this.loginService = new LoginService();
		this.cursosService = new CursosService();
		this.estruturasCurricularesService = new EstruturasCurricularesService();
		this.materiasService = new MateriasService();
		this.objetivosAprendizagemCampoExperienciaService = new
			ObjetivosAprendizagemCampoExperienciaService();

		this.state = {
			showAlert: false,

			buttonDisabled: false,
		};
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);

		try {
			const materiasBase = [
				'Corpo, gestos e movimentos',
				'Escuta, fala, pensamento e imaginação',
				'Espaços, tempos, quantidades, relações e transformações',
				'O eu, o outro e o nós',
				'Traços, sons, cores e formas',
				'Arte',
				'Ciências',
				'Educação Física',
				'Ensino Religioso',
				'Geografia',
				'História',
				'Língua Inglesa',
				'Língua Portuguesa',
				'Matemática',
			];

			function ordenacaoMaterias(m1, m2) {
				if (materiasBase.includes(m1.nome) && !materiasBase.includes(m2.nome)) {
					return -1;
				} else if (!materiasBase.includes(m1.nome) && materiasBase.includes(m2.nome)) {
					return 1;
				} else {
					return m1.nome.localeCompare(m2.nome);
				}
			}

			const curso = await this.cursosService
				.carregarCurso(this.props.match.params.idCurso);

			const estruturaCurricular = await this.estruturasCurricularesService
				.carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

			const materias = await this.materiasService
				.carregarMateriasPorEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

			materias.sort(ordenacaoMaterias);

			const materia = await this.materiasService
				.carregarMateria(this.props.match.params.idMateria);

			await this.carregarObjetivosAprendizagemCampoExperiencia();

			let anos1;
			let anos2;
			if (curso.serie === 0 || curso.serie === 1) {
				anos1 = '0 anos';
				anos2 = '1 anos';
			} else if (curso.serie === 2 || curso.serie === 3) {
				anos1 = '2 anos';
				anos2 = '3 anos';
			} else {
				anos1 = '4 anos';
				anos2 = '5 anos';
			}

			materias.forEach((materia, idx) => Object.assign(materia, {
				value: materia.id,
				label: materia.nome,
				index: idx,
			}));

			this.setState({
				curso,
				estruturaCurricular,
				materia,
				anos1,
				anos2,
				materias,
				materiaSelecionada: materias.find((m) => m.id === this.props.match.params.idMateria),
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarObjetivosAprendizagemCampoExperiencia(idMateria) {
		this.setState({ loadingObjetivosAprendizagem: true });

		if (!idMateria) {
			idMateria = this.props.match.params.idMateria
		}

		const campoExperiencia = await this.objetivosAprendizagemCampoExperienciaService
			.carregarCampoExperienciaPorMateria(idMateria);

		const objetivosAprendizagem = await this.objetivosAprendizagemCampoExperienciaService
			.carregarObjetivosAprendizagemPorMateria(idMateria);

		const fn1 = (objetivoAprendizagem) => new Promise((resolve) => resolve(
			this.objetivosAprendizagemCampoExperienciaService.carregarObjetivosEspecificosDoObjetivoAprendizagem(objetivoAprendizagem.id),
		));
		const actions1 = objetivosAprendizagem.map(fn1);
		const objetivosEspecificos = await Promise.all(actions1);

		objetivosAprendizagem.forEach((objetivoAprendizagem, idx) => Object.assign(objetivoAprendizagem, {
			objetivosEspecificos: objetivosEspecificos[idx]
		}));

		this.setState({
			loadingObjetivosAprendizagem: false,
			campoExperiencia,
			objetivosAprendizagem,
		});
	}

	removerObjetivoAprendizagem(objetivoAprendizagem) {
		const objetivosAprendizagem = this.state.objetivosAprendizagem.filter(
			(objetivoAprendizagem2) => objetivoAprendizagem !== objetivoAprendizagem2,
		);

		this.setState({ objetivosAprendizagem });
	}

	validarCampos() {
		let ret = true;

		this.state.objetivosAprendizagem.forEach((objetivoAprendizagem) => {
			if (!objetivoAprendizagem.descricao) {
				Object.assign(objetivoAprendizagem, { descricaoState: 'has-danger' });
				ret = false;
			}
			objetivoAprendizagem.objetivosEspecificos.forEach((objetivoEspecifico) => {
				if (!objetivoEspecifico.descricao) {
					Object.assign(objetivoEspecifico, { descricaoState: 'has-danger' });
					ret = false;
				}
				if (!objetivoEspecifico.descricao2) {
					Object.assign(objetivoEspecifico, { descricao2State: 'has-danger' });
					ret = false;
				}
			});
		});

		if (!ret) this.forceUpdate();

		return ret;
	}

	async salvarObjetivosAprendizagem() {
		if (!this.validarCampos()) return;

		this.setState({
			buttonDisabled: true,
			showAlert: false,
			loaderModalText: 'Salvando direitos e objetivos de aprendizagem...',
		});

		const objetivosAprendizagemCampoExperiencia = {
			objetivosAprendizagem: this.state.objetivosAprendizagem,
			campoExperiencia: this.state.campoExperiencia,
		};

		try {
			await this.objetivosAprendizagemCampoExperienciaService
				.salvarObjetivosAprendizagemCampoConhecimento(objetivosAprendizagemCampoExperiencia);

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Direitos e objetivos de aprendizagem salvos com sucesso',
			});

			await this.carregarObjetivosAprendizagemCampoExperiencia();
		} catch (e) {

			this.setState({
				buttonDisabled: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao salvar direitos e objetivos de aprendizagem',
			});
		}
	}

	preencherCampoExperienciaObjetivosAprendizagem(materia) {
		try {
			const res = this.getDadosPadrao[this.state.curso.serie](materia, this.props.match.params.idMateria);
			this.setState({
				objetivosAprendizagem: res.objetivosAprendizagem,
				campoExperiencia: res.campoExperiencia,
			});
		} catch (e) { }
	}

	conteudoPagina() {
		return this.state.curso
			? <Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/${this.props.match.params.idEstruturaCurricular}`}>Estrutura curricular</Link></BreadcrumbItem>
						<BreadcrumbItem active>
							Direitos de aprendizagem e objetivos de aprendizagem para {this.state.materia.nome}
						</BreadcrumbItem>
					</Breadcrumb>
					<Form onSubmit={this.onSubmit}>
						<Card>
							<CardHeader>
								<CardTitle tag="h4">Direitos de aprendizagem e objetivos de aprendizagem para {this.state.materia.nome}</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="4">
										<FormGroup className={`has-label ${this.state.nomeState}`}>
											<label>Estrutura curricular</label>
											<Input
												disabled
												value={this.state.estruturaCurricular.nome}
												type="text"
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Curso</label>
											<Input
												value={this.state.curso.nome}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup className={'has-label'}>
											<label>Ciclo</label>
											<Input
												value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
									<Col md="2">
										<FormGroup className={'has-label'}>
											<label>Situação</label>
											<Input
												value={this.situacoes[this.state.estruturaCurricular.situacao].label}
												type="text"
												disabled
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<FormGroup className={'has-label has-danger'}>
											<label>Matérias</label>
											<Select
												noOptionsMessage={() => 'Nenhuma entrada'}
												className={`react-select primary`}
												classNamePrefix="react-select"
												value={this.state.materiaSelecionada}
												onChange={async (event) => {
													this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}`
														+ `/estruturascurriculares/${this.state.estruturaCurricular.id}`
														+ ` /objetivos/materia/${event.id}`);

													this.setState({
														materia: event,
														materiaSelecionada: event,
														showAlert: false,
													});

													await this.carregarObjetivosAprendizagemCampoExperiencia(event.value);
												}}
												options={this.state.materias}
												placeholder="Matérias..."
											/>
										</FormGroup>
									</Col>
									{
										this.props.dadosUsuario.login === 'ojuara' && <Col md="6" className="text-right align-bottom">
											<Button
												onClick={() => this.preencherCampoExperienciaObjetivosAprendizagem(this.state.materiaSelecionada.index)}>
												Preencher com direitos e objetivos de aprendizagem padrão
											</Button>
										</Col>
									}
								</Row>
								<br />
								{
									!this.state.loadingObjetivosAprendizagem ? <Tabs>
										<TabList>
											<Tab>Direitos de aprendizagem</Tab>
											<Tab>Objetivos de aprendizagem</Tab>
										</TabList>
										<TabPanel>
											<Row>
												<Col md="12">
													<FormGroup className={`has-label ${this.state.campoExperienciaState}`}>
														<TextareaAutosize
															className={`autoheight ${this.state.campoExperienciaState}`}
															value={this.state.campoExperiencia.descricao}
															onChange={(evt) => {
																Object.assign(this.state.campoExperiencia,
																	{
																		descricao: evt.target.value,
																	});
																this.setState({ campoExperienciaState: 'primary' });
															}} />
														{this.state.campoExperienciaState === 'has-danger' ? (
															<label className="error">
																Informe o campo de experiências
															</label>
														) : null}
													</FormGroup>
												</Col>
											</Row>
										</TabPanel>
										<TabPanel>
											<Row>
												<Col md="6">
													<Button
														color="primary"
														onClick={() => {
															this.state.objetivosAprendizagem.push({
																descricao: '',
																objetivosEspecificos: [{ descricao: '', descricao2: '', ordem: 0 }],
																materia: { id: this.props.match.params.idMateria },
																ordem: this.state.objetivosAprendizagem.length,
															});
															this.forceUpdate();
														}}>
														Adicionar objetivo de aprendizagem
													</Button>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Table>
														<thead className="text-primary">
															<tr>
																<th rowSpan="2" className="text-center" style={{ width: '20%' }}>Objetivos de aprendizagem e desenvolvimento</th>
																<th colSpan="2" className="text-center" style={{ width: '65%' }}>Objetivos específicos</th>
																<th rowSpan="2" className="text-center" style={{ width: '10%' }}></th>
																<th rowSpan="2" className="text-center" style={{ width: '5%' }}></th>
															</tr>
															<tr>
																<th className="text-center">{this.state.anos1}</th>
																<th className="text-center">{this.state.anos2}</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.objetivosAprendizagem.map((objetivoAprendizagem, index1) => <React.Fragment key={index1}>
																	{
																		objetivoAprendizagem.objetivosEspecificos.map((objetivoEspecifico, index2) => <tr key={index2}>
																			{
																				index2 === 0 && <td rowSpan={objetivoAprendizagem.objetivosEspecificos.length}>
																					<FormGroup className={`has-label ${objetivoAprendizagem.descricaoState}`}>
																						<TextareaAutosize
																							className={`autoheight-curriculo ${objetivoAprendizagem.descricaoState}`}
																							value={objetivoAprendizagem.descricao}
																							onChange={(event) => {
																								Object.assign(objetivoAprendizagem,
																									{
																										descricao: event.target.value,
																										descricaoState: 'primary',
																									});
																								this.forceUpdate();
																							}} />
																						{objetivoAprendizagem.descricaoState === 'has-danger' ? (
																							<label className="error">
																								Informe a descrição do objetivo de aprendizagem.
																							</label>
																						) : null}
																					</FormGroup>
																				</td>
																			}
																			<td className="text-center" style={index2 > 0 ? { border: 'none' } : {}}>
																				<FormGroup className={`has-label ${objetivoEspecifico.descricaoState}`}>
																					<>
																						<TextareaAutosize
																							style={{ width: '90%' }}
																							className={`autoheight-curriculo ${objetivoEspecifico.descricaoState}`}
																							value={objetivoEspecifico.descricao}
																							onChange={(event) => {
																								Object.assign(objetivoEspecifico,
																									{
																										descricao: event.target.value,
																										descricaoState: 'primary',
																									});
																								this.forceUpdate();
																							}} />
																					</>
																					{objetivoEspecifico.descricaoState === 'has-danger' ? (
																						<label className="error">
																							Informe a descrição do objetivo de aprendizagem.
																						</label>
																					) : null}
																				</FormGroup>
																			</td>
																			<td className="text-center" style={index2 > 0 ? { border: 'none' } : {}}>
																				<FormGroup className={`has-label ${objetivoEspecifico.descricao2State}`}>
																					<>
																						<TextareaAutosize
																							style={{ width: '90%' }}
																							className={`autoheight-curriculo ${objetivoEspecifico.descricao2State}`}
																							value={objetivoEspecifico.descricao2}
																							onChange={(event) => {
																								Object.assign(objetivoEspecifico,
																									{
																										descricao2: event.target.value,
																										descricao2State: 'primary',
																									});
																								this.forceUpdate();
																							}} />
																					</>
																					{objetivoEspecifico.descricao2State === 'has-danger' ? (
																						<label className="error">
																							Informe a descrição do objetivo específico.
																						</label>
																					) : null}
																				</FormGroup>
																			</td>
																			<td className="text-left" style={index2 > 0 ? { border: 'none' } : {}}>
																				<Button
																					id={`btnRemoverObjetivoEspecifico${index1}${index2}`}
																					className="btn-icon"
																					color="danger"
																					size="sm"
																					onClick={() => {
																						if (objetivoAprendizagem.objetivosEspecificos.length === 1) return;
																						const objetivosEspecificos = objetivoAprendizagem.objetivosEspecificos.filter(
																							(objetivoEspecifico2) => objetivoEspecifico !== objetivoEspecifico2,
																						);
																						Object.assign(objetivoAprendizagem, { objetivosEspecificos });
																						this.forceUpdate();
																					}}
																					type="button">
																					<i className="fa fa-times" />
																				</Button>
																				<UncontrolledTooltip placement="bottom" target={`btnRemoverObjetivoEspecifico${index1}${index2}`} delay={0}>
																					Remover objetivo específico
																				</UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																				{index2 === objetivoAprendizagem.objetivosEspecificos.length - 1
																					&& <>
																						<Button
																							id={`btnAdicionarObjetivoEspecifico${index1}${index2}`}
																							className="btn-icon"
																							color="success"
																							size="sm"
																							onClick={() => {
																								objetivoAprendizagem.objetivosEspecificos.push({
																									descricao: '',
																									ordem: objetivoAprendizagem.objetivosEspecificos.length,
																								});
																								this.forceUpdate();
																							}}>
																							<i className="fa fa-plus-circle" />
																						</Button>
																						<UncontrolledTooltip placement="bottom" target={`btnAdicionarObjetivoEspecifico${index1}${index2}`} delay={0}>
																							Adicionar objetivo específico
																						</UncontrolledTooltip>
																					</>
																				}
																			</td>
																			{
																				index2 === 0 && <td className="text-center" rowSpan={objetivoAprendizagem.objetivosEspecificos.length} >
																					<Button
																						id={`btnRemoverObjetivoAprendizabem${index1}${index2}`}
																						className="btn-icon"
																						color="danger"
																						size="sm"
																						onClick={() => this.removerObjetivoAprendizagem(objetivoAprendizagem)}
																						type="button">
																						<i className="fa fa-times" />
																					</Button>
																					<UncontrolledTooltip placement="bottom" target={`btnRemoverObjetivoAprendizabem${index1}${index2}`} delay={0}>
																						Remover objetivo de aprendizagem
																					</UncontrolledTooltip>
																				</td>
																			}

																		</tr>)
																	}
																</React.Fragment>)
															}
														</tbody>
													</Table>
												</Col>
											</Row>
										</TabPanel>
									</Tabs> : <div align="center" style={{ margin: 50 }}>
										<Loader
											type="Oval"
											color="#053d7c"
											height="50"
											width="50" />
									</div>
								}
							</CardBody>
							<CardFooter>
								<Row>
									<Col md="6" className="text-left">
										<Button
											color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.props.history.goBack()} >
											Voltar
										</Button>
									</Col>
									<Col md="6" className="text-right">
										<Button color="primary" disabled={this.state.buttonDisabled}
											onClick={() => this.salvarObjetivosAprendizagem()} >
											Salvar direitos e objetivos de aprendizagem
										</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</Form>
				</Col>
			</Row> : <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#053d7c"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className="content">
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.buttonDisabled}
					text={this.state.loaderModalText} />
			</div>
		);
	}
}

export default withRouter(ObjetivosAprendizagem);