import Inicio from 'views/raiz/Inicio';
import Login from 'views/raiz/Login';
import EscolherVinculo from 'views/raiz/EscolherVinculo';
import RecuperacaoSenha from 'views/raiz/RecuperacaoSenha';
import RedefinirSenha from 'views/raiz/RedefinirSenha';
// import CadastroGestor from 'views/raiz/CadastroGestor';
import Cadastro from 'views/raiz/Cadastro';
import CadastroAluno from 'views/raiz/CadastroAluno';
import NoticiasRaiz from 'views/raiz/NoticiasRaiz';
import NoticiaRaiz from 'views/raiz/NoticiaRaiz';
import Documentos from 'views/raiz/Documentos';
import PageNotFound from 'views/raiz/PageNotFound';

const routes = [
  {
    path: '',
    component: Inicio,
    layout: '/',
  },
  {
    path: 'login',
    component: Login,
    layout: '/',
  },
  {
    path: 'login/escolhervinculo',
    component: EscolherVinculo,
    layout: '/',
  },
  {
    path: 'login/recuperacaosenha',
    component: RecuperacaoSenha,
    layout: '/',
  },
  {
    path: 'login/redefinirsenha/:chave',
    component: RedefinirSenha,
    layout: '/',
  },
  /* {
    path: 'cadastrogestor',
    component: CadastroGestor,
    layout: '/',
  }, */
  {
    path: 'cadastro/:chave',
    component: Cadastro,
    layout: '/',
  },
  {
    path: 'cadastro/aluno/:chave',
    component: CadastroAluno,
    layout: '/',
  },
  {
    path: 'noticias/:cidade',
    component: NoticiasRaiz,
    layout: '/',
  },
  {
    path: 'noticias/:cidade/:idNoticia',
    component: NoticiaRaiz,
    layout: '/',
  },
  {
    path: 'documentos/:chave',
    component: Documentos,
    layout: '/',
  },
  {
    path: 'documentos/:idEnte/:chave',
    component: Documentos,
    layout: '/',
  },
	{
    path: 'notfound',
    component: PageNotFound,
    layout: '/',
  },
];

export default routes;
