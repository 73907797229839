/* eslint-disable max-len */
import React from 'react';

import {
	Row,
	Col,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Utils from 'utils/Utils';

import LoaderModal from 'views/components/LoaderModal';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';
import TransporteModal from 'views/components/TransporteModal';
import FichaAlunoModal from 'views/components/FichaAlunoModal';

import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import TransportesService from 'services/TransportesService';

import Papel from 'assets/csv/papeis.json';
import TipoMatricula from 'assets/csv/tipos-matricula.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';

class MatriculasTurma extends React.Component {
	constructor(props) {
		super(props);

		this.idPagina = `${this.props.dadosUsuario.idVinculo}-matriculas-turma-${this.props.anoLetivo.id}`
			+ `-${this.props.escola.id}-${this.props.turma.id}`;

		this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
		this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
		this.like = '';

		this.tiposMatricula = [
			TipoMatricula.REGULAR,
			TipoMatricula.DEPENDENCIA,
		];

		this.situacoesMatricula = [
			SituacaoMatricula.MATRICULADO,
			SituacaoMatricula.TRANSFERIDO,
			SituacaoMatricula.DESISTENTE,
			SituacaoMatricula.APROVADO,
			SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
			SituacaoMatricula.REPROVADO_POR_NOTA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
			SituacaoMatricula.NAO_MATRICULADO,
			SituacaoMatricula.FALECIDO,
		];

		this.state = {
			turma: props.turma,
			escola: props.escola,
			anoLetivo: props.anoLetivo,
			loading: true,

			matriculaSelecionada: null,

			dadosTabela: {
				header: [['Nome', '23%'], ['Nome da mãe', '20%'], ['Tipo', '15%'], ['Situação', '15%'], ['Transporte', '10%'], ['Ficha', '10%']],
				columnAlign: ['text-left', 'text-left', 'text-center', 'text-center', 'text-center', 'text-center'],
				keys: ['nome_str', 'nomeMae_str', 'tipo_str', 'situacao_str', 'tp_str', 'ficha_str'],
				rows: [],
				total: undefined,
			},
		};

		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
		this.transportesService = new TransportesService();
	}

	async componentDidMount() {
		await this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, this.like);

		this.setState({
			loading: false,
		});
	}

	async carregarMatriculas(pagina, linhasPorPagina, like) {
		try {
			const data = await this.turmasService
				.carregarMatriculas(this.state.turma.id, pagina, linhasPorPagina, like);

			const fn2 = (matricula) => new Promise((resolve) => resolve(
				this.transportesService
					.carregarTransportesAluno(matricula.id),
			));

			const actions2 = data.matriculas.map(fn2);

			const transportes = await Promise.all(actions2);

			data.matriculas.forEach((matricula, idx) => Object.assign(
				matricula, {
				tp_str: transportes[idx].length ? this.dropDownTransportes(transportes[idx]) : 'Não',
				ficha_str: <i style={{ cursor: 'pointer' }} onClick={(evt) => {
					evt.stopPropagation();
					this.setState({
						idAluno: matricula.usuario.id,
						showFichaAlunoModal: true,
					});
				}} className="fas fa-address-card fa-2x"></i>,
			},
			));

			data.matriculas.forEach((matricula) => {
				Object.assign(matricula, {
					nome_str: <div>{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg" />}</div>,
					nomeMae_str: matricula.usuario.nomeMae,
					na_str: matricula.novatoNoAno ? '✔' : '',
					pe_str: matricula.propriaEscola ? '✔' : '',
					ap_str: matricula.aprovado ? '✔' : '',
					ra_str: matricula.retidoCicloAdultos ? '✔' : '',
					tr_str: matricula.transferido ? '✔' : '',
					nfe_str: matricula.naoFrequentouEscolaAnoAnterior ? '✔' : '',
					eja_str: matricula.educacaoJovensAdultos ? '✔' : '',
					tipo_str: this.tiposMatricula[matricula.tipo].label,
					situacao_str: this.situacoesMatricula[matricula.situacao].label,
				});
			});

			const diarios = await this.diariosService
				.carregarDiariosDaTurma(this.state.turma.id);

			const diariosAbertos = diarios
				.every((diario) => diario.situacao === SituacaoDiario.ABERTO.value);

			const clickRows = (this.state.turma.situacao === SituacaoTurma.CONSOLIDADA.value)
				|| (this.state.turma.situacao === SituacaoTurma.ABERTA.value && diariosAbertos);

			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela,
					{ rows: data.matriculas, total: data.total }),
				totalMatriculas: data.matriculas.length,
				clickRows,
			});
		} catch (e) {
			this.setState({
				dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
			});
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Erro ao buscar alunos cadastrados',
			});
		}
	}

	dropDownTransportes = (transportes) => {
		const idx = uniqid();
		return <UncontrolledDropdown>
			<DropdownToggle
				aria-haspopup={true}
				color="default"
				data-toggle="collapse"
				id={`navbarDropdownTransporte${idx}`}
				onClick={(evt) => evt.stopPropagation()}
				nav>
				Sim
			</DropdownToggle>
			<DropdownMenu aria-labelledby={`navbarDropdownTransporte${idx}`} right>
				{
					transportes.map((transporte) => <DropdownItem
						key={transporte.id}
						onClick={(evt) => {
							evt.stopPropagation();
							this.setState({
								idTransporte: transporte.id,
								showTransporteModal: true,
							});
						}}>
						{`${transporte.modelo} - ${transporte.itinerario}`}
					</DropdownItem>)
				}
			</DropdownMenu>
		</UncontrolledDropdown>;
	}

	confirmarRemocaoMatricula(matricula) {
		this.setState({
			matriculaSelecionada: matricula,
			showConfirmTypingModal: true,
		});
	}

	editarMatricula(matricula) {
		this.props.history.push(`${this.props.layout}`
			+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
			+ `/escola/${this.props.match.params.idEscola}`
			+ `/turma/${this.props.match.params.idTurma}/matricula/aluno`
			+ `/edicao/${matricula.id}`);
	}

	async removerMatricula(senha) {
		try {
			this.setState({
				showLoaderModal: true,
				loaderModalText: 'Excluindo matrícula...',
			});

			this.props.callbackMessage({
				showAlert: false,
			});

			await this.turmasService
				.removerMatricula(this.state.matriculaSelecionada.id, senha);

			if (this.state.dadosTabela.total % this.linhasPorPagina === 1
				&& this.paginaSelecionada > 1) {
				this.paginaSelecionada -= 1;
			}

			this.setState({
				showLoaderModal: false,
			});

			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Matrícula removida com sucesso',
			});

			this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, this.like);
		} catch (e) {
			this.setState({
				showLoaderModal: false,
			});
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: e || 'Erro ao remover matrícula',
			});
		}
	}

	matriculaSelecionada(matricula) {
		if (!this.state.clickRows) {
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Para alterar a situação de um aluno a turma deve estar consolidada ou aberta com todos os diários abertos',
			});
			return;
		}
		this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
			+ `/escola/${this.state.escola.id}/turma/${this.state.turma.id}/situacao/${matricula.id}`);
	}

	async imprimirBoletimMatricula(idMatricula) {
		this.setState({
			showLoaderModal: true,
			loaderModalText: 'Imprimindo boletim para pdf...',
		});
		this.props.callbackMessage({
			showAlert: false,
		});
		try {
			const pdf = await this.turmasService.imprimirBoletimMatricula(idMatricula);

			const file = Utils.base64ToBlob(pdf);
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.setState({
				showLoaderModal: false,
			});
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Boletim impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
			});
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao imprimir boletim',
			});
		}
	}

	render() {
		return this.state.loading ? <div align="center" style={{ padding: 30 }}>
			<Loader
				type="Oval"
				color="#053d7c"
				height="50"
				width="50" />
		</div> : <>
			<AdaptiveTable
				selectedPage={this.paginaSelecionada}
				rowsPerPage={this.linhasPorPagina}
				clickRows={this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
					&& this.props.role !== Papel.PROFESSOR.value}
				disableActions={this.props.role === Papel.COORDENADOR_PEDAGOGICO.value
					|| this.props.role === Papel.PROFESSOR.value}
				// clickRows={this.state.clickRows}
				disablePrintAction
				rowsPerPageCallback={(info) => {
					this.paginaSelecionada = 1;
					sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
					this.linhasPorPagina = info.rowsPerPage;
					sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
					this.carregarMatriculas(this.paginaSelecionada, info.rowsPerPage, this.like);
				}}
				likeCallback={(text) => {
					this.like = text;
					this.paginaSelecionada = 1;
					sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
					this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, text);
				}}
				paginatorCallback={(page) => {
					this.paginaSelecionada = page;
					sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
					this.carregarMatriculas(this.paginaSelecionada,
						this.linhasPorPagina, this.like);
				}}
				removeButtonCallback={(matricula) => this.confirmarRemocaoMatricula(matricula)}
				editButtonCallback={(matricula) => this.editarMatricula(matricula)}
				printTooltip="Imprimir boletim"
				rowCallback={(matricula) => {
					this.matriculaSelecionada(matricula);
				}}
				data={this.state.dadosTabela} />
			{
				/*
					<Row>
						<Col>
							<br />
							<label><strong>LEGENDA</strong></label><br />
							<label><strong>NA:</strong> Novato no ano</label>&nbsp;-&nbsp;
							<label><strong>PE:</strong> Própria Escola</label>&nbsp;-&nbsp;
							<label><strong>AP:</strong> Aprovado</label>&nbsp;-&nbsp;
							<label><strong>RA:</strong> Retido no ciclo Adultos</label>&nbsp;-&nbsp;
							<label><strong>TR:</strong> Transferido</label>&nbsp;-&nbsp;
							<label><strong>NFE:</strong> Não frequentou a escola no ano
							anterior</label>&nbsp;-&nbsp;
							<label><strong>EJA:</strong> Educação de Jovens e Adultos</label><br />
						</Col>
					</Row>
				*/
			}
			<Row>
				<Col className="text-left">
					<Button
						color="primary"
						onClick={() => this.props.history
							.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`)} >
						Voltar
					</Button>
				</Col>
				<Col className="text-right">
					{
						this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
						&& this.props.role !== Papel.PROFESSOR.value && <>
							<Button
								color="primary"
								onClick={() => this.props.history
									.push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/matricula/aluno`)} >
								Matricular aluno
							</Button>
						</>
					}
				</Col>
			</Row>
			<LoaderModal
				isOpen={this.state.showLoaderModal}
				text={`${this.state.loaderModalText}`} />
			<ConfirmTypingModal
				isOpen={this.state.showConfirmTypingModal}
				callback={(senha) => {
					this.setState({ showConfirmTypingModal: false });
					if (senha) {
						this.removerMatricula(senha);
					} else {
						this.setState({ matriculaSelecionada: null });
					}
				}}
				deletionInstruction='Confirme que você quer excluir a matrícula digitando a sua senha no campo abaixo'
				deletionText={''}
				deletionWarning='A exclusão de uma matrícula acarretará na exclusão de todas as avaliações e frequência do aluno, e demais dados relacionados' />
			<TransporteModal
				isOpen={this.state.showTransporteModal}
				idAnoLetivo={this.props.match.params.idAnoLetivo}
				idTransporte={this.state.idTransporte}
				closeCallback={() => this.setState({ showTransporteModal: false })} />
			<FichaAlunoModal
				isOpen={this.state.showFichaAlunoModal}
				idAluno={this.state.idAluno}
				closeCallback={() => this.setState({ showFichaAlunoModal: false })} />
		</>;
	}
}

export default MatriculasTurma;
