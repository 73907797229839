import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Button,
  Input,
  Table,
  UncontrolledTooltip,
  Breadcrumb,
  BreadcrumbItem,
  //Label,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import moment from 'moment';
import momentbusinessdays from 'moment-business-days';
import isDateFormat from 'is-date-format';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import DatePicker from 'views/components/DatePicker';
import YearPicker from 'views/components/YearPicker';
import CalendarModal from 'views/components/CalendarModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';

import TipoFeriado from 'assets/csv/tipos-feriado.json';
import SituacaoAnoLetivo from 'assets/csv/situacoes-ano-letivo.json';

class CadastroAnoLetivo extends React.Component {
  constructor(props) {
    super(props);

    this.tipos_feriados = [
      TipoFeriado.FERIADO_NACIONAL,
      TipoFeriado.FERIADO_ESTADUAL,
      TipoFeriado.FERIADO_MUNICIPAL,
      TipoFeriado.PONTO_FACULTATIVO,
    ];

    this.situacoesAnosLetivos = [
      SituacaoAnoLetivo.ABERTO,
      SituacaoAnoLetivo.CONSOLIDADO,
    ];

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();

    this.uf = this.props.dadosUsuario.uf;

    this.state = {
      showAlert: false,
      showCalendarModal: false,
      buttonDisabled: false,

      viewDate: new Date(new Date().getFullYear(), 0, 1),

      erro: null,
      anoLetivoSelecionado: null,

      ano: '',

      dataInicioBimestre1: '',
      dataInicioBimestre2: '',
      dataInicioBimestre3: '',
      dataInicioBimestre4: '',
      dataInicioMatriculasOnline: '',

      dataFimBimestre1: '',
      dataFimBimestre2: '',
      dataFimBimestre3: '',
      dataFimBimestre4: '',
      dataFimMatriculasOnline: '',

      dataLimiteBimestre1: '',
      dataLimiteBimestre2: '',
      dataLimiteBimestre3: '',
      dataLimiteBimestre4: '',
      dataLimite: '',

      diasLetivosBimestre1: '',
      diasLetivosBimestre2: '',
      diasLetivosBimestre3: '',
      diasLetivosBimestre4: '',

      sabadoLetivo: false,

      dataInicioRecesso: '',
      dataFimRecesso: '',

      dataFeriado: '',
      nomeFeriado: '',

      dataInicioDiaCancelado: '',
      dataFimDiaCancelado: '',
      motivoDiaCancelado: '',

      dataDiaExtra: '',
      motivoDiaExtra: '',

      feriados: [],
      diasCancelados: [],
      diasExtras: [],

      recesso: [],
      anoLetivo: {},
    };

    this.cadastrarAnoLetivo = this.cadastrarAnoLetivo.bind(this);
    this.atualizarAnoLetivo = this.atualizarAnoLetivo.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.recalcularDiasLetivos = this.recalcularDiasLetivos.bind(this);

    this.validarCamposFeriado = this.validarCamposFeriado.bind(this);
    this.limparFormularioFeriado = this.limparFormularioFeriado.bind(this);
    this.adicionarFeriado = this.adicionarFeriado.bind(this);
    this.removerFeriado = this.removerFeriado.bind(this);

    this.validarCamposDiaCancelado = this.validarCamposDiaCancelado.bind(this);
    this.limparFormularioDiaCancelado = this.limparFormularioDiaCancelado.bind(this);
    this.adicionarDiaCancelado = this.adicionarDiaCancelado.bind(this);
    this.removerDiaCancelado = this.removerDiaCancelado.bind(this);

    this.validarCamposDiaExtra = this.validarCamposDiaExtra.bind(this);
    this.limparFormularioDiaExtra = this.limparFormularioDiaExtra.bind(this);
    this.adicionarDiaExtra = this.adicionarDiaExtra.bind(this);
    this.removerDiaExtra = this.removerDiaExtra.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    
    const dadosEnte = await this.entesService
      .carregarDadosEnte();

    this.setState({ dadosEnte });

    try {
      if (this.props.match.params.idAnoLetivo !== undefined) {
        const anoLetivo = await this.anosLetivosService
          .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

        const feriados = await this.feriadosService
          .carregarFeriadosPorAnoLetivo(anoLetivo.id);

        const diasCancelados = await this.diasCanceladosService
          .carregarDiasCanceladosPorAnoLetivo(anoLetivo.id);

        const diasExtras = await this.diasExtrasService
          .carregarDiasExtrasPorAnoLetivo(anoLetivo.id);

        Object.assign(anoLetivo, { feriados, diasCancelados, diasExtras });
        this.setState({  anoLetivoSelecionado: anoLetivo }, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    if (this.state.anoLetivoSelecionado) {
      const feriados = this.state.anoLetivoSelecionado.feriados.sort(
        (d1, d2) => {
          const m1 = moment(d1.data, 'DD/MM/YYYY');
          const m2 = moment(d2.data, 'DD/MM/YYYY');
          if (m1.isSame(m2)) {
            return 0;
          } if (m1.isBefore(m2)) {
            return -1;
          }
          return 1;
        },
      );

      const diasCancelados = this.state.anoLetivoSelecionado.diasCancelados.sort(
        (d1, d2) => {
          const m1 = moment(d1.data, 'DD/MM/YYYY');
          const m2 = moment(d2.data, 'DD/MM/YYYY');
          if (m1.isSame(m2)) {
            return 0;
          } if (m1.isBefore(m2)) {
            return -1;
          }
          return 1;
        },
      );

      const diasExtras = this.state.anoLetivoSelecionado.diasExtras.sort(
        (d1, d2) => {
          const m1 = moment(d1.data, 'DD/MM/YYYY');
          const m2 = moment(d2.data, 'DD/MM/YYYY');
          if (m1.isSame(m2)) {
            return 0;
          } if (m1.isBefore(m2)) {
            return -1;
          }
          return 1;
        },
      );

      const recesso = this.getDiasRecesso(this.state.anoLetivoSelecionado.dataInicioRecesso,
        this.state.anoLetivoSelecionado.dataFimRecesso);

      const sabados = this.getSabadosAnoLetivo(this.state.anoLetivoSelecionado.ano);

      const holidays = feriados.map((feriado) => feriado.data)
        .concat(recesso !== null ? recesso : [])
        .concat(diasCancelados.map((dia) => dia.data))
        .concat(!this.state.anoLetivoSelecionado.sabadoLetivo ? sabados : [])
        .filter((dia) => diasExtras.every((d) => d.data !== dia));

      momentbusinessdays.updateLocale('pt-br', {
        holidays,
        holidayFormat: 'DD/MM/YYYY',
        workingWeekdays: [1, 2, 3, 4, 5, 6],
      });

      this.setState({
        ano: this.state.anoLetivoSelecionado.ano,
        viewDate: new Date(this.state.anoLetivoSelecionado.ano, 0, 1),

        dataInicioBimestre1: this.state.anoLetivoSelecionado.dataInicioBimestre1,
        dataInicioBimestre2: this.state.anoLetivoSelecionado.dataInicioBimestre2,
        dataInicioBimestre3: this.state.anoLetivoSelecionado.dataInicioBimestre3,
        dataInicioBimestre4: this.state.anoLetivoSelecionado.dataInicioBimestre4,

        dataFimBimestre1: this.state.anoLetivoSelecionado.dataFimBimestre1,
        dataFimBimestre2: this.state.anoLetivoSelecionado.dataFimBimestre2,
        dataFimBimestre3: this.state.anoLetivoSelecionado.dataFimBimestre3,
        dataFimBimestre4: this.state.anoLetivoSelecionado.dataFimBimestre4,

        dataLimiteBimestre1: this.state.anoLetivoSelecionado.dataLimiteBimestre1,
        dataLimiteBimestre2: this.state.anoLetivoSelecionado.dataLimiteBimestre2,
        dataLimiteBimestre3: this.state.anoLetivoSelecionado.dataLimiteBimestre3,
        dataLimiteBimestre4: this.state.anoLetivoSelecionado.dataLimiteBimestre4,
        dataLimite: this.state.anoLetivoSelecionado.dataLimite,

        dataInicioRecesso: this.state.anoLetivoSelecionado.dataInicioRecesso,
        dataFimRecesso: this.state.anoLetivoSelecionado.dataFimRecesso,

        dataInicioMatriculasOnline: this.state.anoLetivoSelecionado.dataInicioMatriculasOnline,
        dataFimMatriculasOnline: this.state.anoLetivoSelecionado.dataFimMatriculasOnline,

        feriados,
        sabados,
        diasCancelados,
        diasExtras,
        recesso,

        sabadoLetivo: this.state.anoLetivoSelecionado.sabadoLetivo,

        diasLetivosBimestre1: this.calcularDiasLetivos(
          this.state.anoLetivoSelecionado.dataInicioBimestre1,
          this.state.anoLetivoSelecionado.dataFimBimestre1,
        ),
        diasLetivosBimestre2: this.calcularDiasLetivos(
          this.state.anoLetivoSelecionado.dataInicioBimestre2,
          this.state.anoLetivoSelecionado.dataFimBimestre2,
        ),
        diasLetivosBimestre3: this.calcularDiasLetivos(
          this.state.anoLetivoSelecionado.dataInicioBimestre3,
          this.state.anoLetivoSelecionado.dataFimBimestre3,
        ),
        diasLetivosBimestre4: this.calcularDiasLetivos(
          this.state.anoLetivoSelecionado.dataInicioBimestre4,
          this.state.anoLetivoSelecionado.dataFimBimestre4,
        ),
      });
    }
  }


  validarCampos() {
    let ret = true;

    if (this.state.ano === '') {
      this.setState({ anoState: 'has-danger' });
      ret = false;
    }

    // inicio bimestre 1
    if (this.state.dataInicioBimestre1 === '') {
      this.setState({
        dataInicioBimestre1State: 'has-danger',
        dataInicioBimestre1Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioBimestre1, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioBimestre1State: 'has-danger',
        dataInicioBimestre1Erro: 'Data inválida',
      });
      ret = false;
    }

    // fim bimestre 1
    if (this.state.dataFimBimestre1 === '') {
      this.setState({
        dataFimBimestre1State: 'has-danger',
        dataFimBimestre1Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimBimestre1, 'dd/mm/yyyy')) {
      this.setState({
        dataFimBimestre1State: 'has-danger',
        dataFimBimestre1Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioBimestre1 !== null
      && moment(this.state.dataInicioBimestre1, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimBimestre1, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimBimestre1State: 'has-danger',
        dataFimBimestre1Erro: 'A data de fim do 1º bimestre deve ser posterior à data de início do 1º bimestre',
      });
      ret = false;
    }

    // início bimestre 2
    if (this.state.dataInicioBimestre2 === '') {
      this.setState({
        dataInicioBimestre2State: 'has-danger',
        dataInicioBimestre2Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioBimestre2, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioBimestre2State: 'has-danger',
        dataInicioBimestre2Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataFimBimestre1 !== null
      && moment(this.state.dataFimBimestre1, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataInicioBimestre2, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataInicioBimestre2State: 'has-danger',
        dataInicioBimestre2Erro: 'A data de início do 2º bimestre deve ser posterior à data de fim do 1º bimestre',
      });
      ret = false;
    }

    // fim bimestre 2
    if (this.state.dataFimBimestre2 === '') {
      this.setState({
        dataFimBimestre2State: 'has-danger',
        dataFimBimestre2Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimBimestre2, 'dd/mm/yyyy')) {
      this.setState({
        dataFimBimestre2State: 'has-danger',
        dataFimBimestre2Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioBimestre2 !== null
      && moment(this.state.dataInicioBimestre2, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimBimestre2, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimBimestre2State: 'has-danger',
        dataFimBimestre2Erro: 'A data de fim do 2º bimestre deve ser posterior à data de início do 2º bimestre',
      });
      ret = false;
    }

    // início bimestre 3
    if (this.state.dataInicioBimestre3 === '') {
      this.setState({
        dataInicioBimestre3State: 'has-danger',
        dataInicioBimestre3Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioBimestre3, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioBimestre3State: 'has-danger',
        dataInicioBimestre3Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataFimBimestre2 !== null
      && moment(this.state.dataFimBimestre2, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataInicioBimestre3, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataInicioBimestre3State: 'has-danger',
        dataInicioBimestre3Erro: 'A data de início do 3º bimestre deve ser posterior à data de fim do 2º bimestre',
      });
      ret = false;
    }

    // fim bimestre 3
    if (this.state.dataFimBimestre3 === '') {
      this.setState({
        dataFimBimestre3State: 'has-danger',
        dataFimBimestre3Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimBimestre3, 'dd/mm/yyyy')) {
      this.setState({
        dataFimBimestre3State: 'has-danger',
        dataFimBimestre3Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioBimestre3 !== null
      && moment(this.state.dataInicioBimestre3, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimBimestre3, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimBimestre3State: 'has-danger',
        dataFimBimestre3Erro: 'A data de fim do 3º bimestre deve ser posterior à data de início do 3º bimestre',
      });
      ret = false;
    }

    // início bimestre 4
    if (this.state.dataInicioBimestre4 === '') {
      this.setState({
        dataInicioBimestre4State: 'has-danger',
        dataInicioBimestre4Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioBimestre4, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioBimestre4State: 'has-danger',
        dataInicioBimestre4Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataFimBimestre3 !== null
      && moment(this.state.dataFimBimestre3, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataInicioBimestre4, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataInicioBimestre4State: 'has-danger',
        dataInicioBimestre4Erro: 'A data de início do 4º bimestre deve ser posterior à data de fim do 3º bimestre',
      });
      ret = false;
    }

    // fim bimestre 4
    if (this.state.dataFimBimestre4 === '') {
      this.setState({
        dataFimBimestre4State: 'has-danger',
        dataFimBimestre4Erro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimBimestre4, 'dd/mm/yyyy')) {
      this.setState({
        dataFimBimestre4State: 'has-danger',
        dataFimBimestre4Erro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioBimestre4 !== null
      && moment(this.state.dataInicioBimestre4, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimBimestre4, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimBimestre4State: 'has-danger',
        dataFimBimestre4Erro: 'A data de fim do 4º bimestre deve ser posterior à data de início do 4º bimestre',
      });
      ret = false;
    }

    // início matrículas online
    if (!this.state.dataInicioMatriculasOnline && this.state.dataFimMatriculasOnline) {
      this.setState({
        dataInicioMatriculasOnlineState: 'has-danger',
        dataInicioMatriculasOnlineErro: 'Informe uma data',
      });
      ret = false;
    } else if (this.state.dataInicioMatriculasOnline 
        && !isDateFormat(this.state.dataInicioMatriculasOnline, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioMatriculasOnlineState: 'has-danger',
        dataInicioMatriculasOnlineErro: 'Data inválida',
      });
      ret = false;
    } 

    // fim matrículas online
    if (this.state.dataInicioMatriculasOnline && !this.state.dataFimMatriculasOnline) {
      this.setState({
        dataFimMatriculasOnlineState: 'has-danger',
        dataFimMatriculasOnlineErro: 'Informe uma data',
      });
      ret = false;
    } else if (this.state.dataFimMatriculasOnline 
        && !isDateFormat(this.state.dataFimMatriculasOnline, 'dd/mm/yyyy')) {
      this.setState({
        dataFimMatriculasOnlineState: 'has-danger',
        dataFimMatriculasOnlineErro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioMatriculasOnline
      && moment(this.state.dataInicioMatriculasOnline, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimMatriculasOnline, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimMatriculasOnlineState: 'has-danger',
        dataFimMatriculasOnlineErro: 'A data de fim das matrículas online deve ser posterior à data de início das matrículas online',
      });
      ret = false;
    }

    // data limite
    if (this.state.dataLimite) {
      if (!isDateFormat(this.state.dataLimite, 'dd/mm/yyyy')) {
        this.setState({
          dataLimiteState: 'has-danger',
          dataLimiteErro: 'Data inválida',
        });
        ret = false;
      } else if (moment(this.state.dataLimite, 'DD/MM/YYYY').isSameOrBefore(
        moment(this.state.dataFimBimestre4, 'DD/MM/YYYY'),
      )) {
        this.setState({
          dataLimiteState: 'has-danger',
          dataLimiteErro: 'A data limite para consolidação das turmas e diários deve ser posterior a data de término do ano letivo.',
        });
        ret = false;
      }
    }

    // inicio recesso
    if (this.state.dataInicioRecesso === '') {
      this.setState({
        dataInicioRecessoState: 'has-danger',
        dataInicioRecessoErro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioRecesso, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioRecessoState: 'has-danger',
        dataInicioRecessoErro: 'Data inválida',
      });
      ret = false;
    }

    // fim recesso
    if (this.state.dataFimRecesso === '') {
      this.setState({
        dataFimRecessoState: 'has-danger',
        dataFimRecessoErro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimRecesso, 'dd/mm/yyyy')) {
      this.setState({
        dataFimRecessoState: 'has-danger',
        dataFimRecessoErro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicioRecesso !== null
      && moment(this.state.dataInicioRecesso, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.dataFimRecesso, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimRecessoState: 'has-danger',
        dataFimRecessoErro: 'A data de fim do recesso deve ser posterior à data de início do recesso',
      });
      ret = false;
    }

    /* if (this.state.diasLetivosBimestre1 + this.state.diasLetivosBimestre2
      + this.state.diasLetivosBimestre3 + this.state.diasLetivosBimestre4 < 200) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'O total de dias letivos é inferior a 200',
      });
      ret = false;
    } */

    return ret;
  }

  async cadastrarAnoLetivo() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando ano letivo...',
    });

    const anoLetivo = {
      ano: this.state.ano,
      dataInicioBimestre1: this.state.dataInicioBimestre1,
      dataInicioBimestre2: this.state.dataInicioBimestre2,
      dataInicioBimestre3: this.state.dataInicioBimestre3,
      dataInicioBimestre4: this.state.dataInicioBimestre4,
      dataFimBimestre1: this.state.dataFimBimestre1,
      dataFimBimestre2: this.state.dataFimBimestre2,
      dataFimBimestre3: this.state.dataFimBimestre3,
      dataFimBimestre4: this.state.dataFimBimestre4,
      dataInicioRecesso: this.state.dataInicioRecesso,
      dataFimRecesso: this.state.dataFimRecesso,
      dataInicioMatriculasOnline: this.state.dataInicioMatriculasOnline,
      dataFimMatriculasOnline: this.state.dataFimMatriculasOnline,
      feriados: this.state.feriados,
      diasCancelados: this.state.diasCancelados,
      diasExtras: this.state.diasExtras,
      diasLetivosBimestre1: this.state.diasLetivosBimestre1,
      diasLetivosBimestre2: this.state.diasLetivosBimestre2,
      diasLetivosBimestre3: this.state.diasLetivosBimestre3,
      diasLetivosBimestre4: this.state.diasLetivosBimestre4,
      dataLimiteBimestre1: this.state.dataLimiteBimestre1,
      dataLimiteBimestre2: this.state.dataLimiteBimestre2,
      dataLimiteBimestre3: this.state.dataLimiteBimestre3,
      dataLimiteBimestre4: this.state.dataLimiteBimestre4,
      dataLimite: this.state.dataLimite,
      sabadoLetivo: this.state.sabadoLetivo,
    };

    try {
      await this.anosLetivosService.cadastrarAnoLetivo(anoLetivo);
      this.limparFormulario();
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ano letivo cadastrado com sucesso',
      });
    } catch (err) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: err || 'Erro ao cadastrar ano letivo',
      });
    }
  }

  async atualizarAnoLetivo() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando ano letivo...',
    });

    const anoLetivo = {
      id: this.state.anoLetivoSelecionado.id,
      ano: this.state.ano,
      situacao: this.state.anoLetivoSelecionado.situacao,
      dataInicioBimestre1: this.state.dataInicioBimestre1,
      dataInicioBimestre2: this.state.dataInicioBimestre2,
      dataInicioBimestre3: this.state.dataInicioBimestre3,
      dataInicioBimestre4: this.state.dataInicioBimestre4,
      dataFimBimestre1: this.state.dataFimBimestre1,
      dataFimBimestre2: this.state.dataFimBimestre2,
      dataFimBimestre3: this.state.dataFimBimestre3,
      dataFimBimestre4: this.state.dataFimBimestre4,
      dataInicioRecesso: this.state.dataInicioRecesso,
      dataFimRecesso: this.state.dataFimRecesso,
      dataInicioMatriculasOnline: this.state.dataInicioMatriculasOnline,
      dataFimMatriculasOnline: this.state.dataFimMatriculasOnline,
      feriados: this.state.feriados,
      diasCancelados: this.state.diasCancelados,
      diasExtras: this.state.diasExtras,
      diasLetivosBimestre1: this.state.diasLetivosBimestre1,
      diasLetivosBimestre2: this.state.diasLetivosBimestre2,
      diasLetivosBimestre3: this.state.diasLetivosBimestre3,
      diasLetivosBimestre4: this.state.diasLetivosBimestre4,
      dataLimiteBimestre1: this.state.dataLimiteBimestre1,
      dataLimiteBimestre2: this.state.dataLimiteBimestre2,
      dataLimiteBimestre3: this.state.dataLimiteBimestre3,
      dataLimiteBimestre4: this.state.dataLimiteBimestre4,
      dataLimite: this.state.dataLimite,
      sabadoLetivo: this.state.sabadoLetivo,
    };

    try {
      await this.anosLetivosService.atualizarAnoLetivo(anoLetivo);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ano letivo atualizado com sucesso',
      });
      this.limparFormularioFeriado();
      this.limparFormularioDiaCancelado();
      this.limparFormularioDiaExtra();
    } catch (e) {

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar ano letivo',
      });
    }
  }

  limparFormulario() {
    this.setState({

      feriados: [],
      recesso: [],

      viewDate: new Date(new Date().getFullYear(), 0, 1),

      anoState: '',

      dataInicioBimestre1State: '',
      dataInicioBimestre2State: '',
      dataInicioBimestre3State: '',
      dataInicioBimestre4State: '',

      dataFimBimestre1State: '',
      dataFimBimestre2State: '',
      dataFimBimestre3State: '',
      dataFimBimestre4State: '',

      dataInicioRecessoState: '',
      dataFimRecessoState: '',

      dataLimiteBimestre1State: '',
      dataLimiteBimestre2State: '',
      dataLimiteBimestre3State: '',
      dataLimiteBimestre4State: '',
      dataLimiteState: '',

      dataInicioMatriculasOnlineState: '',
      dataFimMatriculasOnlineState: '',

      dataInicioBimestre1: '',
      dataInicioBimestre2: '',
      dataInicioBimestre3: '',
      dataInicioBimestre4: '',

      dataFimBimestre1: '',
      dataFimBimestre2: '',
      dataFimBimestre3: '',
      dataFimBimestre4: '',

      dataInicioRecesso: '',
      dataFimRecesso: '',

      dataLimiteBimestre1: '',
      dataLimiteBimestre2: '',
      dataLimiteBimestre3: '',
      dataLimiteBimestre4: '',
      dataLimite: '',

      dataInicioMatriculasOnline: '',
      dataFimMatriculasOnline: '',

      ano: '',

      diasLetivosBimestre1: '',
      diasLetivosBimestre2: '',
      diasLetivosBimestre3: '',
      diasLetivosBimestre4: '',

      sabadoLetivo: false,

      showAlert: false,
      buttonDisabled: false,

    });
    this.limparFormularioFeriado();
    this.limparFormularioDiaCancelado();
    this.limparFormularioDiaExtra();
  }

  validarCamposFeriado() {
    let ret = true;
    if (this.state.dataFeriado === '') {
      this.setState({ dataFeriadoState: 'has-danger' });
      ret = false;
    } else if (!isDateFormat(this.state.dataFeriado, 'dd/mm/yyyy')) {
      this.setState({ dataFeriadoState: 'has-danger' });
      ret = false;
    }

    if (this.state.nomeFeriado === '') {
      this.setState({ nomeFeriadoState: 'has-danger' });
      ret = false;
    }
    if (!this.state.tipoFeriadoSelecionado) {
      this.setState({ tipoFeriadoState: 'danger' });
      ret = false;
    }
    return ret;
  }

  validarCamposDiaCancelado() {
    let ret = true;
    if (this.state.dataInicioDiaCancelado === '') {
      this.setState({ dataInicioDiaCanceladoState: 'has-danger' });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicioDiaCancelado, 'dd/mm/yyyy')) {
      this.setState({ dataInicioDiaCanceladoState: 'has-danger' });
      ret = false;
    }

    if (this.state.dataFimDiaCancelado === '') {
      this.setState({ dataFimDiaCanceladoState: 'has-danger' });
      ret = false;
    } else if (!isDateFormat(this.state.dataFimDiaCancelado, 'dd/mm/yyyy')) {
      this.setState({ dataFimDiaCanceladoState: 'has-danger' });
      ret = false;
    }

    if (this.state.motivoDiaCancelado === '') {
      this.setState({ motivoDiaCanceladoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  validarCamposDiaExtra() {
    let ret = true;
    if (this.state.dataDiaExtra === '') {
      this.setState({ dataDiaExtraState: 'has-danger' });
      ret = false;
    } else if (!isDateFormat(this.state.dataDiaExtra, 'dd/mm/yyyy')) {
      this.setState({ dataDiaExtraState: 'has-danger' });
      ret = false;
    }

    if (this.state.motivoDiaExtra === '') {
      this.setState({ motivoDiaExtraState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  recalcularDiasLetivos(feriados, diasCancelados, diasExtras, sabadoLetivo) {
    const holidays = feriados.map((feriado) => feriado.data)
      .concat(this.state.recesso !== null ? this.state.recesso : [])
      .concat(diasCancelados.map((dia) => dia.data))
      .concat(!sabadoLetivo ? this.state.sabados : [])
      .filter((dia) => diasExtras.every((d) => d.data !== dia));

    momentbusinessdays.updateLocale('pt-br', {
      holidays,
      holidayFormat: 'DD/MM/YYYY',
      workingWeekdays: [1, 2, 3, 4, 5, 6],
    });

    this.setState({
      feriados,
      diasCancelados,
      diasExtras,
      diasLetivosBimestre1: this.calcularDiasLetivos(
        this.state.dataInicioBimestre1, this.state.dataFimBimestre1,
      ),
      diasLetivosBimestre2: this.calcularDiasLetivos(
        this.state.dataInicioBimestre2, this.state.dataFimBimestre2,
      ),
      diasLetivosBimestre3: this.calcularDiasLetivos(
        this.state.dataInicioBimestre3, this.state.dataFimBimestre3,
      ),
      diasLetivosBimestre4: this.calcularDiasLetivos(
        this.state.dataInicioBimestre4, this.state.dataFimBimestre4,
      ),
    });
  }

  adicionarFeriado() {
    if (!this.validarCamposFeriado()) return;

    const feriado = {
      data: this.state.dataFeriado,
      nome: this.state.nomeFeriado,
      tipo: this.state.tipoFeriadoSelecionado.value,
    };

    const feriados = this.state.feriados.concat([feriado]).sort(
      (d1, d2) => {
        const m1 = moment(d1.data, 'DD/MM/YYYY');
        const m2 = moment(d2.data, 'DD/MM/YYYY');
        if (m1.isSame(m2)) {
          return 0;
        } if (m1.isBefore(m2)) {
          return -1;
        }
        return 1;
      },
    );

    this.recalcularDiasLetivos(feriados, this.state.diasCancelados,
      this.state.diasExtras, this.state.sabadoLetivo);

    this.limparFormularioFeriado();
  }

  adicionarDiaCancelado() {
    if (!this.validarCamposDiaCancelado()) return;

    let diasCancelados = [];

    const data = moment(this.state.dataInicioDiaCancelado, 'DD/MM/YYYY');
    const dataFim = moment(this.state.dataFimDiaCancelado, 'DD/MM/YYYY');
    while (!data.isAfter(dataFim)) {
      const diaCancelado = {
        data: data.format('DD/MM/YYYY'),
        motivo: this.state.motivoDiaCancelado,
      };
      diasCancelados.push(diaCancelado);
      data.add(1, 'days');
    }

    diasCancelados = this.state.diasCancelados.concat(diasCancelados).sort(
      (d1, d2) => {
        const m1 = moment(d1.data, 'DD/MM/YYYY');
        const m2 = moment(d2.data, 'DD/MM/YYYY');
        if (m1.isSame(m2)) {
          return 0;
        } if (m1.isBefore(m2)) {
          return -1;
        }
        return 1;
      },
    );

    this.recalcularDiasLetivos(this.state.feriados, diasCancelados,
      this.state.diasExtras, this.state.sabadoLetivo);

    this.limparFormularioDiaCancelado();
  }

  adicionarDiaExtra() {
    if (!this.validarCamposDiaExtra()) return;

    const data = moment(this.state.dataDiaExtra, 'DD/MM/YYYY');
    const diaExtra = {
      data: data.format('DD/MM/YYYY'),
      motivo: this.state.motivoDiaExtra,
    };

    const diasExtras = this.state.diasExtras.concat([diaExtra]).sort(
      (d1, d2) => {
        const m1 = moment(d1.data, 'DD/MM/YYYY');
        const m2 = moment(d2.data, 'DD/MM/YYYY');
        if (m1.isSame(m2)) {
          return 0;
        } if (m1.isBefore(m2)) {
          return -1;
        }
        return 1;
      },
    );

    this.recalcularDiasLetivos(this.state.feriados, this.state.diasCancelados,
      diasExtras, this.state.sabadoLetivo);

    this.limparFormularioDiaExtra();
  }

  removerFeriado(feriado) {
    const feriados = this.state.feriados.filter(
      (feriado2) => feriado !== feriado2,
    );

    this.recalcularDiasLetivos(feriados, this.state.diasCancelados,
      this.state.diasExtras, this.state.sabadoLetivo);
  }

  removerDiaCancelado(dia) {
    const diasCancelados = this.state.diasCancelados.filter(
      (dia2) => dia !== dia2,
    );

    this.recalcularDiasLetivos(this.state.feriados, diasCancelados,
      this.state.diasExtras, this.state.sabadoLetivo);
  }

  removerDiaExtra(dia) {
    const diasExtras = this.state.diasExtras.filter(
      (dia2) => dia !== dia2,
    );

    this.recalcularDiasLetivos(this.state.feriados, this.state.diasCancelados,
      diasExtras, this.state.sabadoLetivo);
  }

  limparFormularioFeriado() {
    this.setState({
      dataFeriadoState: '',
      nomeFeriadoState: '',
      tipoFeriadoState: '',

      dataFeriado: '',
      nomeFeriado: '',
      tipoFeriadoSelecionado: '',
    });
  }

  limparFormularioDiaCancelado() {
    this.setState({
      dataInicioDiaCanceladoState: '',
      dataFimDiaCanceladoState: '',
      motivoDiaCanceladoState: '',

      dataInicioDiaCancelado: '',
      dataFimDiaCancelado: '',
      motivoDiaCancelado: '',
    });
  }

  limparFormularioDiaExtra() {
    this.setState({
      dataDiaExtraState: '',
      motivoDiaExtraState: '',

      dataDiaExtra: '',
      motivoDiaExtra: '',
    });
  }

  calcularDiasLetivos = (data1, data2) => {
    const m1 = momentbusinessdays(data1, 'DD/MM/YYYY');
    const m2 = momentbusinessdays(data2, 'DD/MM/YYYY');
    if (m1.isValid() && m2.isValid()) {
      if (m1.isSameOrBefore(m2)) {
        let ret = m2.businessDiff(m1);
        if (m2.isBusinessDay()) ret += 1;
        return ret;
      }
      return -1;
    }
    return '';
  }

  getDiasRecesso = (data1, data2) => {
    const m1 = momentbusinessdays(data1, 'DD/MM/YYYY');
    const m2 = momentbusinessdays(data2, 'DD/MM/YYYY');
    if (m1.isValid() && m2.isValid()) {
      if (m1.isSameOrBefore(m2)) {
        const datas = [];
        while (!m1.isSame(m2)) {
          datas.push(m1.format('DD/MM/YYYY'));
          m1.add(1, 'd');
        }
        datas.push(data2);
        return datas;
      }
      return null;
    }
    return [];
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.anoLetivoSelecionado) {
      this.cadastrarAnoLetivo();
    } else {
      this.atualizarAnoLetivo();
    }
  }

  getSabadosAnoLetivo = (ano) => {
    const sabados = [];

    const d = moment(new Date(ano, 0, 1));
    while (d.day() !== 6) d.add(1, 'days');
    while (d.year() === parseInt(ano, 10)) {
      sabados.push(d.format('DD/MM/YYYY'));
      d.add(7, 'days');
    }

    return sabados;
  }

  conteudoPagina() {
    let diasLetivosBimestre1Str = 'Intervalo inválido';
    if (this.state.diasLetivosBimestre1 === '') {
      diasLetivosBimestre1Str = this.state.diasLetivosBimestre1;
    } else if (this.state.diasLetivosBimestre1 >= 0) {
      diasLetivosBimestre1Str = `${this.state.diasLetivosBimestre1} dias letivos`;
    }

    let diasLetivosBimestre2Str = 'Intervalo inválido';
    if (this.state.diasLetivosBimestre2 === '') {
      diasLetivosBimestre2Str = this.state.diasLetivosBimestre2;
    } else if (this.state.diasLetivosBimestre2 >= 0) {
      diasLetivosBimestre2Str = `${this.state.diasLetivosBimestre2} dias letivos`;
    }

    let diasLetivosBimestre3Str = 'Intervalo inválido';
    if (this.state.diasLetivosBimestre3 === '') {
      diasLetivosBimestre3Str = this.state.diasLetivosBimestre3;
    } else if (this.state.diasLetivosBimestre3 >= 0) {
      diasLetivosBimestre3Str = `${this.state.diasLetivosBimestre3} dias letivos`;
    }

    let diasLetivosBimestre4Str = 'Intervalo inválido';
    if (this.state.diasLetivosBimestre4 === '') {
      diasLetivosBimestre4Str = this.state.diasLetivosBimestre4;
    } else if (this.state.diasLetivosBimestre4 >= 0) {
      diasLetivosBimestre4Str = `${this.state.diasLetivosBimestre4} dias letivos`;
    }

    let diasRecessoStr = 'Intervalo inválido';
    if (this.state.recesso !== null) {
      if (this.state.recesso.length === 0) {
        diasRecessoStr = '';
      } else {
        diasRecessoStr = `${this.state.recesso.length} dias`;
      }
    }

    return !this.state.dadosEnte ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/anosletivos">Anos Letivos</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.anoLetivoSelecionado ? 'Edição' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do ano letivo</CardTitle>
              </CardHeader>
              <CardBody>
                <Tabs>
                  <TabList>
                    <Tab>Dados do ano letivo</Tab>
                    <Tab>Feriados</Tab>
                    <Tab>Dias letivos extra</Tab>
                    <Tab>Dias letivos cancelados</Tab>
                    { this.state.dadosEnte.limitesBimestrais && <Tab>Datas limite</Tab> }
                  </TabList>
                  <TabPanel>
                    {this.state.anoLetivoSelecionado
                      && <Row>
                        <Col md="2">
                          <FormGroup className="has-label">
                            <label>Situação</label>
                            <Input
                              disabled
                              type="text"
                              value={this.situacoesAnosLetivos[
                                this.state.anoLetivoSelecionado.situacao].label}
                            />
                          </FormGroup>
                        </Col>
                      </Row>}
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.anoState}`}>
                          <label>Ano *</label>
                          <YearPicker
                            value={this.state.ano}
                            disabled={this.state.anoLetivoSelecionado !== null}
                            onChange={(ano) => {
                              const feriados = this.feriadosService.carregarFeriados(
                                ano.year(), this.uf,
                              );

                              const sabados = this.getSabadosAnoLetivo(ano.toDate().getFullYear());

                              momentbusinessdays.updateLocale('pt-br', {
                                holidays: feriados.map((feriado) => feriado.data)
                                  .concat(!this.state.sabadoLetivo ? sabados : []),
                                holidayFormat: 'DD/MM/YYYY',
                                workingWeekdays: [1, 2, 3, 4, 5, 6],
                              });

                              this.limparFormulario();

                              const anoStr = ano.toDate().getFullYear();

                              this.setState({
                                viewDate: new Date(anoStr, 0, 1),
                                ano: `${anoStr}`,
                                feriados,
                                sabados,
                                diasCancelados: [],
                                diasExtras: [],
                              });
                            }}
                            onClickCalendarButton={() => {
                              this.setState({
                                showCalendarModal: true,
                                anoLetivo: {
                                  ano: this.state.ano,
                                  dataInicioBimestre1: this.state.dataInicioBimestre1,
                                  dataInicioBimestre2: this.state.dataInicioBimestre2,
                                  dataInicioBimestre3: this.state.dataInicioBimestre3,
                                  dataInicioBimestre4: this.state.dataInicioBimestre4,
                                  dataFimBimestre1: this.state.dataFimBimestre1,
                                  dataFimBimestre2: this.state.dataFimBimestre2,
                                  dataFimBimestre3: this.state.dataFimBimestre3,
                                  dataFimBimestre4: this.state.dataFimBimestre4,
                                  dataInicioRecesso: this.state.dataInicioRecesso,
                                  dataFimRecesso: this.state.dataFimRecesso,
                                  dataLimiteBimestre1: this.state.dataLimiteBimestre1,
                                  dataLimiteBimestre2: this.state.dataLimiteBimestre2,
                                  dataLimiteBimestre3: this.state.dataLimiteBimestre3,
                                  dataLimiteBimestre4: this.state.dataLimiteBimestre4,
                                  dataLimite: this.state.dataLimite,
                                  dataInicioMatriculasOnline: this.state.dataInicioMatriculasOnline,
                                  dataFimMatriculasOnline: this.state.dataFimMatriculasOnline,
                                  feriados: this.state.feriados,
                                  diasCancelados: this.state.diasCancelados,
                                  diasExtras: this.state.diasExtras,
                                  recesso: this.state.recesso,
                                },
                              });
                            }}
                          />
                          {this.state.anoState === 'has-danger' ? (
                            <label className="error">
                              Informe o ano.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataInicioRecessoState}`}>
                          <label>Início recesso junino *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataInicioRecesso}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');

                              const recesso = this.getDiasRecesso(
                                dataStr, this.state.dataFimRecesso,
                              );

                              const holidays = this.state.feriados.map((feriado) => feriado.data)
                                .concat(recesso !== null ? recesso : [])
                                .concat(this.state.diasCancelados.map((dia) => dia.data))
                                .concat(!this.state.sabadoLetivo ? this.state.sabados : [])
                                .filter((dia) => this.state.diasExtras
                                  .every((d) => d.data !== dia));

                              momentbusinessdays.updateLocale('pt-br', {
                                holidays,
                                holidayFormat: 'DD/MM/YYYY',
                                workingWeekdays: [1, 2, 3, 4, 5, 6],
                              });
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataInicioRecesso: dataStr,
                                recesso,
                                dataInicioRecessoState: '',
                                diasLetivosBimestre1: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre1, this.state.dataFimBimestre1,
                                ),
                                diasLetivosBimestre2: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre2, this.state.dataFimBimestre2,
                                ),
                                diasLetivosBimestre3: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre3, this.state.dataFimBimestre3,
                                ),
                                diasLetivosBimestre4: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre4, this.state.dataFimBimestre4,
                                ),
                              });
                            }}
                          />
                          {this.state.dataInicioRecessoState === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataInicioRecessoErro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataFimRecessoState}`}>
                          <label>Fim recesso junino *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataFimRecesso}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');

                              const recesso = this.getDiasRecesso(
                                this.state.dataInicioRecesso, dataStr,
                              );

                              const holidays = this.state.feriados.map((feriado) => feriado.data)
                                .concat(recesso !== null ? recesso : [])
                                .concat(this.state.diasCancelados.map((dia) => dia.data))
                                .concat(!this.state.sabadoLetivo ? this.state.sabados : [])
                                .filter((dia) => this.state.diasExtras
                                  .every((d) => d.data !== dia));

                              momentbusinessdays.updateLocale('pt-br', {
                                holidays,
                                holidayFormat: 'DD/MM/YYYY',
                                workingWeekdays: [1, 2, 3, 4, 5, 6],
                              });

                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataFimRecesso: dataStr,
                                recesso,
                                dataFimRecessoState: '',
                                diasLetivosBimestre1: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre1, this.state.dataFimBimestre1,
                                ),
                                diasLetivosBimestre2: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre2, this.state.dataFimBimestre2,
                                ),
                                diasLetivosBimestre3: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre3, this.state.dataFimBimestre3,
                                ),
                                diasLetivosBimestre4: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre4, this.state.dataFimBimestre4,
                                ),
                              });
                            }}
                          />
                          {this.state.dataFimRecessoState === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataFimRecessoErro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label>&nbsp;</label>
                          <Input
                            value={diasRecessoStr}
                            className="transparent-input"
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataInicioBimestre1State}`}>
                          <label>Início 1º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataInicioBimestre1}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataInicioBimestre1: dataStr,
                                diasLetivosBimestre1: this.calcularDiasLetivos(
                                  dataStr, this.state.dataFimBimestre1,
                                ),
                                dataInicioBimestre1State: '',
                              });
                            }}
                          />
                          {this.state.dataInicioBimestre1State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataInicioBimestre1Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataFimBimestre1State}`}>
                          <label>Fim 1º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataFimBimestre1}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataFimBimestre1: dataStr,
                                diasLetivosBimestre1: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre1, dataStr,
                                ),
                                dataFimBimestre1State: '',
                              });
                            }}
                          />
                          {this.state.dataFimBimestre1State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataFimBimestre1Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label>&nbsp;</label>
                          <Input
                            value={diasLetivosBimestre1Str}
                            className="transparent-input"
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataInicioBimestre2State}`}>
                          <label>Início 2º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataInicioBimestre2}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataInicioBimestre2: dataStr,
                                diasLetivosBimestre2: this.calcularDiasLetivos(
                                  dataStr, this.state.dataFimBimestre2,
                                ),
                                dataInicioBimestre2State: '',
                              });
                            }}
                          />
                          {this.state.dataInicioBimestre2State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataInicioBimestre2Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataFimBimestre2State}`}>
                          <label>Fim 2º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataFimBimestre2}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataFimBimestre2: dataStr,
                                diasLetivosBimestre2: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre2, dataStr,
                                ),
                                dataFimBimestre2State: '',
                              });
                            }}
                          />
                          {this.state.dataFimBimestre2State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataFimBimestre2Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label>&nbsp;</label>
                          <Input
                            value={diasLetivosBimestre2Str}
                            className="transparent-input"
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataInicioBimestre3State}`}>
                          <label>Início 3º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataInicioBimestre3}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataInicioBimestre3: dataStr,
                                diasLetivosBimestre3: this.calcularDiasLetivos(
                                  dataStr, this.state.dataFimBimestre3,
                                ),
                                dataInicioBimestre3State: '',
                              });
                            }}
                          />
                          {this.state.dataInicioBimestre3State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataInicioBimestre3Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataFimBimestre3State}`}>
                          <label>Fim 3º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataFimBimestre3}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataFimBimestre3: dataStr,
                                diasLetivosBimestre3: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre3, dataStr,
                                ),
                                dataFimBimestre3State: '',
                              });
                            }}
                          />
                          {this.state.dataFimBimestre3State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataFimBimestre3Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label>&nbsp;</label>
                          <Input
                            value={diasLetivosBimestre3Str}
                            className="transparent-input"
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataInicioBimestre4State}`}>
                          <label>Início 4º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataInicioBimestre4}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataInicioBimestre4: dataStr,
                                diasLetivosBimestre4: this.calcularDiasLetivos(
                                  dataStr, this.state.dataFimBimestre4,
                                ),
                                dataInicioBimestre4State: '',
                              });
                            }}
                          />
                          {this.state.dataInicioBimestre4State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataInicioBimestre4Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={`has-label ${this.state.dataFimBimestre4State}`}>
                          <label>Fim 4º bimestre *</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataFimBimestre4}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataFimBimestre4: dataStr,
                                diasLetivosBimestre4: this.calcularDiasLetivos(
                                  this.state.dataInicioBimestre4, dataStr,
                                ),
                                dataFimBimestre4State: '',
                              });
                            }}
                          />
                          {this.state.dataFimBimestre4State === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataFimBimestre4Erro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label>&nbsp;</label>
                          <Input
                            value={diasLetivosBimestre4Str}
                            className="transparent-input"
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className={`has-label  ${this.state.dataLimiteState}`}>
                          <label>Data limite para consolidação de turmas e diários</label>
                          <DatePicker
                            viewDate={this.state.viewDate}
                            value={this.state.dataLimite}
                            clearButtonCallback={() => this.setState({ dataLimite: undefined })}
                            onChange={(data) => {
                              const dataStr = moment(data).format('DD/MM/YYYY');
                              this.setState({
                                viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                dataLimite: dataStr,
                                dataLimiteState: '',
                              });
                            }}
                          />
                          {this.state.dataLimiteState === 'has-danger' ? (
                            <label className="error">
                              {this.state.dataLimiteErro}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      this.state.dadosEnte.matriculasOnline && <Row>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.dataInicioMatriculasOnlineState}`}>
                            <label>Início das matrículas online</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataInicioMatriculasOnline}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataInicioMatriculasOnline: dataStr,
                                  dataInicioMatriculasOnlineState: '',
                                });
                              }}
                            />
                            {this.state.dataInicioMatriculasOnlineState === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataInicioMatriculasOnlineErro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.dataFimMatriculasOnlineState}`}>
                            <label>Fim das matrículas online</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataFimMatriculasOnline}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataFimMatriculasOnline: dataStr,
                                  dataFimMatriculasOnlineState: '',
                                });
                              }}
                            />
                            {this.state.dataFimMatriculasOnlineState === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataFimMatriculasOnlineErro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {/*
                    <Row>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={this.state.sabadoLetivo}
                              onChange={() => {
                                this.setState({
                                  sabadoLetivo: !this.state.sabadoLetivo,
                                });
                                this.recalcularDiasLetivos(this.state.feriados,
                                  this.state.diasCancelados, this.state.diasExtras,
                                  !this.state.sabadoLetivo);
                              }} />{' '}
                                Sábado letivo
                              <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                     */}
                    <div className="category form-category">
                      * Campos obrigatórios
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col md="8">
                        <Table hover>
                          <thead className="text-primary">
                            <tr>
                              <th style={{ width: '15%' }}>Data</th>
                              <th style={{ width: '45%' }}>Nome</th>
                              <th style={{ width: '30%' }}>Tipo</th>
                              <th className="text-center" style={{ width: '10%' }}>Ação</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.feriados.map((feriado, index) => (
                                <tr key={index}>
                                  <td>{feriado.data}</td>
                                  <td>{feriado.nome}</td>
                                  <td>{this.tipos_feriados[feriado.tipo].label}</td>
                                  <td className="text-center">
                                    <Button
                                      id={`btnRemoverItem${index}`}
                                      className="btn-icon"
                                      color="danger"
                                      size="sm"
                                      onClick={() => this.removerFeriado(feriado)}
                                      type="button">
                                      <i className="fa fa-times" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${index}`} delay={0}>
                                      Remover
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td style={{ verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.dataFeriadoState}`}>
                                  <DatePicker
                                    value={this.state.dataFeriado}
                                    viewDate={this.state.viewDate}
                                    onChange={(data) => {
                                      this.setState({
                                        dataFeriadoState: '',
                                        dataFeriado: moment(data).format('DD/MM/YYYY'),
                                      });
                                    }}
                                  />
                                  {this.state.dataFeriadoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe uma data válida
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.nomeFeriadoState}`}>
                                  <Input
                                    value={this.state.nomeFeriado}
                                    type="text"
                                    maxLength="100"
                                    onChange={(evt) => this.setState({
                                      nomeFeriado: evt.target.value,
                                      nomeFeriadoState: 'has-success',
                                    })}
                                  />
                                  {this.state.nomeFeriadoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe o nome do feriado.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                <FormGroup className={'has-label has-danger'}>
                                  <Select
                                    noOptionsMessage={() => 'Nenhuma entrada'}
                                    className={`react-select primary ${this.state.tipoFeriadoState}`}
                                    classNamePrefix="react-select"
                                    value={this.state.tipoFeriadoSelecionado}
                                    onChange={(event) => {
                                      this.setState({ tipoFeriadoState: 'primary', tipoFeriadoSelecionado: event });
                                    }}
                                    options={this.tipos_feriados}
                                    placeholder="Tipo..."
                                  />
                                  {this.state.tipoFeriadoState === 'danger' ? (
                                    <label className="error">
                                      Informe o tipo do feriado.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                <Button color="primary" onClick={this.adicionarFeriado}>
                                  Adicionar
                                </Button>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col md="8">
                        <Table hover>
                          <thead className="text-primary">
                            <tr>
                              <th style={{ width: '15%' }}>Data</th>
                              <th style={{ width: '75%' }}>Motivo</th>
                              <th className="text-center" style={{ width: '10%' }}>Ação</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.diasExtras.map((dia, index) => (
                                <tr key={index}>
                                  <td>{dia.data}</td>
                                  <td>{dia.motivo}</td>
                                  <td className="text-center">
                                    <Button
                                      id={`btnRemoverDia${index}`}
                                      className="btn-icon"
                                      color="danger"
                                      size="sm"
                                      onClick={() => this.removerDiaExtra(dia)}
                                      type="button">
                                      <i className="fa fa-times" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target={`btnRemoverDia${index}`} delay={0}>
                                      Remover
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <Table>
                          <tfoot>
                            <tr>
                              <td style={{ width: '15%', verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.dataDiaExtraState}`}>
                                  <label>Data *</label>
                                  <DatePicker
                                    value={this.state.dataDiaExtra}
                                    viewDate={this.state.viewDate}
                                    onChange={(data) => {
                                      this.setState({
                                        viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                        dataDiaExtraState: '',
                                        dataDiaExtra: moment(data).format('DD/MM/YYYY'),
                                      });
                                    }}
                                  />
                                  {this.state.dataDiaExtraState === 'has-danger' ? (
                                    <label className="error">
                                      Informe uma data válida
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.motivoDiaExtraState}`}>
                                  <label>Motivo *</label>
                                  <Input
                                    value={this.state.motivoDiaExtra}
                                    type="text"
                                    maxLength="100"
                                    onChange={(evt) => this.setState({
                                      motivoDiaExtra: evt.target.value,
                                      motivoDiaExtraState: 'has-success',
                                    })}
                                  />
                                  {this.state.motivoDiaExtraState === 'has-danger' ? (
                                    <label className="error">
                                      Informe o motivo do dia letivo extra
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ width: '10%', verticalAlign: 'top' }}>
                                <FormGroup className={'has-label'}>
                                  <label>&nbsp;</label>
                                  <Button color="primary" onClick={this.adicionarDiaExtra}>
                                    Adicionar
                                  </Button>
                                </FormGroup>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col md="8">
                        <Table hover>
                          <thead className="text-primary">
                            <tr>
                              <th style={{ width: '15%' }}>Data</th>
                              <th style={{ width: '75%' }}>Motivo</th>
                              <th className="text-center" style={{ width: '10%' }}>Ação</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.diasCancelados.map((dia, index) => (
                                <tr key={index}>
                                  <td>{dia.data}</td>
                                  <td>{dia.motivo}</td>
                                  <td className="text-center">
                                    <Button
                                      id={`btnRemoverDia${index}`}
                                      className="btn-icon"
                                      color="danger"
                                      size="sm"
                                      onClick={() => this.removerDiaCancelado(dia)}
                                      type="button">
                                      <i className="fa fa-times" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target={`btnRemoverDia${index}`} delay={0}>
                                      Remover
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <Table>
                          <tfoot>
                            <tr>
                              <td style={{ width: '15%', verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.dataInicioDiaCanceladoState}`}>
                                  <label>Início *</label>
                                  <DatePicker
                                    value={this.state.dataInicioDiaCancelado}
                                    viewDate={this.state.viewDate}
                                    onChange={(data) => {
                                      this.setState({
                                        viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                        dataInicioDiaCanceladoState: '',
                                        dataInicioDiaCancelado: moment(data).format('DD/MM/YYYY'),
                                      });
                                    }}
                                  />
                                  {this.state.dataInicioDiaCanceladoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe uma data válida
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ width: '15%', verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.dataFimDiaCanceladoState}`}>
                                  <label>Fim *</label>
                                  <DatePicker
                                    value={this.state.dataFimDiaCancelado}
                                    viewDate={this.state.viewDate}
                                    onChange={(data) => {
                                      this.setState({
                                        viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                        dataFimDiaCanceladoState: '',
                                        dataFimDiaCancelado: moment(data).format('DD/MM/YYYY'),
                                      });
                                    }}
                                  />
                                  {this.state.dataFimDiaCanceladoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe uma data válida
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                <FormGroup className={`has-label ${this.state.motivoDiaCanceladoState}`}>
                                  <label>Motivo *</label>
                                  <Input
                                    value={this.state.motivoDiaCancelado}
                                    type="text"
                                    maxLength="100"
                                    onChange={(evt) => this.setState({
                                      motivoDiaCancelado: evt.target.value,
                                      motivoDiaCanceladoState: 'has-success',
                                    })}
                                  />
                                  {this.state.motivoDiaCanceladoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe o motivo do cancelamento
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td style={{ width: '10%', verticalAlign: 'top' }}>
                                <FormGroup className={'has-label'}>
                                  <label>&nbsp;</label>
                                  <Button color="primary" onClick={this.adicionarDiaCancelado}>
                                    Adicionar
                                  </Button>
                                </FormGroup>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Col>
                    </Row>
                  </TabPanel>
                  {
                    this.state.dadosEnte.limitesBimestrais && <TabPanel>
                      <Row>
                        <Col md="2">
                          <FormGroup className={`has-label  ${this.state.dataLimiteBimestre1State}`}>
                            <label>Data limite para inserção de informações do 1º bimestre</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataLimiteBimestre1}
                              clearButtonCallback={() => this.setState({ dataLimiteBimestre1: undefined })}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataLimiteBimestre1: dataStr,
                                  dataLimiteBimestre1State: '',
                                });
                              }}
                            />
                            {this.state.dataLimiteBimestre1State === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataLimiteBimestre1Erro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup className={`has-label  ${this.state.dataLimiteBimestre2State}`}>
                            <label>Data limite para inserção de informações do 2º bimestre</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataLimiteBimestre2}
                              clearButtonCallback={() => this.setState({ dataLimiteBimestre2: undefined })}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataLimiteBimestre2: dataStr,
                                  dataLimiteBimestre2State: '',
                                });
                              }}
                            />
                            {this.state.dataLimiteBimestre2State === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataLimiteBimestre2Erro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup className={`has-label  ${this.state.dataLimiteBimestre3State}`}>
                            <label>Data limite para inserção de informações do 3º bimestre</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataLimiteBimestre3}
                              clearButtonCallback={() => this.setState({ dataLimiteBimestre3: undefined })}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataLimiteBimestre3: dataStr,
                                  dataLimiteBimestre3State: '',
                                });
                              }}
                            />
                            {this.state.dataLimiteBimestre3State === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataLimiteBimestre3Erro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup className={`has-label  ${this.state.dataLimiteBimestre4State}`}>
                            <label>Data limite para inserção de informações do 4º bimestre</label>
                            <DatePicker
                              viewDate={this.state.viewDate}
                              value={this.state.dataLimiteBimestre4}
                              clearButtonCallback={() => this.setState({ dataLimiteBimestre4: undefined })}
                              onChange={(data) => {
                                const dataStr = moment(data).format('DD/MM/YYYY');
                                this.setState({
                                  viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                                  dataLimiteBimestre4: dataStr,
                                  dataLimiteBimestre4State: '',
                                });
                              }}
                            />
                            {this.state.dataLimiteBimestre4State === 'has-danger' ? (
                              <label className="error">
                                {this.state.dataLimiteBimestre4Erro}
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPanel>
                  }
                </Tabs>
              </CardBody>
              <CardFooter className="text-right">
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push('/gestor/anosletivos')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.anoLetivoSelecionado
                        ? <Button color="primary" disabled={this.state.buttonDisabled}>
                          Cadastrar
                        </Button>
                        : <Button style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
        <CalendarModal
          anoLetivo={this.state.anoLetivo}
          isOpen={this.state.showCalendarModal}
          toggle={() => this.setState({ showCalendarModal: !this.state.showCalendarModal })} />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </Row>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações do ano letivo
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(CadastroAnoLetivo);
