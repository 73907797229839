import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import UsuarioService from 'services/UsuarioService';

class PerfilMenu extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();

    this.state = this.getCollapseStates(props.routesProfile);
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((route) => {
      initialState = {
        openAvatar: this.getCollapseInitialState(route),
        ...initialState,
      };
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState = (route) => {
    if (window.location.pathname.indexOf(route.path) !== -1) {
      return true;
    }
    return false;
  }

  render() {
    return <div className="user">
      <div className="photo">
        {this.props.dadosUsuario.foto && <img src={`data:image/jpeg;base64,${this.props.dadosUsuario.foto}`} alt="Avatar" />}
      </div>
      <div className="info">
        <a
          href="#expand"
          data-toggle="collapse"
          aria-expanded={this.state.openAvatar}
          onClick={() => this.setState({ openAvatar: !this.state.openAvatar })
          }
        >
          <span className="active">
            {this.props.dadosUsuario.nome ? this.props.dadosUsuario.nome.split(' ')[0] : null}
            <b className="caret" />
          </span>
        </a>
        <Collapse isOpen={this.state.openAvatar}>
          <ul className="nav">
            {
              this.props.routesProfile.map((menu, index) => (
                <li className={this.getCollapseInitialState(menu) ? 'active' : ''} key={index}>
                  <NavLink to={menu.layout + menu.path}>
                    <span className="sidebar-mini-icon">{menu.mini}</span>
                    <span className="sidebar-normal">{menu.name}</span>
                  </NavLink>
                </li>
              ))
            }
          </ul>
        </Collapse>
      </div>
    </div>;
  }
}

export default withRouter(PerfilMenu);
