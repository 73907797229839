import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import AdaptiveTable from 'views/components/AdaptiveTable';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';
import EstoquesService from 'services/EstoquesService';

import DestinoEstoqueSaida from 'assets/csv/destinos-estoque-saida.json';
import OrigemEstoqueEntrada from 'assets/csv/origens-estoque-entrada.json';
import UnidadeMedida from 'assets/csv/unidades-medida.json';

class Entradas extends React.Component {
  constructor(props) {
    super(props);

    this.origensEstoqueEntrada = [
      OrigemEstoqueEntrada.ESTOQUE_CENTRAL,
      OrigemEstoqueEntrada.RECURSOS_PROPRIOS,
    ];

    this.idPagina = `${props.idVinculo}-estoqueitem-${props.idEstoqueItem}-entradas`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '40%'], ['Quantidade', '15%'], ['Origem', '20%'], ['Data', '15%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'quantidade', 'origem_str', 'data'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoqueEntradas = this.carregarEstoqueEntradas.bind(this);
  }

  async componentDidMount() {
    this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoqueEntradas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoqueItemEntradas(this.props.idEstoqueItem, this.props.idEscola, pagina, linhasPorPagina, like);

      data.estoqueEntradas.forEach((estoqueEntrada) => Object.assign(estoqueEntrada, {
        origem_str: this.origensEstoqueEntrada[estoqueEntrada.origem].label,
        nome: estoqueEntrada.estoqueItem.nome,
        disabledRemove: estoqueEntrada.origem === OrigemEstoqueEntrada.ESTOQUE_CENTRAL.value,
        disabledEdit: estoqueEntrada.origem === OrigemEstoqueEntrada.ESTOQUE_CENTRAL.value,
      }));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoqueEntradas, total: data.total }),
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar itens do estoque',
      });

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarEstoqueEntradas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
      </>
    )
  }
}

class Saidas extends React.Component {
  constructor(props) {
    super(props);

    this.destinosEstoqueSaida = [
      DestinoEstoqueSaida.TRANSFERENCIA_ESCOLA,
      DestinoEstoqueSaida.CONSUMO_PROPRIO,
    ];

    this.idPagina = `${props.idVinculo}-estoqueitem-${props.idEstoqueItem}-saidas`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '40%'], ['Quantidade', '15%'], ['Destino', '20%'], ['Data', '15%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'quantidade', 'destino_str', 'data'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoqueSaidas = this.carregarEstoqueSaidas.bind(this);
  }

  async componentDidMount() {
    this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoqueSaidas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoqueItemSaidas(this.props.idEstoqueItem, this.props.idEscola, pagina, linhasPorPagina, like);

      data.estoqueSaidas.forEach((estoqueSaida) => Object.assign(estoqueSaida, {
        destino_str: this.destinosEstoqueSaida[estoqueSaida.destino].label,
        nome: estoqueSaida.estoqueItem.nome,
      }));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoqueSaidas, total: data.total }),
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar saída do estoque',
      });

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarEstoqueSaidas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
      </>
    )
  }
}

class EstoqueItem extends React.Component {
  constructor(props) {
    super(props);

    this.unidadesMedida = [
      UnidadeMedida.QUILOGRAMA,
      UnidadeMedida.LITRO,
      UnidadeMedida.UNIDADE,
      UnidadeMedida.METRO,
      UnidadeMedida.METRO_QUADRADO,
      UnidadeMedida.METRO_CUBICO,
      UnidadeMedida.CAIXA,
      UnidadeMedida.PACOTE,
      UnidadeMedida.GRAMA,
      UnidadeMedida.MILILITRO,
      UnidadeMedida.PAR,
      UnidadeMedida.FARDO
    ];

    this.estoquesService = new EstoquesService();
    this.escolasService = new EscolasService();

    this.state = {
      loading: true,

      showAlert: false,
      showLoaderModal: false,

      nome: '',
      nomeState: '',
      loaderModalText: '',

      estoqueItemSelecionado: null,
      unidadeMedidaSelecionada: '',

      erro: null,
    };

  }

  async componentDidMount() {
    try {
      const estoque = await this.estoquesService
        .carregarEstoque(this.props.match.params.idEstoque);

      if (this.props.match.params.idEscola !== undefined) {
        const escola = await this.escolasService.carregarEscola(this.props.match.params.idEscola);
        this.setState({ escola });
      }

      const estoqueItem = await this.estoquesService
        .carregarEstoqueItem(this.props.match.params.idEstoqueItem);

      this.setState({ estoqueItemSelecionado: estoqueItem }, () => this.contentLoad());

      this.setState({
        estoque,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.estoqueItemSelecionado.nome,
      unidadeMedidaSelecionada: this.unidadesMedida[this.state.estoqueItemSelecionado.unidadeMedida],
    });
  }

  voltar() {
    if (this.props.match.params.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`);
    }
  }

  conteudoPagina() {
    return this.state.loading ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        {
          this.props.match.params.idEscola === undefined ? <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques`}>Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueItemSelecionado ? this.state.estoqueItemSelecionado.nome : 'Cadastro de Item'}</BreadcrumbItem>
          </Breadcrumb> : <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueItemSelecionado ? this.state.estoqueItemSelecionado.nome : 'Cadastro de Item'}</BreadcrumbItem>
          </Breadcrumb>
        }
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Dados do item</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup className={`has-label ${this.state.nomeState}`}>
                  <label>Nome *</label>
                  <Input
                    disabled
                    value={this.state.nome}
                    name="nomeEstoque"
                    type="text"
                    maxLength="100"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className={'has-label has-danger'}>
                  <label>Unidade de medida *</label>
                  <Select
                    isDisabled
                    noOptionsMessage={() => 'Nenhuma entrada'}
                    className={`react-select primary ${this.state.unidadeMedidaState}`}
                    classNamePrefix="react-select"
                    value={this.state.unidadeMedidaSelecionada}
                    options={this.unidadesMedida}
                    placeholder="Unidade de medida..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Tabs>
                  <TabList>
                    <Tab>Entradas</Tab>
                    <Tab>Saídas</Tab>
                  </TabList>
                  <TabPanel>
                    <Entradas
                      idEstoqueItem={this.props.match.params.idEstoqueItem}
                      idEscola={this.props.match.params.idEscola}
                      history={this.props.history}
                      layout={this.props.layout}
                      callbackError={() => this.setState({ erro: true })}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Saidas
                      idEstoqueItem={this.props.match.params.idEstoqueItem}
                      idEscola={this.props.match.params.idEscola}
                      history={this.props.history}
                      layout={this.props.layout}
                      callbackError={() => this.setState({ erro: true })}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6" className="text-left">
                <Button color="primary" onClick={() => this.voltar()} >
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(EstoqueItem);
