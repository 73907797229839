/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';

import * as math from 'mathjs';

import {
  Table,
  Row,
  Col,
} from 'reactstrap';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class AtaFinalResultado extends React.Component {
  constructor(props) {
    super(props);

    let avaliacoes = [];
    this.props.avaliacoes.forEach((avaliacoesDoAluno, idx) => {
      avaliacoes[idx] = [];
      this.props.descricoesAvaliacoes.forEach((desc) => {
        avaliacoes[idx].push(avaliacoesDoAluno.filter((avaliacao) => avaliacao
          .descricaoAvaliacao.id === desc.id));
      });
    });

    avaliacoes = avaliacoes.map((avaliacoesDoAluno) => math.transpose(avaliacoesDoAluno));

    this.state = {
      avaliacoes,
    };
  }

  totalFaltas = (faltas) => faltas.faltasBimestre1
    + faltas.faltasBimestre2
    + faltas.faltasBimestre3
    + faltas.faltasBimestre4;

  notas = (avaliacoesDoAluno) => {
    const ret = avaliacoesDoAluno.map((avaliacoesDaMateria) => {
      const mediaAnual = this.mediaAnual(avaliacoesDaMateria);
      return [
        Number.isInteger(mediaAnual) ? `${mediaAnual},0` : mediaAnual.toString().replace('.', ','),
        this.conteudoRecuperacao(avaliacoesDaMateria),
        this.mediaFinal(avaliacoesDaMateria),
      ];
    });
    return ret;
  }

  mediaAnual = (avaliacoes) => {
    if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
      if (avaliacoes.every((avaliacao) => avaliacao.resultado === '#')) {
        return '#';
      }
      return '';
    }

    let soma = 0;
    let total = 0;

    for (let i = 0; i < avaliacoes.length; i += 1) {
      if (!avaliacoes[i].descricaoAvaliacao.recuperacao) {
        soma += parseFloat(avaliacoes[i].resultado.replace(',', '.'));
        total += 1;
      }
    }
    return (soma / total).toFixed(1);
  }

  mediaFinal = (avaliacoes) => {
    if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
      if (avaliacoes.every((avaliacao) => avaliacao.resultado === '#')) {
        return '#';
      }
      return '';
    }

    const mediaAnual = this.mediaAnual(avaliacoes);

    if (!this.props.turma.temRecuperacao) {
      return Number.isInteger(mediaAnual) ? `${mediaAnual},0` : mediaAnual;
    }

    if (mediaAnual >= this.props.turma.mediaReprovacaoAntesRecuperacao
      && mediaAnual < this.props.turma.mediaAprovacao) {
      const avaliacaoRecuperacao = avaliacoes
        .find((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao);

      if (avaliacaoRecuperacao.resultado === '') {
        return '';
      }

      const notaRecuperacao = parseFloat(avaliacaoRecuperacao.resultado.replace(',', '.'));

      const resultado = (this.props.turma.pesoMediaAvaliacoes * mediaAnual
        + this.props.turma.pesoRecuperacao * notaRecuperacao)
        / (this.props.turma.pesoMediaAvaliacoes + this.props.turma.pesoRecuperacao);

      return Number.isInteger(resultado) ? `${resultado},0` : resultado.toFixed(1).toString().replace('.', ',');
    }

    return Number.isInteger(mediaAnual) ? `${mediaAnual},0` : mediaAnual.toString().replace('.', ',');
  }

  alunoEmRecuperacao = (avaliacoes) => {
    if (!this.avaliacoesBimestresFeitas(avaliacoes)) {
      return false;
    }

    const mediaAnual = this.mediaAnual(avaliacoes);

    if (mediaAnual >= this.props.turma.mediaReprovacaoAntesRecuperacao
      && mediaAnual < this.props.turma.mediaAprovacao) {
      return true;
    }

    return false;
  }

  avaliacoesBimestresFeitas = (avaliacoes) => avaliacoes
    .every((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao
      || (avaliacao.resultado !== '' && avaliacao.resultado !== '#'))

  avaliacaoRecuperacaoFeita = (avaliacoes) => {
    if (!this.props.turma.temRecuperacao) return true;
    const avaliacaoRecuperacao = avaliacoes
      .find((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao);
    return avaliacaoRecuperacao.resultado !== '';
  }

  conteudoRecuperacao(avaliacoes) {
    if (!this.props.turma.temRecuperacao) return '#';

    if (!avaliacoes.every((avaliacao) => avaliacao.resultado === '#')
      && !this.avaliacoesBimestresFeitas(avaliacoes)) return '';

    const avaliacaoRecuperacao = avaliacoes
      .find((avaliacao) => avaliacao.descricaoAvaliacao.recuperacao);

    if (avaliacaoRecuperacao.resultado === '#') return '#';

    return this.alunoEmRecuperacao(avaliacoes) ? avaliacaoRecuperacao.resultado : '#';
  }

  resultadoFinal(avaliacoesDoAluno, totalDeFaltas, faltasAbonadas, matricula) {
    if (matricula.situacao !== SituacaoMatricula.MATRICULADO.value) {
      switch (matricula.situacao) {
        case SituacaoMatricula.TRANSFERIDO.value:
          return 'TR';
        case SituacaoMatricula.DESISTENTE.value:
          return 'EV';
        case SituacaoMatricula.FALECIDO.value:
          return 'FA';
        default:
          break;
      }
    }

    if (avaliacoesDoAluno.some(
      (avaliacoesMateria) => !avaliacoesMateria.every((avaliacao) => avaliacao.resultado === '#')
        && (!this.avaliacoesBimestresFeitas(avaliacoesMateria)
          || (this.alunoEmRecuperacao(avaliacoesMateria)
            && !this.avaliacaoRecuperacaoFeita(avaliacoesMateria))),
    )) return 'MAT';

    const reprovadoFalta = (1 - (totalDeFaltas - faltasAbonadas)
      / this.props.totalAulas) < this.props.turma.frequenciaMinima / 100;

    this.qtdReprovacoes = 0;
    avaliacoesDoAluno.forEach((avaliacoesMateria) => {
      const MA = this.mediaAnual(avaliacoesMateria);
      if (!this.props.turma.temRecuperacao) {
        if (MA < this.props.turma.mediaAprovacao) {
          this.qtdReprovacoes += 1;
        }
      } else if (MA < this.props.turma.mediaReprovacaoAntesRecuperacao) {
        this.qtdReprovacoes += 1;
      } else if (MA < this.props.turma.mediaAprovacao) {
        const NR = parseFloat(this.conteudoRecuperacao(avaliacoesMateria).replace(',', '.'));
        const MF = (this.props.turma.pesoMediaAvaliacoes * MA
          + this.props.turma.pesoRecuperacao * NR)
          / (this.props.turma.pesoMediaAvaliacoes + this.props.turma.pesoRecuperacao);
        if (MF < this.props.turma.mediaAprovacaoAposRecuperacao) {
          this.qtdReprovacoes += 1;
        }
      }
    });
    const reprovadoNota = this.qtdReprovacoes > 0;
    if (!reprovadoFalta && !reprovadoNota) {
      return 'AP';
    }
    if (reprovadoFalta && !reprovadoNota) {
      return 'RF';
    }
    if (reprovadoFalta) {
      return 'RNF';
    }
    return 'RN';
  }

  render() {
    return (
      <>
        <Table>
          <thead className="text-primary">
            <tr>
              <th rowSpan="2" style={{ width: '14%' }}>Aluno</th>
              {
                this.props.materias
                  .map((materia, idx) => <th colSpan="3" style={{ width: '3%' }} className="text-center" key={idx}>{materia.nome}</th>)
              }
              <th rowSpan="2" style={{ width: '1%' }} className="text-center">%F</th>
              <th rowSpan="2" style={{ width: '1%' }} className="text-center">F</th>
              <th rowSpan="2" style={{ width: '1%' }} className="text-center">FA</th>
              <th rowSpan="2" style={{ width: '1%' }} className="text-center">RF</th>
            </tr>
            <tr>
              {
                this.props.materias.map((_, idx) => <React.Fragment key={idx}>
                  <th className="text-center">MA</th>
                  <th className="text-center">NR</th>
                  <th className="text-center">MF</th>
                </React.Fragment>)
              }
            </tr>
          </thead>
          <tbody>
            {
              this.state.avaliacoes.map((avaliacoesDoAluno, idx) => <tr key={idx}>
                <td className="text-left">{this.props.matriculas[idx].usuario.nome}</td>
                {
                  this.notas(avaliacoesDoAluno).map((notas, idx2) => <React.Fragment key={idx2}>
                    <td className="text-center">
                      {notas[0]}
                    </td>
                    <td className="text-center">
                      {notas[1]}
                    </td>
                    <td className="text-center">
                      {notas[2]}
                    </td>
                  </React.Fragment>)
                }
                <td className="text-center">
                  {
                    (this.props.matriculas[idx].situacao === SituacaoMatricula.DESISTENTE.value
                    || this.props.matriculas[idx].situacao === SituacaoMatricula.TRANSFERIDO.value
                    || this.props.matriculas[idx].situacao === SituacaoMatricula.FALECIDO.value) ? '-'
                      : this.props.totalAulas
                        ? ((1 - ((this.totalFaltas(this.props.faltas[idx])
                        - this.props.abonos[idx].quantidade) / this.props.totalAulas)) * 100).toFixed(1).replace('.', ',')
                        : ''
                  }
                </td>
                <td className="text-center">{this.totalFaltas(this.props.faltas[idx])}</td>
                <td className="text-center">{this.props.abonos[idx].quantidade}</td>
                <td className="text-center">{this.resultadoFinal(avaliacoesDoAluno, this.totalFaltas(this.props.faltas[idx]),
                  this.props.abonos[idx].quantidade, this.props.matriculas[idx])}</td>
              </tr>)
            }
          </tbody>
        </Table>
        <Row>
          <Col>
            <label><strong>LEGENDA</strong></label><br />
            <label><strong>MA:</strong> Média anual</label>&nbsp;-&nbsp;
            <label><strong>NR:</strong> Nota de recuperação</label>&nbsp;-&nbsp;
            <label><strong>MF:</strong> Média final</label>&nbsp;-&nbsp;
            <label><strong>%F:</strong> Percentual frequência</label>&nbsp;-&nbsp;
            <label><strong>F:</strong> Faltas</label>&nbsp;-&nbsp;
            <label><strong>FA:</strong> Faltas abonadas</label>&nbsp;-&nbsp;
            <label><strong>RF:</strong> Resultado final</label><br />
            <label><strong>MAT:</strong> Matriculado</label>&nbsp;-&nbsp;
            <label><strong>AP:</strong> Aprovado</label>&nbsp;-&nbsp;
            <label><strong>APP:</strong> Aprovado com progressão parcial</label>&nbsp;-&nbsp;
            <label><strong>RN:</strong> Reprovado por nota</label>&nbsp;-&nbsp;
            <label><strong>RF:</strong> Reprovado por frequência</label>&nbsp;-&nbsp;
            <label><strong>RNF:</strong> Reprovado por nota e frequência</label>&nbsp;-&nbsp;
            <label><strong>TR:</strong> Transferido</label>&nbsp;-&nbsp;
            <label><strong>EV:</strong> Evadido</label>
          </Col>
        </Row>
      </>
    );
  }
}

export default AtaFinalResultado;
