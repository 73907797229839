import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import moment from 'moment';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Row,
	Table,
	UncontrolledDropdown,
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Select from 'react-select';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import AnosLetivosService from 'services/AnosLetivosService';
import AtelieService from 'services/AtelieService';
import DiariosService from 'services/DiariosService';
import EscolasService from 'services/EscolasService';
import LoginService from 'services/LoginService';
import MateriasService from 'services/MateriasService';
import TurmasService from 'services/TurmasService';

import Papel from 'assets/csv/papeis.json';
import SituacaoItemAtelieMatricula from 'assets/csv/situacoes-itens-atelies-matricula';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Turno from 'assets/csv/turnos.json';

class AtelieAluno extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.situacoes = [
			SituacaoItemAtelieMatricula.SATISFATORIO,
			SituacaoItemAtelieMatricula.NAO_SATISFATORIO,
			SituacaoItemAtelieMatricula.NAO_INFORMADA,
		];

		this.salvarAvaliacaoAtelie = this.salvarAvaliacaoAtelie.bind(this);
		this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);

		this.state = {
			showAlert: false,
			showLoaderModal: false,
			error: null,
		};

		this.loginService = new LoginService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.materiasService = new MateriasService();
		this.atelieService = new AtelieService();
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const turma = await this.turmasService
				.carregarTurma(this.props.match.params.idTurma);

			const diario = await this.diariosService
				.carregarDiario(this.props.match.params.idDiario);

			const materias = (await this.diariosService
				.carregarMateriasDoProfessor(this.props.match.params.idDiario))
				.filter((materia) => materia.atelie);

			const matriculas = await this.diariosService
				.carregarMatriculas(this.props.match.params.idDiario);

			const matricula = matriculas.find((m) => m.id === this.props.match.params.idMatricula)

			const selectMatriculas = [];
			matriculas.forEach((matricula) => {
				selectMatriculas.push({
					label: matricula.usuario.nome,
					value: matricula.id,
				});
			});

			const matriculaSelecionada = {
				label: matricula.usuario.nome,
				value: matricula.id,
			};

			const selectMaterias = [];
			materias.forEach((materia) => {
				selectMaterias.push({
					label: materia.nome,
					value: materia.id,
				});
			});

			const materiaSelecionada = {
				label: materias[0].nome,
				value: materias[0].id,
			};

			await this.carregarAvaliacaoAtelie(
				matriculaSelecionada.value, materiaSelecionada.value,
			);

			this.setState({
				anoLetivo,
				escola,
				turma,
				diario,
				selectMatriculas,
				matriculaSelecionada,
				selectMaterias,
				materiaSelecionada,
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarAvaliacaoAtelie(idMatricula, idMateria) {
		this.setState({ carregandoItensAtelies: true });

		const itensAtelies = await this.atelieService
			.carregarItensAteliesPorMateria(idMateria);

		const situacoesBimestres = [];
		for (let bimestre = 1; bimestre <= 4; ++bimestre) {
			const fn = (itemAtelie) => new Promise((resolve) => resolve(
				this.atelieService
					.carregarSituacaoItemAtelieNaMatriculaMateriaDiarioEBimestre(
						itemAtelie.id, idMatricula, idMateria, this.props.match.params.idDiario, bimestre),
			));
			const actions = itensAtelies.map(fn);
			const situacoesItensAtelies = await Promise.all(actions);
			situacoesBimestres.push(situacoesItensAtelies);
		}

		const observacoesAtelie = await this.atelieService
			.getObservacoesAtelieNaMatriculaEMateria(idMatricula, idMateria);

		this.setState({
			itensAtelies,
			observacoesAtelie,
			situacoesBimestres,
			carregandoItensAtelies: false,
		});
	}

	async salvarAvaliacaoAtelie() {
		this.setState({
			showLoaderModal: true,
			showAlert: false,
		});

		try {

			const avaliacaoAtelie = {
				situacoesBimestres: this.state.situacoesBimestres,
				observacoesAtelieMatriculaMateria: this.state.observacoesAtelie,
			}

			await this.atelieService.salvarAvaliacaoAtelie(
				this.state.matriculaSelecionada.value,
				this.state.materiaSelecionada.value,
				avaliacaoAtelie,
			);

			await this.carregarAvaliacaoAtelie(this.state.matriculaSelecionada.value, this.state.materiaSelecionada.value);

			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Avaliação ateliê do aluno salvas com sucesso',
			});
		} catch (msg) {
			this.setState({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao salvar avaliação ateliê do aluno',
			});
		}
	}

	voltar() {
		if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
			return `${this.props.layout}`
				+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
				+ `/escola/${this.state.escola.id}`
				+ `/turma/${this.state.turma.id}`
				+ `/diario/${this.state.diario.id}`;
		}
		return `${this.props.layout}`
			+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
			+ `/escola/${this.state.escola.id}`
			+ `/turma/${this.state.turma.id}`
			+ `/diario/${this.state.diario.id}/alfabetizacao`;
	}

	dataLimiteExcedida(avaliacao) {
		if (!this.state.dadosEnte.limitesBimestrais) return false;

		let dataLimite = null;
		if (avaliacao.descricaoAvaliacao.nome === '1º semestre' && this.state.anoLetivo.dataLimiteBimestre2) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre2, 'DD/MM/YYYY');
		} else if (avaliacao.descricaoAvaliacao.nome === '2º semestre' && this.state.anoLetivo.dataLimiteBimestre4) {
			dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre4, 'DD/MM/YYYY');
		}

		if (!dataLimite) return false;

		dataLimite.set('hours', 23);
		dataLimite.set('minutes', 59);
		dataLimite.set('seconds', 59);

		const today = moment();

		return today.isAfter(dataLimite)
	}

	marcarTodosAteliesSatisfatorio() {
		this.state.itensAtelies.forEach((_, idxh) => {
			[0, 1, 2, 3].forEach((bimestre) => {
				Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
					situacao: SituacaoItemAtelieMatricula.SATISFATORIO.value,
				});
			});
		});
		this.forceUpdate();
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem active>Ateliê de {this.state.materiaSelecionada.label}</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">
								Ateliê de {this.state.materiaSelecionada.label}
								&nbsp;de {this.state.matriculaSelecionada.label} no {this.state.turma.curso.nome}
							</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="3">
									<FormGroup className={'has-label has-danger'}>
										<label>Aluno</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={'react-select primary'}
											classNamePrefix="react-select"
											value={this.state.matriculaSelecionada}
											onChange={async (event) => {
												this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
													+ `/escola/${this.props.match.params.idEscola}`
													+ `/turma/${this.props.match.params.idTurma}`
													+ `/diario/${this.props.match.params.idDiario}`
													+ `/atelie/${event.value}`);

												this.setState({ matriculaSelecionada: event })
												await this.carregarAvaliacaoAtelie(
													event.value, this.state.materiaSelecionada.value,
												);
											}}
											options={this.state.selectMatriculas}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup className={'has-label has-danger'}>
										<label>Componente curricular</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={'react-select primary'}
											classNamePrefix="react-select"
											value={this.state.materiaSelecionada}
											onChange={async (event) => {
												this.setState({ materiaSelecionada: event })
												await this.carregarAvaliacaoAtelie(
													this.state.matriculaSelecionada.value, event.value,
												);
											}}
											options={this.state.selectMaterias}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<Tabs>
										<TabList>
											<Tab>Ateliê</Tab>
											<Tab>Observações</Tab>
										</TabList>
										<TabPanel>
											{
												this.state.carregandoItensAtelies ? <div align="center" style={{ padding: 30 }}>
													<Loader
														type="Oval"
														color="#053d7c"
														height="50"
														width="50" />
												</div> :
													<>
														<Row>
															<Col className="text-right">
																<Button onClick={() => this.marcarTodosAteliesSatisfatorio()}>
																	Marcar todos como satisfatório
																</Button>
															</Col>
														</Row>
														<Table>
															<thead className="text-primary">
																<tr>
																	<th rowSpan="2" style={{ width: '40%' }}>Descrição</th>
																	<th colSpan="4" className="text-center" style={{ width: '60%' }}>Situação</th>
																</tr>
																<tr>
																	<th className="text-center">1º Bimestre</th>
																	<th className="text-center">2º Bimestre</th>
																	<th className="text-center">3º Bimestre</th>
																	<th className="text-center">4º Bimestre</th>
																</tr>
															</thead>
															<tbody>
																{
																	this.state.itensAtelies.map((itemAtelie, idxh) => <tr
																		key={uniqid()}>
																		<td><div className="preserve-format">{itemAtelie.descricao}</div></td>
																		{
																			[0, 1, 2, 3].map((bimestre) => <td key={uniqid()} className="text-center">
																				<UncontrolledDropdown>
																					<DropdownToggle
																						aria-haspopup={true}
																						color={this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].color}
																						data-toggle="collapse"
																						id="acoesItemAteliesDropdownMenuLink"
																						onClick={(evt) => evt.stopPropagation()}
																						nav>
																						<Button color={this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].color}>
																							{this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].label} <b className="caret" />
																						</Button>
																					</DropdownToggle>
																					<DropdownMenu aria-labelledby="acoesItemAteliesDropdownMenuLink" right>
																						<DropdownItem
																							onClick={() => {
																								if (this.props.role !== Papel.PROFESSOR.value) return;
																								Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																									...this.state.situacoesBimestres[bimestre][idxh],
																									situacao: SituacaoItemAtelieMatricula.NAO_INFORMADA.value
																								});
																								this.forceUpdate();
																							}}>
																							{SituacaoItemAtelieMatricula.NAO_INFORMADA.label}
																						</DropdownItem>
																						<DropdownItem
																							onClick={() => {
																								if (this.props.role !== Papel.PROFESSOR.value) return;
																								Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																									...this.state.situacoesBimestres[bimestre][idxh],
																									situacao: SituacaoItemAtelieMatricula.SATISFATORIO.value
																								});
																								this.forceUpdate();
																							}}>
																							{SituacaoItemAtelieMatricula.SATISFATORIO.label}
																						</DropdownItem>
																						<DropdownItem
																							onClick={() => {
																								if (this.props.role !== Papel.PROFESSOR.value) return;
																								Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																									...this.state.situacoesBimestres[bimestre][idxh],
																									situacao: SituacaoItemAtelieMatricula.NAO_SATISFATORIO.value
																								});
																								this.forceUpdate();
																							}}>
																							{SituacaoItemAtelieMatricula.NAO_SATISFATORIO.label}
																						</DropdownItem>
																					</DropdownMenu>
																				</UncontrolledDropdown>
																			</td>)
																		}
																	</tr>)
																}
															</tbody>
														</Table>
													</>
											}
										</TabPanel>
										<TabPanel>
											{
												this.state.carregandoItensAtelies ? <div align="center" style={{ padding: 30 }}>
													<Loader
														type="Oval"
														color="#053d7c"
														height="50"
														width="50" />
												</div> : <Input
													disabled={this.props.role !== Papel.PROFESSOR.value}
													value={this.state.observacoesAtelie.observacoes}
													onChange={(evt) => {
														Object.assign(
															this.state.observacoesAtelie, { observacoes: evt.target.value },
														);
														this.forceUpdate();
													}}
													type="textarea"
													style={{ height: '500px' }} />
											}
										</TabPanel>
									</Tabs>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<Row>
								<Col className="text-left">
									<Button
										color="primary"
										onClick={() => this.props.history
											.push(this.voltar())} >
										Voltar
									</Button>
								</Col>
								<Col className="text-right">
									{
										this.props.role === Papel.PROFESSOR.value
										&& <Button
											color="primary"
											disabled={this.state.showLoaderModal}
											onClick={this.salvarAvaliacaoAtelie} >
											Salvar ateliê do aluno
										</Button>
									}
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#053d7c"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.showLoaderModal}
					text="Salvando avaliação ateliê do aluno..." />
			</div>
		);
	}
}

export default withRouter(AtelieAluno);
