import { useNavigate, useLocation, useParams } from "react-router-dom";

import { connect } from 'react-redux';
import { editDadosUsuario } from 'redux/actions/DadosUsuarioActions';
import { editFotoUsuario } from "redux/actions/DadosUsuarioActions";

export function withRouter(Component) {

	function ComponentWithRouterProps(props) {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		const history = {
			push: (params) => {
				if (typeof(params) === 'string') {
					navigate(params);
				} else {
					navigate(params.pathname, { state: params.state })
				}
			},
			goBack: () => {
				navigate(-1);
			},
			location,
		}

		const match = {
			params,
		}

		const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });
		
		const ConnectedComponent = connect(mapStateToProps, { editDadosUsuario, editFotoUsuario })(Component);
        
		return <ConnectedComponent {...props} navigate={navigate} location={location} params={params} history={history} match={match}/>;
	}

	return ComponentWithRouterProps;
}