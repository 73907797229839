import React from 'react';
import { withRouter } from 'hoc/withRouter';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

class Tutoriais extends React.Component {
  render = () =>
    <div className="content">
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem active>Tutoriais</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Tutoriais</CardTitle>
            </CardHeader>
            <CardBody>
              <Table className="text-center">
                <thead>
                  <tr>
                    <th>Tutorial</th>
                    <th>Quem pode realizar as ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=w3rCHiqXOT4" target="_blank" rel="noopener noreferrer">Cadastro de Gestor</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=doz96Vt3Ywk" target="_blank" rel="noopener noreferrer">Cadastro de Escola</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=lKHQEIorLMg" target="_blank" rel="noopener noreferrer">Cadastro de Estrutura Curricular</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=NEboenxNA74" target="_blank" rel="noopener noreferrer">Cadastro de Ano Letivo</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=s2JMJgO5Z5c" target="_blank" rel="noopener noreferrer">Cadastro de Turma</a></td>
                    <td>Gestor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=TTSfkAPk43M" target="_blank" rel="noopener noreferrer">Envio de Convites</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=ZzlMI4Cq_wQ" target="_blank" rel="noopener noreferrer">Adicionando diretor em escola</a></td>
                    <td>Gestor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=rdXP6LkvoQw" target="_blank" rel="noopener noreferrer">Cadastro de Professor/Diretor</a></td>
                    <td>Professor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=TM5zC_UdgMA" target="_blank" rel="noopener noreferrer">Adicionando Professor em Turma</a></td>
                    <td>Gestor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=k7eaZtZQI7M" target="_blank" rel="noopener noreferrer">Cadastro de Aluno</a></td>
                    <td>Gestor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=VBDUXC4eQp0" target="_blank" rel="noopener noreferrer">Matrícula de Aluno</a></td>
                    <td>Gestor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=xixN7Z0NEMg" target="_blank" rel="noopener noreferrer">Cadastro de Histórico Escolar de Aluno</a></td>
                    <td>Gestor e Diretor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=bEXctra3glM" target="_blank" rel="noopener noreferrer">Acesso ao diário de classe</a></td>
                    <td>Professor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=m3qg532MYbo" target="_blank" rel="noopener noreferrer">Lançamento de frequência por dia</a></td>
                    <td>Professor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=PXlbEqXSo3g" target="_blank" rel="noopener noreferrer">Lançamento de frequência por mês</a></td>
                    <td>Professor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=B_Vtg89vH4c" target="_blank" rel="noopener noreferrer">Lançamento de relatório do aluno</a></td>
                    <td>Professor</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.youtube.com/watch?v=Guf253XsRew" target="_blank" rel="noopener noreferrer">Lançamento de notas do aluno</a></td>
                    <td>Professor</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
}

export default withRouter(Tutoriais);
