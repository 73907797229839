import React from 'react';

import {
  Row,
  Col,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import ChamadosOuvidoriaService from 'services/ChamadosOuvidoriaService';

import Papel from 'assets/csv/papeis.json';
import TipoChamadoOuvidoria from 'assets/csv/tipos-chamado-ouvidoria.json';
import SituacaoChamadoOuvidoria from 'assets/csv/situacoes-chamado-ouvidoria.json';

class Chamados extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-ouvidoria-chamados-${props.situacao}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.tipos = [
      TipoChamadoOuvidoria.RECLAMACAO,
      TipoChamadoOuvidoria.ELOGIO,
      TipoChamadoOuvidoria.SUGESTAO,
    ];

    this.situacoes = [
      SituacaoChamadoOuvidoria.ABERTO,
      SituacaoChamadoOuvidoria.EM_ANDAMENTO,
      SituacaoChamadoOuvidoria.FINALIZADO,
    ];

    this.state = {
      chamadoOuvidoriaSelecionado: null,

      dadosTabela: {
        header: [['Título', '60%'], ['Data', '10%'], ['Tipo', '10%'], ['Protocolo', '10%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['titulo', 'data', 'tipo_str', 'protocolo'],
        rows: [],
        total: undefined,
      },
    };

    this.chamadosOuvidoriaService = new ChamadosOuvidoriaService();
  }


  async componentDidMount() {
    this.carregarChamadosOuvidoria(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarChamadosOuvidoria(pagina, linhasPorPagina, like) {
    try {
      const data = this.props.role === Papel.GESTOR.value ? await this.chamadosOuvidoriaService
        .carregarChamadosOuvidoriaPorEnteSituacao(this.props.situacao, pagina, linhasPorPagina, like)
        : await this.chamadosOuvidoriaService
          .carregarChamadosOuvidoriaPorSituacao(this.props.situacao, pagina, linhasPorPagina, like);

      data.chamadosOuvidoria.forEach((chamadoOuvidoria) => {
        Object.assign(chamadoOuvidoria, {
          tipo_str: this.tipos[chamadoOuvidoria.tipo].label,
          situacao_str: this.situacoes[chamadoOuvidoria.situacao].label,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.chamadosOuvidoria, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar chamados de ouvidoria cadastrados',
      });
    }
  }

  editarChamadoOuvidoria(chamado) {
    this.props.history.push(`${this.props.layout}/ouvidoria/chamados/${chamado.id}/editar`);
  }

  confirmarRemocaoChamadoOuvidoria(chamado) {
    this.setState({ chamadoOuvidoriaSelecionado: chamado, showConfirmModal: true });
  }

  async removerChamadoOuvidoria() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.chamadosOuvidoriaService
        .removerChamadoOuvidoria(this.state.chamadoOuvidoriaSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Chamado da ouvidoria removido com sucesso',
      });

      this.carregarChamadosOuvidoria(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover chamado da ouvidoria',
      });
    }
  }

  chamadoOuvidoriaSelecionado(chamado) {
    this.props.history.push(`${this.props.layout}/ouvidoria/chamados/${chamado.id}`);
  }

  render() {
    return (
      <>
        <br />
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={this.props.role === Papel.GESTOR.value}
              disablePrintAction
              clickRows
              editButtonCallback={(chamadoOuvidoria) => this.editarChamadoOuvidoria(chamadoOuvidoria)}
              removeButtonCallback={(chamadoOuvidoria) => this.confirmarRemocaoChamadoOuvidoria(chamadoOuvidoria)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarChamadosOuvidoria(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarChamadosOuvidoria(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarChamadosOuvidoria(this.paginaSelecionada,
                  this.linhasPorPagina, this.like);
              }}
              rowCallback={(chamadoOuvidoria) => this.chamadoOuvidoriaSelecionado(chamadoOuvidoria)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerChamadoOuvidoria();
            } else {
              this.setState({ chamadoOuvidoriaSelecionado: null });
            }
          }}
          text='Confirme a exclusão do chamado de ouvidoria' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo chamado de ouvidoria...' />
      </>
    );
  }
}

export default Chamados;
