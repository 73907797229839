import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import UsuarioService from 'services/UsuarioService';

class PreCadastroAluno extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,

      nome: '',

      nomeState: '',

      showLoaderModal: false,

      alunos: [],

      erro: null,
    };

    this.loginService = new LoginService();
    this.usuarioService = new UsuarioService();

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    if(this.props.location.state && this.props.location.state.msg) {
      this.setState(this.props.location.state.msg);
    }
    
  }

  validarCampos() {
    let ret = true;

    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  onSubmit(e) {
    e.preventDefault();
    if(this.state.alunos.length > 0) {
      this.props.history.push({ 
        pathname: `${this.props.layout}/alunos/cadastro`, 
        state: { nome: this.state.nome },
      });
    } else {
      this.pesquisarAluno();
    }
  }

  async pesquisarAluno() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {
      const aluno = {
        nome: this.state.nome.trim(),
      }

      const alunos = await this.usuarioService.pesquisarAluno(aluno);

      if(alunos.length === 0) {
        this.props.history.push({ 
          pathname: `${this.props.layout}/alunos/cadastro`, 
          state: { nome: this.state.nome },
        });
      } else {
        this.setState({
          showAlert: true,
          alertColor: 'danger',
          alertMsg: 'Atenção! Existem alunos cadastrados com nomes parecidos com o informado. Verifique se o aluno já foi cadastrado antes. Caso não tenha sido cadastrado, clique novamente no botão Cadastrar Aluno.',
          showLoaderModal: false, 
          alunos,
        });
      }
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao pesquisar aluno',
      });
    }
  }

  conteudoPagina() {
    return <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/alunos`}>Alunos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Pré Cadastro</BreadcrumbItem>
          </Breadcrumb>
          <Form 
            onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do aluno</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome completo do aluno *</label>
                      <Input
                        value={this.state.nome}
                        type="text"
                        maxLength="100"
                        onChange={(evt) => this.setState({ 
                          nome: evt.target.value, 
                          nomeState: '',
                        })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do aluno.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
                {
                  this.state.alunos.length > 0 && <Row>
                  <Col>
                    <br/>
                    <h5>Alunos cadastrados:</h5>
                    <Table hover>
                        <thead className="text-primary">
                          <tr>
                            <th style={{ width: '35%' }}>Nome</th>
                            <th style={{ width: '20%' }}>Data nascimento</th>
                            <th style={{ width: '35%' }}>Mãe</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.alunos.map((aluno, idx) => <tr 
                              className='tr-clickable' key={idx}
                              onClick={() => this.props.history.push(`${this.props.layout}/alunos/historico/${aluno.id}`)}
                            >
                              <td>{aluno.nome}</td>
                              <td>{aluno.dataNascimento}</td>
                              <td>{aluno.nomeMae}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                }
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push(`${this.props.layout}/alunos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                      Cadastrar Aluno
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Pesquisando aluno..." />
      </div>
    );
  }
}

export default withRouter(PreCadastroAluno);
