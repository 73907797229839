import React from 'react';

import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';

import TransportesService from 'services/TransportesService';

class TransportesAnoLetivo extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-transportes-ano-letivo-${this.props.match.params.idAnoLetivo}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.transportesService = new TransportesService();

    this.state = {
      dadosTabela: {
        header: [['Modelo', '30%'], ['Itinerário', '60%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['modelo', 'itinerario'],
        rows: [],
        total: undefined,
      },
    };
  }


  async componentDidMount() {
    await this.carregarTransportes(this.paginaSelecionada,
      this.linhasPorPagina, this.like);
  }

  async carregarTransportes(pagina, linhasPorPagina, like) {
    try {
      const data = await this.transportesService
        .carregarTransportes(pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.transportes, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  render() {
    return <>
      <Row>
        <Col>
          <AdaptiveTable
            selectedPage={this.paginaSelecionada}
            rowsPerPage={this.linhasPorPagina}
            disableActions
            clickRows
            rowsPerPageCallback={(info) => {
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.linhasPorPagina = info.rowsPerPage;
              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
              this.carregarTransportes(this.paginaSelecionada, info.rowsPerPage, this.like);
            }}
            likeCallback={(text) => {
              this.like = text;
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarTransportes(this.paginaSelecionada, this.linhasPorPagina, text);
            }}
            paginatorCallback={(page) => {
              this.paginaSelecionada = page;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarTransportes(this.paginaSelecionada, this.linhasPorPagina, this.like);
            }}
            rowCallback={(transporte) => {
              this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/transporte/${transporte.id}` });
            }}
            data={this.state.dadosTabela}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary" disabled={this.state.buttonDisabled}
            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos`)} >
            Voltar
          </Button>
        </Col>
      </Row>
    </>;
  }
}

export default TransportesAnoLetivo;
