import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';

import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

import Papel from 'assets/csv/papeis.json';

class Pais extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-pais`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarPais = this.carregarPais.bind(this);

    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      dadosTabela: {
        header: [['Nome', '100%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    this.carregarPais(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarPais(pagina, linhasPorPagina, like) {
    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.PAIS.value, pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar pais cadastrados',
      });
    }
  }

  paisSelecionado(pais) {
    this.props.history.push({
      pathname: `${this.props.layout}/pais/${pais.id}`,
    });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Pais</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Pais cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  clickRows={true}
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disableActions
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarPais(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarPais(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarPais(this.paginaSelecionada, this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(pais) => {
                    this.paisSelecionado(pais);
                  }}
                  data={this.state.dadosTabela}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Pais);
