import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Alert from 'views/components/Alert';
import ListagemChamados from 'views/components/ListagemChamados';

import LoginService from 'services/LoginService';

import SituacaoChamado from 'assets/csv/situacoes-chamado.json';

class Chamados extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      chamadoSelecionado: null,

      showLoaderModal: false,
      showConfirmModal: false,
    };
  }


  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Chamados</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Chamados cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <Tabs>
                  <TabList>
                    <Tab>Abertos</Tab>
                    <Tab>Em andamento</Tab>
                    <Tab>Finalizados</Tab>
                  </TabList>
                  <TabPanel>
                    <ListagemChamados
                      {...this.props}
                      situacao={SituacaoChamado.ABERTO.value}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ListagemChamados
                      {...this.props}
                      situacao={SituacaoChamado.EM_ANDAMENTO.value}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ListagemChamados
                      {...this.props}
                      situacao={SituacaoChamado.FINALIZADO.value}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                </Tabs>
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary"
                  onClick={() => {
                    this.props.history.push({ pathname: '/gestor/chamados/cadastro' });
                  }}>
                  Cadastrar chamado
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Chamados);
