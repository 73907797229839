import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import Datetime from 'react-datetime';
import isDateFormat from 'is-date-format';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import TurmasService from 'services/TurmasService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';
import TipoAssinatura from 'assets/csv/tipos-assinatura.json';

class ImprimirDeclaracaoFrequenciaMensal extends React.Component {
  constructor(props) {
    super(props);

    this.tiposAssinatura = [
      TipoAssinatura.MANUAL,
      TipoAssinatura.AUTOMATICA,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.turmasService = new TurmasService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: null,

      escolaSelecionada: null,

      carregandoAlunos: false,

      alunoSelecionado: null,
      matriculaSelecionada: null,

      dataInicial: '',
      dataFinal: '',

      matriculas: [],
      alunos: [],

      tipoAssinaturaSelecionado: TipoAssinatura.MANUAL,

      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };

    this.imprimirDeclaracaoFrequenciaMensal = this
      .imprimirDeclaracaoFrequenciaMensal.bind(this);
  }


  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, alunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const data = await this.escolasService
        .carregarEscolas(1, 999999, '');

      const selectEscolas = [];
      data.escolas.forEach((escola) => selectEscolas.push({
        value: escola.id,
        label: escola.nome,
      }));

      this.setState({
        selectEscolas,
        dadosEnte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    if (!this.state.matriculaSelecionada) {
      this.setState({ matriculaState: 'danger' });
      ret = false;
    }

    // data inicial
    if (this.state.dataInicial === '') {
      this.setState({
        mesInicialState: 'has-danger',
        mesInicialErro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataInicial, 'dd-mm-yyyy')) {
      this.setState({
        mesInicialState: 'has-danger',
        mesInicialErro: 'Data inválida',
      });
      ret = false;
    }

    // data final
    if (this.state.dataFinal === '') {
      this.setState({
        mesFinalState: 'has-danger',
        mesFinalErro: 'Informe uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataFinal, 'dd-mm-yyyy')) {
      this.setState({
        mesFinalState: 'has-danger',
        mesFinalErro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.dataInicial !== null
      && moment(this.state.dataInicial, 'DD-MM-YYYY').isSameOrAfter(
        moment(this.state.dataFinal, 'DD-MM-YYYY').clone().startOf('month'),
      )) {
      this.setState({
        mesFinalState: 'has-danger',
        mesFinalErro: 'O mês final deve ser posterior ao mês inicial',
      });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      escolaSelecionada: null,

      alunoState: '',
      matriculaState: '',

      alunoSelecionado: null,
      matriculaSelecionada: null,

      alunos: [],
      matriculas: [],

      carregandoAlunos: false,
    });
  }

  async carregarAlunos(like) {
    try {
      let data;
      if(this.state.dadosEnte.alunosPorEscola) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosEscola(this.state.escolaSelecionada.value, Papel.ALUNO.value, 1, 99999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 99999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async carregarMatriculas(idAluno) {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Carregando matrículas do aluno...',
    });

    try {
      const matriculas = await this.turmasService
        .carregarTodasMatriculasDoAluno(idAluno);

      if (matriculas.length === 0) {
        this.setState({
          buttonDisabled: false,
          showAlert: true,
          alertColor: 'danger',
          alertMsg: 'O aluno selecionado não possui matrículas ativas.',
        });
      } else {
        const selectMatriculas = [];
        matriculas.forEach((matricula) => {
          if (matricula.idEscola === this.state.escolaSelecionada.value) {
            selectMatriculas.push({ 
              value: matricula.id, 
              label: `${matricula.curso} - ${matricula.nomeTurma} - ${matricula.ano}`,
              ano: parseInt(matricula.ano),
            });
          }
        });

        if (selectMatriculas.length === 0) {
          this.setState({
            buttonDisabled: false,
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'O aluno selecionado não possui matrículas ativas na sua escola.',
          });
        }

        this.setState({
          buttonDisabled: false,
          matriculas: selectMatriculas,
        });
      }
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar matrículas do aluno',
      });
    }
  }

  async imprimirDeclaracaoFrequenciaMensal(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo declaração de frequência mensal para pdf...',
    });

    try {
      const pdf = await this.escolasService
        .imprimirDeclaracaoFrequenciaMensal(this.state.escolaSelecionada.value,
          this.state.matriculaSelecionada.value,
          this.state.dataInicial, this.state.dataFinal,
          this.state.tipoAssinaturaSelecionado.value);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Declaração de frequência mensal impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir declaração de frequência mensal',
      });
    }
  }

  conteudoPagina() {
    return this.state.selectEscolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/documentos`}>Documentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Imprimir Declaração de Frequência Mensal</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirDeclaracaoFrequenciaMensal}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir Declaração de Frequência Mensal</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({ escolaState: '', escolaSelecionada: event });
                        }}
                        options={this.state.selectEscolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Aluno *</label>
                      <Select
                        isLoading={this.state.carregandoAlunos}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.alunoState}`}
                        classNamePrefix="react-select"
                        value={this.state.alunoSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({
                            alunoState: 'primary',
                            alunoSelecionado: event,
                            matriculas: [],
                            matriculaSelecionada: '',
                          });
                          this.carregarMatriculas(event.value);
                        }}
                        options={this.state.alunos}
                        placeholder="Digite o nome de um aluno cadastro no sistema..."
                        isDisabled={!this.state.escolaSelecionada}
                      />
                      {this.state.alunoState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um aluno cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Matrícula *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.matriculaState}`}
                        classNamePrefix="react-select"
                        value={this.state.matriculaSelecionada}
                        onChange={(event) => {
                          this.setState({ 
                            matriculaState: 'primary', 
                            matriculaSelecionada: event,
                            viewDate: new Date(event.ano, 0, 1),
                            mesInicial: undefined,
                            mesFinal: undefined,
                          });
                        }}
                        options={this.state.matriculas}
                        placeholder="Selecione uma matricula..."
                        isDisabled={!this.state.alunoSelecionado}
                      />
                      {this.state.matriculaState === 'danger' ? (
                        <label className="error">
                          Informe a matricula.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Assinatura *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoAssinaturaState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoAssinaturaSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoAssinaturaState: '', tipoAssinaturaSelecionado: event });
                        }}
                        options={this.tiposAssinatura}
                        placeholder="Tipo de assinatura..."
                      />
                      {this.state.tipoAssinaturaState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da assinatura do documento.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.mesInicialState}`}>
                      <label>Mês inicial *</label>
                      <Datetime
                        viewDate={this.state.viewDate}
                        timeFormat={false}
                        locale="pt-br"
                        onChange={(mes) => {
                          this.setState({
                            dataInicial: moment(mes).clone().startOf('month').format('DD-MM-YYYY'),
                            mesInicial: moment(mes).clone().startOf('month').format('MMMM')
                              .toUpperCase(),
                            mesInicialState: '',
                            viewDate: mes,
                          });
                        }}
                        dateFormat="M"
                        value={this.state.mesInicial}
                        closeOnSelect
                      />
                      {this.state.mesInicialState === 'has-danger' ? (
                        <label className="error">
                          {this.state.mesInicialErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.mesFinalState}`}>
                      <label>Mês final *</label>
                      <Datetime
                        viewDate={this.state.viewDate}
                        timeFormat={false}
                        locale="pt-br"
                        onChange={(mes) => {
                          this.setState({
                            dataFinal: moment(mes).clone().endOf('month').format('DD-MM-YYYY'),
                            mesFinal: moment(mes).clone().endOf('month').format('MMMM')
                              .toUpperCase(),
                            mesFinalState: '',
                            viewDate: mes,
                          });
                        }}
                        dateFormat="M"
                        value={this.state.mesFinal}
                        closeOnSelect
                      />
                      {this.state.mesFinalState === 'has-danger' ? (
                        <label className="error">
                          {this.state.mesFinalErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="error">
                      OBS: O cálculo do percentual de frequência mês a mês é feito
                      de acordo com as informações de frequência lançadas pelos professores.
                      Certifique-se de que os professores lançaram toda a frequência do período
                      antes de emitir a declaração.
                    </label>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/documentos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirDeclaracaoFrequenciaMensal);
