import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Input,
} from 'reactstrap';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';

class ImprimirRelatorioProfessores extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.relatoriosService = new RelatoriosService();

    this.imprimirRelatorioProfessores = this.imprimirRelatorioProfessores.bind(this);

    this.state = {
      showAlert: false,
      error: null,

      email: true,
      telefone: true,
      endereco: true,
      mae: false,
      pai: false,
      nascimento: false,
      cpf: false,
      escolaridade: false,
      sexo: false,
      etnia: false,
      nis: false,
      situacao: false,
      auxiliar: false,
      contratado: false,
    };
  }

  maximoSelecionados() {
    let selecionados = 0;
    if (this.state.email) selecionados += 1;
    if (this.state.telefone) selecionados += 1;
    if (this.state.endereco) selecionados += 1;
    if (this.state.mae) selecionados += 1;
    if (this.state.pai) selecionados += 1;
    if (this.state.nascimento) selecionados += 1;
    if (this.state.cpf) selecionados += 1;
    if (this.state.escolaridade) selecionados += 1;
    if (this.state.sexo) selecionados += 1;
    if (this.state.etnia) selecionados += 1;
    if (this.state.nis) selecionados += 1;
    if (this.state.situacao) selecionados += 1;
    if (this.state.auxiliar) selecionados += 1;
    if (this.state.contratado) selecionados += 1;

    return selecionados >= 3;
  }

  async imprimirRelatorioProfessores(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo relatório de professores...',
    });

    try {
      const campos = {
        email: this.state.email,
        telefone: this.state.telefone,
        endereco: this.state.endereco,
        mae: this.state.mae,
        pai: this.state.pai,
        nascimento: this.state.nascimento,
        cpf: this.state.cpf,
        escolaridade: this.state.escolaridade,
        sexo: this.state.sexo,
        etnia: this.state.etnia,
        nis: this.state.nis,
        situacao: this.state.situacao,
        auxiliar: this.state.auxiliar,
        contratado: this.state.contratado,
      };

      const pdf = await this.relatoriosService
        .imprimirRelatorioProfessores(campos);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de professores impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site.',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de professores',
      });
    }
  }

  conteudoPagina() {
    return <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios/professores`}>Relatórios de professores</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de professores cadastrados</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioProfessores}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir relatório de professores cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <label>Campos do relatório (máximo três)</label>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.email && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.email}
                          onChange={(e) => this.setState({
                            email: e.target.checked,
                          })} />
                        Email
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.telefone && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.telefone}
                          onChange={(e) => this.setState({
                            telefone: e.target.checked,
                          })} />
                        Telefone
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.endereco && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.endereco}
                          onChange={(e) => this.setState({
                            endereco: e.target.checked,
                          })} />
                        Endereço
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.mae && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.mae}
                          onChange={(e) => this.setState({
                            mae: e.target.checked,
                          })} />
                        Nome da mãe
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.pai && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.pai}
                          onChange={(e) => this.setState({
                            pai: e.target.checked,
                          })} />
                        Nome do pai
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.nascimento && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.nascimento}
                          onChange={(e) => this.setState({
                            nascimento: e.target.checked,
                          })} />
                        Data de nascimento
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.cpf && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.cpf}
                          onChange={(e) => this.setState({
                            cpf: e.target.checked,
                          })} />
                        CPF
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.escolaridade && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.escolaridade}
                          onChange={(e) => this.setState({
                            escolaridade: e.target.checked,
                          })} />
                        Escolaridade
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.sexo && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.sexo}
                          onChange={(e) => this.setState({
                            sexo: e.target.checked,
                          })} />
                        Sexo
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.etnia && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.etnia}
                          onChange={(e) => this.setState({
                            etnia: e.target.checked,
                          })} />
                        Etnia
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.nis && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.nis}
                          onChange={(e) => this.setState({
                            nis: e.target.checked,
                          })} />
                        NIS
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.situacao && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.situacao}
                          onChange={(e) => this.setState({
                            situacao: e.target.checked,
                          })} />
                        Situação
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.auxiliar && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.auxiliar}
                          onChange={(e) => this.setState({
                            auxiliar: e.target.checked,
                          })} />
                        Auxiliar
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          disabled={!this.state.contratado && this.maximoSelecionados()}
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.contratado}
                          onChange={(e) => this.setState({
                            contratado: e.target.checked,
                          })} />
                        Contratado
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <br/>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios/professores`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirRelatorioProfessores);
