import React from 'react';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import OcorrenciasService from 'services/OcorrenciasService';

import Papel from 'assets/csv/papeis.json';

class Ocorrencias extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-`
      + `turmas-escola-ocorrencias${this.props.match.params.idAnoLetivo}`
      + `-${this.props.match.params.idEscola}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      ocorrenciaSelecionada: null,

      dadosTabela: {
        header: [['Usuário', '35%'], ['Mãe', '35%'], ['Data', '15%']],
        columnAlign: ['text-left', 'text-left', 'text-center'],
        keys: ['nome', 'nomeMae', 'data'],
        rows: [],
        total: undefined,
      },
    };

    this.ocorrenciasService = new OcorrenciasService();
  }

  async componentDidMount() {
    this.carregarOcorrencias(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarOcorrencias(pagina, linhasPorPagina, like) {
    try {
      const data = await this.ocorrenciasService
        .carregarOcorrencias(this.props.match.params.idAnoLetivo,
          this.props.match.params.idEscola, pagina, linhasPorPagina, like);

      data.ocorrencias.forEach((ocorrencia) => {
        Object.assign(ocorrencia, {
          nome: ocorrencia.usuario.nome,
          nomeMae: ocorrencia.usuario.nomeMae,
        });
      });

      this.setState({
        dadosTabela: Object.assign(
          this.state.dadosTabela,
          { rows: data.ocorrencias, total: data.total },
        ),
      });
    } catch (e) {

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar ocorrências cadastradas',
      });
    }
  }

  editarOcorrencia(ocorrencia) {
    this.props.history.push(`${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/ocorrencias/edicao/${ocorrencia.id}`);
  }

  confirmarRemocaoOcorrencia(ocorrencia) {
    this.setState({ ocorrenciaSelecionada: ocorrencia, showConfirmModal: true });
  }

  async removerOcorrencia() {
    this.setState({ showLoaderModal: true });

    try {
      await this.ocorrenciasService
        .removerOcorrencia(this.state.ocorrenciaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ocorrência removida com sucesso',
      });

      this.carregarOcorrencias(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao remover ocorrência',
      });
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          clickRows
          disablePrintAction
          disableActions={this.props.role !== Papel.GESTOR.value
            && this.props.role !== Papel.DIRETOR.value
            && this.props.role !== Papel.SECRETARIO.value
          }
          editButtonCallback={(ocorrencia) => this.editarOcorrencia(ocorrencia)}
          removeButtonCallback={(ocorrencia) => this.confirmarRemocaoOcorrencia(ocorrencia)}
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarOcorrencias(this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarOcorrencias(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarOcorrencias(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(ocorrencia) => {
            this.props.history.push(`${this.props.layout}`
              + `/anosletivos/${this.props.match.params.idAnoLetivo}`
              + `/escola/${this.props.match.params.idEscola}`
              + `/ocorrencia/${ocorrencia.id}`);
          }}
          data={this.state.dadosTabela} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerOcorrencia();
            } else {
              this.setState({ ocorrenciaSelecionada: null });
            }
          }}
          text='Confirme a exclusão da ocorrência' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

export default Ocorrencias;
