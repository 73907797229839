import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';

class ImprimirRelatorioAlunosBolsaFamiliaPorEscola extends React.Component {
  constructor(props) {
    super(props);

    this.orientacoesPagina = [
      { value: true, label: 'Retrato' },
      { value: false, label: 'Paisagem' },
    ]

    this.campos = [
      { value: 'nome', label: 'Nome' },
      { value: 'telefone', label: 'Telefone' },
      { value: 'dataNascimento', label: 'Data de nascimento' },
      { value: 'naturalidade', label: 'Naturalidade' },
      { value: 'endereco', label: 'Endereço' },
      { value: 'sexo', label: 'Sexo' },
      { value: 'etnia', label: 'Etnia' },
      { value: 'nee', label: 'NEE' },
      { value: 'idCenso', label: 'ID Censo' },
      { value: 'nis', label: 'NIS' },
      { value: 'cartaoSUS', label: 'Cartão SUS' },
      { value: 'bolsaFamilia', label: 'Bolsa Família' },
      { value: 'pontoReferencia', label: 'Ponto de Referência' },
      { value: 'nomeSocial', label: 'Nome Social' },
      { value: 'usaTransporte', label: 'Usa transporte' },
      { value: 'nomeMae', label: 'Nome da mãe' },
      { value: 'nomePai', label: 'Nome do pai' },
      { value: 'profissaoMae', label: 'Profissão da mãe' },
      { value: 'profissaoPai', label: 'Profissão do pai' },
      { value: 'rgMae', label: 'RG da mãe' },
      { value: 'rgPai', label: 'RG do pai' },
      { value: 'registroNascimento', label: 'Registro de nascimento' },
      { value: 'tituloEleitor', label: 'Título de eleitor' },
      { value: 'situacaoMilitar', label: 'Situação militar' },
      { value: 'rg', label: 'RG' },
      { value: 'cpf', label: 'CPF' },
      { value: 'dataMatricula', label: 'Data da matrícula' },
      { value: 'situacao', label: 'Situação' },
      { value: 'tipoSanguineo', label: 'Tipo Sanguíneo' },
      { value: 'fardamento', label: 'Informações de fardamento' },
      { value: 'turma', label: 'Turma' },
    ]

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.relatoriosService = new RelatoriosService();

    this.imprimirRelatorioAlunosBolsaFamiliaPorEscola = this.imprimirRelatorioAlunosBolsaFamiliaPorEscola.bind(this);

    this.state = {
      showAlert: false,
      error: null,

      camposSelecionados: [
        { value: 'idCenso', label: 'ID Censo' },
        { value: 'nome', label: 'Nome' },
        { value: 'turma', label: 'Turma' },
      ],

      orientacaoPaginaSelecionada: undefined,
    };
  }

  async componentDidMount() {
    try {
      let data = await this.anosLetivosService.carregarAnosLetivos(1, 99999, '');
      const { anosLetivos } = data;
      anosLetivos.forEach((anoLetivo) => Object.assign(
        anoLetivo, { value: anoLetivo.id, label: anoLetivo.ano },
      ));

      data = await this.escolasService.carregarEscolas(1, 99999, '');
      let { escolas } = data;
      escolas.forEach((escola) => Object.assign(
        escola, { value: escola.id, label: escola.nome },
      ));
      /*if (this.props.role === Papel.GESTOR.value) {
        escolas = [{ value: null, label: 'Todas as escolas' }].concat(escolas);
      }*/

      this.setState({
        anosLetivos,
        escolas,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async imprimirRelatorioAlunosBolsaFamiliaPorEscola(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo relatório de alunos do bolsa família por escola para pdf...',
    });

    try {
      
      const informacoesRelatorioAlunos = {
        retrato: this.state.orientacaoPaginaSelecionada.value, 
      };
  
      this.state.camposSelecionados.forEach((campo) => 
        Object.assign(informacoesRelatorioAlunos, { [campo.value]: true })
      );

      const pdf = await this.relatoriosService
        .imprimirRelatorioAlunosBolsaFamiliaPorEscola(this.state.anoLetivoSelecionado.id,
          this.state.escolaSelecionada.id, informacoesRelatorioAlunos);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de alunos do bolsa família por escola impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site.',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de alunos do bolsa família por escola',
      });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.anoLetivoSelecionado) {
      this.setState({ anoLetivoState: 'danger' });
      ret = false;
    }
    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }
    if (!this.state.orientacaoPaginaSelecionada) {
      this.setState({ orientacaoPaginaState: 'danger' });
      ret = false;
    }
    if (!this.state.camposSelecionados || this.state.camposSelecionados.length === 0) {
      this.setState({ camposState: 'danger' });
      ret = false;
    }
    return ret;
  }

  conteudoPagina() {
    return this.state.anosLetivos ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios/alunos`}>Relatórios de alunos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de alunos do bolsa família por escola</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioAlunosBolsaFamiliaPorEscola}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir relatório de alunos do bolsa família por escola</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup className={'has-label has-danger'}>
                      <label>Ano letivo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.anoLetivoState}`}
                        classNamePrefix="react-select"
                        placeholder="Ano letivo..."
                        value={this.state.anoLetivoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            anoLetivoSelecionado: event,
                            escolaSelecionada: '',
                            anoLetivoState: '',
                          });
                        }}
                        options={this.state.anosLetivos}
                      />
                      {this.state.anoLetivoState === 'danger' ? (
                        <label className="error">
                          Informe o ano letivo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4" style={{ marginTop: 10 }}>
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        isDisabled={!this.state.anoLetivoSelecionado}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        placeholder="Escola..."
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            escolaSelecionada: event,
                            escolaState: '',
                          });
                        }}
                        options={this.state.escolas}
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Orientação da página *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.orientacaoPaginaState}`}
                        classNamePrefix="react-select"
                        placeholder="Orientação da página..."
                        value={this.state.orientacaoPaginaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            orientacaoPaginaSelecionada: event,
                            orientacaoPaginaState: '',
                          });
                        }}
                        options={this.orientacoesPagina}
                      />
                      {this.state.orientacaoPaginaState === 'danger' ? (
                        <label className="error">
                          Informe a orientação da página.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="10">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Campos *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.camposState}`}
                        classNamePrefix="react-select"
                        placeholder="Campos..."
                        closeMenuOnSelect={false}
                        isMulti
                        value={this.state.camposSelecionados}
                        onChange={(event) => {
                          this.setState({
                            camposSelecionados: event,
                            camposState: '',
                          });
                        }}
                        options={this.campos}
                      />
                      {this.state.camposState === 'danger' ? (
                        <label className="error">
                          Informe os campos do relatório
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios/alunos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirRelatorioAlunosBolsaFamiliaPorEscola);
