/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import LoaderModal from 'views/components/LoaderModal';
import AdaptiveTable from 'views/components/AdaptiveTable';
import FichaAlunoModal from 'views/components/FichaAlunoModal';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';

import TurmasService from 'services/TurmasService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import TransportesService from 'services/TransportesService';
import DiariosService from 'services/DiariosService';

import Papel from 'assets/csv/papeis.json';
import TipoMatricula from 'assets/csv/tipos-matricula.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';

class MatriculasTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-matriculas-turma-multiseriado-${this.props.match.params.idAnoLetivo}`
      + `-${this.props.match.params.idEscola}-${this.props.match.params.idTurma}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.tiposMatricula = [
      TipoMatricula.REGULAR,
      TipoMatricula.DEPENDENCIA,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      loading: true,
      showFichaAlunoModal: false,
      showConfirmTypingModal: false,
      turma: props.turma,

      dadosTabela: {
        header: [['Nome', '21%'], ['Nome da mãe', '18%'], [curso, '10%'], ['Tipo', '13%'], ['Situação', '13%'], ['Transporte', '8%'], ['Ficha', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-center', 'text-center', 'text-center', 'text-center', 'text-center'],
        keys: ['nome_str', 'nomeMae_str', 'curso', 'tipo_str', 'situacao_str', 'tp_str', 'ficha_str'],
        rows: [],
        total: undefined,
      },
    };

    this.turmasService = new TurmasService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
    this.transportesService = new TransportesService();
    this.diariosService = new DiariosService();

    this.matriculaSelecionada = this.matriculaSelecionada.bind(this);
  }

  async componentDidMount() {
    await this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, this.like);

    const diarios = await this.diariosService
      .carregarDiariosDaTurmaMultiseriado(this.props.turma.id);

    const diariosAbertos = diarios
      .every((diario) => diario.situacao === SituacaoDiario.ABERTO.value);

    const clickRows = (this.props.turma.situacao === SituacaoTurma.CONSOLIDADA.value)
      || (this.props.turma.situacao === SituacaoTurma.ABERTA.value && diariosAbertos);

    this.setState({
      loading: false,
      clickRows,
    });
  }

  dropDownTransportes = (transportes) => {
    const idx = uniqid();
    return <UncontrolledDropdown>
      <DropdownToggle
        aria-haspopup={true}
        color="default"
        data-toggle="collapse"
        id={`navbarDropdownTransporte${idx}`}
        onClick={(evt) => evt.stopPropagation()}
        nav>
        Sim
      </DropdownToggle>
      <DropdownMenu aria-labelledby={`navbarDropdownTransporte${idx}`} right>
        {
          transportes.map((transporte) => <DropdownItem
            key={transporte.id}
            onClick={(evt) => {
              evt.stopPropagation();
              this.setState({
                idTransporte: transporte.id,
                showTransporteModal: true,
              });
            }}>
            {transporte.placa}
          </DropdownItem>)
        }
      </DropdownMenu>
    </UncontrolledDropdown>;
  }

  async carregarMatriculas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.turmasService
        .carregarMatriculasTurmaMultiseriado(this.props.turma.id, pagina, linhasPorPagina, like);

      const fn2 = (matricula) => new Promise((resolve) => resolve(
        this.transportesService
          .carregarTransportesAluno(matricula.id),
      ));

      const actions2 = data.matriculas.map(fn2);

      const transportes = await Promise.all(actions2);

      data.matriculas.forEach((matricula, idx) => Object.assign(
        matricula, {
          tp_str: transportes[idx].length ? this.dropDownTransportes(transportes[idx]) : 'Não',
          ficha_str: <i style={{ cursor: 'pointer' }} onClick={(evt) => {
            evt.stopPropagation();
            this.setState({
              idAluno: matricula.usuario.id,
              showFichaAlunoModal: true,
            });
          }} className="fas fa-address-card fa-2x"></i>,
        },
      ));

      data.matriculas.forEach((matricula) => {
        Object.assign(matricula, {
          nome_str: <div>{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg"/>}</div>,
          nomeMae_str: matricula.usuario.nomeMae,
          na_str: matricula.novatoNoAno ? '✔' : '',
          pe_str: matricula.propriaEscola ? '✔' : '',
          ap_str: matricula.aprovado ? '✔' : '',
          ra_str: matricula.retidoCicloAdultos ? '✔' : '',
          tr_str: matricula.transferido ? '✔' : '',
          nfe_str: matricula.naoFrequentouEscolaAnoAnterior ? '✔' : '',
          eja_str: matricula.educacaoJovensAdultos ? '✔' : '',
          tipo_str: this.tiposMatricula[matricula.tipo].label,
          situacao_str: this.situacoesMatricula[matricula.situacao].label,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.matriculas, total: data.total }),
        totalMatriculas: data.matriculas.length,
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  matriculaSelecionada(matricula) {
    if (!this.state.clickRows) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Para alterar a situação de um aluno a turma deve estar consolidada ou aberta com todos os diários abertos',
      });
      return;
    }
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}`
      + `/escola/${this.props.turma.escola.id}/turma/${matricula.idTurma}/situacao/${matricula.id}`);
  }

  editarMatricula(matricula) {
    this.props.history.push(`${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/${matricula.idTurma}/matricula/aluno`
      + `/edicao/${matricula.id}`);
  }

  confirmarRemocaoMatricula(matricula) {
    this.setState({
      matriculaSelecionada: matricula,
      showConfirmTypingModal: true,
    });
  }

  async removerMatricula(senha) {
    try {
      this.props.callbackMessage({ showAlert: false });
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Excluindo matrícula...',
      });

      await this.turmasService
        .removerMatricula(this.state.matriculaSelecionada.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Matrícula removida com sucesso',
      });

      this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao remover matrícula',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
      <AdaptiveTable
        selectedPage={this.paginaSelecionada}
        rowsPerPage={this.linhasPorPagina}
        clickRows={this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
          && this.props.role !== Papel.PROFESSOR.value}
        disableActions={this.props.role === Papel.COORDENADOR_PEDAGOGICO.value
         || this.props.role === Papel.PROFESSOR.value}
        // clickRows={this.state.clickRows}
        disablePrintAction
        rowsPerPageCallback={(info) => {
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.linhasPorPagina = info.rowsPerPage;
          sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
          this.carregarMatriculas(this.paginaSelecionada, info.rowsPerPage, this.like);
        }}
        likeCallback={(text) => {
          this.like = text;
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarMatriculas(this.paginaSelecionada, this.linhasPorPagina, text);
        }}
        paginatorCallback={(page) => {
          this.paginaSelecionada = page;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarMatriculas(this.paginaSelecionada,
            this.linhasPorPagina, this.like);
        }}
        removeButtonCallback={(matricula) => this.confirmarRemocaoMatricula(matricula)}
        editButtonCallback={(matricula) => this.editarMatricula(matricula)}
        printTooltip="Imprimir boletim"
        rowCallback={(matricula) => {
          this.matriculaSelecionada(matricula);
        }}
        data={this.state.dadosTabela} />
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.turma.escola.id}`)} >
            Voltar
          </Button>
        </Col>
      </Row>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
      <FichaAlunoModal
        isOpen={this.state.showFichaAlunoModal}
        idAluno={this.state.idAluno}
        closeCallback={() => this.setState({ showFichaAlunoModal: false })} />
      <ConfirmTypingModal
        isOpen={this.state.showConfirmTypingModal}
        callback={(senha) => {
          this.setState({ showConfirmTypingModal: false });
          if (senha) {
            this.removerMatricula(senha);
          } else {
            this.setState({ matriculaSelecionada: null });
          }
        }}
        deletionInstruction='Confirme que você quer excluir a matrícula digitando a sua senha no campo abaixo'
        deletionText={''}
        deletionWarning='A exclusão de uma matrícula acarretará na exclusão de todas as avaliações e frequência do aluno, e demais dados relacionados' />
    </>;
  }
}

export default MatriculasTurmaMultiseriado;
