import React from 'react';

import {
	Row,
	Col,
	FormGroup,
	Input,
	Button,
	Modal,
	ModalBody,
	Label
} from 'reactstrap';

import Alert from 'views/components/Alert';

import moment from 'moment';
import Select from 'react-select';

import EventosService from 'services/EventosService';

import TipoEvento from 'assets/csv/tipos-evento.json';

class EventoModal extends React.Component {
	constructor(props) {
		super(props);

		this.tiposEvento = props.professor ? [
			TipoEvento.AVISO,
			TipoEvento.ATIVIDADE,
			TipoEvento.PROVA,
			TipoEvento.CONTEUDO,
			{ ...TipoEvento.DIARIO_ALUNO, isDisabled: true },
			{ ...TipoEvento.EVENTO_PRESENCIAL, isDisabled: true },
		] : [
			TipoEvento.AVISO,
			{ ...TipoEvento.ATIVIDADE, isDisabled: true },
			{ ...TipoEvento.PROVA, isDisabled: true },
			{ ...TipoEvento.CONTEUDO, isDisabled: true },
			{ ...TipoEvento.DIARIO_ALUNO, isDisabled: true },
			TipoEvento.EVENTO_PRESENCIAL,
		];

		this.state = {
			titulo: '',
			tipoEventoSelecionado: '',
			descricao: '',
			confirmacaoPresenca: false,

			showAlert: false,
		};

		this.eventosService = new EventosService();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.evento !== prevProps.evento) {
			const data = moment(this.props.evento.inicio).format('DD/MM/YYYY');
			
			let confirmacaoPresenca = false;
			if (this.props.confirmarPresenca) {
				if (this.props.evento.anoLetivo) {
					const presenca = await this.eventosService.carregarPresencaEventoAnoLetivo(this.props.evento.id);
					confirmacaoPresenca = presenca.confirmacao;
				} else if (this.props.evento.escola) {
					const presenca = await this.eventosService.carregarPresencaEventoEscola(this.props.evento.id);
					confirmacaoPresenca = presenca.confirmacao;
				} else if (this.props.evento.diario) {
					const presenca = await this.eventosService.carregarPresencaEventoDiario(this.props.evento.id);
					confirmacaoPresenca = presenca.confirmacao;
				}
			}

			this.setState({
				titulo: this.props.evento.titulo,
				tipoEventoSelecionado: this.tiposEvento[this.props.evento.tipo],
				descricao: this.props.evento.descricao,
				data,
				confirmacaoPresenca,
				showAlert: false
			});
		}
	}

	validarCampos() {
		let ret = true;

		if (!this.state.titulo) {
			this.setState({ tituloState: 'has-danger' });
			ret = false;
		}

		if (!this.state.tipoEventoSelecionado) {
			this.setState({ tipoEventoState: 'danger' });
			ret = false;
		}

		if (!this.state.descricao) {
			this.setState({ descricaoState: 'has-danger' });
			ret = false;
		}

		return ret;
	}

	cadastrar() {
		if (!this.validarCampos()) return;

		this.limparCampos();

		this.props.callbackCadastrar({
			id: this.props.evento.id,
			titulo: this.state.titulo,
			tipo: this.state.tipoEventoSelecionado.value,
			diaInteiro: this.props.evento.diaInteiro,
			inicio: moment(this.props.evento.inicio).format('DD/MM/YYYY HH:mm:ss'),
			fim: moment(this.props.evento.fim).format('DD/MM/YYYY HH:mm:ss'),
			descricao: this.state.descricao,
		});
	}

	excluir() {
		this.props.callbackExcluir(this.props.evento.id);
	}

	limparCampos() {
		this.setState({
			titulo: '',
			tipoEventoSelecionado: '',
			descricao: '',

			tituloState: '',
			tipoEventoState: '',
			descricaoState: '',
		})
	}

	render() {
		return (
			<Modal size="xl"
				isOpen={this.props.isOpen}>
				<ModalBody>
					<Alert
						className="mt-3"
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<h4>{!this.props.evento.id ? 'Cadastro' : 'Edição'} de evento - {this.state.data}</h4>
					<Row>
						<Col md="8">
							<FormGroup className={`has-label ${this.state.tituloState}`}>
								<label>Título *</label>
								<Input
									disabled={this.props.onlyRead}
									value={this.state.titulo}
									type="text"
									maxLength="100"
									onChange={(evt) => this.setState({
										titulo: evt.target.value,
										tituloState: 'has-success',
									})}
								/>
								{this.state.tituloState === 'has-danger' ? (
									<label className="error">
										Informe o nome título do evento.
									</label>
								) : null}
							</FormGroup>
						</Col>
						<Col md="4">
							<FormGroup className={'has-label has-danger'}>
								<label>Tipo do evento *</label>
								<Select
									isDisabled={this.props.onlyRead}
									noOptionsMessage={() => 'Nenhuma entrada'}
									className={`react-select primary ${this.state.tipoEventoState}`}
									classNamePrefix="react-select"
									value={this.state.tipoEventoSelecionado}
									onChange={(event) => {
										this.setState({ tipoEventoState: '', tipoEventoSelecionado: event });
									}}
									options={this.tiposEvento.filter((tipoEvento) => !tipoEvento.isDisabled)}
									placeholder="Tipo do evento..."
								/>
								{this.state.tipoEventoState === 'danger' ? (
									<label className="error">
										Informe o tipo do evento.
									</label>
								) : null}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup className={`has-label ${this.state.descricaoState}`}>
								<label>Descrição *</label>
								<Input
									disabled={this.props.onlyRead}
									value={this.state.descricao}
									onChange={(evt) => this.setState({
										descricao: evt.target.value,
										descricaoState: 'has-success',
									})}
									type="textarea"
									style={{ height: '200px' }} />
								{this.state.descricaoState === 'has-danger' ? (
									<label className="error">
										Informe a descrição do evento.
									</label>
								) : null}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						{
							this.props.onlyRead && this.props.confirmarPresenca && <Col className="mt-3">
								<FormGroup check>
									<Label className="form-check-label">
										<Input
											className="form-check-input"
											type="checkbox"
											checked={this.state.confirmacaoPresenca}
											onChange={async (e) => {
												try {
													const checkValue = e.target.checked;
													this.setState({
														showAlert: false,
													});
													if (this.props.evento.anoLetivo) {
														await this.eventosService.confirmacaoPresencaEventoAnoLetivo(this.props.evento.id, checkValue)
													} else if (this.props.evento.escola) {
														await this.eventosService.confirmacaoPresencaEventoEscola(this.props.evento.id, checkValue)
													} else if (this.props.evento.diario) {
														await this.eventosService.confirmacaoPresencaEventoDiario(this.props.evento.id, checkValue)
													}
													
													this.setState({
														confirmacaoPresenca: checkValue,
														showAlert: true,
														alertColor: 'success',
														alertMsg: checkValue ? 'Presença no evento confirmada com sucesso' : 'Presença no evento desconfirmada com sucesso',
													});
												} catch (e) {
													this.setState({
														showAlert: true,
														alertColor: 'danger',
														alertMsg: e || 'Erro ao alterar confirmação de presença no evento',
													});
													this.setState({
														confirmacaoPresenca: false,
													});
												}
											}} />
										Confirmar presença
										<span className="form-check-sign">
											<span className="check"></span>
										</span>
									</Label>
								</FormGroup>
							</Col>
						}
						{
							!this.props.onlyRead && this.props.evento.id && <Col>
								<Button
									color="danger"
									type="button"
									onClick={() => this.excluir()}>
									Excluir
								</Button>
							</Col>
						}
						<Col className="text-right">
							<Button
								color="default"
								data-dismiss="modal"
								type="button"
								onClick={() => {
									this.props.callbackFechar();
								}}>
								{this.props.onlyRead ? 'Fechar' : 'Cancelar'}
							</Button>
							{
								!this.props.onlyRead && <Button
									color="primary"
									type="button"
									onClick={() => this.cadastrar()}>
									{!this.props.evento.id ? 'Cadastrar' : 'Atualizar'}
								</Button>
							}
						</Col>
					</Row>
				</ModalBody>
			</Modal >
		);
	}
}

export default EventoModal;
