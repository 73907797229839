import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import isDateFormat from 'is-date-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import DatePicker from 'views/components/DatePicker';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import TarefasService from 'services/TarefasService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';

class CadastroTarefa extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-tarefas`;

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.fileInputRef = React.createRef();
    this.fileInputRef2 = React.createRef();

    this.nomeRef = React.createRef();

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.tarefasService = new TarefasService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,

      erro: false,
    };

    this.cadastrarTarefa = this.cadastrarTarefa.bind(this);
    this.atualizarTarefa = this.atualizarTarefa.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService
          .carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idAnoLetivo
        ? await this.escolasService
          .carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idAnoLetivo
        ? await this.turmasService
          .carregarTurma(this.props.match.params.idTurma) : null;

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      let tarefa = null;

      if (this.props.match.params.idTarefa !== undefined) {
        tarefa = await this.tarefasService
          .carregarTarefa(this.props.match.params.idTarefa);
      } else {
        tarefa = {
          nome: '',
          descricao: '',
          dataInicio: '',
          dataFim: '',
					turma: { id: this.props.match.params.idTurma },
					materia: { id: this.props.match.params.idMateria }
        };
      }

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        tarefa,
        ready: true,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (this.state.tarefa.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.tarefa.descricao === '') {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }

    if (this.state.tarefa.dataInicio === '') {
      this.setState({
        dataInicioState: 'has-danger',
        dataInicioErro: 'Informe a data de início da tarefa',
      });
      ret = false;
    } else if (!isDateFormat(this.state.tarefa.dataInicio, 'dd/mm/yyyy')) {
      this.setState({
        dataInicioState: 'has-danger',
        dataInicioErro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.tarefa.dataInicio && this.state.tarefa.dataFim
      && moment(this.state.tarefa.dataInicio, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.tarefa.dataFim, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataInicioState: 'has-danger',
        dataInicioErro: 'A data de início da tarefa deve ser anterior à data de fim',
      });
      ret = false;
    }

    if (this.state.tarefa.dataFim === '') {
      this.setState({
        dataFimState: 'has-danger',
        dataFimErro: 'Informe a data de fim da tarefa',
      });
      ret = false;
    } else if (!isDateFormat(this.state.tarefa.dataFim, 'dd/mm/yyyy')) {
      this.setState({
        dataFimState: 'has-danger',
        dataFimErro: 'Data inválida',
      });
      ret = false;
    } else if (this.state.tarefa.dataInicio && this.state.tarefa.dataFim
      && moment(this.state.tarefa.dataInicio, 'DD/MM/YYYY').isSameOrAfter(
        moment(this.state.tarefa.dataFim, 'DD/MM/YYYY'),
      )) {
      this.setState({
        dataFimState: 'has-danger',
        dataFimErro: 'A data de fim da tarefa deve ser posterior à data de início',
      });
      ret = false;
    }
    return ret;
  }

  async cadastrarTarefa() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando tarefa...',
    });

    try {
      await this.tarefasService.cadastrarTarefa(
        this.props.match.params.idTurma,
        this.props.match.params.idMateria, this.state.tarefa,
      );

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Tarefa cadastrada com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar tarefa',
      });
    }
  }

  async atualizarTarefa() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando tarefa...',
    });

    try {
      await this.tarefasService.atualizarTarefa(this.state.tarefa);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Tarefa atualizada com sucesso',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar tarefa',
      });
    }
  }

  limparFormulario() {
    this.setState({
      tarefa: {
        nome: '',
        dataInicio: '',
        dataFim: '',
        descricao: '',
      },
    });
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.state.anoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
                <BreadcrumbItem active>{this.props.match.params.idTarefa ? 'Edição de tarefa' : 'Cadastro de tarefa'}</BreadcrumbItem>
              </> : <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais`}>Turmas Virtuais</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais/turma/${this.props.match.params.idTurma}/diario/${this.props.match.params.idDiario}/materia/${this.props.match.params.idMateria}`}>{this.state.diario.ano} - {this.state.diario.nome} - {this.state.diario.nomeTurma} - {this.state.diario.escola} - {this.state.materia.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{this.props.match.params.idTarefa ? 'Edição de tarefa' : 'Cadastro de tarefa'}</BreadcrumbItem>
              </>
            }
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{this.state.tarefa.id ? 'Edição de tarefa' : 'Cadastro de tarefa'}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="8">
                  <FormGroup className={`has-label ${this.state.nomeState}`}>
                    <label>Nome *</label>
                    <Input
                      type="text"
                      maxLength="200"
                      value={this.state.tarefa.nome}
                      onChange={(e) => this.setState({
                        tarefa: { ...this.state.tarefa, nome: e.target.value },
                        nomeState: 'has-success',
                      })}
                    />
                    {this.state.nomeState === 'has-danger' ? (
                      <label className="error">
                        Informe um nome para a tarefa.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={`has-label ${this.state.dataInicioState}`}>
                    <label>Data de início *</label>
                    <DatePicker
                      viewDate={this.state.viewDate}
                      value={this.state.tarefa.dataInicio}
                      onChange={(data) => {
                        const dataStr = moment(data).format('DD/MM/YYYY');
                        Object.assign(this.state.tarefa, { dataInicio: dataStr });
                        this.setState({
                          viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                          dataInicioState: '',
                        });
                      }}
                    />
                    {this.state.dataInicioState === 'has-danger' ? (
                      <label className="error">
                        {this.state.dataInicioErro}
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={`has-label ${this.state.dataFimState}`}>
                    <label>Data de fim *</label>
                    <DatePicker
                      viewDate={this.state.viewDate}
                      value={this.state.tarefa.dataFim}
                      onChange={(data) => {
                        const dataStr = moment(data).format('DD/MM/YYYY');
                        Object.assign(this.state.tarefa, { dataFim: dataStr });
                        this.setState({
                          viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                          dataFimState: '',
                        });
                      }}
                    />
                    {this.state.dataFimState === 'has-danger' ? (
                      <label className="error">
                        {this.state.dataFimErro}
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label ${this.state.descricaoState}`}>
                    <label>Descrição *</label>
                    <FormGroup className={`has-label ${this.state.descricaoState}`}>
                      <br />
                      <TextareaAutosize
                        className="autoheight"
                        disabled={this.props.role !== Papel.PROFESSOR.value}
                        value={this.state.tarefa.descricao}
                        onChange={(evt) => {
                          Object.assign(
                            this.state.tarefa, { descricao: evt.target.value },
                          );
                          this.forceUpdate();
                        }} />
                    </FormGroup>
                    {this.state.descricaoState === 'has-danger' ? (
                      <label className="error">
                        Informe a descrição da tarefa
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  {
                    !this.state.tarefa.id
                      ? <Button color="primary"
                        onClick={() => this.cadastrarTarefa()}>
                        Cadastrar
                      </Button>
                      : <Button color="primary"
                        onClick={() => this.atualizarTarefa()}>
                        Atualizar
                      </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroTarefa);
