import React from 'react';

import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AwsUtil from 'utils/AwsUtil';

import AdaptiveTable from 'views/components/AdaptiveTable';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import AnosLetivosService from 'services/AnosLetivosService';

import Papel from 'assets/csv/papeis.json';

class ArquivosAnoLetivo extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-arquivos-ano-letivo-${this.props.idAnoLetivo}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.anosLetivosService = new AnosLetivosService();

    this.fileInputRef = React.createRef();

    this.fileChange = this.fileChange.bind(this);

    this.state = {
      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    await this.carregarArquivosAnoLetivo(this.paginaSelecionada,
      this.linhasPorPagina, this.like);
  }

  async carregarArquivosAnoLetivo(pagina, linhasPorPagina, like) {
    try {
      const data = await this.anosLetivosService
        .carregarArquivosAnoLetivo(this.props.idAnoLetivo, pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.arquivos, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  fileChange(e) {
    this.props.callbackMessage({ showAlert: false });

    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.props.callbackMessage({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({ showLoaderModal: false });
            this.props.callbackMessage({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                showLoaderModal: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.anosLetivosService.salvarArquivoAnoLetivo(
                this.props.idAnoLetivo, arquivo,
              );

              await this.carregarArquivosAnoLetivo(this.paginaSelecionada,
                this.linhasPorPagina, this.like);

              this.setState({ showLoaderModal: false });
              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo salvo com sucesso.',
              });
            } catch (msg) {
              this.setState({ showLoaderModal: false });
              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao salvar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  confirmarRemocaoArquivoAnoLetivo(arquivo) {
    this.setState({ arquivoSelecionado: arquivo, showConfirmModal: true });
  }

  async removerArquivoAnoLetivo() {
    this.setState({ 
			showLoaderModal: true,
			loaderModalText: 'Removendo arquivo...',
		});

    try {
      await this.anosLetivosService
        .removerArquivoAnoLetivo(this.state.arquivoSelecionado.id);

      this.setState({ showLoaderModal: false });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Arquivo removido com sucesso',
      });

      await await this.carregarArquivosAnoLetivo(this.paginaSelecionada,
        this.linhasPorPagina, this.like);
    } catch (msg) {

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover arquivo',
      });
    }
  }

  render() {
    return <>
      <Row>
        <Col>
          <AdaptiveTable
            disablePrintAction
            disableEditAction
            disableRemoveButton={this.props.role !== Papel.GESTOR.value}
            clickRows
            selectedPage={this.paginaSelecionada}
            rowsPerPage={this.linhasPorPagina}
            disableActions={this.props.role !== Papel.GESTOR.value}
            rowsPerPageCallback={(info) => {
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.linhasPorPagina = info.rowsPerPage;
              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
              this.carregarArquivosAnoLetivo(this.paginaSelecionada, info.rowsPerPage, this.like);
            }}
            likeCallback={(text) => {
              this.like = text;
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarArquivosAnoLetivo(this.paginaSelecionada, this.linhasPorPagina, text);
            }}
            paginatorCallback={(page) => {
              this.paginaSelecionada = page;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarArquivosAnoLetivo(this.paginaSelecionada,
                this.linhasPorPagina, this.like);
            }}
            removeButtonCallback={(arquivo) => this.confirmarRemocaoArquivoAnoLetivo(arquivo)}
            rowCallback={(arquivo) => AwsUtil.open(arquivo.url)}
            data={this.state.dadosTabela}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary" disabled={this.state.showLoaderModal}
            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos`)} >
            Voltar
          </Button>
        </Col>
        <Col className="text-right">
          {
            this.props.role === Papel.GESTOR.value
            && <Button
              color="primary"
              disabled={this.state.showLoaderModal}
              onClick={() => this.fileInputRef.current.click()} >
              Inserir arquivo
            </Button>
          }
        </Col>
      </Row>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={this.state.loaderModalText} />
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerArquivoAnoLetivo();
          } else {
            this.setState({ arquivoSelecionado: null });
          }
        }}
        text='Confirme a exclusão do arquivo' />
      <div className="fileinput text-center">
        <input
          type="file"
          onChange={this.fileChange}
          ref={this.fileInputRef} />
      </div>
    </>;
  }
}

export default ArquivosAnoLetivo;
