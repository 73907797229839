import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

import RootFooter from './Footer';

class PageNotFound extends React.Component {
  render = () => <>
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li><NavLink to="/">&nbsp;</NavLink></li>
          </ul>
        </nav>
      </div>
    </header>
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Página não encontrada</h2>
            <ol>
              <li><NavLink to="/">Início</NavLink></li>
              <li>Página não encontrada</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Desculpe, esta página não está disponível</h2>
              <h4>O link que você seguiu pode estar quebrado ou a página pode ter sido removida</h4>
              <br/><br/>
              <img src="https://s3.sa-east-1.amazonaws.com/sisaeduc-public/broken-link.png" alt="Página não encontrada"/>
            </div>
          </div>
        </div>
      </section>
    </main>
    <RootFooter />
  </>;
}

export default withRouter(PageNotFound);
