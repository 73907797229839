import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Quill from 'views/components/Quill';

import Alert from 'views/components/Alert';

import NoticiasService from 'services/NoticiasService';

class Noticia extends React.Component {
  constructor(props) {
    super(props);

    this.noticiasService = new NoticiasService();

    this.state = {
      showAlert: false,
      erro: null,
    };
  }

  async componentDidMount() {
    try {
      const data = await this.noticiasService
        .carregarNoticiaPublica(this.props.match.params.cidade,
          this.props.match.params.idNoticia);

			Object.assign(data.noticia, {
				conteudo: this.adicionarToken(data.noticia.conteudo),
			});

      this.setState({
        noticia: data.noticia,
        cidade: data.cidadeEstado,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

	adicionarToken(conteudoHtml) {
		return conteudoHtml.replace(/(src|href)="([^"]+)"/g, (match, atributo, url) => {
			const novaUrl = url.replace(`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}`, `${env.BACKEND_URL}/resource/arquivonoticia`);
			return `${atributo}="${novaUrl}"`;
		});
	};

  conteudoPagina() {
    return (!this.state.noticia)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className={'noticias'} >
                <div className={'noticia'} >
                  <Row>
                    <Col>
                      <div className={'titulo-noticia'}>
                        <p>{this.state.noticia.titulo}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <div className="social-icons">
                        <a className="whatsapp" target="_blank" alt="Compartilhar no WhatsApp" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=${this.state.noticia.titulo} | ${env.SYSNAME} - ${env.BASE_ADDRESS}/noticias/${this.props.match.params.cidade}/${this.state.noticia.id}`}>
                          <i className="fab fa-whatsapp fa-2x"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="facebook" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${env.BASE_ADDRESS}/noticias/${this.props.match.params.cidade}/${this.state.noticia.id}&src=sdkpreparse`}>
                          <i className="fab fa-facebook fa-2x"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="twitter" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?url=${env.BASE_ADDRESS}/noticias/${this.props.match.params.cidade}/${this.state.noticia.id}`}>
                          <i className="fab fa-twitter fa-2x"></i>
                        </a>
                      </div>
                    </Col>
                    <Col className="text-right">
                      <p className="data">{this.state.noticia.data}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Quill
                        readOnly
                        noBorder
                        value={this.state.noticia.conteudo} />
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.props.history.push(`/noticias/${this.props.match.params.cidade}`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col >
      </Row >;
  }

  render() {
    return (
      <>
        <header id="header" className="fixed-top">
          <div className="container d-flex align-items-center">
            <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li><NavLink to="/">&nbsp;</NavLink></li>
              </ul>
            </nav>
          </div>
        </header>
        <main id="main">
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Notícias</h2>
                <ol>
                  <li><NavLink to="/">Início</NavLink></li>
                  <li><NavLink to={`/noticias/${this.props.match.params.cidade}`}>Notícias {this.state.cidade}</NavLink></li>
                  <li>Notícia</li>
                </ol>
              </div>
            </div>
          </section>
          <div className="container">
            <Alert
              style={{ marginTop: '20px' }}
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
          </div>
          <section className="inner-page">
            <div className="container">
              {!this.state.erro
                ? this.conteudoPagina()
                : <Card>
                  <div align="center" style={{ margin: 50 }}>
                    Erro ao buscar informações da página
                  </div>
                </Card>
              }
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(Noticia);
