import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import NumberFormat from 'react-number-format';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';
import EscolasService from 'services/EscolasService';

import DestinoEstoqueSaida from 'assets/csv/destinos-estoque-saida.json';

class EstoqueSaida extends React.Component {
  constructor(props) {
    super(props);

    this.destinosEstoqueSaida = [
      DestinoEstoqueSaida.TRANSFERENCIA_ESCOLA,
      DestinoEstoqueSaida.CONSUMO_PROPRIO,
    ];

    this.estoquesService = new EstoquesService();
    this.escolasService = new EscolasService();

    this.state = {
      loading: true,

      showAlert: false,
      showLoaderModal: false,

      quantidade: '',
      quantidadeState: '',

      observacoes: '',
      observacoesState: '',
      loaderModalText: '',

      estoqueSaidaSelecionada: null,
      destinoSaidaSelecionado: '',

      erro: null,
    };

  }

  async componentDidMount() {
    try {
      const estoque = await this.estoquesService
        .carregarEstoque(this.props.match.params.idEstoque);

      if (this.props.match.params.idEscola !== undefined) {
        const escola = await this.escolasService.carregarEscola(this.props.match.params.idEscola);
        this.setState({ escola });
      }

      const data = await this.estoquesService
        .carregarEstoqueItens(this.props.match.params.idEstoque, 1, 999999, '');

      data.estoqueItens.forEach((estoqueItem) => Object.assign(estoqueItem, {
        label: estoqueItem.nome,
        value: estoqueItem.id,
      }));

      const estoqueSaida = await this.estoquesService
        .carregarEstoqueSaida(this.props.match.params.idEstoqueSaida);

      this.setState({
        estoque,
				estoqueSaidaSelecionada: estoqueSaida,
        estoqueItens: data.estoqueItens,
        loading: false,
      }, () => this.contentLoad());
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      estoqueItemSelecionado: { value: this.state.estoqueSaidaSelecionada.estoqueItem.id, label: this.state.estoqueSaidaSelecionada.estoqueItem.nome },
      quantidade: this.state.estoqueSaidaSelecionada.quantidade,
      observacoes: this.state.estoqueSaidaSelecionada.observacoes,
      destinoEstoqueSaidaSelecionado: this.destinosEstoqueSaida[this.state.estoqueSaidaSelecionada.destino],
      escolaDestinoSelecionada: this.state.estoqueSaidaSelecionada.escolaDestino ? { value: this.state.estoqueSaidaSelecionada.escolaDestino.id, label: this.state.estoqueSaidaSelecionada.escolaDestino.nome } : undefined,
      autor: this.state.estoqueSaidaSelecionada.vinculo.nomeUsuario,
    });
  }

  voltar() {
    if (this.props.match.params.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`);
    }
  }

  conteudoPagina() {
    return this.state.loading ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        {
          this.props.match.params.idEscola === undefined ? <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques`}>Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueSaidaSelecionada.estoqueItem.nome}</BreadcrumbItem>
          </Breadcrumb> : <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueSaidaSelecionada.estoqueItem.nome}</BreadcrumbItem>
          </Breadcrumb>
        }
        <Form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Dados da saída</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Item do estoque *</label>
                    <Select
                      isDisabled
                      noOptionsMessage={() => 'Nenhuma saida'}
                      className={`react-select primary`}
                      classNamePrefix="react-select"
                      value={this.state.estoqueItemSelecionado}
                      options={this.state.estoqueItens}
                      placeholder="Item do estoque..."
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Quantidade *</label>
                    <Input
                      disabled
                      value={this.state.quantidade}
                      decimalSeparator=","
                      decimalScale={1}
                      tag={NumberFormat} />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Destino da saída *</label>
                    <Select
                      isDisabled
                      noOptionsMessage={() => 'Nenhuma saida'}
                      className={`react-select primary`}
                      classNamePrefix="react-select"
                      value={this.state.destinoEstoqueSaidaSelecionado}
                      options={this.destinosEstoqueSaida}
                      placeholder="Tipo da saída..."
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Data *</label>
                    <Input
                      disabled
                      value={this.state.estoqueSaidaSelecionada.data}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.destinoEstoqueSaidaSelecionado?.value === DestinoEstoqueSaida.TRANSFERENCIA_ESCOLA.value && <Row>
                  <Col md="12">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola destino *</label>
                      <Select
                        isDisabled={this.props.match.params.idEstoqueSaida !== undefined}
                        noOptionsMessage={() => 'Nenhuma tipo'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaDestinoSelecionada}
                        options={this.state.escolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              }
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label`}>
                    <label>Observações</label>
                    <Input
                      disabled
                      value={this.state.observacoes}
                      name="observacoesEstoque"
                      type="textarea"
                      rows="5"
                      onChange={(e) => this.setState({ observacoes: e.target.value, observacoesState: 'has-success' })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label`}>
                    <label>Autor</label>
                    <Input
                      disabled
                      value={this.state.autor}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary" onClick={() => this.voltar()} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(EstoqueSaida);
