import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import moment from 'moment';

import Select from 'react-select';
import InputMask from 'react-input-mask';
import Loader from 'react-loader-spinner';
import { cnpj } from 'cpf-cnpj-validator';
import validator from 'validator';

import Alert from 'views/components/Alert';
import DatePicker from 'views/components/DatePicker';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoEscola from 'assets/csv/situacoes-escola.json';
import ZonaEscola from 'assets/csv/zonas-escola.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Turno from 'assets/csv/turnos.json';

import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

class CadastroEscola extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoEscola.ATIVA,
      SituacaoEscola.INATIVA,
    ];

    this.zonas = [
      ZonaEscola.URBANA,
      ZonaEscola.RURAL,
    ];

    this.selectCiclosEnsinoEscola = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.selectTurnosEscola = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      dataAtoCriacao: '',
      dataAtoAutorizacao: '',

			nome: '',
			codigoINEP: '',
			endereco: '',
			cnpj: '',
			email: '',
			atoCriacao: '',
			atoAutorizacao: '',

      nomeState: '',
      codigoINEPState: '',
      enderecoState: '',
      situacaoState: '',
      zonaState: '',
      situacaoSelecionada: this.situacoes[0],
      zonaSelecionada: '',

      ciclosEnsinoEscola: [],
      turnosEscola: [],

      loaderModalText: '',

      escolaSelecionada: null,
      erro: null,

      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };

    this.cadastrarEscola = this.cadastrarEscola.bind(this);
    this.atualizarEscola = this.atualizarEscola.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

	async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      if (this.props.match.params.idEscola !== undefined) {
        const escola = await this.escolasService
          .carregarEscola(this.props.match.params.idEscola);

        const ciclosEnsinoEscola = await this.escolasService
          .carregarCiclosEnsino(this.props.match.params.idEscola)

        const turnosEscola = await this.escolasService
          .carregarTurnos(this.props.match.params.idEscola)

        this.setState({
          escolaSelecionada: escola,
          ciclosEnsinoEscola,
          turnosEscola,
        }, () => this.contentLoad());
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
			nome: this.state.escolaSelecionada.nome,
			endereco: this.state.escolaSelecionada.endereco,
			codigoINEP: this.state.escolaSelecionada.codigoINEP,
			cnpj: this.state.escolaSelecionada.cnpj,
			email: this.state.escolaSelecionada.email,
			atoCriacao: this.state.escolaSelecionada.atoCriacao,
			atoAutorizacao: this.state.escolaSelecionada.atoAutorizacao,
      situacaoSelecionada: this.situacoes[this.state.escolaSelecionada.situacao],
      zonaSelecionada: this.zonas[this.state.escolaSelecionada.zona],
      dataAtoCriacao: this.state.escolaSelecionada.dataAtoCriacao,
      dataAtoAutorizacao: this.state.escolaSelecionada.dataAtoAutorizacao,
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.codigoINEP === '') {
      this.setState({ codigoINEPState: 'has-danger' });
      ret = false;
    }
    if (this.state.codigoINEP !== ''
      && this.state.codigoINEP.length !== 8) {
      this.setState({ codigoINEPState: 'has-danger' });
      ret = false;
    }
    if (this.state.cnpj !== ''
      && !cnpj.isValid(this.state.cnpj)) {
      this.setState({ cnpjState: 'has-danger' });
      ret = false;
    }
    if (this.state.email !== ''
      && !validator.isEmail(this.state.email)) {
      this.setState({ emailState: 'has-danger' });
      ret = false;
    }
    if (!this.state.situacaoSelecionada) {
      this.setState({ situacaoState: 'danger' });
      ret = false;
    }
    if (!this.state.zonaSelecionada) {
      this.setState({ zonaState: 'danger' });
      ret = false;
    }
    if (this.state.endereco === '') {
      this.setState({ enderecoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarEscola() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando escola...',
    });

    const ciclosEnsino = this.state.ciclosEnsinoEscola.map((cicloEnsinoEscola) => {
      const obj = {};
      obj.cicloEnsino = cicloEnsinoEscola.value;
      return obj;
    });

    const turnos = this.state.turnosEscola.map((turnoEscola) => {
      const obj = {};
      obj.turno = turnoEscola.value;
      return obj;
    });

    const escola = {
      nome: this.state.nome,
      codigoINEP: this.state.codigoINEP,
      email: this.state.email,
      cnpj: this.state.cnpj,
      endereco: this.state.endereco,
      situacao: this.state.situacaoSelecionada.value,
      zona: this.state.zonaSelecionada.value,
      atoCriacao: this.state.atoCriacao,
      atoAutorizacao: this.state.atoAutorizacao,
      dataAtoCriacao: this.state.dataAtoCriacao,
      dataAtoAutorizacao: this.state.dataAtoAutorizacao,
      ciclosEnsino,
      turnos,
    };

    try {
      await this.escolasService.cadastrarEscola(escola);
      this.limparFormulario();
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Escola cadastrada com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar escola',
      });
    }
  }

  async atualizarEscola() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando escola...',
    });

    const ciclosEnsino = this.state.ciclosEnsinoEscola.map((cicloEnsinoEscola) => {
      const obj = {};
      obj.cicloEnsino = cicloEnsinoEscola.value;
      return obj;
    });

    const turnos = this.state.turnosEscola.map((turnoEscola) => {
      const obj = {};
      obj.turno = turnoEscola.value;
      return obj;
    });

    const escola = {
      id: this.state.escolaSelecionada.id,
      nome: this.state.nome,
      codigoINEP: this.state.codigoINEP,
      email: this.state.email,
      cnpj: this.state.cnpj,
      endereco: this.state.endereco,
      situacao: this.state.situacaoSelecionada.value,
      zona: this.state.zonaSelecionada.value,
      atoCriacao: this.state.atoCriacao,
      atoAutorizacao: this.state.atoAutorizacao,
      dataAtoCriacao: this.state.dataAtoCriacao,
      dataAtoAutorizacao: this.state.dataAtoAutorizacao,
      ciclosEnsino,
      turnos,
    };

    try {
      await this.escolasService.atualizarEscola(escola);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Escola atualizada com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar escola',
      });
    }
  }

  limparFormulario() {
    this.state.nome = '';
    this.state.codigoINEP = '';
    this.state.cnpj = '';
    this.state.email = '';
    this.state.endereco = '';
    this.state.atoCriacao = '';
    this.state.atoAutorizacao = '';

    this.setState({
      nomeState: '',
      codigoINEPState: '',
      enderecoState: '',
      situacaoState: '',
      zonaState: '',
      cnpjState: '',
      emailState: '',

      dataAtoCriacao: '',
      dataAtoAutorizacao: '',

      ciclosEnsinoEscola: [],
      turnosEscola: [],

      situacaoSelecionada: this.situacoes[0],
      zonaSelecionada: '',
      showLoaderModal: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.escolaSelecionada) {
      this.cadastrarEscola();
    } else {
      this.atualizarEscola();
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idEscola !== undefined
      && this.state.escolaSelecionada === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/escolas">Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.escolaSelecionada ? this.state.escolaSelecionada.nome : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da escola</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        value={this.state.nome}
                        name="nomeEscola"
                        type="text"
                        maxLength="100"
                        onChange={(e) => this.setState({ 
													nome: e.target.value,
													nomeState: 'has-success' 
												})}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome da escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.codigoINEPState}`}>
                      <label>Código INEP *</label>
                      <Input
                        value={this.state.codigoINEP}
                        name="codigoINEPEscola"
                        type="text"
                        maxLength="8"
                        onChange={(e) => this.setState({ 
													codigoINEP: e.target.value,
													codigoINEPState: 'has-success' 
												})}
                      />
                      {this.state.codigoINEPState === 'has-danger' ? (
                        <label className="error">
                          Informe um código INEP válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.cnpjState}`}>
                      <label>CNPJ</label>
                      <Input
                        value={this.state.cnpj}
                        type="text"
                        mask="99.999.999/9999-99"
                        maskChar=""
                        onChange={(e) => this.setState({ 
													cnpj: e.target.value,
													cnpjState: 'has-success' 
												})}
                        tag={InputMask}
                      />
                      {this.state.cnpjState === 'has-danger' ? (
                        <label className="error">
                          Informe um CNPJ válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação de funcionamento *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoState: '', situacaoSelecionada: event });
                        }}
                        options={this.situacoes}
                        placeholder="Situação..."
                      />
                      {this.state.situacaoState === 'danger' ? (
                        <label className="error">
                          Informe a situação da escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className={`has-label ${this.state.enderecoState}`}>
                      <label>Endereço *</label>
                      <Input
                        value={this.state.endereco}
                        name="enderecoEscola"
                        type="text"
                        maxLength="200"
                        onChange={(e) => this.setState({ 
													endereco: e.target.value,
													enderecoState: 'has-success' 
												})}
                      />
                      {this.state.enderecoState === 'has-danger' ? (
                        <label className="error">
                          Informe o endereço da escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.emailState}`}>
                      <label>Email</label>
                      <Input
                        value={this.state.email}
                        onChange={(e) => this.setState({ 
													email: e.target.value,
													emailState: ''
												})}
                        type="text"
                        maxLength='200'
                      />
                      {this.state.emailState === 'has-danger' && (
                        <label className="error">
                          Informe um email válido.
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Zona *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.zonaState}`}
                        classNamePrefix="react-select"
                        value={this.state.zonaSelecionada}
                        onChange={(event) => {
                          this.setState({ zonaState: '', zonaSelecionada: event });
                        }}
                        options={this.zonas}
                        placeholder="Zona..."
                      />
                      {this.state.zonaState === 'danger' ? (
                        <label className="error">
                          Informe a zona da escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Ato de criação</label>
                      <Input
                        value={this.state.atoCriacao}
												onChange={(e) => this.setState({
													atoCriacao: e.target.value,
												})}
                        type="text"
                        maxLength="100"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Data ato de criação</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataAtoCriacao}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataAtoCriacao: dataStr,
                          });
                        }}
                        clearButtonCallback={() => this.setState({ dataAtoCriacao: '' })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Ato de autorização</label>
                      <Input
                        value={this.state.atoAutorizacao}
												onChange={(e) => this.setState({
													atoAutorizacao: e.target.value
												})}
                        type="text"
                        maxLength="100"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Data ato de autorização</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataAtoAutorizacao}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataAtoAutorizacao: dataStr,
                          });
                        }}
                        clearButtonCallback={() => this.setState({ dataAtoAutorizacao: '' })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className={'has-label'}>
                      <label>Etapas de funcionamento</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={'react-select primary'}
                        classNamePrefix="react-select"
                        placeholder="Etapas de funcionamento..."
                        closeMenuOnSelect={false}
                        isMulti
                        value={this.state.ciclosEnsinoEscola}
                        onChange={(event) => {
                          this.setState({
                            ciclosEnsinoEscola: event || [],
                          });
                        }}
                        options={this.selectCiclosEnsinoEscola}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className={'has-label'}>
                      <label>Turnos de funcionamento</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={'react-select primary'}
                        classNamePrefix="react-select"
                        placeholder="Turnos de funcionamento..."
                        closeMenuOnSelect={false}
                        isMulti
                        value={this.state.turnosEscola}
                        onChange={(event) => {
                          this.setState({
                            turnosEscola: event || [],
                          });
                        }}
                        options={this.selectTurnosEscola}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.showLoaderModal} onClick={() => this.props.history.push('/gestor/escolas')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.escolaSelecionada
                        ? <Button type="submit" color="primary" disabled={this.state.showLoaderModal} >
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary" disabled={this.state.showLoaderModal}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(CadastroEscola);
